import axios from 'axios'

const notifications = {
  namespaced: true,
  state: {
    messages: {},
    notifications: {}
  },
  getters: {
    messages: state => state.messages,
    notifications: state => state.notifications
  },
  mutations: {
    setMessages (state, result) {
      state.messages = result
    },
    setNotifications (state, result) {
      state.notifications = result
    }
  },
  actions: {
    getAllNotifications ({ dispatch }) {
      dispatch('getNotifications')
      dispatch('getMessages')
    },
    getMessages ({ commit, rootGetters }) {

    },
    getNotifications ({ commit, rootGetters }) {

    }
  }
}

export { notifications }
