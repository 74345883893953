<template>
  <div class="mb-5">
    <div class="d-flex justify-content-end align-items-center">
      <pcg-checkbox
          class="pr-4"
          :value="checkboxSemester"
          @input="addSpecialitySemesters">
        <template>
          {{ $t('components.prk_matrices.checkbox') }}
        </template>
      </pcg-checkbox>
      <pcg-btn size="small" class="font-weight-bold mr-0 mb-3" v-b-modal.studyPlanListModal>
        {{ $t('components.prk_matrices.add_study_plan') }}
      </pcg-btn>
    </div>
    <div class="matrix-study-plans-list">
      <prk-table
          :items="items"
          :excluded-fields="EXCLUDED_FIELDS"
          v-if="items.length > 0"
          :show-headline="false"
          :busy="loading"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <template v-slot:table-colgroup="{ fields }">
          <col v-for="field in fields" :key="field.key"
               :style="{ width: field.key !== 'actions' ? '' : '5%' }"
          >
        </template>

        <template v-slot:content="{ header, data }">
          <span v-if="header.key !== 'actions'">
            {{ data.item[header.key] }}
          </span>

          <div v-else class="d-flex justify-content-end align-items-center">
            <pcg-btn
                round variant="danger"
                text="delete"
                class="prk-button"
                :icon="iconRemove"
                @click="destroyStudyPlan(data.item.id)"
            />
            <base-arrow
                :show="data.item._showDetails"
                class="ml-2"
                @click="data.item._showDetails = !data.item._showDetails"
            />
          </div>
        </template>
        <template v-slot:row-details="row">
          <matrices-study-plans-details
              :studyPlanId="row.item.id"
              :semesters="row.item.semesters"
              @add:semester="addSemester"
              @add:all-semesters="addAllSemesters"
              @remove:semester="removeSemester"
              @remove:all-semesters="removeAllSemesters"
          />
        </template>
      </prk-table>
    </div>

    <add-study-plan-modal
        :matrix-id="matrixId"
        :reload="reload"
        :study-plans-ids="this.$_.map(this.studyPlans, 'id')"
        :academicYear="academicYear"
        @studyPlanAdded="studyPlanAdded"
    />

    <base-beam>
      <div class="d-flex align-items-center justify-content-end h-100">
        <pcg-btn variant="additional" class="font-weight-bold" size="small">
          {{ $t('general.save') }}
        </pcg-btn>
        <pcg-btn size="small" class="font-weight-bold">
          {{ $tc('general.generate_syllabuses', 2) }}
        </pcg-btn>
      </div>
    </base-beam>
  </div>
</template>

<script>
import PrkTable from '../PrkTable'
import AddStudyPlanModal from './modals/AddStudyPlanModal'
import useList from '../../composables/useList'
import useIcon from '../../composables/useIcon'
import BaseArrow from '../base_components/BaseArrow'
import MatricesStudyPlansDetails from './MatricesStudyPlansDetails'
import { inject } from '@vue/composition-api'
import { mapGetters } from 'vuex'

export default {
  name: 'MatricesStudyPlan',
  setup (props, { root }) {
    const loading = inject('loading', false)
    const {
      items,
      setItems,
      removeRow,
      removeSwal,
    } = useList(root)
    const { iconRemove } = useIcon(root)
    return {
      loading,
      iconRemove,
      items,
      setItems,
      removeRow,
      removeSwal,
    }
  },
  components: {
    MatricesStudyPlansDetails,
    BaseArrow,
    AddStudyPlanModal,
    PrkTable,
  },
  data () {
    return {
      reload: false,
    }
  },
  props: {
    studyPlans: { type: Array, required: true },
    matrixId: String,
    academicYear: String,
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    checkboxSemester () {
      const semesters = this._getSpecialitySemesters()
      if (semesters.length > 0) {
        return semesters.every(semester => semester.selected)
      } else {
        return false
      }
    },
  },
  watch: {
    contrast () {
      const { iconRemove } = useIcon(this.$root)
      this.iconRemove = iconRemove
    },
    studyPlans (newVal) {
      this.studyPlans.forEach(plan => {
        plan.semesters.sort((a, b) => a.semesterNumber - b.semesterNumber)
      })
      this.setItems(newVal)
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['id', 'text', 'value', 'semesters',
      'semesterIds', 'subjectNameIds', 'semesterNumbers', '_showDetails']
  },
  methods: {
    studyPlanAdded (id, studyPlan) {
      this.$emit('add-study-plan-to-list', { id, ...studyPlan })
      this.$bvModal.hide('studyPlanListModal')
    },
    async destroyStudyPlan (studyPlanId) {
      const response = await this.removeSwal()
      if (response.value) {
        this.$emit('study-plan-removed', studyPlanId)
        this.reload = !this.reload
      }
    },
    addSemester (studyPlanId, semesterId) {
      this.$emit('add:semester', studyPlanId, semesterId)
    },
    addAllSemesters (studyPlanId, semesterIds) {
      const studyPlan = this.studyPlans.find(plan => plan.id === studyPlanId)
      this.$selectAll(studyPlan.semesters)
      this.$emit('add:all-semesters', studyPlanId, semesterIds)
    },
    removeSemester (studyPlanId, semesterId) {
      this.$emit('remove:semester', studyPlanId, semesterId)
    },
    removeAllSemesters (studyPlanId, semesterIds) {
      const studyPlan = this.studyPlans.find(plan => plan.id === studyPlanId)
      this.$unselectAll(studyPlan.semesters)
      this.$emit('remove:all-semesters', studyPlanId, semesterIds)
    },
    addSpecialitySemesters (value) {
      if (value) {
        this._loopSemesters((studyPlan, semester) => {
          semester.selected = true
          this.addSemester(studyPlan.id, semester.id)
        })
      } else {
        this._loopSemesters((studyPlan, semester) => {
          semester.selected = false
          this.removeSemester(studyPlan.id, semester.id)
        })
      }
    },
    _loopSemesters (fun) {
      this.studyPlans.forEach(studyPlan => {
        studyPlan.semesters.forEach(semester => {
          if (semester.speciality !== null || semester.specialization !== null) {
            fun.length === 2 ? fun(studyPlan, semester) : fun(semester)
          }
        })
      })
    },
    _getSpecialitySemesters () {
      const semesters = []
      this._loopSemesters((semester) => {
        semesters.push(semester)
      })
      return semesters
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
