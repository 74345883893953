export default function (root) {
  const makeSyllabusCardUniq = (syllabuses) => {
    if (syllabuses.length === 0) return []
    let clonedSyllabuses = null
    if (syllabuses[0].attributes) {
      clonedSyllabuses = root.$_.cloneDeep(syllabuses.map(syll => ({ id: syll.id, ...syll.attributes })))
    } else {
      clonedSyllabuses = root.$_.cloneDeep(syllabuses)
    }
    const groupedByName = root.$_.groupBy(clonedSyllabuses, 'syllabusCard')

    for (const key in groupedByName) {
      if (groupedByName[key].length === 1) continue
      groupedByName[key].slice(1).forEach((syllabus, index) => {
        const foundedSyllabus = clonedSyllabuses.find(syll => syll.id === syllabus.id)
        foundedSyllabus.syllabusCard = `${syllabus.syllabusCard}_${index + 1}`
      })
    }
    return clonedSyllabuses
  }

  return {
    makeSyllabusCardUniq,
  }
}
