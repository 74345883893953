import { required, requiredUnless } from 'vuelidate/lib/validators'

export const studyPlanFormValidations = {
  validations: {
    fields: {
      name: { value: { required } },
      faculty: { value: { required } },
      courseName: {
        value: {
          required: requiredUnless(function () {
            return this.isSpecialKind
          }),
        },
      },
      academicYear: { value: { required } },
      type: {
        value: {
          required: requiredUnless(function () {
            return this.isSpecialKind
          }),
        },
      },
      kind: {
        value: {
          required: requiredUnless(function () {
            return this.isSpecialKind
          }),
        },
      },
      studyPlanType: {
        value: {
          required: requiredUnless(function () {
            return this.isSpecialKind
          }),
        },
      },
    },
  },
}
