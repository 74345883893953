<template>
  <prk-select
      color="aqua"
      :label="$tc('general.course', 1)"
      :includeBlank="true"
      :options="courseNames"
      :value="$route.query.course_name_id"
      @input="$emit('filter:course', $event)"
  />
</template>

<script>
import PrkSelect from '../PrkSelect'

export default {
  name: 'CourseFilter',
  components: {
    PrkSelect,
  },
  props: {
    courseNames: { required: true, type: Array },
  },
}
</script>
