<template>
  <transition-group
      class="tags-container"
      name="fade"
      tag="p"
  >
    <span v-for="tag in value"
          :key="tag.value"
          class="tag"
          :class="className"
          @click="remove(tag)"
    >
      {{ tag.text }}
      <span v-if="!withoutRemove">
        ✕
      </span>
    </span>
  </transition-group>
</template>

<script>
export default {
  name: 'prk-tag',
  props: {
    withoutRemove: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Array,
      default: () => [],
    },
    className: { default: null },
  },
  methods: {
    remove (removeTag) {
      this.$emit('input', this.value.filter(tag => tag.value !== removeTag.value))
      this.$emit('removed', removeTag.value)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/vars.scss';

.tags-container {
  padding-top: 1.8rem;
}

.tag {
  color: $main-color;
  background-color: $color-active-filter;
  margin-right: 15px;
  margin-bottom: 10px;
  display: inline-block;
  white-space: nowrap;
  font-size: $font-size-s;
  font-weight: 500;
  line-height: 1rem;
  padding: 8px 13px;
  border-radius: 12px;
  //transition: background-color 0.3s;

  &:hover {
    cursor: pointer;
    background-color: darken($color-active-filter, 10%);

    span {
      color: darken($pcg-dark-gray, 10%);
    }
  }

  span {
    color: $pcg-dark-gray;
    padding-left: 10px;
    font-size: 0.9rem;
  }
}

</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .tag {
    color: $main-color;
    background-color: $color-active-filter;

    &:hover {
      background-color: darken($color-active-filter, 10%);

      span {
        color: darken($pcg-dark-gray, 10%);
      }
    }

    span {
      color: $pcg-dark-gray;
    }
  }
}
</style>
