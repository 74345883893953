<template>
  <pcg-modal
      :modal-id="modalId"
      :size="size"
      :btn-title="btnTitle"
      :action="action"
  >
    <template #icon>
      <base-icon width="20" height="20" class="mr-3_5" :iconColor="iconColor">
        <icon-download/>
      </base-icon>
      {{ title }}
    </template>
    <span class="font-weight-bold
                 prk-fz-14
                 pcg-the-darkest-gray-color
                 mb-3
                 d-inline-block"
    >
        {{ description }}
    </span>
    <slot/>
  </pcg-modal>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'PrkDownloadModal',
  props: {
    action: {
      type: Function,
      required: true,
    },
    modalId: {
      required: true,
      type: String,
    },
    title: {
      required: true,
      type: String,
    },
    description: {
      type: String,
    },
    btnTitle: {
      required: true,
      type: String,
    },
    size: {
      type: String,
      default: 'md',
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    iconColor () {
      return this.contrast ? '#008A39' : '#253B80'
    },
  },
}
</script>
