<template>
  <base-button
      text="comments"
      class="mx-2 position-relative prk-button"
      v-b-modal="modalId"
      @click="$emit('input')"
  >
    <base-icon width="19" height="18">
      <icon-comments/>
    </base-icon>
    <div class="comment-count">
      {{ commentCount }}
    </div>
  </base-button>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'prk-comment-modal-button',
  props: {
    commentCount: { type: Number, default: 0 },
    modalId: { required: true, type: String },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
