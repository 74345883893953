import api from './index'

export default {
  searchList (path, params, query) {
    const url = path
    const body = {}
    body.params = params
    body.query = query
    return api.post(url, body)
  },
}
