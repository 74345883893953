<template>
  <span class="item-d prk-fz-12 font-weight-bold" :class="activeColor">
    {{ $t('general.teaching_effects') }}
  </span>
</template>

<script>
export default {
  name: 'StudyPlansTableTermsTeachingEffects',
  props: {
    semester: { required: true, type: Object },
  },
  computed: {
    activeColor () {
      return { 'pcg-main-active-color': this.semester.activeEffects }
    },
  },
}
</script>
