<template>
  <div>
    <prk-table
        :items="items"
        :show-headline="false"
        :excluded-fields="['id', 'effects']"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: field.key === 'name' ? '45%' : '' }"
        >
      </template>

      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'name'" style="line-height: 3.26rem">
          {{ data.item.name }}
        </div>

        <div v-else-if="header.key === 'actions'"
             class="d-flex justify-content-end align-items-center">
          <pcg-btn
              variant="additional" size="small" class="m-0 mr-3"
              @click="setCurrent(data.item)"
              v-b-modal.standardEffectsModal
          >
            <span class="font-weight-bold prk-fz-12">
              {{ $tc('general.standard_effect', 2) }}
            </span>
          </pcg-btn>
          <pcg-btn
              round text="edit" class="mr-3 prk-button" :icon="iconEdit"
              @click="editStandard(data.item.id)"
          />
          <pcg-btn
              round :icon="iconRemove" text="delete"
              class="mr-4 prk-button" variant="danger"
              @click="removeStandard(data.item.id, data.index)"
          />
        </div>
      </template>
    </prk-table>
    <courses-effect-modal :standard-id="standardId" :standard-name="standardName"/>
  </div>
</template>

<script>
import useList from '../../composables/useList'
import PrkTable from '../PrkTable'
import CoursesEffectModal from './modal/CoursesEffectModal'
import useIcon from '../../composables/useIcon'
import { debounce } from 'lodash'
import qs from 'qs'
import { mapGetters } from 'vuex'

export default {
  name: 'DictionariesStandardTable',
  components: {
    CoursesEffectModal,
    PrkTable,
  },
  setup (props, { root }) {
    const { iconEdit, iconRemove } = useIcon(root)
    const { items, setItems, removeRow } = useList(root)
    return {
      items,
      setItems,
      removeRow,
      iconEdit,
      iconRemove,
    }
  },
  data () {
    return {
      currentStandardEffects: null,
      standardId: '',
      standardName: '',
    }
  },
  created () {
    this.getStandards()
    this.$watch('filters', debounce(function () {
      this.getStandards()
    }, 200), {
      deep: true,
    })
  },
  props: {
    filters: Object,
  },
  methods: {
    setCurrent (standard) {
      this.standardId = standard.id
      this.standardName = standard.name
    },
    closeModal () {
      this.$bvModal.hide('directionalEffects')
    },
    editStandard (id) {
      this.$router.push({
        name: 'standard_edit_path',
        params: { id: id },
      })
    },
    async getStandards () {
      const params = {
        filters: this.filters,
      }

      let { data: standards } = await this.$api.get(`standards?${qs.stringify(params)}`)

      standards = standards.map(standard => {
        return {
          id: standard.id,
          name: standard.attributes.name,
          course: standard.attributes.course,
        }
      })
      this.setItems(standards)
    },
    async removeStandard (id, index) {
      const removeHandler = async () => {
        await this.$api.destroy(`standards/${id}`)
      }
      await this.removeRow(index, null, removeHandler)
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  &.pcg-table-container {
    border-radius: 0 0 15px 15px;

    .pcg-table-content {
      border-radius: 0 0 15px 15px;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
