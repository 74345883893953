<template>
  <prk-select
      class="mb-3"
      color="aqua"
      :default-option="defaultOption"
      :label="$t('components.prk_dictionaries.general_effects')"
      :options="generalEffects"
  >
    <template>
      <b-dd-form>
        <div v-for="(effect, index) in generalEffects"
             class="container py-3 pointer"
             :key="index">
          <div class="row align-items-center">
            <div class="col-auto w-0 mr-3">
              <pcg-checkbox
                  v-model="effect.selected"
                  @input="updateGeneralEffectIds"
              />
            </div>
            <div class="col-sm">
              <span class="prk-fz-12 font-weight-bold"
                    :class="color(effect)"
              >
                {{ effect.text }}
              </span>
            </div>
            <div class="col-sm">
              <span class="prk-fz-12 font-weight-bold"
                    :class="color(effect)"
              >
                {{ effect.type.name }}
              </span>
            </div>
            <div class="col-sm">
              <span class="prk-fz-12 font-weight-bold"
                    :class="color(effect)"
              >
                {{ effect.studyProfile.name }}
              </span>
            </div>
          </div>
        </div>
      </b-dd-form>
    </template>
  </prk-select>
</template>

<script>
import PrkSelect from '../../../PrkSelect'
import useDefaultOption from '../../../../composables/useDefaultOption'
import { inject } from '@vue/composition-api'

export default {
  name: 'NewFieldModalGeneralEffects',
  setup (_, { root }) {
    const generalEffectIds = inject('generalEffectIds', [])
    const { getDefaultOption } = useDefaultOption(root, 'selected_effects')
    return { getDefaultOption, generalEffectIds }
  },
  components: {
    PrkSelect,
  },
  jsonapi: {
    generalEffects: {
      config: {
        method: 'get',
        url: 'general_effects?',
        params: {
          serializer: 'simple',
        },
      },
      update (generalEffects) {
        return generalEffects.map(effect => {
          return {
            text: effect.name,
            ...this.$_.omit(effect, 'name'),
            selected: false,
          }
        })
      },
    },
  },
  data () {
    return {
      generalEffects: [],
    }
  },
  computed: {
    defaultOption () {
      return this.getDefaultOption(this.generalEffects)
    },
  },
  watch: {
    generalEffects () {
      this.generalEffects.filter(el => this.generalEffectIds.includes(el.id))
        .forEach(el => (el.selected = true))
    },
  },
  methods: {
    color (effect) {
      return effect.selected ? 'pcg-main-active-color' : 'pcg-gray-color'
    },
    updateGeneralEffectIds () {
      const ids = this.generalEffects.filter(el => el.selected).map(el => el.id)
      this.$emit('set:general-effect-ids', ids)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .dropdown.b-dropdown .b-dropdown-form:focus {
    outline: none !important;
  }
  .dropdown-menu.show {
    width: 100%;
  }
}
</style>
