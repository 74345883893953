<template>
  <div>
    <return path-name="dictionaries_faculties_path">
      {{ $t('components.prk_dictionaries.back_to_faculties') }}
    </return>

    <add-course :add-resource="addCourse" button-name="new_course"/>

    <dictionary-table
        :edit-resource="editResource"
        :destroy-resource="destroyResource"
        :items="items"
        resource-name="courseName"
        url="course_names"
    >
      <template #default="{ item }">
        <show-specialities :router-object="routerObject(item)" button-name="speciality"/>
      </template>
    </dictionary-table>

    <structure-modal
        :action="action(courseName, 'course_names', params)"
        :btn-title="btnTitle"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="courseName"
        @hidden="hideModal('courseName')"
        @clear-error="errors[$event]= ''"
    />

  </div>
</template>

<script>
import Return from '../../components/base_components/Return'
import StructureModal from '../../components/dictionaries/shared/StructureModal'
import DictionaryTable from '../../components/dictionaries/shared/DictionaryTable'
import AddCourse from '../../components/dictionaries/DictionariesAddResourceButton'
import ShowSpecialities from '../../components/dictionaries/DictionariesShowNestedResourcesButton'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import { dictionary } from '../../mixins/dictionary'

export default {
  name: 'DictionaryCourses',
  setup (_, { root }) {
    const { items, setItems, removeRow, findItemById } = useList(root)
    const suffixes = { add: 'add_course', edit: 'edit_course', new: 'new_course' }
    const {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      title,
      updateResource,
    } = useDictionary(root, suffixes)
    return {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      items,
      removeRow,
      setItems,
      title,
      updateResource,
    }
  },
  components: {
    ShowSpecialities,
    AddCourse,
    DictionaryTable,
    StructureModal,
    Return,
  },
  mixins: [dictionary],
  jsonapi: {
    courseNames: {
      config: ({ facultyId }) => ({
        method: 'get',
        url: `course_names?faculty_id=${facultyId}`,
      }),
      variables () {
        return {
          facultyId: this.facultyId,
        }
      },
      update (courseNames) {
        return courseNames.map(courseName => {
          return this.$_.omit(courseName, '__type')
        })
      },
      skip () {
        return !this.facultyId.length
      },
    },
  },
  data () {
    return {
      params: {},
      facultyId: '',
      courseNames: [],
      courseName: {
        name: '',
        shortcut: '',
        [Symbol.for('_innerName')]: 'courseName',
      },
    }
  },
  watch: {
    courseNames () {
      this.setItems(this.courseNames)
    },
  },
  created () {
    this.facultyId = this.$route.params.facultyId
    localStorage.setItem('facultyId', this.facultyId)
    localStorage.setItem('faculty', this.$route.query.faculty)
  },
  mounted () {
    this.link = document.querySelector('[href="/dictionaries/faculties"]')
    this.link.classList.add('active', 'link-active')
  },
  methods: {
    addCourse () {
      this.$bvModal.show('add-modal')
      this.params = { faculty_id: this.facultyId, course_name: this.courseName }
    },

    routerObject (item) {
      return {
        name: 'dictionaries_course_specialities_path',
        params: {
          courseNameId: item.id,
        },
        query: { course: item.name },
      }
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$route.query.faculty,
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  beforeRouteLeave (to, from, next) {
    this.link.classList.remove('active', 'link-active')
    next()
  },
}
</script>
