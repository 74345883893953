<template>
  <div>
    <syllabus-empty-subsection v-if="!filteredCategories.length" :section="section"/>

    <div v-for="(category, index) in filteredCategories" :key="category.id">
      <prk-table
          v-if="env && env.classCard"
          :syllabus-header="true"
          :show-headline-index="false"
          :items="filterEffects(category.id)"
          show-sub-heading
          :excluded-fields="EXCLUDED_FIELDS"
          :show-headline="showHeadline && index === 0"
          :headline-index="headlineIndex"
          :disable-actions="!actions"
          :class-card="env.classCard == 1"
      >
        <template #headline-text>
          <div class="d-flex align-items-center">
            <span>{{ section.header }}</span>
            <comment-modal-button
                modal-id="commentModal"
                :comment-count="section.commentCount"
                @input="setComments(section, 'SyllabusSection')"
            />
          </div>
        </template>

        <template #subheading-text>
          <span class="font-weight-bold mr-5">{{ category.name }}</span>
          <span class="font-weight-normal pcg-gray-color">{{ category.description }}</span>
        </template>

        <template v-slot:table-colgroup="{ fields }">
          <col v-for="field in fields" :key="field.key"
               :style="{ width: colgroupDefinition }">
        </template>

        <template v-slot:content="{ header, data }">
          <div v-if="header.key === 'educationalOutcome'">{{ data.item[header.key] }}</div>
          <div v-if="header.key === 'effectContentObj'">{{ data.item[header.key] }}</div>
          <objective-effects-keks v-if="header.key === 'keks'" :keks="data.item.keks"/>
          <objective-effects-kens v-if="header.key === 'kens'" :kens="data.item.kens"/>
          <pcg-text-input v-if="section.additionalFields && header.key === 'verificationMethod'"
                          :value="data.item.verificationMethod"
                          @input="$emit('update:verificationMethod', $event, data.item.id)"
                          style="position: relative; bottom: .5rem;"
          >
          </pcg-text-input>
        </template>

        <template #cell(actions)="{ item: effect }">
          <objective-effects-actions
              :effect="effect"
              :edit-effect="editEffect"
              :remove-effect="removeEffect"
          />
        </template>
      </prk-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import PrkTable from '../PrkTable'
import ObjectiveEffectsKeks from './objective_effects/ObjectiveEffectsKeks'
import ObjectiveEffectsKens from './objective_effects/ObjectiveEffectsKens'
import ObjectiveEffectsActions from './objective_effects/ObjectiveEffectsActions'
import CommentModalButton from '../PrkCommentModalButton'
import SyllabusEmptySubsection from './SyllabusEmptySubsection'
import useComments from '../../composables/useComments'

export default {
  name: 'objective-effects',
  setup (_, { root }) {
    const { setComments } = useComments(root)
    return { setComments }
  },
  components: {
    ObjectiveEffectsActions,
    ObjectiveEffectsKens,
    ObjectiveEffectsKeks,
    CommentModalButton,
    PrkTable,
    SyllabusEmptySubsection,
  },
  props: {
    categories: Array,
    effects: Array,
    removeEffect: { type: Function, default: () => ({}) },
    editEffect: { type: Function, default: () => ({}) },
    actions: { type: Boolean, default: false },
    showHeadline: { type: Boolean, default: false },
    section: { type: Object, default: () => ({}) },
  },
  computed: {
    ...mapState('syllabusFill', ['hasTeachingEffects']),
    ...mapGetters('environment', ['env']),
    headlineIndex () {
      return this.section?.number
    },
    filteredCategories () {
      const filtered = []
      this.categories.map(category => {
        if (this.filterEffects(category.id).length > 0) {
          filtered.push(category)
        }
      })
      return filtered
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['id', 'categoryId', 'formOfClasses', 'customId',
      'commentCount', 'comments', 'formOfClassId']
  },
  methods: {
    ...mapActions('syllabusFill', ['addForm']),
    colgroupDefinition (key) {
      switch (key) {
        case 'educational_outcome':
          return '15%'
        case 'effect_content_obj':
          return '55%'
        case 'keks':
        case 'kens':
          return '15%'
        case 'verification_method':
          return '15%'
      }
    },
    filterEffects (categoryId) {
      const effectsByCategory = this.effects.filter(effect => effect.categoryId === categoryId)
        .sort((a, b) => a.educationalOutcome.localeCompare(b.educationalOutcome))

      if (this.hasTeachingEffects) {
        return effectsByCategory
      } else {
        return effectsByCategory.map(effect => ({ ...this.$_.omit(effect, 'kens') }))
      }
    },
  },
}
</script>
