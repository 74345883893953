export const courses = {
  namespaced: true,
  state: {
    course: null,
    modified: false,
  },
  mutations: {
    SET_COURSE (state, course) {
      state.course = course
    },
    SET_MODIFIED (state, modified) {
      state.modified = modified
    },
  },
  actions: {
    setCourse ({ commit }, course) {
      commit('SET_COURSE', course)
    },
    setModified ({ commit }, modified) {
      commit('SET_MODIFIED', modified)
    },
  },
}
