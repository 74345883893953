<template>
  <pcg-btn
      v-b-modal.coordinators-group
      variant="additional"
      size="small"
      round
      class="prk-button"
      :text="$tc('general.educator', 2)"
      :icon="iconUser"
      @click="setSyllabusId(syllabusId)"
      ref="iconUser"
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import useIcon from '../../../../../composables/useIcon'

export default {
  name: 'SyllabusEducatorsAdminActions',
  setup (_, { root }) {
    const { iconUser } = useIcon(root)
    return { iconUser }
  },
  props: {
    syllabusId: { required: true, type: String },
  },
  methods: {
    ...mapActions('syllabus', ['setSyllabusId']),
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  mounted () {
    const { iconUser } = useIcon(this.$root)
    this.iconUser = iconUser
  },
  watch: {
    contrast () {
      const { iconUser } = useIcon(this.$root)
      this.iconUser = iconUser
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
