export default function (root) {
  const sortEffects = (effects) => {
    const sortByCategory = []
    const groupedByCategory = root.$_.groupBy(effects, 'categoryName')
    const keys = Object.keys(groupedByCategory).sort().reverse()
    for (const key of keys) {
      sortByCategory.push(...groupedByCategory[key].sort((a, b) => a.symbol.localeCompare(b.symbol, undefined, { numeric: true, sensitivity: 'base' })))
    }
    return sortByCategory
  }

  return {
    sortEffects,
  }
}
