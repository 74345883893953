<template>
  <pcg-modal
      :action="action"
      :btn-title="btnTitle"
      icon="fas fa-plus"
      :modal-id="modalId"
      size="md"
      :title="titleModal"
      @hidden="$emit('hidden')"
  >
    <b-row>
      <b-col v-for="(field, key, index) in properties"
             :key="index" :cols="index !== 2 ? 6 : 12">
        <pcg-text-input
            :class="index === 2 ? 'my-3' : ''"
            :error-text="errors[key]"
            :label="$t(`general.${key}`)"
            :show-error="!!errors[key]"
            :value="field"
            :type="index !== 2 ? 'input' : 'textarea'"
            @input="input(key, $event)"
        />
      </b-col>
    </b-row>
  </pcg-modal>
</template>

<script>
import useModal from '../../../composables/useModal'
import { dictionaryModals } from '../../../mixins/dictionary_modals'

export default {
  name: 'CategoryFormModal',
  setup () {
    const { input } = useModal()
    return { input }
  },
  mixins: [dictionaryModals],
  computed: {
    omittedProperties () {
      return ['id', '_innerName']
    },
    properties () {
      return this.$_.omit(this.$attrs, this.omittedProperties)
    },
  },
}
</script>
