export const subjects = {
  namespaced: true,
  state: {
    allSubjects: [],
    formOfClasses: [],
    formOfCredits: [],

    // new
    newSubject: null,
    editedSubject: null,
    showWarning: false,
  },
  mutations: {
    ADD_SUBJECT (state, subject) {
      state.allSubjects.push(subject)
    },
    SET_NEW_SUBJECT (state, subject) {
      state.newSubject = subject
    },
    ADD_OR_UPDATE (state, subject) {
      const index = state.allSubjects.findIndex(sub => sub.id === subject.id)
      if (!~index) {
        state.allSubjects.push(subject)
      } else {
        this.$app.$set(state.allSubjects, index, subject)
      }
    },
    SET_EDITED_SUBJECT (state, subject) {
      state.editedSubject = subject
    },
    SET_SUBJECTS (state, subjects) {
      state.allSubjects = subjects
    },
    REMOVE_SUBJECT (state, removedSubject) {
      const index = state.allSubjects.findIndex(sub => sub.id === removedSubject.id)
      if (!~index) {
        state.allSubjects.push(removedSubject)
      } else {
        this.$app.$set(state.allSubjects, index, removedSubject)
      }
    },
    SET_FORM_OF_CLASSES (state, formOfClasses) {
      state.formOfClasses = formOfClasses.map(formOfClass => {
        return { value: formOfClass.id, text: formOfClass.attributes.name }
      })
    },
    SET_SHOW_WARNING (state, showWarning) {
      state.showWarning = showWarning
    },
  },
  actions: {
    addSubject ({ commit }, subject) {
      commit('ADD_SUBJECT', subject)
    },
    setNewSubject ({ commit }, subject) {
      commit('SET_NEW_SUBJECT', subject)
    },
    setEditedSubject ({ commit }, subject) {
      commit('SET_EDITED_SUBJECT', subject)
    },
    removeSubject ({ commit }, removedSubject) {
      commit('REMOVE_SUBJECT', removedSubject)
    },
    async getFormOfClasses ({ commit }) {
      const { data: formOfClasses } = await this.$app.$api.get('form_of_classes')
      commit('SET_FORM_OF_CLASSES', formOfClasses)
    },
    setSubjects ({ commit }, subjects) {
      commit('SET_SUBJECTS', subjects)
    },
    addOrUpdate ({ commit }, subject) {
      commit('ADD_OR_UPDATE', subject)
    },
    setShowWarning ({ commit }, showWarning) {
      commit('SET_SHOW_WARNING', showWarning)
    },
  },
}
