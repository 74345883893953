<template>
  <pcg-modal
      :action="closeModal"
      :btn-title="btnTitle"
      :modal-id="modalId"
      :title="titleModal"
      @hidden="$emit('hidden')"
      scrollable
      size="xl"
  >
    <b-row class="pcg-dark-gray-color font-weight-normal mb-4 ml-0">
      <b-col cols="4">
        {{ $t('components.view_missing_effects_modal.symbol') }}
      </b-col>
      <b-col cols="8">
        {{ $t('components.view_missing_effects_modal.description') }}
      </b-col>
    </b-row>
    <b-row v-for="missingKek in missingKeks" :key="missingKek.id" class="pcg-dark-gray-color font-weight-normal mb-4 ml-0">
      <b-col cols="4">
        {{ missingKek.symbol }}
      </b-col>
      <b-col cols="8">
        {{ missingKek.description }}
      </b-col>
    </b-row>

  </pcg-modal>
</template>

<script>
import useModal from '../../../composables/useModal'
import { dictionaryModals } from '../../../mixins/dictionary_modals'

export default {
  name: 'ViewMissingEffectsModal',
  setup () {
    const { defaultOption, input } = useModal()
    return {
      defaultOption,
      input,
    }
  },
  mixins: [dictionaryModals],
  props: {
    missingKeks: Array,
  },
  methods: {
    closeModal () {
      this.$bvModal.hide('missing-effects')
    },
  },
}
</script>
