export default function () {
  const excludedFields = ['selected', 'color', 'effects', '_showDetails', 'studyProfileId', 'typeId']
  const mapGeneralEffects = (generalEffects) => {
    return generalEffects.map(generalEffect => {
      return {
        id: generalEffect.id,
        name: generalEffect.attributes.name,
        shortcut: generalEffect.attributes.shortcut,
        color: generalEffect.attributes.color,
        effects: generalEffect.attributes.effects,
        studyProfileId: generalEffect.attributes.studyProfileId,
        typeId: generalEffect.attributes.typeId,
        _showDetails: false,
        selected: false,
      }
    })
  }

  return {
    excludedFields,
    mapGeneralEffects,
  }
}
