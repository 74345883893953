<template>
  <b-row align-v="center">
    <b-col>
      {{ $t('components.prk_modals.course_effect.symbol') }}
    </b-col>
    <b-col>
      <pcg-text-input
          :error-text="$t('general.errors.required')"
          :show-error="error"
          :show-label="false"
          :value="symbol"
          @input="$emit('update:symbol', $event)"
      />
    </b-col>
  </b-row>
</template>

<script>

import { inject } from '@vue/composition-api'

export default {
  name: 'EffectSymbol',
  setup (_, { root }) {
    let standard = ''
    if (root.$route.query.standard) {
      standard = true
    } else {
      standard = inject('standard')
    }
    const courseNameId = inject('courseNameId')
    const specialityId = inject('specialityId')
    const editMode = inject('editMode', false)
    const courseEffects = inject('courseEffects', [])
    return {
      courseNameId,
      specialityId,
      standard,
      courseEffects,
      editMode,
    }
  },
  props: {
    activeCategory: {
      required: true,
      type: Object,
    },
    categories: {
      required: true,
      type: Array,
    },
    symbol: {
      type: String,
      default: '',
    },
    error: {
      required: true,
      type: Boolean,
    },
  },
  jsonapi: {
    courseName: {
      config: ({ courseNameId }) => ({
        method: 'get',
        url: `course_names/${courseNameId}`,
      }),
      variables () {
        return { courseNameId: this.courseNameId }
      },
      skip () {
        return !this.courseNameId.length
      },
    },
    speciality: {
      config: ({ specialityId }) => ({
        method: 'get',
        url: `specialities/${specialityId}`,
      }),
      variables () {
        return { specialityId: this.specialityId }
      },
      skip () {
        return !this.specialityId?.length
      },
    },
  },
  data () {
    return {
      courseName: null,
      speciality: null,
      shown: false,
    }
  },
  computed: {
    index () {
      return this.courseEffects.filter(ce => ce.categoryId === this.activeCategory.value).length + 1
    },
    categoryShortcut () {
      return this.activeCategory.shortcut
    },
    courseNameShortcut () {
      return this.courseName?.shortcut || this.courseName?.name[0]
    },
    specialityShortcut () {
      return this.speciality?.shortcut || this.speciality?.name[0]
    },
  },
  watch: {
    categoryShortcut () {
      if (!this.shown && this.editMode) {
        this.shown = true
        return
      }
      this.generateSymbol()
    },
  },
  methods: {
    generateSymbol () {
      if (this.standard || !this.categoryShortcut.length) return
      let symbol = ''
      if (this.speciality) {
        symbol = `${this.courseNameShortcut}${this.specialityShortcut}${this.categoryShortcut}${this.index}`
      } else {
        if (this.courseName) {
          symbol = `${this.courseNameShortcut}${this.categoryShortcut}${this.index}`
        } else {
          symbol = `${this.categoryShortcut}${this.index}`
        }
      }
      this.$emit('update:symbol', symbol)
    },
  },
}
</script>
