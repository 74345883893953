<template>
  <div>
    <prk-table
        v-if="items.length > 0"
        :items="items"
        :busy="loading"
        :show-headline="false"
        class="prk-shadow"
        :excluded-fields="['id', 'active', 'effect_type']"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: colgroupDefinition(field.key) }"
        >
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <pcg-spinner variant="small" />
        </div>
      </template>

      <template #cell(description)="{ item: effect }">
        <span class="d-inline-block pr-5">
          {{ effect.description }}
        </span>
      </template>

      <template #cell(actions)="{ item: effect, index }">
        <div class="d-flex justify-content-end">
          <div class="d-flex justify-content-end mr-5">
            <active-switch
                :active.sync="effect.active"
                :update-resource="updateResource"
                :url="url"
                :item="effect"
            />
          </div>
          <pcg-btn round text="edit" class="mr-3 prk-button" :icon="iconEdit"
                   @click="editResource(effect.id, 'effect')"
          />
          <pcg-btn round :icon="iconRemove" text="delete"
                   class="mr-3 prk-button" variant="danger"
                   @click="destroyResource(url, effect.id, index)"
          />
        </div>
      </template>
    </prk-table>

    <effect-form-modal
        :action="action(effect, url)"
        :btn-title="editMode ? $t('general.edit') : $t('general.add')"
        :edit-mode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="editMode ? $t('general.edit') : $t('general.add')"
        v-bind.sync="effect"
        @hidden="hideModal('effect', ['effect_type'])"
        @clear-error="errors[$event] = ''"
    />
  </div>
</template>

<script>
import PrkTable from '../../PrkTable'
import EffectFormModal from '../modal/EffectFormModal'
import ActiveSwitch from '../ActiveSwitch'
import useList from '../../../composables/useList'
import useIcon from '../../../composables/useIcon'
import useDictionary from '../../../composables/useDictionary'
import { dictionary } from '../../../mixins/dictionary'
import { mapGetters } from 'vuex'

export default {
  name: 'EffectTable',
  setup (_, { root }) {
    const {
      items, setItems, removeRow, findItemById,
    } = useList(root)
    const { iconEdit, iconRemove } = useIcon(root)
    const {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      updateResource,
    } = useDictionary(root)
    return {
      items,
      setItems,
      removeRow,
      addResource,
      clearModel,
      destroyResource,
      findItemById,
      iconEdit,
      iconRemove,
      editMode,
      editResource,
      errors,
      updateResource,
    }
  },
  components: {
    EffectFormModal,
    PrkTable,
    ActiveSwitch,
  },
  mixins: [dictionary],
  props: {
    currentRole: String,
    url: { type: String, default: 'effects' },
    innerName: { type: String, default: 'effect' },
    effects: { type: Array, required: true },
    effect_type: { type: String, required: true },
    category: { type: Boolean, default: false },
    loading: { type: Boolean },
  },
  data () {
    return {
      effect: {
        symbol: '',
        description: '',
        range: '',
        effect_type: this.effect_type,
        active: false,
        [Symbol.for('_innerName')]: this.innerName,
      },
    }
  },
  watch: {
    effects (newEffects) {
      this.setItems(newEffects)
    },
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
  methods: {
    colgroupDefinition (key) {
      switch (key) {
        case 'description':
          return '45%'
        case 'range':
          return '15%'
      }
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-table-container {
    border-radius: 15px 15px 0 0;

    .pcg-table-content {
      border-radius: 15px 15px 0 0;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
