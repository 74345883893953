<template>
  <b-col cols="12" lg="auto" class="mt-3 mt-lg-0">
    <div class="d-flex justify-content-center justify-content-sm-end">
      <pcg-btn class="w-100 w-lg-auto m-0" size="small" @click="remove">
          <span class="prk-fz-12">
            {{ this.$t('general.remove_term') }}
          </span>
      </pcg-btn>
    </div>
  </b-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapActions, mapGetters } = createNamespacedHelpers('semesters')

export default {
  name: 'NewSubjectRemoveSemester',
  props: {
    semesterId: { required: true, type: String },
  },
  computed: {
    ...mapGetters(['getNumberOfSemesterWithSameNumber', 'getSemesterIndex']),
  },
  methods: {
    ...mapActions(['removeSemester', 'setSelectedSemester']),
    remove () {
      const amount = this.getNumberOfSemesterWithSameNumber(this.semesterId)
      const index = this.getSemesterIndex(this.semesterId)
      if (amount === 1) return
      this.removeSemester(index)
      this.$store.dispatch('studyPlans/setModified', true)
    },
  },
}
</script>
