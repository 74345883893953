export const error = {
  namespaced: true,
  state: {
    generalError: '',
  },
  mutations: {
    SET_GENERAL_ERROR (state, generalError) {
      state.generalError = generalError
    },
  },
  actions: {
    setGeneralError ({ commit }, generalError) {
      commit('SET_GENERAL_ERROR', generalError)
    },
  },
}
