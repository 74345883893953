<template>
  <div class="dictionaries-fields-list">
    <div class="d-sm-flex justify-content-end">
<!--      <pcg-btn variant="additional" class="font-weight-bold" size="small">-->
<!--        {{ $t('general.print') }}-->
<!--      </pcg-btn>-->
      <pcg-btn variant="additional" size="small"
               @click="$bvModal.show('add-modal')"
               class="font-weight-bold">
        {{ $t('components.prk_dictionaries.new_field') }}
      </pcg-btn>
    </div>

    <prk-table
        v-if="items.length > 0"
        class="prk-shadow mt-4"
        :items="items"
        :excludedFields="['id', 'generalEffectIds', 'dateTo', 'dateFrom']"
        :show-headline="false"
    >
      <template v-slot:head(name)>
        {{ $tc('general.field', 2) }}
      </template>
      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'actions'">
          <div class="d-flex justify-content-end align-items-center">
            <fields-effective-date
                :dateTo.sync="data.item.dateTo"
                :dateFrom.sync="data.item.dateFrom"
                :errors="errors"
                :item="data.item"
                :update-resource="updateResource"
                url="fields"
            />
            <pcg-btn round text="edit" class="mr-3 prk-button" :icon="iconEdit"
                     @click="editResource(data.item.id, 'field')"/>
            <pcg-btn round :icon="iconRemove" text="delete"
                     class="mr-3 prk-button" variant="danger"
                     @click="destroyResource('fields',
                     data.item.id, data.index)"/>
            <pcg-btn
                variant="additional"
                size="small"
                @click="$router.push({ name: 'dictionaries_disciplines_path',
                params: { fieldId: data.item.id, field: data.item.name }})"
                class="m-0"
            >
              <span class="font-weight-bold prk-fz-12">
                {{ $tc('general.discipline', 2) }}
              </span>
            </pcg-btn>
          </div>
        </div>
      </template>
    </prk-table>

    <new-field-modal
        :action="action(field, 'fields')"
        :btn-title="btnTitle"
        :editMode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="field"
        @hidden="hideModal('field')"
        @set:general-effect-ids="addGeneralEffectIds"
        @clear-error="errors[$event] = ''"
    />
    <div class="anchor"/>
  </div>
</template>

<script>
import PrkTable from '../../components/PrkTable'
import NewFieldModal from '../../components/dictionaries/modal/NewFieldModal'
import FieldsEffectiveDate from '../../components/dictionaries/FieldsEffectiveDate'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import useIcon from '../../composables/useIcon'
import { provide, ref, computed } from '@vue/composition-api'
import { dictionary } from '../../mixins/dictionary'
import { mapGetters } from 'vuex'

export default {
  name: 'DictionaryFields',
  setup (_, { root }) {
    const suffixes = { add: 'add_field', edit: 'edit_field', new: 'new_field' }
    const {
      items, setItems, removeRow, findItemById,
    } = useList(root)
    const { iconEdit, iconRemove } = useIcon(root)
    const field = ref({
      name: '',
      generalEffectIds: [],
      [Symbol.for('_innerName')]: 'field',
    })
    const {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      title,
      updateResource,
    } = useDictionary(root, suffixes)
    provide('generalEffectIds', computed(() => field.value.generalEffectIds))
    return {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      field,
      findItemById,
      iconEdit,
      iconRemove,
      items,
      removeRow,
      setItems,
      title,
      updateResource,
    }
  },
  components: {
    FieldsEffectiveDate,
    NewFieldModal,
    PrkTable,
  },
  mixins: [dictionary],
  created () {
    this.getFields()
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
  methods: {
    addGeneralEffectIds (generalEffectIds) {
      this.field.generalEffectIds = generalEffectIds
    },

    async getFields () {
      let { data: fields } = await this.$api.get('fields')
      fields = fields.map(field => {
        return {
          id: field.id,
          ...this.$_.omit(field.attributes, 'disciplines'),
          [Symbol.for('_innerName')]: 'field',
        }
      })
      this.setItems(fields)
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$t('views.header.dictionary.fields'),
      })
    },
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-table-container {
    border-radius: 15px 15px 5px 5px;

    .pcg-table-content {
      border-radius: 15px 15px 5px 5px;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
