const getDefaultState = () => {
  return {
    keu: {
      id: '',
      educationalOutcome: '',
      effectContentObj: '',
      categoryId: '',
      categoryName: '',
      formOfClasses: [],
      formOfCredits: [],
      keks: [],
      kens: [],
    },
    editMode: false,
  }
}

export const courseLearningOutcome = {
  namespaced: true,
  state: getDefaultState(),
  mutations: {
    SET_KEU (state, keu) {
      state.keu = keu
    },
    SET_EDIT_MODE (state, mode) {
      state.editMode = mode
    },
    ADD_KEKS (state, keks) {
      keks.forEach(kek => {
        state.keu.keks.push(kek)
      })
    },
    ADD_KENS (state, kens) {
      kens.forEach(ken => {
        state.keu.kens.push(ken)
      })
    },
    REMOVE_KEK (state, id) {
      const index = state.keu.keks.findIndex(kek => kek.id === id)
      if (!~index) return
      state.keu.keks.splice(index, 1)
    },
    REMOVE_KEN (state, id) {
      const index = state.keu.kens.findIndex(ken => ken.id === id)
      if (!~index) return
      state.keu.kens.splice(index, 1)
    },
    SET_CATEGORY_ID (state, categoryId) {
      state.keu.categoryId = categoryId
    },
    SET_CATEGORY_NAME (state, categoryName) {
      state.keu.categoryName = categoryName
    },
    SET_DESCRIPTION (state, description) {
      state.keu.effectContentObj = description
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    setKeu ({ commit }, keu) {
      commit('SET_KEU', keu)
    },
    setEditMode ({ commit }, mode) {
      commit('SET_EDIT_MODE', mode)
    },
    addKeks ({ commit }, keks) {
      commit('ADD_KEKS', keks)
    },
    addKens ({ commit }, kens) {
      commit('ADD_KENS', kens)
    },
    setCategoryId ({ commit }, categoryId) {
      commit('SET_CATEGORY_ID', categoryId)
    },
    setCategoryName ({ commit }, categoryName) {
      commit('SET_CATEGORY_NAME', categoryName)
    },
    setDescription ({ commit }, description) {
      commit('SET_DESCRIPTION', description)
    },
    removeKek ({ commit }, id) {
      commit('REMOVE_KEK', id)
    },
    removeKen ({ commit }, id) {
      commit('REMOVE_KEN', id)
    },
    resetState ({ commit }) {
      commit('RESET_STATE')
    },
  },
}
