<template>
  <div>
    <prk-download-modal
        :modal-id="modalId"
        :title="$t('components.prk_modals.effects_picker.name')"
        :description="$t('components.prk_modals.effects_picker.description')"
        :btn-title="$t('components.prk_modals.effects_picker.btn_title')"
        :action="validAndSendId"
    >
      <template v-for="select in selects">
        <b-row align-v="center" :key="select" align-h="start" class="mt-4 mb-3">
          <b-col cols="auto">
            {{ $tc(`general.${$_.snakeCase($pluralize(select, 1))}`, 1) }}
          </b-col>
          <b-col cols="auto">
            <prk-select
                color="aqua"
                :options="$data[select]"
                v-model="$data[`${$pluralize(select, 1)}Id`]"
                :include-blank="true"
            />
          </b-col>
        </b-row>
      </template>
      <b-row align-v="center" align-h="start" class="mt-4 mb-3">
        <b-col cols="auto">
          {{ $tc('general.academic_year', 1) }}
        </b-col>
        <b-col cols="auto">
          <prk-select
              color="aqua"
              :options="academicYears"
              v-model="academicYear"
              :include-blank="true"
          />
        </b-col>
      </b-row>
    </prk-download-modal>
    <download-course-effect-list-modal :courses="courses" :action="action"/>
  </div>
</template>

<script>
import PrkDownloadModal from './PrkDownloadModal'
import PrkSelect from '../../PrkSelect'
import useHelpers from '../../../composables/useHelpers'
import { required } from 'vuelidate/lib/validators'
import { getStudyData } from '../../../mixins/getStudyData'
import { inject } from '@vue/composition-api'
import qs from 'qs'
import DownloadCourseEffectListModal from './DownloadCourseEffectListModal'

export default {
  name: 'PreviousYearModal',
  setup (_, { root }) {
    const { generateAcademicYears } = useHelpers(root)
    const defaultFacultyId = inject('facultyId')
    const defaultCourseNameId = inject('courseNameId')
    const defaultTypeId = inject('typeId')
    const defaultSpecialityId = inject('specialityId')
    const defaultStudyProfileId = inject('studyProfileId')

    return {
      generateAcademicYears,
      defaultFacultyId,
      defaultCourseNameId,
      defaultTypeId,
      defaultSpecialityId,
      defaultStudyProfileId,
    }
  },
  validations: {
    modelId: { required },
  },
  components: {
    DownloadCourseEffectListModal,
    PrkSelect,
    PrkDownloadModal,
  },
  mixins: [getStudyData],
  props: {
    modalId: {
      type: String,
      default: 'downloadModal',
    },
    action: {
      type: Function,
      required: true,
    },
  },
  data () {
    return {
      modelId: '',
      models: [],
      academicYears: this.generateAcademicYears(),
      selects: ['faculties', 'courseNames', 'types', 'studyProfiles', 'specialities'],
      facultyId: '',
      courseNameId: '',
      typeId: '',
      specialityId: '',
      studyProfileId: '',
      academicYear: '',
      courses: [],
    }
  },
  watch: {
    defaultFacultyId: {
      immediate: true,
      handler (newVal) {
        this.facultyId = newVal
      },
    },
    defaultCourseNameId: {
      immediate: true,
      handler (newVal) {
        this.courseNameId = newVal
      },
    },
    defaultTypeId: {
      immediate: true,
      handler (newVal) {
        this.typeId = newVal
      },
    },
    defaultStudyProfileId: {
      immediate: true,
      handler (newVal) {
        this.studyProfileId = newVal
      },
    },
    defaultSpecialityId: {
      immediate: true,
      handler (newVal) {
        this.specialityId = newVal
      },
    },
    facultyId: {
      immediate: true,
      handler (newVal) {
        this.getData(null, ['faculties'], { course_names: { faculty_id: newVal } })
      },
    },
  },
  created () {
    this.getData(['faculties'])
    const academicYearsArray = this.$route.query.academic_year.split('/')
    this.academicYear = `${academicYearsArray[0] - 1}/${academicYearsArray[1] - 1}`
  },
  mounted () {
    this.facultyId = this.defaultFacultyId
  },
  methods: {
    async validAndSendId () {
      const params = {
        filters: {
          faculty_id: this.facultyId,
          course_name_id: this.courseNameId,
          type_id: this.typeId,
          study_profile_id: this.studyProfileId,
          speciality_id: this.specialityId,
          academic_year: this.academicYear,
          selected: false,
        },
      }
      const { data: response } = await this.$api.get(`courses/search?${qs.stringify(params)}`)
      if (response.length === 0) {
        return this.$swal({
          title: this.$t('general.course_not_found'),
        })
      }
      this.courses = response.map(course => {
        return {
          id: course.id,
          faculty: course.attributes.faculty,
          courseName: course.attributes.name,
          kind: course.attributes.type,
          studyProfile: course.attributes.studyProfile,
          speciality: course.attributes.speciality,
          academicYear: course.attributes.academicYear,
        }
      })
      this.$bvModal.show('downloadCourseEffectListModal')
    },
  },
}
</script>
