<template>
  <pcg-modal
      modal-id="subjectEffectModal"
      size="xl"
      :title="$t('general.objective_effects')"
      icon="pcg-icon-document"
      hide-footer
      scrollable
  >
    <objective-effects :categories="categories" :effects="effects"/>
  </pcg-modal>
</template>

<script>
import { inject } from '@vue/composition-api'
import ObjectiveEffects from '../ObjectiveEffects'
import { mapState } from 'vuex'

export default {
  name: 'PrkObjectiveEffectsModal',
  setup () {
    const categories = inject('categories')
    return { categories }
  },
  components: {
    ObjectiveEffects,
  },
  props: {
    matrices: { required: true, type: Array },
  },
  computed: {
    ...mapState('syllabus', ['syllabusId']),
    effects () {
      const syllabus = this.matrices.map(matrix => matrix.syllabuses)
        .flat().find(syllabus => syllabus.id === this.syllabusId)
      return syllabus ? syllabus.effects : []
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .next-section {
    .pcg-table-header-I {
      padding-top: 0;
    }
  }
}
</style>
