<template>
  <pcg-modal
      :action="action"
      :btn-title="btnTitle"
      icon="fas fa-plus"
      :modal-id="modalId"
      size="md"
      :title="titleModal"
      @hidden="$emit('hidden')"
  >

    <b-row class="d-flex justify-content-between align-items-center">
      <b-col cols="6" class="mb-3 mb-sm-0">
        <pcg-text-input
            :label="$t('components.prk_modals.add_effect.symbol')"
            :error-text="errors.symbol"
            :show-error="!!errors.symbol"
            :value="$attrs.symbol"
            @input="input('symbol', $event)"
        />
      </b-col>

      <b-col cols="6" class="mb-3 mb-sm-0">
        <prk-main-select
            color="aqua"
            :default-option="defaultOption('categories')"
            :error-text="errors.category"
            :label="$t('components.prk_modals.add_effect.category')"
            :options="categories"
            :show-error="!!errors.category"
            @input="input('categoryId', $event)"
        >
        </prk-main-select>
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <pcg-text-input
            class="mt-4"
            ref="textarea"
            type="textarea"
            :label="$t('components.prk_modals.add_effect.description')"
            :error-text="errors.description"
            :show-error="!!errors.description"
            :value="$attrs.description"
            @input="input('description', $event)"
        />
      </b-col>
    </b-row>
  </pcg-modal>
</template>

<script>
import PrkMainSelect from '../../PrkSelect'
import useModal from '../../../composables/useModal'
import { dictionaryModals } from '../../../mixins/dictionary_modals'

export default {
  name: 'FormEffectModal',
  setup () {
    const { defaultOption, input } = useModal()
    return {
      defaultOption,
      input,
    }
  },
  components: { PrkMainSelect },
  mixins: [dictionaryModals],
  props: {
    editMode: {
      type: Boolean,
      required: true,
    },
    categories: {
      type: Array,
      required: true,
    },
  },
}
</script>
