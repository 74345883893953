export default function () {
  function setErrors (newErrors) {
    const errors = {}
    for (const errorKey in newErrors) {
      errors[errorKey] = newErrors[errorKey][0]
    }
    return errors
  }

  return {
    setErrors,
  }
}
