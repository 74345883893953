export default function (errors = {}) {
  function defaultOption (resourceName) {
    if (this.editMode) {
      // eslint-disable-next-line consistent-this
      const vm = this
      return this[resourceName].find(resource => {
        const key = _resourceIdKey(resourceName, this)
        return resource.value === vm.$attrs[key]
      })
    } else {
      return { text: this.$t('general.choose') }
    }
  }

  function input (prop, event) {
    this.$emit(`update:${prop}`, event)
    if (prop.match(/Id$/)) {
      const resources = _findResources(prop, this)
      const index = resources.findIndex(el => el.value === event)
      const newProp = prop.replace(/Id$/, '')

      this.$emit(`update:${newProp}`, resources[index].text)
      this.$emit('clear-error', newProp)
    }
    if (Object.keys(errors).length > 0) {
      this.$emit('clear-error', prop)
    }
  }

  function _resourceIdKey (resource, vm) {
    return `${vm.$pluralize(resource, 1)}Id`
  }

  const _findResources = (prop, vm) => {
    if (prop === 'syllabusId') {
      return vm.syllabuses
    } else {
      return vm[vm.$pluralize(prop.replace(/Id$/, ''))]
    }
  }

  return {
    defaultOption,
    input,
  }
}
