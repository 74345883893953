import { ref } from '@vue/composition-api'

const lecturers = ref([])

export default function (root) {
  function addLecturer (lecturerId, subjectFormId) {
    const lecturerIndex = lecturers.value.findIndex(lec => lec.id === lecturerId)
    let subjectFormRelationships = lecturers.value[lecturerIndex].relationships.subjectForms.data

    subjectFormRelationships.push({ id: subjectFormId, type: 'subjectForm' })
    subjectFormRelationships = root.$_.unionBy(subjectFormRelationships, 'id')
    lecturers.value[lecturerIndex].relationships.subjectForms.data = subjectFormRelationships

    return lecturers.value[lecturerIndex]
  }

  async function getLecturers () {
    const { data: lecturersData } = await this.$api.get('lecturers')
    return lecturersData.map(lecturer => {
      return {
        id: lecturer.id,
        avatar: 'https://picsum.photos/300/150/?image=42',
        firstName: lecturer.attributes.firstName,
        fullName: lecturer.attributes.fullName,
        lastName: lecturer.attributes.lastName,
        text: lecturer.attributes.fullName,
        value: lecturer.id,
        relationships: lecturer.relationships,
      }
    })
  }

  function getLecturersBySubjectForm (subjectFormId) {
    return lecturers.value.filter(lecturer => {
      return lecturer.relationships.subjectForms.data.map(el => el.id).includes(subjectFormId)
    }).map(lecturer => {
      return { text: lecturer.fullName, value: lecturer.id }
    })
  }

  function removeLecturer (lecturerId, subjectFormId) {
    const lecturerIndex = lecturers.value.findIndex(lec => lec.id === lecturerId)
    const subjectFormRelationships = lecturers.value[lecturerIndex].relationships.subjectForms.data
    const formIndex = subjectFormRelationships.findIndex(el => el.id === subjectFormId)

    subjectFormRelationships.splice(formIndex, 1)
  }

  return {
    addLecturer,
    getLecturers,
    getLecturersBySubjectForm,
    lecturers,
    removeLecturer,
  }
}
