<template>
  <div id="plan-history">
    <h1 class="prk-fz-20">{{ $t('general.study_plan') }}</h1>
    <plan-info v-if="plans.length" :plan="plans[0]"/>
    <div v-for="(value, key, index) in planHistory" :key="index">
      <div class="ml-3">
        <h3> {{ property(key) }}</h3>
        <div class="d-flex justify-content-between prk-color-error prk-background-error box mb-2">
        <span class="prk-fz-12">
          {{ value[0] }}
        </span>
          <span class="font-weight-bold">
          {{ $t('general.previous_version') }}
        </span>
        </div>
        <div class="d-flex justify-content-between prk-color-success prk-background-success box mb-2">
        <span class="prk-fz-12">
          {{ value[1] }}
        </span>
          <span class="font-weight-bold">
          {{ $t('general.current_version') }}
        </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PlanInfo from './PlanInfo'

export default {
  name: 'PlanHistory',
  components: { PlanInfo },
  props: {
    property: { required: true, type: Function },
    studyPlanU10Id: { required: true, type: Number },
  },
  data () {
    return {
      plans: [],
      planHistory: {},
    }
  },
  async created () {
    await this.getPlans()
    this.comparePlans()
  },
  methods: {
    comparePlans () {
      const [firstPlan, secondPlan] = this.comparePlanInit()
      const difference = this.$_.reduce(firstPlan, function (result, value, key) {
        return _.isEqual(value, secondPlan[key])
          ? result : result.concat(key)
      }, [])
      difference.forEach(field => {
        this.$set(this.planHistory, field, [])
        this.planHistory[field].push(this.plans[0][field])
        this.planHistory[field].push(this.plans[1][field])
      })
      this.planHistory = this.$_.omit(this.planHistory, 'updated')
    },
    comparePlanInit () {
      const firstPlan = this.plans[0]
      const secondPlan = this.plans[1]
      return [firstPlan, secondPlan]
    },
    async getPlans () {
      const { data: studyPlans } = await this.$api.get('study_plans', {
        u10_id: this.studyPlanU10Id,
        updated: '0',
        serializer: 'plan_history',
      })
      this.plans = studyPlans.map(plan => plan.attributes).sort((a, b) => {
        return a.updated - b.updated
      })
    },
  },
}
</script>
