<template>
  <div>
    <b-row>
      <b-col sm="12" md="6" lg="4">
        <pcg-search :placeholder="$t('general.search')" v-model="search"/>
      </b-col>
    </b-row>

    <general-effects-buttons
        :collapse-all="collapseAll"
        :expand-all="expandAll"
    />

    <div class="characteristic-effects-list">
      <prk-table
          v-if="generalEffects.length > 0"
          :items="filteredGeneralEffects"
          :show-headline="false"
          :excluded-fields="EXCLUDED_FIELDS"
      >
        <template v-slot:table-colgroup="{ fields }">
          <col v-for="field in fields" :key="field.key"
               :style="{ width: field.key === 'id' ? '5%' : '15%' }"
          >
        </template>

        <template v-slot:head(id)>
          <pcg-checkbox :value="selectedAll" @input="select"/>
        </template>

        <template v-slot:head(details)/>

        <template v-slot:content="{ header, data }">
          <div v-if="header.key === 'id'" class="position-relative">
            <div class="prk-color-status" :style="{'background-color': data.item.color}"
                 v-b-popover.hover.top="data.item.name"
                 :class="data.item.class"
            />
            <pcg-checkbox v-model="data.item.selected"/>
          </div>

          <div v-else-if="header.key === 'actions'">
            <div class="d-flex justify-content-end">
              <div @click="data.item._showDetails = !data.item._showDetails"
                   class="d-flex justify-content-end align-items-center pr-2"
              >
                  <span style="line-height: 1.5rem" class="pointer font-weight-bold"
                        @click="id = data.item.subjectNameId">
                    {{ expandInfo(data.item._showDetails) }}
                  </span>
                <base-arrow :show="data.detailsShowing" class="prk-fz-20"/>
              </div>
              <pcg-btn
                  round variant="danger"
                  text="delete"
                  :icon="iconRemove"
                  class="prk-button"
                  @click="removeGeneralEffect(data.item.id)"
              />
            </div>
          </div>
        </template>
        <template v-slot:row-details="row">
          <div v-if="row.item.effects.length > 0">
            <course-general-effect-effects :effects="row.item.effects"/>
          </div>
        </template>
      </prk-table>
    </div>
    <general-effect-modal
        :excludedGeneralEffectIds="excludedGeneralEffectIds"
        @add:general-effect="generalEffectsAdded($event)"
    />
  </div>
</template>

<script>
import PrkTable from '../PrkTable'
import GeneralEffectModal from '../dictionaries/modal/GeneralEffectModal'
import CourseGeneralEffectEffects from '../courses/CourseGeneralEffectEffects'
import GeneralEffectsButtons from './general_effects/GeneralEffectsButtons'
import useIcon from '../../composables/useIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'GeneralEffects',
  setup (_, { root }) {
    const { iconRemove } = useIcon(root)
    return { iconRemove }
  },
  components: {
    GeneralEffectsButtons,
    CourseGeneralEffectEffects,
    GeneralEffectModal,
    PrkTable,
  },
  props: {
    generalEffects: { type: Array, required: true },
    course: { type: Boolean, default: false },
  },
  data () {
    return {
      search: '',
      tags: [],
      modalId: `effectsPickerModal_${this.$uuidv4()}`,
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    excludedGeneralEffectIds () {
      return this.$_.map(this.generalEffects, 'id')
    },
    selectedGeneralEffects () {
      return this.generalEffects.filter(genEff => genEff.selected)
    },
    filteredGeneralEffects () {
      return this.generalEffects.filter(effect => {
        return effect.name.toLowerCase().includes(this.search.toLowerCase())
      })
    },
    generalEffectIds () {
      return this.selectedGeneralEffects.map(genEff => {
        return {
          id: genEff.id,
          name: genEff.name,
          __type: 'general_effect',
        }
      })
    },
    selectedAll () {
      return this.selectedGeneralEffects.length === this.generalEffects.length
    },
  },
  watch: {
    generalEffectIds () {
      this.$emit('general-effects-was-selected', this.generalEffectIds)
    },
    contrast () {
      const { iconRemove } = useIcon(this.$root)
      this.iconRemove = iconRemove
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['color', 'selected', '_showDetails', 'effects', 'typeId', 'studyProfileId']
  },
  methods: {
    select (selected) {
      selected ? this.$selectAll(this.generalEffects) : this.$unselectAll(this.generalEffects)
    },
    collapseAll () {
      this.generalEffects.forEach(el => (el._showDetails = false))
    },
    expandAll () {
      this.generalEffects.forEach(el => (el._showDetails = true))
    },
    expandInfo (expanded) {
      if (expanded) {
        return this.$t('components.prk_matrices.collapse_effect')
      } else {
        return this.$t('components.prk_matrices.expand_effect')
      }
    },
    async removeGeneralEffect (id) {
      const response = await this.$removeSwal()
      if (response.value) {
        this.$emit('remove:general-effect', id)
      }
    },
    generalEffectsAdded (generalEffects) {
      this.$emit('add:general-effect', generalEffects)
      this.$bvModal.hide('generalEffectModal')
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  .pcg-table-container {
    border-radius: 15px;

    .pcg-table-content {
      border-radius: 15px;
    }
  }

  .tags-container {
    display: flex;
  }

  &.tag-padding {
    .tag {
      padding-right: 30px;
    }
  }

  &.wrap {
    .tags-container {
      flex-wrap: wrap;
    }
  }

  .box-container {
    padding-bottom: 0;
  }

  .box-container.col {
    padding-left: 0 !important;
    padding-bottom: 0;
  }

  .box {
    box-shadow: none !important;
    padding-left: 0 !important;
    background-color: transparent !important;
    padding-bottom: 0 !important;
  }

  .tag {
    background-color: $pcg-lightest-gray !important;
  }

  .dropdown-item::before, .tag::before {
    content: '';
    display: block;
    background-color: $main-active-color;
    width: .571rem;
    height: .571rem;
    border-radius: 50%;
    margin-right: .714rem;
    margin-top: .214rem;
    float: left;
  }

  .right {
    left: auto;
    right: -13px;
  }

  @media only screen and (max-width: 998px) {
    .pcg-slide-tab-btn {
      padding: 0 .86rem !important;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .tag {
    background-color: $pcg-lightest-gray !important;
  }

  .dropdown-item::before, .tag::before {
    background-color: $main-active-color;
  }

  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
