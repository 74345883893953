<template>
  <base-beam>
    <prk-beam desktop-class="justify-content-end"
              mobile-active-class="justify-content-between">
      <template #default>
        <pcg-btn v-if="editMode && syllabus.header.status !== 'accepted'" variant="additional" size="small" @click="$emit('clear')">
          {{ $t('general.clear') }}
        </pcg-btn>
        <div class="d-flex align-items-center">
          <download-pdf-button class="mx-2" :download-pdf="downloadPdf"/>
          <download-doc-button :download-doc="downloadDoc"/>

          <comment-modal-button
              v-if="editMode"
              modal-id="allCommentsModal"
              :comment-count="allCommentCount"
          />

          <pcg-btn
              v-if="editMode && syllabus.header.status !== 'accepted'"
              class="mx-2 prk-button"
              :icon="iconSave"
              round
              text="save"
              @click="saveAction"
          />
        </div>
      </template>

      <template #mobile_beam>
        <pcg-btn v-if="editMode && syllabus.header.status !== 'accepted'" variant="additional" size="small" @click="$emit('clear')">
          {{ $t('general.clear') }}
        </pcg-btn>

        <download-pdf-button class="mr-2" :download-pdf="downloadPdf"/>
        <download-doc-button :download-doc="downloadDoc"/>

        <comment-modal-button
            v-if="editMode"
            modal-id="allCommentsModal"
            :comment-count="allCommentCount"
        />

        <pcg-btn
            v-if="editMode && syllabus.header.status !== 'accepted'"
            class="mr-2 prk-button"
            :icon="iconSave"
            round
            text="save"
            @click="saveAction"
        />
      </template>
    </prk-beam>
  </base-beam>
</template>

<script>
import PrkBeam from './PrkBeam'
import CommentModalButton from '../PrkCommentModalButton'
import DownloadPdfButton from '../DownloadPdfButton'
import { inject } from '@vue/composition-api'
import useIcon from '../../composables/useIcon'
import { pdf } from '../../mixins/syllabus/pdf'
import { doc } from '../../mixins/syllabus/doc'
import DownloadDocButton from '../DownloadDocButton'
import { mapGetters } from 'vuex'

export default {
  name: 'PrkBeamSyllabusesEducator',
  setup (_, { root }) {
    const status = inject('status', 'template')
    const { iconSave } = useIcon(root)
    const editMode = inject('editMode')
    return { iconSave, status, editMode }
  },
  components: {
    DownloadDocButton,
    DownloadPdfButton,
    CommentModalButton,
    PrkBeam,
  },
  mixins: [pdf, doc],
  props: {
    syllabus: { required: true, type: Object },
    saveAction: Function,
  },
  jsonapi: {
    comments: {
      config: ({ syllabusId }) => ({
        method: 'get',
        url: `comments?syllabus_id=${syllabusId}`,
      }),
      fetchPolicy: 'no-cache',
      variables () {
        return {
          syllabusId: this.syllabusId,
        }
      },
    },
  },
  data () {
    return {
      comments: [],
      show_to_fill: true,
      syllabusId: this.$route.params.id,
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    allCommentCount () {
      return this.comments.length + this.$store.state.comments.allCommentCount
    },
  },
  watch: {
    contrast () {
      const { iconSave } = useIcon(this.$root)
      this.iconSave = iconSave
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
