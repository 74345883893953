import { inject } from '@vue/composition-api'

export const syllabusHeaderStatus = {
  setup () {
    const status = inject('status')
    const statusesDataCompleted = inject('statusesDataCompleted')
    return { status, statusesDataCompleted }
  },
  data () {
    return {
      component: null,
    }
  },
  computed: {
    mappedStatus () {
      if (this.statusesDataCompleted.includes(this.status)) {
        return 'to_verification'
      } else {
        return 'to_fill'
      }
    },
  },
  watch: {
    mappedStatus: {
      handler () {
        this.component = this.$_.camelCase(this.mappedStatus) + 'Content'
      },
      immediate: true,
    },
  },
}
