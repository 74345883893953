import Auth from '../../api/auth'
import Cookies from 'js-cookie'

const LOCAL_STORAGE_AUTH_TOKEN_KEY = 'prkAT'
const LOCAL_STORAGE_REDIRECT_TO_KEY = 'prkRT'
const LOCAL_STORAGE_USER_FIRST_NAME_KEY = 'prkFN'
const LOCAL_STORAGE_USER_LAST_NAME_KEY = 'prkLN'
const LOCAL_STORAGE_USER_ROLES_KEY = 'prkRS'
const LOCAL_STORAGE_USER_CURRENT_ROLE_KEY = 'prkCR'
const LOCAL_STORAGE_USER_AVATAR_KEY = 'prkAV'
const LOCAL_STORAGE_USER_ID_KEY = 'prkUID'

const auth = {
  namespaced: true,
  state: {
    token: localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN_KEY) || null,
    redirectTo: JSON.parse(localStorage.getItem(LOCAL_STORAGE_REDIRECT_TO_KEY)) || null,
    firstName: localStorage.getItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY) || null,
    lastName: localStorage.getItem(LOCAL_STORAGE_USER_LAST_NAME_KEY) || null,
    roles: JSON.parse(localStorage.getItem(LOCAL_STORAGE_USER_ROLES_KEY)) || null,
    currentRole: localStorage.getItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY) || null,
    avatar: localStorage.getItem(LOCAL_STORAGE_USER_AVATAR_KEY) || null,
    userId: localStorage.getItem(LOCAL_STORAGE_USER_ID_KEY) || null,
  },
  getters: {
    token: state => state.token,
    signedIn: state => !!state.token,
    getRedirectTo: state => state.redirectTo,
    roles: state => state.roles,
    currentRole: state => state.currentRole,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    fullName: state => `${state.firstName} ${state.lastName}`,
    avatar: state => state.avatar,
    userId: state => state.userId,
    abbr: state => `${state.firstName[0]} ${state.lastName[0]}`.toUpperCase(),
  },
  mutations: {
    setToken (state, token) {
      state.token = token
    },
    setRedirectTo (state, redirectTo) {
      state.redirectTo = redirectTo
    },
    setUser (state, payload) {
      state.roles = payload.roles
      state.firstName = payload.firstName
      state.lastName = payload.lastName
      payload.avatar ? state.avatar = payload.avatar.url : state.avatar = null
      state.userId = payload.userId
    },
    setCurrentRole (state, role) {
      state.currentRole = role
    },
  },
  actions: {
    fetchUser ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        Auth.fetchUser()
          .then(response => {
            localStorage.setItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY, response.data.data.attributes.firstName)
            localStorage.setItem(LOCAL_STORAGE_USER_LAST_NAME_KEY, response.data.data.attributes.lastName)
            localStorage.setItem(LOCAL_STORAGE_USER_ROLES_KEY, JSON.stringify(response.data.data.attributes.roles))
            localStorage.setItem(LOCAL_STORAGE_USER_AVATAR_KEY, response.data.data.attributes.avatar.url)
            localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, response.data.data.id)
            commit('setUser', { ...response.data.data.attributes, userId: response.data.data.id })
            dispatch('setCurrentRole', state.currentRole)
            resolve(response)
          })
          .catch(error => {
            localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_LAST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ROLES_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_AVATAR_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY)
            commit('setToken', null)
            commit('setUser', {})
            reject(error)
          })
      })
    },
    signIn ({ commit, dispatch, state }, params) {
      return new Promise((resolve, reject) => {
        Auth.signIn(params)
          .then(response => {
            const authToken = response.headers.authorization
            localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, authToken)
            localStorage.setItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY, response.data.data.attributes.firstName)
            localStorage.setItem(LOCAL_STORAGE_USER_LAST_NAME_KEY, response.data.data.attributes.lastName)
            localStorage.setItem(LOCAL_STORAGE_USER_ROLES_KEY, JSON.stringify(response.data.data.attributes.roles))
            localStorage.setItem(LOCAL_STORAGE_USER_AVATAR_KEY, response.data.data.attributes.avatar.url)
            localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, response.data.data.id)
            commit('setToken', authToken)
            commit('setUser', { ...response.data.data.attributes, userId: response.data.data.id })
            dispatch('setCurrentRole', state.currentRole)
            Cookies.set('prkOpenIdSessionState', params.session_state)
            resolve(response)
          })
          .catch(error => {
            localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_LAST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ROLES_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_AVATAR_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY)
            commit('setToken', null)
            commit('setUser', {})
            reject(error)
          })
      })
    },
    devSignIn ({ commit, dispatch, state }, payload) {
      return new Promise((resolve, reject) => {
        Auth.devSignIn(payload)
          .then(response => {
            const authToken = response.headers.authorization
            localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN_KEY, authToken)
            localStorage.setItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY, response.data.data.attributes.firstName)
            localStorage.setItem(LOCAL_STORAGE_USER_LAST_NAME_KEY, response.data.data.attributes.lastName)
            localStorage.setItem(LOCAL_STORAGE_USER_ROLES_KEY, JSON.stringify(response.data.data.attributes.roles))
            localStorage.setItem(LOCAL_STORAGE_USER_AVATAR_KEY, response.data.data.attributes.avatar.url)
            localStorage.setItem(LOCAL_STORAGE_USER_ID_KEY, response.data.data.id)
            commit('setToken', authToken)
            commit('setUser', { ...response.data.data.attributes, userId: response.data.data.id })
            dispatch('setCurrentRole', state.currentRole)
            dispatch('user/getCatalogOfferCounters', {}, { root: true })
            dispatch('user/getDictionaryWordsCounters', {}, { root: true })
            resolve(response)
          })
          .catch(error => {
            localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_LAST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ROLES_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_AVATAR_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY)
            commit('setToken', null)
            commit('setUser', {})
            reject(error)
          })
      })
    },
    signOut ({ commit, dispatch }) {
      return new Promise((resolve, reject) => {
        Auth.signOut()
          .then(response => {
            localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_LAST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ROLES_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_AVATAR_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY)
            commit('setToken', null)
            commit('setUser', {})
            Cookies.remove('prkOpenIdSessionState')
            resolve(response)
          })
          .catch(error => {
            localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_FIRST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_LAST_NAME_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ROLES_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_AVATAR_KEY)
            localStorage.removeItem(LOCAL_STORAGE_USER_ID_KEY)
            commit('setToken', null)
            commit('setUser', {})
            reject(error)
          })
      })
    },
    setCurrentRole ({ state, commit, dispatch }, role) {
      if (Array.isArray(state.roles) && state.roles.length > 0) {
        if (state.roles.includes(role)) {
          localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, role)
          commit('setCurrentRole', role)
        } else {
          localStorage.setItem(LOCAL_STORAGE_USER_CURRENT_ROLE_KEY, state.roles[0])
          commit('setCurrentRole', state.roles[0])
        }
      }
    },
    setRedirectTo ({ commit }, payload) {
      commit('setRedirectTo', payload)
      localStorage.setItem(LOCAL_STORAGE_REDIRECT_TO_KEY, JSON.stringify(payload))
    },
    clearRedirectTo ({ commit }) {
      commit('setRedirectTo', null)
      localStorage.removeItem(LOCAL_STORAGE_REDIRECT_TO_KEY)
    },
  },
}

export {
  auth,
}
