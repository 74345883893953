var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$jsonapi.loading)?_c('div',{staticClass:"text-center text-danger py-3 pcg-white-background-color"},[_c('pcg-spinner',{attrs:{"variant":"small"}})],1):_c('prk-table',{attrs:{"items":_vm.studyPlans,"show-headline":false,"excluded-fields":_vm.EXCLUDED_FIELDS,"custom-fields":_vm.CUSTOM_FIELDS},scopedSlots:_vm._u([{key:"head(id)",fn:function(){return [_c('pcg-checkbox',{attrs:{"value":_vm.selectedAll},on:{"input":_vm.selectPlans}})]},proxy:true},{key:"cell(id)",fn:function(ref){
var plan = ref.item;
return [_c('study-plans-table-checkbox',{attrs:{"plan":plan,"select-plan":_vm.selectPlan}})]}},{key:"cell(actions)",fn:function(ref){
var plan = ref.item;
var index = ref.index;
return [_c('study-plans-table-actions',{attrs:{"study-plan":plan},on:{"remove-plan":function($event){return _vm.removePlan(plan, index)}}})]}},{key:"cell(details)",fn:function(ref){
var plan = ref.item;
var detailsShowing = ref.detailsShowing;
return [_c('base-arrow',{staticClass:"ml-2",attrs:{"show":detailsShowing},on:{"click":function($event){return _vm.showDetails(plan)}}})]}},{key:"row-details",fn:function(ref){
var plan = ref.item;
return [_c('study-plans-table-terms',{attrs:{"study-plan":plan},on:{"row-selected":_vm.selectSemester,"row-clicked":function($event){_vm.studyPlan = plan}}})]}}])}),_vm._v(" "),_c('portal',{attrs:{"to":"study-plan-pagination"}},[(_vm.pagination.totalPages)?_c('pcg-pagination',{staticClass:"mt-4 prk-pagination",attrs:{"page-count":_vm.pagination.totalPages,"total-records":_vm.pagination.totalRecords},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e()],1),_vm._v(" "),(_vm.selectedPlanAndTermCount > 0)?_c('prk-beam-study-plan-list',{attrs:{"count":_vm.selectedPlanAndTermCount,"study-plans":_vm.studyPlans},on:{"remove-rows":_vm.removePlans,"selected":function($event){return _vm.$emit('selected', $event)}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }