<template>
  <path fill-rule="evenodd" clip-rule="evenodd"
        d="M8.71823 11.1831V18H11.3849V11.1831H18V8.51647H11.3849V2H8.71823V8.51647H2V11.1831H8.71823Z"/>
</template>

<script>
// width: 19, height: 19
export default {
  name: 'IconPlus',
}
</script>
