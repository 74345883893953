<template>
  <b-col cols="12" lg="auto" class="mt-3 mt-lg-0">
    <div class="d-lg-flex justify-content-end">
      <pcg-btn size="small" @click="openSubjectFormModal" class="m-0 w-100 w-lg-auto">
        <span class="prk-fz-12">
          + {{ $t('general.new_subject') }}
        </span>
      </pcg-btn>
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'NewSubjectAddSubject',
  inject: ['$v'],
  methods: {
    openSubjectFormModal () {
      this.$bvModal.show('add-subject')
      this.$v.$reset()
    },
  },
}
</script>
