<template>
  <div>
    <div class="d-flex justify-content-end mb-4">
      <pcg-btn variant="additional" size="small" v-b-modal.add-modal>
        <span class="font-weight-bold">
          + {{ $t('components.prk_modals.add_effect.teacher') }}
        </span>
      </pcg-btn>
    </div>
    <effect-table :currentRole="currentRole"
                  :effects="teachingEffects"
                  :loading="$jsonapi.loading"
                  inner-name="teaching_effect"
                  effect_type="teaching"
                  url="teaching_effects"
    />
    <pcg-pagination v-if="pagination.totalPages"
                    :page-count="pagination.totalPages"
                    :total-records="pagination.totalRecords"
                    v-model="pagination.currentPage"
                    class="mt-4 prk-pagination"
    />
  </div>
</template>

<script>
import EffectTable from '../../components/dictionaries/shared/EffectTable'
import usePagination from '../../composables/usePagination'
import { app } from '../../index'
import { mapGetters } from 'vuex'

export default {
  name: 'DictionaryTeachingEffects',
  setup (_, { root }) {
    const { pagination, setPagination } = usePagination(root)
    return { pagination, setPagination }
  },
  components: {
    EffectTable,
  },
  jsonapi: {
    teachingEffects: {
      config: ({ page }) => ({
        method: 'get',
        url: 'teaching_effects',
        params: {
          pagination: '1',
          page: page,
        },
      }),
      variables () {
        return {
          page: this.pagination.currentPage,
        }
      },
      update (teachingEffects, requestInfo) {
        if (!this.init) this.setPagination(requestInfo.raw.data)
        return teachingEffects.map(teachingEffect => {
          return {
            ...teachingEffect,
            [Symbol.for('_innerName')]: 'teaching_effect',
          }
        })
      },
    },
  },
  data () {
    return {
      teachingEffects: [],
      init: false,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
  },
  watch: {
    'pagination.currentPage' () {
      this.$jsonapi.queries.teachingEffects.refetch()
    },
  },
  created () {
    const unwatch = this.$watch('teachingEffects', () => {
      this.init = true
      unwatch()
    })
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$t('views.header.dictionary.teaching_effects'),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    const role = localStorage.getItem('prkCR')
    if (role === 'admin' || role === 'employer') {
      next(vm => {
        vm.setHeader()
      })
    } else {
      app.$toastr.e(app.$t('general.forbidden'))
    }
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
