<template>
  <div class="didactic-initial" v-b-modal.lecturers-modal @click="$emit('click')">
    <div v-for="lecturer in lecturers" :key="lecturer.id"
         class="d-flex align-items-center"
    >
    <prk-avatar :avatar="lecturer.avatar" :abbr="lecturer.abbr" hide-background/>
    </div>
  </div>
</template>

<script>

import PrkAvatar from '../../PrkAvatar'
export default {
  name: 'DidacticInitial',
  components: { PrkAvatar },
  props: {
    lecturers: { required: true, type: Array },
  },
  methods: {
    closeModal () {
      this.$bvModal.hide(this.modalId)
      this.modalSaved = true
    },
    setPopoverText (person) {
      switch (person.abbr) {
        case 'AM':
          return `${this.$t('general.responsible_person')}:
                  <br> ${person.fullName}`
        case 'MG':
          return `${this.$t('general.supervisor')}:
                  <br> ${person.fullName}`
        case 'ZP':
          return `${this.$t('general.coordinating_person')}:
                  <br> ${person.fullName}`
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.didactic-initial {
  display: flex;
  justify-content: center;
  background: $pcg-lightest-aqua-blue;
  border-radius: 100px;
  height: 40px;
  cursor: pointer;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .didactic-initial {
    background: $pcg-lightest-aqua-blue;
  }
}
</style>
