<template>
  <pcg-text-input
      :error-text="nameError"
      :label="$t('general.name')"
      :show-error="!!nameError"
      :value="name"
      @input="input('name', $event)"
  />
</template>

<script>
import useModal from '../../../../composables/useModal'

export default {
  name: 'NewMatrixName',
  setup () {
    const { input } = useModal()
    return { input }
  },
  props: {
    name: { required: true, type: String },
    nameError: { required: true, type: String },
  },
}
</script>
