<template>
  <pcg-modal
      :action="createCourse"
      :btn-title="$t('general.save')"
      icon="fas fa-plus"
      modal-id="newCourseModal"
      size="md"
      :title="$t('components.prk_modals.add_course.name')"
      @shown="init"
      @hidden="() => { clearModel(course); $v.$reset() }"
  >

    <b-row>
      <b-col cols="12" class="mt-4">
        <new-course-faculty
            :faculty-error="$v.course.facultyId.$error"
            :faculty.sync="course.facultyId"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" class="mt-4">
        <!--        TODO should be move to another component like in PrkNewMatrixModal. All prk-select-->

        <pcg-search-select
            v-model="course.courseNameId"
            always-active
            :error-text="$t('general.errors.required')"
            :label="$tc('general.course', 1)"
            :options="courseNames"
            :show-error="$v.course.courseNameId.$error"
            show-label
        />
      </b-col>
    </b-row>

    <b-row class="pb-4 mt-3">
      <b-col sm="12" md="6" class="mt-3">
        <prk-main-select
            v-model="course.typeId"
            color="aqua"
            :default-option="defaultOption"
            :error-text="$t('general.errors.required')"
            :label="$t('general.type')"
            :options="types"
            :show-error="$v.course.typeId.$error"
        />
      </b-col>
      <b-col sm="12" md="6" class="mt-3">
        <prk-main-select
            v-model="course.academicYear"
            color="aqua"
            :default-option="academicYears[0]"
            :error-text="$t('general.errors.required')"
            :label="$tc('general.academic_year', 1)"
            :options="academicYears"
            :show-error="$v.course.academicYear.$error"
        />
      </b-col>
      <b-col sm="12" md="6" class="mt-3">
        <prk-main-select
            v-model="course.studyProfileId"
            color="aqua"
            :default-option="defaultOption"
            :label="$t('general.profile')"
            :options="studyProfiles"
            :error-text="$t('general.errors.required')"
            :show-error="$v.course.studyProfileId.$error"
        />
      </b-col>
      <speciality-select
          :course-name-id="course.courseNameId"
          :type-id="course.typeId"
          :speciality.sync="course.specialityId"
      />
    </b-row>

    <template #button-before>
      <pcg-btn variant="additional" @click="$bvModal.hide('newCourseModal')">
        {{ $t('general.cancel') }}
      </pcg-btn>
    </template>
  </pcg-modal>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import PrkMainSelect from '../../PrkSelect'
import NewCourseFaculty from './NewCourseFaculty'
import SpecialitySelect from '../../shared_view/SpecialitySelect'
import useHelpers from '../../../composables/useHelpers'
import { getStudyData } from '../../../mixins/getStudyData'
import { required } from 'vuelidate/lib/validators'

const { mapActions } = createNamespacedHelpers('courses')

export default {
  name: 'PrkNewCourseModal',
  setup (_, { root }) {
    const { generateAcademicYears } = useHelpers(root)
    return { generateAcademicYears }
  },
  components: {
    NewCourseFaculty,
    SpecialitySelect,
    PrkMainSelect,
  },
  validations: {
    course: {
      facultyId: { required },
      courseNameId: { required },
      typeId: { required },
      academicYear: { required },
      studyProfileId: { required },
    },
  },
  props: {
    currentYear: { required: true, type: String },
  },
  mixins: [getStudyData],
  data () {
    return {
      course: {
        facultyId: '',
        courseNameId: '',
        course: '',
        typeId: '',
        studyProfileId: '',
        specialityId: '',
        speciality: '',
        academicYear: '',
      },
      academicYears: [],
      structures: ['faculties', 'course_names', 'types', 'study_profiles', 'specialities'],
      errors: [],
    }
  },
  computed: {
    defaultOption () {
      return { text: this.$t('general.choose') }
    },
  },
  watch: {
    'course.facultyId' (newFacultyId) {
      if (!newFacultyId.length) return

      this.getData(['course_names'], null, {
        course_names: { faculty_id: newFacultyId }
      })

      this.types = []

      this.course.courseNameId = ''
      this.course.typeId = ''
    },
    'course.courseNameId' (newCourseNameId) {
      if (!newCourseNameId.length) return

      this.getData(['types'], null, {
        types: { course_name_id: newCourseNameId }
      })

      this.course.typeId = ''
      this.course.specialityId = ''
    },
    'course.typeId' () {
      this.course.specialityId = ''
    }
  },
  created () {
    this.getData(['faculties', 'study_profiles'])
  },
  methods: {
    ...mapActions(['setCourse']),
    init () {
      this.academicYears = this.generateAcademicYears()
      this.course.academicYear = this.currentYear
    },
    async createCourse () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      try {
        const courseName = this.courseNames.find(course => course.value === this.course.courseNameId).text
        const speciality = this.specialities.find(speciality => speciality.value === this.course.specialityId)?.text || ''
        const faculty = this.faculties.find(faculty => faculty.value === this.course.facultyId).text
        const type = this.types.find(type => type.value === this.course.typeId).text
        const profile = this.studyProfiles.find(profile => profile.value === this.course.studyProfileId)?.text || ''

        this.course = this.$_.omit(this.course, 'courseId')
        this.course.name = courseName
        this.course.speciality = speciality
        this.course.faculty = faculty
        this.course.type = type
        this.course.studyProfile = profile

        const params = {
          academic_year: this.course.academicYear,
          faculty_id: this.course.facultyId,
          type_id: this.course.typeId,
          study_profile_id: this.course.studyProfileId,
          course_name_id: this.course.courseNameId,
          speciality_id: this.course.specialityId,
        }

        const checker = await this.$api.get(`courses/check_uniqueness?${this.$qs.stringify(params)}`)
        if (!checker) {
          await this.setCourse(this.$_.cloneDeep(this.course))
          await this.$router.push({
            name: 'courses_new_path',
            query: { academic_year: this.currentYear },
          })
          this.$bvModal.hide('newCourseModal')
          this.clearModel(this.course)
        } else {
          this.$toastr.e(this.$t('views.courses.course_exist'))
        }
      } catch (error) {
        this.errors = error
      }
    },
  }
  ,
}
</script>
