<template>
  <div>
    <div class="courses-list">
      <prk-list-filter
          filters-type="course"
          @update:filters="refreshFilters"
      />
      <courses-table
          class=" mb-5"
          :filters="filters"
          @update:course-id="courseId = $event"
          @update:current-page="refreshPage($event)"
      />
      <prk-new-course-modal :current-year="filters.academic_year"/>
    </div>

    <portal-target name="course-pagination" />
  </div>
</template>

<script>
import PrkNewCourseModal from '../../components/courses/modal/PrkNewCourseModal'
import PrkListFilter from '../../components/PrkListFilter'
import CoursesTable from '../../components/courses/CoursesTable'
import useFilters from '../../composables/useFilters'

export default {
  name: 'CoursesList',
  setup (_, { root }) {
    const { filters, updateFilters } = useFilters(root)
    return { filters, updateFilters }
  },
  components: {
    CoursesTable,
    PrkListFilter,
    PrkNewCourseModal,
  },
  data () {
    return {
      courseId: '',
      currentPage: 1,
    }
  },
  created () {
    const academicYear = this.$route.query.academic_year || this.filters.academic_year
    const query = this.$route.query
    if (Object.prototype.hasOwnProperty.call(query, 'page')) {
      this.currentPage = query.page
    }
    this.$router.push({
      path: this.$route.path,
      query: { academic_year: academicYear, page: this.currentPage, ...this.$_.omit(this.filters, 'academic_year') },
    }).catch(() => {})
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.courses.title'),
      })
    },
    refreshPage (page) {
      if (this.currentPage !== page) {
        this.currentPage = page
        this.updateFilters(this.currentPage, 'page')
      }
    },
    refreshFilters (value, field) {
      if (field !== 'page') {
        this.currentPage = 1
      }
      this.updateFilters(value, field)
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },

}
</script>
