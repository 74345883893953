<template>
  <div>
    <div class="prk-fz-14 font-weight-bold">
      {{ $t('components.prk_syllabus.literature.basic') }}
    </div>
    <div class="prk-fz-12 font-italic pcg-dark-gray-color">
      <img :src="require('../../../../assets/images/icons/edit-inactive.svg')"
           class="edit-icon"/>
      {{ $t('components.prk_syllabus.section.fill_user') }}
    </div>

    <div class="prk-fz-14 font-weight-bold">
      {{ $t('components.prk_syllabus.literature.supplementary') }}
    </div>
    <div class="prk-fz-12 font-italic pcg-dark-gray-color">
      <img :src="require('../../../../assets/images/icons/edit-inactive.svg')"
           class="edit-icon"/>
      {{ $t('components.prk_syllabus.section.fill_user') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrkLiteratureElement',
}
</script>

<style scoped>
img {
  margin: 0.9rem .5rem 1.7rem 0;
  transform: translateY(5px);
}
</style>
