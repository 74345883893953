import { render, staticRenderFns } from "./AdminActions.vue?vue&type=template&id=ae2a12b0&scoped=true&"
import script from "./AdminActions.vue?vue&type=script&lang=js&"
export * from "./AdminActions.vue?vue&type=script&lang=js&"
import style0 from "./AdminActions.vue?vue&type=style&index=0&id=ae2a12b0&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae2a12b0",
  null
  
)

export default component.exports