import { reactive } from '@vue/composition-api'

export default function (root) {
  const pagination = reactive({
    currentPage: root.$route.query.page ? +root.$route.query.page : 1,
    totalRecords: '',
    totalPages: '',
  })

  const setPagination = (response) => {
    if (!response.meta) return

    const paginationData = response.meta.pagination
    pagination.totalPages = paginationData.pages
    pagination.totalRecords = paginationData.count
  }

  return {
    pagination,
    setPagination,
  }
}
