<template>
  <div>
    <a @click="action">
      <div class="d-flex align-items-center return" v-on="$listeners">
        <i class="pcg-icon-arrow-left mr-3 pcg-main-active-color prk-fz-24"></i>
        <span class="pcg-main-active-color font-weight-bold">
        <slot/>
      </span>
      </div>
    </a>
    <new-changes :path="path" :path-name="pathName" />
  </div>
</template>

<script>
import NewChanges from '../modals/NewChanges'
export default {
  name: 'Return',
  components: {
    NewChanges,
  },
  props: {
    pathName: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
    modifiedForm: {
      type: Boolean,
      default: false,
    },
    query: {
      type: Object,
      default: () => ({}),
    },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    action () {
      if (this.modifiedForm) {
        this.$bvModal.show('new-changes')
      } else {
        this.$router.push({
          name: this.pathName,
          path: this.path,
          query: this.query,
          params: this.params,
        })
      }
    },
  },
}
</script>

<style scoped>
  .return {
    cursor: pointer;
  }

  .router-link {
    text-decoration: none;
  }
</style>
