<template>
  <div class="d-flex">
    <pcg-checkbox
        v-for="(checkbox, index) in checkboxes"
        :key="index"
        class="mr-3"
        :value="setCheckbox(checkbox.text)"
        @input="setRoles(checkbox.text, $event)"
    >
      {{ $t(`general.${checkbox.text}`) }}
    </pcg-checkbox>
  </div>
</template>

<script>
export default {
  name: 'PermissionRoles',
  props: {
    roles: { required: true, type: Array },
  },
  data () {
    return {
      checkboxes: [
        {
          text: 'all',
        },
        {
          text: 'coordinator',
        },
        {
          text: 'responsible_person',
        },
        {
          text: 'supervisor',
        },
      ],
    }
  },
  methods: {
    setRoles (role, state) {
      if (role === 'all') {
        if (state) {
          this.$emit('update:roles', this.checkboxes.map(x => (x.text)).splice(1, 3))
        } else {
          this.$emit('update:roles', [])
        }
      } else {
        if (state) {
          if (!this.roles.includes(role)) {
            const roles = [...this.roles]
            roles.push(role)
            this.$emit('update:roles', roles)
          }
        } else {
          const index = this.roles.indexOf(role)
          const roles = [...this.roles]
          roles.splice(index, 1)
          this.$emit('update:roles', roles)
        }
      }
    },
    setCheckbox (text) {
      if ((this.roles.length === this.checkboxes.length - 1) && text === 'all') return true
      return !!this.roles.includes(text)
    },
  },
}
</script>
