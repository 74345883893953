export const tableCheckbox = {
  props: {
    data: { required: true, type: Object },
    header: { type: Object },
    items: { required: true, type: Array },
    select: { required: true, type: Function },
  },
  data () {
    return {
      state: false,
    }
  },
  methods: {
    selectCheckbox (event) {
      this.state = event
      this.select('', this.args)
      this.$emit('input', event)
    },
  },
}
