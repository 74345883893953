<template>
  <b-row class="mt-4">
    <b-col>
      <label class="pcg-input-label">{{ $t('general.description') }}</label>
      <textarea-autosize
          v-model="description"
          type="textarea"
          :min-height="30"
          class="text-area-autosize form-control pcg-field"
      />
      <div v-if="showError" class="pcg-error-messages">{{ $t('general.errors.required') }}</div>
    </b-col>
  </b-row>
</template>
<script>

export default {
  name: 'description-effect',
  props: {
    showError: Boolean,
  },
  computed: {
    description: {
      set: _.debounce(function (description) {
        this.$store.dispatch('courseLearningOutcome/setDescription', description)
      }, 500),
      get () {
        return this.$store.state.courseLearningOutcome.keu.effectContentObj
      },
    },
  },
}
</script>

<style lang="scss">
</style>
