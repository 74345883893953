export default function (root) {
  const prepareSyllabus = (syllabus) => {
    return {
      id: syllabus.id,
      status: syllabus.header.status,
      ownWork: +syllabus.ownWork,
      workWithTeacher: +syllabus.workWithTeacher,
      syllabusSections: _mapSyllabusSection(syllabus.sections),
      literatures: _mapLiteratures(syllabus.literatures),
      didacticMethods: _mapDidacticMethods(syllabus.didacticMethods),
      subjectEffects: _mapSubjectEffects(syllabus.subjectEffects),
      leaderIds: syllabus.leaders.map(leader => leader.value),
      comments: syllabus.comments,
    }
  }

  const _mapSyllabusSection = (sections) => {
    return sections.map((section, index) => {
      if (index === 0) {
        return {
          id: section.id,
          syllabusElementSubsections: section.elements.map(element => {
            return {
              id: element.id,
              value: element.value,
            }
          }),
          syllabusBasicInformation: {
            id: section.basicInformationId,
            syllabusBasicInformationRows: section.rows,
          },
        }
      } else {
        return {
          id: section.id,
          syllabusElementSubsections: section.elements.map(element => {
            return {
              id: element.id,
              value: element.value,
            }
          }),
        }
      }
    })
  }
  const _mapLiteratures = (literatures) => {
    return literatures.map(literature => {
      if (Object.hasOwnProperty.call(literature, 'id')) {
        return root.$_.pick(literature, ['id'])
      } else {
        return root.$_.omit(literature, ['customId'])
      }
    })
  }
  const _mapSubjectEffects = (subjectEffects) => {
    return subjectEffects.map(effect => {
      return {
        ...root.$_.pick(effect, ['id', 'categoryId']),
        symbol: effect.educationalOutcome,
        description: effect.effectContentObj,
        courseEffectIds: effect.keks.map(kek => kek.id),
        teachingEffectIds: effect.kens.map(ken => ken.id),
        formOfCreditIds: effect.formOfCredits.map(form => form.value),
        formOfClassIds: effect.formOfClasses.map(form => form.value),
        verificationMethod: effect.verificationMethod,
      }
    })
  }
  const _mapDidacticMethods = (didacticMethods) => {
    return didacticMethods.map(method => {
      return {
        ...root.$_.pick(method, ['id', 'formOfClassId', 'text']),
      }
    })
  }

  return {
    prepareSyllabus,
  }
}
