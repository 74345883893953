<template>
  <div class="d-flex justify-content-end">
    <pcg-btn
        round text="edit"
        class="mr-3 prk-button"
        :icon="iconEdit"
        v-b-modal.courseEffectModal
        @click="editCourseEffect(courseEffect)"
    />
    <pcg-btn
        round :icon="iconRemove" text="delete"
        class="mr-4 prk-button" variant="danger"
        @click="removeCourseEffect(courseEffect.customId)"
    />
  </div>
</template>

<script>
import useIcon from '../../../composables/useIcon'
import { mapGetters } from 'vuex'
export default {
  name: 'CourseEffectActions',
  setup (_, { root }) {
    const { iconEdit, iconRemove } = useIcon(root)
    return { iconEdit, iconRemove }
  },
  props: {
    courseEffect: { required: true, type: Object },
  },
  methods: {
    editCourseEffect (courseEffect) {
      this.$emit('update:editMode', true)
      this.$emit('update:editedCourseEffect', this.$_.cloneDeep(courseEffect))
    },
    async removeCourseEffect (id) {
      const response = await this.$removeSwal()
      if (response.value) {
        this.$emit('remove:course-effect', id)
      }
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
