<template>
  <div>
    <return path-name="syllabuses_templates_list_path">
      {{ $t('components.prk_syllabus.return.template_list') }}
    </return>

    <syllabus-header
        :chooseLanguage="true"
        :duringEducation="$t('components.prk_syllabus.header.during_education')"
        :emitData="emitData"
        :language="syllabus.language"
        :show-training-cycle="syllabus.showTrainingCycle"
        :logo.sync="syllabus.logo"
        @headerData="getHeaderData"
        @set-show-training-cycle="setShowTrainingCycle($event)"
    />

    <div class="prk-pcg-syllabus-grid">
      <pcg-text-input
          v-model="syllabus.name"
          :label="$t('general.template_name')"
          :error-text="errors.name"
          :show-error="!!errors.name"
          @focus="errors.name = ''"
      />

      <prk-basic-information
          v-if="loadBasicInformation"
          v-bind.sync="sections[0]"
      />

      <div v-for="(section, index) in filteredSections"
           :key="section.number">
        <prk-basic-section
            v-bind.sync="filteredSections[index]"
            @close-section="closeSection"
        />
      </div>
    </div>

    <div class="d-flex justify-content-center mt-3 mb-5">
      <pcg-btn class="font-weight-bold" @click="addNewSection">
        {{ $t('components.prk_syllabus.section.new_section') }}
      </pcg-btn>
    </div>
    <prk-beam-syllabuses-new
        :add-new-section="addNewSection"
        :syllabus="syllabus"
        :sections="sections"
        @publish="emitData = true"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SyllabusHeader from '../../components/syllabus/PrkMainSyllabusHeader'
import PrkBasicInformation
  from '../../components/syllabus/NewSyllabus/PrkBasicInformation'
import PrkBasicSection
  from '../../components/syllabus/NewSyllabus/PrkBasicSection'
import Return from '../../components/base_components/Return'
import PrkBeamSyllabusesNew from '../../components/beams/PrkBeamSyllabusesNew'
import useSyllabusTemplates from '../../composables/useSyllabusTemplates'
import useModalValidation from '../../composables/useModalValidation'

const { mapActions } = createNamespacedHelpers('syllabusTemplate')

export default {
  name: 'SyllabusForm',
  setup (props, { root }) {
    const {
      addNewSection,
      closeSection,
      getConfig,
      syllabus,
      sections,
      mapSyllabus,
      mapSections,
      mapSubsections,
      mapBasicInformation,
      mapBasicInformationRow,
    } = useSyllabusTemplates(root)
    const { setErrors } = useModalValidation()
    return {
      addNewSection,
      closeSection,
      getConfig,
      syllabus,
      sections,
      mapSyllabus,
      mapSections,
      mapSubsections,
      mapBasicInformation,
      mapBasicInformationRow,
      setErrors,
    }
  },
  components: {
    PrkBeamSyllabusesNew,
    Return,
    PrkBasicSection,
    PrkBasicInformation,
    SyllabusHeader,
  },
  provide: {
    status: 'template',
  },
  data () {
    return {
      emitData: false,
      logo: null,
      errors: {},
      loadBasicInformation: false,
      dataTransferred: false,
    }
  },
  computed: {
    filteredSections () {
      return this.sections.slice(1, this.sections.length)
    },
  },
  watch: {
    dataTransferred () {
      if (this.dataTransferred) this.saveSyllabusTemplate()
    },
  },
  async created () {
    const sections = JSON.parse(localStorage.getItem('sections'))
    const syllabus = JSON.parse(localStorage.getItem('syllabus'))
    const logo = this.$store.state.syllabusTemplate.syllabus.logo || syllabus.logo || {}

    if (sections?.length) {
      this.setSyllabus(syllabus)
      syllabus.logo = logo
      this.syllabus = syllabus
      this.setSections(sections)
      this.sections = sections
      this.syllabus.syllabus_sections = this.sections
    } else if (!sections?.length && this.$route.params.id) {
      const response = await this.$api.get(`syllabuses/${this.$route.params.id}/show_template`)
      this.mapSyllabus(response.data)
      this.mapSections(response.included)
      this.mapSubsections(response.included)
      this.mapBasicInformation(response.included)
      this.mapBasicInformationRow(response.included)
      this.syllabus.syllabus_sections = this.sections
      this.setSyllabus(this.syllabus)
    }
    const date = this.$moment().format('MM DD YYYY, h:mm:ss')
    if (this.$route.name === 'syllabuses_template_copy') {
      this.syllabus.name = `${this.syllabus.name}-kopia ${date}`
    }
    this.loadBasicInformation = true
    if (this.$checkRouteName('syllabuses_new_path')) {
      this.syllabus.syllabus_sections = this.sections
    }
  },
  methods: {
    ...mapActions(['setSections', 'setSyllabus', 'setReturnUrl']),

    clear () {
      this.setSections([])
      this.setSyllabus({})
      this.dataTransferred = false
      this.emitData = false
    },

    async getHeaderData (data) {
      this.syllabus = { ...this.syllabus, ...data }
      this.dataTransferred = true
    },

    initNewSyllabus () {
      let subtitleKey = ''
      if (this.$route.name === 'syllabuses_template_edit') {
        subtitleKey = 'edit_template'
      } else if (this.$route.name === 'syllabuses_template_copy') {
        subtitleKey = 'copy_template'
      } else {
        subtitleKey = 'new_template'
      }
      this.setReturnUrl(this.$route.path)

      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.syllabus.syllabuses_templates'),
        subtitle: this.$t(`views.header.syllabus.${subtitleKey}`),
      })
    },

    setSyllabusBasicInformation () {
      this.syllabus.syllabus_sections[0].syllabusBasicInformation =
          { id: this.syllabus.syllabus_sections[0].basicInformationId }
      this.syllabus.syllabus_sections[0].syllabusBasicInformation
        .syllabusBasicInformationRows = this.syllabus.syllabus_sections[0].rows
    },
    setSubSections () {
      this.syllabus.syllabus_sections.forEach(section => {
        section.syllabus_element_subsections = section.elements
        delete section.elements
      })
    },

    async saveSyllabusTemplate (published) {
      const config = this.getConfig()
      this.setSyllabusBasicInformation()
      this.setSubSections()
      const params = { syllabus: { published, ...this.syllabus } }
      this.$nextTick(async () => {
        try {
          const response = await this.$api[config.action](config.url, params)
          const id = response.id

          if (id && ['image/jpeg', 'image/png'].includes(this.syllabus.logo.type)) {
            const logo = new FormData()
            logo.append('image', this.syllabus.logo)

            await this.$api.post(`syllabuses/set_logo?id=${id}`, logo)
          }

          await this.$router.push({ name: 'syllabuses_templates_list_path' })
          this.errors = {}
        } catch (e) {
          this.errors = this.setErrors(e.errors)
        } finally {
          this.clear()
        }
      })
    },
    setShowTrainingCycle (event) {
      this.syllabus.showTrainingCycle = event
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.initNewSyllabus()
    })
  },
}
</script>
