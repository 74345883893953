<template>
  <div id="semester-history">
    <template v-for="(name, index) in semesterSpecialityNames">
      <h1 class="prk-fz-20" :key="index">
        {{ $tc('general.term') }} {{ semesterNumbers[index] }} {{ getSpecialityName(name) }}
      </h1>
      <div v-for="(value, key, index) in differences[index]" :key="`${index}_${uuidv4()}`">
        <div class="ml-3">
          <h3> {{ property(key) }}</h3>
          <div class="d-flex justify-content-between prk-color-error prk-background-error box mb-2">
            <span class="prk-fz-12">
              {{ value[0] }}
            </span>
            <span class="font-weight-bold">
              {{ $t('general.previous_version') }}
            </span>
          </div>
          <div class="d-flex justify-content-between prk-color-success prk-background-success box mb-2">
            <span class="prk-fz-12">
              {{ value[1] }}
            </span>
            <span class="font-weight-bold">
              {{ $t('general.current_version') }}
            </span>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import useHelpers from '../../../../composables/useHelpers'
export default {
  name: 'SemesterHistory',
  setup () {
    const { uuidv4 } = useHelpers()
    return { uuidv4 }
  },
  props: {
    compareSemestersInit: { required: true, type: Function },
    property: { required: true, type: Function },
    studyPlanId: { required: true, type: String },
  },
  data () {
    return {
      semesters: [],
      semesterHistory: [],
    }
  },
  computed: {
    semesterSpecialityNames () {
      return this.semesterHistory.map(sem => sem.info.specialityName)
    },
    semesterNumbers () {
      return this.semesterHistory.map(sem => sem.info.semesterNumber)
    },
    differences () {
      return this.semesterHistory.map(sem => this.$_.omit(sem, 'info', 'updated'))
    },
  },
  async created () {
    await this.getSemesters()
    this.compareSemesters()
  },
  methods: {
    addDifference (field, index) {
      const specialityName = this.semesters[index * 2].specialityName
      const semesterNumber = this.semesters[index * 2].semesterNumber
      const u10Id = this.semesters[index * 2].u10Id
      const semesterIndex = this.semesterHistory.findIndex(sem => sem.info.u10Id === u10Id)
      const arrayOfDifference = [this.semesters[index * 2][field], this.semesters[index * 2 + 1][field]]

      if (!~semesterIndex) {
        this.semesterHistory.push(
          {
            info: { specialityName, semesterNumber, u10Id },
            [field]: arrayOfDifference,
          },
        )
      } else {
        this.semesterHistory[semesterIndex][field] = arrayOfDifference
      }
    },
    compareSemesters () {
      this.compareSemestersInit(this.semesters).forEach((semPair, index) => {
        const [firstSemester, secondSemester] = semPair
        const difference = this.$_.reduce(firstSemester, function (result, value, key) {
          return _.isEqual(value, secondSemester[key])
            ? result : result.concat(key)
        }, [])
        difference.forEach(field => {
          this.addDifference(field, index)
        })
      })
    },
    getSpecialityName (name) {
      if (name) return `- ${name}`
      else return ''
    },
    async getSemesters () {
      const { data: semesters } = await this.$api.get('semesters', {
        study_plan_id: this.studyPlanId,
        updated: '1',
        serializer: 'semester_history',
      })
      this.semesters = semesters.map(sem => sem.attributes).sort((a, b) => {
        return a.u10Id - b.u10Id || a.updated - b.updated
      })
    },
  },
}
</script>
