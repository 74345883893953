<template>
  <div>
    <div class="d-flex justify-content-end mb-4">
      <pcg-btn variant="additional" size="small" v-b-modal.add-modal>
        <span class="font-weight-bold">
          + {{ $t('general.new_course_effect') }}
        </span>
      </pcg-btn>
    </div>
    <effect-table :effects="courseEffects" effect_type="course" :category="false"/>
  </div>
</template>

<script>
import EffectTable from '../../components/dictionaries/shared/EffectTable'

export default {
  name: 'DictionaryCourseEffects',
  components: {
    EffectTable,
  },
  data () {
    return {
      courseEffects: [],
    }
  },
  methods: {
    async getCourseEffects () {
      const { data: courseEffects } = await this.$api.get('effects', { effect_type: 'course' })
      this.courseEffects = courseEffects.map(effect => {
        return {
          id: effect.id,
          symbol: effect.attributes.symbol,
          description: effect.attributes.description,
          range: effect.attributes.range,
          active: effect.attributes.active,
          [Symbol.for('_innerName')]: 'effect',
        }
      })
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$tc('general.course_effect', 2),
      })
    },
  },
  created () {
    this.getCourseEffects()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>
