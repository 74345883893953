<template>
  <pcg-btn
      :disabled="!semester.hasSubjects"
      v-b-modal.subjectsModal
      class="item-e"
      variant="additional"
      size="small"
      @click="setState"
  >
    <span class="prk-fz-12 font-weight-bold">
      {{ $tc('general.subjects', 2) }}
    </span>
  </pcg-btn>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'StudyPlansTableTermsSubjects',
  props: {
    semester: { required: true, type: Object },
    studyPlan: { required: true, type: Object },
  },
  computed: {
    globalSemester () {
      return this.$store.state.semesters.semester
    },
  },
  watch: {
    'globalSemester.hasSubjects' () {
      if (!this.globalSemester) return
      if (!this.globalSemester.hasSubjects &&
          this.globalSemester.id === this.semester.id) {
        this.$emit('update:has-subjects')
      }
    },
  },
  methods: {
    ...mapActions('studyPlans', ['setPlan']),
    ...mapActions('semesters', ['setSemester']),
    setState () {
      this.setPlan(this.studyPlan)
      this.setSemester(this.semester)
    },
  },
}
</script>
