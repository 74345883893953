export const statuses = [
  {
    text: 'to_fill',
    value: 'to_fill',
    class: 'to_fill',
  },
  {
    text: 'during_fill',
    value: 'during_fill',
    class: 'during_fill',
  },
  {
    text: 'to_verification',
    value: 'to_verification',
    class: 'to_verification',
  },
  {
    text: 'to_improve',
    value: 'to_improve',
    class: 'to_improve',
  },
  {
    text: 'accepted',
    value: 'accepted',
    class: 'accepted',
  },
]
