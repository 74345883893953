export default {
  install: function (Vue) {
    let subItemsType = ''

    const getSubItemsType = (type) => {
      switch (type) {
        case 'study_plan':
          subItemsType = 'semesters'
          break
      }
      return subItemsType
    }

    const selectHandler = (items, value = true) => {
      if (!items || !items.length) return

      subItemsType = getSubItemsType(items[0].__type)
      items.forEach((item) => {
        if ('selected' in item) {
          item.selected = value
        }
        if (subItemsType in item) {
          selectHandler(item[subItemsType], value)
        }
      })
    }
    Vue.prototype.$selectAll = selectHandler
    Vue.prototype.$unselectAll = (items) => selectHandler(items, false)

    Vue.prototype.$removeItem = (items, index) => {
      if (!Number.isInteger(index)) throw new Error('invalid number')
      if (!Array.isArray(items)) throw new Error('collection is not array')
      items.splice(index, 1)
    }

    Vue.prototype.$removeItems = (items, ids) => {
      if (!Array.isArray(items) || !Array.isArray(ids)) throw new Error('collection is not array')
      items.splice(0, items.length, ...items.filter(item => !ids.includes(item.id)))
    }
  },
}
