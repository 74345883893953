<template>
  <b-row align-v="center" class="mt-3 mb-4">
    <b-col cols="auto">
      {{ $t('general.category') }}
    </b-col>
    <b-col cols="auto">
      <prk-select
          color="aqua"
          :default-option="defaultOption"
          :error-text="$t('general.errors.required')"
          :options="categories"
          :show-error="$v.categoryData.id.$error"
          @input="emit($event)"
          @toggle="$v.$reset()"
      />
    </b-col>
  </b-row>
</template>

<script>
import PrkSelect from '../../../PrkSelect'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CourseEffectCategories',
  validations: {
    categoryData: { id: { required } },
  },
  components: {
    PrkSelect,
  },
  props: {
    categoryId: { type: String, default: '' },
    categories: { required: true, type: Array },
    touchCategories: { required: true, type: Boolean },
  },
  data () {
    return {
      categoryData: {
        id: '',
        index: -1,
      },
    }
  },
  computed: {
    defaultOption () {
      const cat = this.categories.find(cat => cat.value === this.categoryId)
      if (cat) return this.$_.omit(cat, 'shortcut')
      else return { text: this.$t('general.choose') }
    },
  },
  watch: {
    categoryId: {
      immediate: true,
      handler () {
        const catIndex = this.categories.findIndex(cat => cat.value === this.categoryId)
        if (~catIndex) {
          this.categoryData.id = this.categories[catIndex].value
          this.categoryData.index = catIndex
          this.$emit('update:category-data', this.categoryData)
        }
      },
    },
    'categoryData.id' () {
      this.categoryData.index = this.categories.findIndex(cat => cat.value === this.categoryData.id)
    },
    touchCategories (newTouch) {
      if (newTouch) this.$v.$touch()
      this.$emit('update:touch-categories', false)
    },
  },
  methods: {
    async emit (id) {
      this.categoryData.id = id
      await this.$nextTick()
      this.$emit('update:category-data', this.categoryData)
    },
  },
}
</script>
