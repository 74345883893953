<template>
  <b-row>
    <b-col>
      <pcg-search-select
          v-model="subjectNameId"
          always-active
          class="my-3"
          :disabled="disabled"
          :error-text="$t('views.study_plans.errors.name_of_subject')"
          :label=" $t('general.name_of_subject')"
          :options="subjectNames"
          :show-error="showError"
          show-label
          @typing="searchSubjectNames"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'SubjectNames',
  props: {
    disabled: { required: true, default: false },
    subjectName: { required: true, type: Object },
    showError: { default: false, type: Boolean },
  },
  data () {
    return {
      subjectNames: [],
    }
  },
  computed: {
    subjectNameId: {
      get () {
        return this.subjectName.id
      },
      set (subjectNameId) {
        const subjectName = this.subjectNames.find(el => el.value === subjectNameId)
        this.$emit('update:name', subjectName.text)
        this.$emit('update:id', subjectName.value)
      },
    },
  },
  async created () {
    const subjectNames = await this.getSubjectNames()
    this.subjectNames = this.mapSubjectNames(subjectNames)

    if (!this.subjectNameId) return
    const subjectName = await this.getSubjectName()
    this.setSubjectName(subjectName)
  },

  methods: {
    async getSubjectName () {
      const url = `subject_names/${this.subjectNameId}`
      const { data: subjectName } = await this.$api.get(url)
      return subjectName
    },

    async getSubjectNames (query = '') {
      const url = `subject_names?query=${query}`
      const { data: subjectNames } = await this.$api.get(url, {
        pagination: 1,
      })
      return subjectNames
    },

    mapSubjectNames (subjectNames) {
      return subjectNames.map(subjectName => {
        return {
          text: subjectName.attributes.name,
          value: subjectName.id,
        }
      })
    },

    setSubjectName (subjectName) {
      this.subjectNames.push({
        value: subjectName.id,
        text: subjectName.attributes.name,
      })
      this.subjectNames = this.$_.unionBy(this.subjectNames, 'value')
      this.subjectNameId = subjectName.id
    },

    searchSubjectNames: _.debounce(async function (query) {
      const subjectNames = await this.getSubjectNames(query)
      this.subjectNames = this.mapSubjectNames(subjectNames)
    }, 500),
  },
}
</script>
