export const fillStatus = {
  computed: {
    fillContent () {
      return ['to_improve', 'during_fill', 'to_fill'].includes(this.status)
    },
  },
}

export const showStatus = {
  computed: {
    showContent () {
      return ['to_verification', 'to_improve'].includes(this.status)
    },
  },
}
