<template>
  <div class="position-relative">
    <div v-if="plan.name === planName"
         class="prk-color-status success"
         v-b-popover.hover.top="$t('general.new_plan')"
    />
    <div v-if="isUpdatedPlan"
         class="prk-color-status to_fill"
    />
    <pcg-checkbox :value="plan.selected" @input="select" />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapState } = createNamespacedHelpers('studyPlans')
export default {
  name: 'StudyPlansTableCheckbox',
  props: {
    plan: { required: true, type: Object },
    selectPlan: { required: true, type: Function },
  },
  computed: {
    ...mapState(['planName', 'updatedPlans']),
    isUpdatedPlan () {
      return this.updatedPlans.map(plan => plan.u10Id).includes(this.plan.u10Id)
    },
  },
  methods: {
    select (selected) {
      this.selectPlan(this.plan, selected)
      this.$emit('update:selected', selected)
    },
  },
}
</script>
