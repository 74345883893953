<template>
  <div class="matrices">
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Matrices',
}
</script>
