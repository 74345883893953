<template>
  <div class="syllabuses">
    <transition name="slide" mode="out-in">
      <router-view @rerender="forceRerender" :key="componentKey"/>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Syllabuses',
  data () {
    return {
      componentKey: 0,
    }
  },
  methods: {
    forceRerender () {
      this.componentKey += 1
    },
  },
}
</script>

<style scoped>

</style>
