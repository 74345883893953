<template>
  <fragment>
    <div v-for="(section, index) in sections" :key="section.id" class="mb-5">

      <div v-if="section.number % 1 === 0 && section.visible && section.number !== '1.0'"
           class="d-flex align-items-center pcg-main-color prk-fz-24 mb-4 font-weight-bold" style="margin-left: 2.14rem;">
        {{ section.header }}
        <comment-modal-button
            modal-id="commentModal"
            :comment-count="section.commentCount"
            @input="setComments(section, 'SyllabusSection')"
        />
      </div>

      <div class="prk-shadow">
        <component v-if="section.component !== 'empty-section'"
                   :is="section.component"
                   :section="section"
                   :index="(index).toString()"
                   :user="{ id: userId, avatar: avatar}"
        />
        <div class="to-fill-content" v-if="section.elements.length > 0">
          <syllabus-box>
            <template #content>
              <prk-horizontal-line :width="{ value: -30, unit: 'px' }"/>
              <!--      TODO  TU  TRZEBA ZMIENIC NA  VUEX -->
              <to-fill-content v-if="fillContent && editMode" :section="section"/>
              <to-show-content v-else :section="section"/>
            </template>
          </syllabus-box>
        </div>
      </div>

      <div v-if="section.description"
           class="mb-5 mt-2 description pcg-the-darkest-gray-color">
        <span v-html="section.description"/>
      </div>
    </div>

    <syllabus-comments :sections="sections"/>

  </fragment>
</template>

<script>
import SubjectInformation from './subject_information/PrkSubjectInformation'
import EducationGoals from './education_goals/PrkEducationGoals'
import SyllabusBox from '../PrkSyllabusBox'
import PrkHorizontalLine from '../PrkHorizontalLine'
import ToFillContent from '../syllabus_box/ToFillContent'
import EmptySubsection from '../SyllabusEmptySubsection'
import AssessmentMethods from './assessment_methods/PrkAssessmentMethods'
import StudentWorkload from './student_workload/PrkStudentWorkload'
import Literature from './literature/Literature'
import ToShowContent from './ToShowContent'
import SyllabusComments from './SyllabusComments'
import CommentModalButton from '../../PrkCommentModalButton'
import useComments from '../../../composables/useComments'
import { inject } from '@vue/composition-api'
import { mapState } from 'vuex'
import { fillStatus } from '../../../mixins/syllabus/status'

export default {
  name: 'syllabus-sections',
  setup (props, { root }) {
    const status = inject('status')
    const editMode = inject('editMode', true)
    const { setComments } = useComments(root, props)
    return {
      status,
      editMode,
      setComments,
    }
  },
  components: {
    SyllabusComments,
    ToShowContent,
    ToFillContent,
    PrkHorizontalLine,
    SyllabusBox,
    SubjectInformation,
    EducationGoals,
    EmptySubsection,
    AssessmentMethods,
    StudentWorkload,
    Literature,
    CommentModalButton,
  },
  mixins: [fillStatus],
  props: {
    sections: { required: true, type: Array },
  },
  provide () {
    return {
      userId: this.userId,
    }
  },
  computed: {
    ...mapState('auth', ['avatar', 'userId']),
  },
}
</script>
