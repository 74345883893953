<template>
  <div>
    <div class="d-flex justify-content-end mb-4">
      <pcg-btn variant="additional" size="small" v-b-modal.add-modal>
        <span class="font-weight-bold">
          + {{ $t('general.new_standard_effect') }}
        </span>
      </pcg-btn>
    </div>
    <b-row align-v="center" align-h="end" class="mt-3 mb-4 mr-0">
      <div class="pcg-slide-tabs">
        <button
            v-for="(category, index) in categories"
            ref="categoriesRef"
            :key="index"
            class="pcg-slide-tab-btn"
            @click="changeCategory(index, $event,{ effectType: 'standard' })"
        >
          {{ category.text }}
        </button>
      </div>
    </b-row>

    <prk-table
        :show-headline="false"
        :items="items"
        :excluded-fields="excludedFields"
        v-if="items.length > 0"
        :fields-props="{ tdClass: tdClass }"
        class="mt-3 directional-effects prk-shadow"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: field.key === 'description' ? '55%' : '' }"
        >
      </template>

      <template v-slot:head(details)/>

      <template v-slot:content="{ header, data }">
        <template v-if="header.key === 'description'">
          <prk-animated-description :description="data.item.description" />
        </template>
        <base-arrow
            v-else-if="header.key === 'details'"
            :show="data.item.toggleDetails"
            @click="showText(data, $event)"
        />
        <div v-else-if="header.key === 'actions'">
          <div class="d-flex justify-content-end align-items-baseline">
            <pcg-switch class="custom-switch mr-2"
                        v-model="data.item.active"
                        @input="updateResource(data.item, 'effects')"
            />
            <span class="prk-fz-12 font-weight-bold mr-3"
                  :class="{ 'pcg-main-active-color': data.item.active }"
            >
              <span v-if="data.item.active">
                {{ $tc('general.active', 2) }}
              </span>
              <span v-else>
                {{ $tc('general.inactive', 2) }}
              </span>
            </span>
            <pcg-btn round text="edit" class="mr-3 prk-button" :icon="iconEdit"
                     @click="editResource(data.item.id, 'effect')"/>
            <pcg-btn round :icon="iconRemove" text="delete"
                     class="mr-3 prk-button" variant="danger"
                     @click="destroyResource('effects',
                       data.item.id, data.index)"/>
          </div>
        </div>
      </template>
    </prk-table>

    <form-effect-modal
        :action="action(effect, 'effects')"
        :btn-title="editMode ? $t('general.update') : $t('general.add')"
        :categories="categories"
        :edit-mode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title="editMode ? $t('general.edit') : $t('general.add_characteristic')"
        v-bind.sync="effect"
        @clear-error="errors[$event] = ''"
        @hidden="hideModal('effect', ['effect_type'])"
    />
  </div>
</template>

<script>
import useEffects from '../../composables/useEffects'
import PrkTable from '../../components/PrkTable'
import FormEffectModal from '../../components/dictionaries/modal/FormEffectModal'
import useDictionary from '../../composables/useDictionary'
import useIcon from '../../composables/useIcon'
import useExpandText from '../../composables/useExpandText'
import { dictionary } from '../../mixins/dictionary'
import PrkAnimatedDescription from '../../components/PrkAnimatedDescription'
import { mapGetters } from 'vuex'

export default {
  name: 'DictionaryStandardEffects',
  components: { PrkAnimatedDescription, FormEffectModal, PrkTable },
  mixins: [dictionary],
  setup (props, { root, refs }) {
    const {
      categories,
      changeCategory,
      checkIfCanAdd,
      findItemById,
      items,
      removeRow,
      tmpCategoryId,
    } = useEffects({ root, refs }, { effect_type: 'standard' })
    const {
      canAdd,
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      updateResource,
    } = useDictionary(root)
    const { iconEdit, iconRemove } = useIcon(root)
    const { showText, tdClass } = useExpandText('dictionary-standard-effects')
    return {
      canAdd,
      categories,
      changeCategory,
      checkIfCanAdd,
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      items,
      updateResource,
      iconEdit,
      iconRemove,
      removeRow,
      showText,
      tdClass,
      tmpCategoryId,
    }
  },
  data () {
    return {
      standardEffects: [],
      effect: {
        symbol: '',
        description: '',
        category: '',
        categoryId: '',
        toggleDetails: false,
        active: false,
        effect_type: 'standard',
        [Symbol.for('_innerName')]: 'effect',
      },
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    excludedFields () {
      return ['selected', 'toggleDetails', 'categoryId', 'range', 'id', 'active', 'effect_type']
    },
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
    'effect.categoryId': {
      handler () {
        const catId = this.effect.categoryId
        if (catId.length) {
          this.tmpCategoryId = catId
          this.canAdd = this.checkIfCanAdd()
        }
      },
    },
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$tc('general.standard_effect', 2),
      })
    },
  },

  beforeRouteEnter (to, from, next) {
    next(vm => { vm.setHeader() })
  },
}
</script>
<style scoped lang="scss">

::v-deep {
  .pcg-table-container {
    border-radius: 15px 15px 0 0;

    .pcg-table-content {
      border-radius: 15px 15px 0 0;
    }
  }
}

.custom-switch {
  transform: translateY(5px);
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
