<template>
  <b-row class="mt-4">
    <b-col cols="auto" class="position-relative width-10">
      <pcg-input-number
          :error-text="$t('general.errors.greater_0')"
          :label="$t('general.percent')"
          :max="100"
          :min="0"
          :show-error="error"
          :value="percent"
          @input="setMaxLength(+$event)"
      />
      <div class="percent">%</div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'DisciplinePercent',
  props: {
    error: { required: true, type: Boolean },
    percent: { required: true, type: Number },
  },
  methods: {
    setMaxLength (percent) {
      const pcgInputNumber = document.querySelector('.pcg-number-input')
      if (percent > 100) {
        this.$emit('update:percent', 100)
        pcgInputNumber.value = 100
      } else if (percent < 0) {
        this.$emit('update:percent', 0)
        pcgInputNumber.value = 0
      }
      this.$emit('update:percent', percent)
    },
  },
}
</script>

<style scoped>
.percent {
  position: absolute;
  top: 2.571rem;
  left: 3.9rem;
  font-size: 1.2rem;
}

.width-10 {
  width: 10rem;
}
</style>
