<template>
  <div>
    <pcg-search-select
        :options="units"
        autocomplete="on"
        always-active
        :show-label="false"
        :value="value"
        :placeholder="$t('general.choose')"
        @input="$emit('input', $event)"
        class="unit-select"
    />
  </div>
</template>
<script>

export default {
  name: 'basic-information-about-subject-search-select-unit',
  data () {
    return {
      units: [],
    }
  },
  props: {
    value: { type: [String, Number], default: '' },
  },
  created () {
    this.getUnits()
  },
  methods: {
    async getUnits () {
      const { data: units } = await this.$api.get('unit_names')
      this.units = units.map(unit => {
        return {
          text: unit.attributes.name,
          value: unit.id,
        }
      })
    },
  },
}
</script>
