<template>
  <div class="d-flex">
    <pcg-btn v-if="specialKind" text="pin" class="mr-3 prk-button"
             :icon="iconDrawingPin" round
             v-b-modal.pinToStudyPlan
             @click="emitSubject"
    />
    <pcg-btn v-if="!subject.parentId"
             v-b-modal.subject-form
             :icon="iconEdit" class="mr-3 prk-button"
             round text="edit"
             @click="emitSubject"
    />
    <pcg-btn round :icon="iconRemove" class="prk-button"
             text="delete" variant="danger"
             @click="removeSubject"
    />

  </div>
</template>

<script>
import useIcon from '../../../../composables/useIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'StudyPlanTableActions',
  setup (_, { root }) {
    const { iconDrawingPin, iconEdit, iconRemove } = useIcon(root)
    return { iconDrawingPin, iconEdit, iconRemove }
  },
  props: {
    subjectByFormId: { required: true, type: String },
    subjects: { required: true, type: Array },
  },
  data () {
    return {
      errors: [],
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    subject () {
      return this.subjects.find(sub => sub.id === this.subjectId)
    },
    subjectId () {
      return this.subjectByFormId.split('|')[0]
    },
    subjectFormId () {
      return this.subjectByFormId.split('|')[1]
    },
    subjectFormCount () {
      return this.subject.subjectForms.filter(form => form._destroy !== 1).length
    },
    specialKind () {
      return this.$store.state.studyPlans.plan?.specialKind || false
    },
  },
  methods: {
    async removeSubject () {
      let text = ''
      if (this.subjectFormCount > 1) {
        text = this.$t('views.study_plans.subjects.more_than_one_form')
      }
      const response = await this.$removeSwal(1, text)
      if (!response.value) return
      if (this.$checkRouteName('study_plans_list_path')) {
        try {
          await this.$api.destroy(`subjects/${this.subjectId}`)
          this.$emit('remove', this.subject.id)
        } catch (e) {
          this.$showError(this.$getErrors(e.errors))
        }
      } else {
        this.$emit('remove', this.subject.id)
      }
    },
    emitSubject () {
      this.$emit('set:subject-id', this.subjectId)
      this.$emit('set:subject-form-id', this.subjectFormId)
    },
  },
  watch: {
    contrast () {
      const { iconDrawingPin, iconEdit, iconRemove } = useIcon(this.$root)
      this.iconDrawingPin = iconDrawingPin
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
