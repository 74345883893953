<template>
  <b-col sm="12" md="6" class="mt-3">
    <prk-select
        color="aqua"
        :default-option="defaultOption('specialities')"
        :includeBlank="true"
        :label="$t('general.speciality')"
        :options="specialities"
        :value="$attrs.speciality"
        @input="input('speciality', $event)"
    />
  </b-col>
</template>

<script>
import { newMatrixModal } from '../../mixins/new_matrix_modal'

export default {
  name: 'SpecialitySelect',
  mixins: [newMatrixModal],
  props: {
    typeId: { type: String },
    courseNameId: { type: String },
    kindId: { type: String },
  },
  watch: {
    kindId () {
      if (!this.typeId) {
        if (!this.kindId) {
          this.specialities = []
          return
        }

        this.getData(['specialities'], null, {
          specialities: { kind_id: this.kindId, course_name_id: this.courseNameId },
        })
      }
    },
    typeId () {
      this.getData(['specialities'], null, {
        specialities: { type_id: this.typeId, course_name_id: this.courseNameId },
      })
    },
  },
}
</script>
