var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('return',{staticClass:"mb-1 d-inline-block",attrs:{"path-name":"dictionaries_general_effects_path"}},[_vm._v("\n    "+_vm._s(_vm.$t('components.prk_syllabus.return.general_effects'))+"\n  ")]),_vm._v(" "),_c('characteristic-effect-buttons',{attrs:{"add-effect":_vm.addEffect,"characteristic-effects":_vm.items}}),_vm._v(" "),_c('b-row',{staticClass:"mt-3 mb-4 mr-0",attrs:{"align-v":"center","align-h":"end"}},[_c('categories',{ref:"allCategories",attrs:{"categories":_vm.categories,"change-category":_vm.changeCategory,"args":{ effectType: 'characteristic', generalEffectId: _vm.generalEffectId }}})],1),_vm._v(" "),(_vm.items.length > 0)?_c('prk-table',{staticClass:"mt-3 directional-effects prk-shadow",attrs:{"excluded-fields":_vm.excludedFields,"fields-props":{ tdClass: _vm.tdClass },"items":_vm.items,"show-headline":false},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'description' ? '70%' : '10%' })})})}},{key:"head(id)",fn:function(){return [_c('pcg-checkbox',{attrs:{"value":_vm.checkedAll},on:{"input":_vm.select}})]},proxy:true},{key:"head(details)",fn:function(){return undefined},proxy:true},{key:"content",fn:function(ref){
var header = ref.header;
var data = ref.data;
return [(header.key === 'id')?_c('pcg-checkbox',{model:{value:(data.item.selected),callback:function ($$v) {_vm.$set(data.item, "selected", $$v)},expression:"data.item.selected"}}):(header.key === 'description')?[_c('prk-animated-description',{attrs:{"description":data.item.description}})]:(header.key === 'details')?_c('base-arrow',{staticClass:"effects-arrows",attrs:{"show":data.item.toggleDetails},on:{"click":function($event){return _vm.showText(data, $event)}}}):(header.key === 'actions')?_c('div',[_c('div',{staticClass:"d-flex justify-content-end"},[_c('pcg-btn',{staticClass:"mr-3 prk-button",attrs:{"round":"","text":"edit","icon":_vm.iconEdit},on:{"click":function($event){return _vm.editResource(data.item.id,
                   'effect')}}}),_vm._v(" "),_c('pcg-btn',{staticClass:"mr-3 prk-button",attrs:{"round":"","icon":_vm.iconRemove,"text":"delete","variant":"danger"},on:{"click":function($event){return _vm.destroyResource('characteristic_effects',
                   data.item.id, data.index)}}})],1)]):_vm._e()]}}],null,false,1082395108)}):_vm._e(),_vm._v(" "),_c('form-effect-modal',_vm._b({attrs:{"action":_vm.action(_vm.effect, 'effects'),"btn-title":_vm.editMode ? _vm.$t('general.update') : ("+ " + (_vm.$t('general.new_effect'))),"categories":_vm.categories,"edit-mode":_vm.editMode,"errors":_vm.errors,"modalId":_vm.editMode ? 'edit-modal' : 'add-modal',"title-modal":_vm.editMode ? _vm.$t('general.edit') : _vm.$t('general.new_effect')},on:{"clear-error":function($event){_vm.errors[$event] = ''},"hidden":function($event){return _vm.hideModal('effect')}}},'form-effect-modal',_vm.effect,false,true))],1)}
var staticRenderFns = []

export { render, staticRenderFns }