<template>
  <div class="d-flex align-items-center description animate-cell">
    <div class="wrapper text-truncate description">
      {{ description }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrkAnimatedDescription',
  props: {
    description: { required: true, type: String },
  },
}
</script>
