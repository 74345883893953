<template>
  <pcg-modal
      modal-id="newMatrixModal"
      size="md"
      :title="$t('components.prk_modals.add_matrix.add')"
      icon="fas fa-plus"
      :btn-title="$t('general.save')"
      :action="createMatrix"
      @shown="matrix.academicYear = currentYear"
      @hidden="$emit('hidden')"
  >

    <new-matrix-name
        :name.sync="matrix.name"
        :name-error="errors.name"
        @clear-error="errors[$event] = ''"
    />

    <b-row>
      <new-matrix-faculty
          :faculty-error="errors.faculty"
          :faculty.sync="matrix.facultyId"
          @update:faculty="matrix.courseNameId = ''"
          @clear-error="errors[$event] = ''"
      />
    </b-row>
    <b-row>
      <new-matrix-course-name
          :course-name-error="errors.courseName"
          :courseName.sync="matrix.courseNameId"
          :faculty-id="matrix.facultyId"
          :copied="!!editedMatrix"
          @update:courseName="matrix.courseId = ''"
          @clear-error="errors[$event] = ''"
      />
    </b-row>
    <b-row class="pb-3 mt-3">
      <new-matrix-type
          :course-name-id="matrix.courseNameId"
          :type-error="errors.type"
          :type.sync="matrix.typeId"
          @clear-error="errors[$event] = ''"
          @update:type="matrix.courseId = ''"
      />
      <new-matrix-kind
          :type-id="matrix.typeId"
          :kind-error="errors.kind"
          :kind.sync="matrix.kindId"
          @clear-error="errors[$event] = ''"
      />
      <new-matrix-academic-year
        :academic-year-error="errors.academicYear"
        :academicYear.sync="matrix.academicYear"
        @clear-error="errors[$event] = ''"
      />
      <new-matrix-study-profile
          :study-profile-error="errors.studyProfile"
          :studyProfile.sync="matrix.studyProfileId"
          @clear-error="errors[$event] = ''"
          @update:studyProfile="matrix.courseId = ''"
      />
      <speciality-select
          :course-name-id="matrix.courseNameId"
          :kind-id="matrix.kindId"
          :type-id="matrix.typeId"
          :speciality.sync="matrix.specialityId"
      />
      <new-matrix-syllabus
          :syllabusTemplate.sync="matrix.syllabusTemplateId"
          :syllabus-error="errors.syllabusTemplate"
          @clear-error="errors[$event] = ''"
      />
    </b-row>
    <b-row class="mb-4">
      <new-matrix-course
          :course.sync="matrix.courseId"
          :course-error="errors.course"
          :matrix="matrix"
          @clear-error="errors[$event] = ''"
      />
    </b-row>

    <b-row>
      <pcg-checkbox :value="isUniversityWideMatrix" @input="setMatrixType($event)">
        <span :class="{ 'pcg-main-active-color': isUniversityWideMatrix }">
         {{ $t('general.university_wide_matrix') }}
        </span>
      </pcg-checkbox>
    </b-row>

    <template #button-before>
      <pcg-btn variant="additional" @click="$bvModal.hide('newMatrixModal')">
        {{ $t('general.cancel') }}
      </pcg-btn>
    </template>
  </pcg-modal>
</template>

<script>
import NewMatrixType from './new_matrix_modal/NewMatrixType'
import NewMatrixKind from './new_matrix_modal/NewMatrixKind'
import NewMatrixStudyProfile from './new_matrix_modal/NewMatrixStudyProfile'
import NewMatrixFaculty from './new_matrix_modal/NewMatrixFaculty'
import NewMatrixCourseName from './new_matrix_modal/NewMatrixCourseName'
import SpecialitySelect from '../../shared_view/SpecialitySelect'
import NewMatrixSyllabus from './new_matrix_modal/NewMatrixSyllabus'
import NewMatrixCourse from './new_matrix_modal/NewMatrixCourse'
import NewMatrixName from './new_matrix_modal/NewMatrixName'
import NewMatrixAcademicYear from './new_matrix_modal/NewMatrixAcademicYear'
import { getStudyData } from '../../../mixins/getStudyData'

export default {
  name: 'PrkNewMatrixModal',
  components: {
    NewMatrixAcademicYear,
    NewMatrixName,
    NewMatrixCourse,
    NewMatrixSyllabus,
    SpecialitySelect,
    NewMatrixCourseName,
    NewMatrixFaculty,
    NewMatrixStudyProfile,
    NewMatrixKind,
    NewMatrixType,
  },
  mixins: [getStudyData],
  props: {
    editedMatrix: { type: Object, default: null },
    currentYear: { required: true, type: String },
  },
  data () {
    return {
      matrix: this.initMatrix(),
      errors: {
        academicYear: '',
        course: '',
        courseName: '',
        faculty: '',
        kind: '',
        name: '',
        studyProfile: '',
        syllabusTemplate: '',
        type: '',
      },
    }
  },
  computed: {
    isUniversityWideMatrix () {
      return this.matrix.matrixType === 'university_wide'
    },
  },
  watch: {
    'matrix.facultyId' () {
      this.matrix.courseNameId = ''
      this.matrix.typeId = ''
      this.matrix.kindId = ''
      this.matrix.specialityId = ''
    },
    'matrix.courseNameId' () {
      this.matrix.typeId = ''
      this.matrix.kindId = ''
      this.matrix.specialityId = ''
    },
    'matrix.typeId' () {
      this.matrix.kindId = ''
      this.matrix.specialityId = ''
    },
    'matrix.kindId' () {
      this.matrix.specialityId = ''
    },
    editedMatrix () {
      if (this.editedMatrix) {
        this.matrix = this.editedMatrix
        this.$bvModal.show('newMatrixModal')
      } else {
        this.matrix = this.initMatrix()
      }
    },
  },
  methods: {
    initMatrix () {
      return {
        name: '',
        facultyId: '',
        courseNameId: '',
        kindId: '',
        typeId: '',
        studyProfileId: '',
        syllabusTemplateId: '',
        academicYear: '',
        specialityId: '',
        courseId: '',
        study_plan_ids: [],
        matrixType: 'standard',
      }
    },
    async createMatrix () {
      this.matrix.syllabusId = this.matrix.syllabusTemplateId
      try {
        const { id } = await this.$api.post('matrices', { matrix: this.matrix })
        await this.$router.push({
          name: 'matrix_edit_path',
          params: { id: id },
          query: {
            name: this.matrix.name,
            courseId: this.matrix.courseId,
          },
        })
        this.$bvModal.hide('newMatrixModal')
        this.clearModel(this.matrix)
      } catch (e) {
        const errors = this.$getErrors(e.errors)
        for (const errorsKey in errors) {
          this.errors[errorsKey] = errors[errorsKey]
        }
      }
    },
    setMatrixType (value) {
      this.clearModel(this.errors)
      this.matrix.matrixType = value ? 'university_wide' : 'standard'
    },
  },
}
</script>
