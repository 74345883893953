<template>
  <span class="item-b prk-fz-12 font-weight-bold" :class="activeColor">
    <span v-if="semester.active">
      {{ $tc('general.switch', 1) }}
    </span>
    <span v-else>
      {{ $tc('general.switch', 2) }}
    </span>
  </span>
</template>

<script>
export default {
  name: 'StudyPlansTableTermsActiveTerm',
  props: {
    semester: { required: true, type: Object },
  },
  computed: {
    activeColor () {
      return { 'pcg-main-active-color': this.semester.active }
    },
  },
}
</script>
