<template>
  <div>
    <div v-if="matrixSpinner" class="text-center text-danger py-3 pcg-white-background-color">
      <pcg-spinner variant="small"/>
    </div>
    <prk-table
        v-else
        :excluded-fields="EXCLUDED_FIELDS"
        :fields-props="{ thAttr: thAttr }"
        :items="matrices"
        :show-headline="false"
    >
      <template v-slot:head(id)>
        <pcg-checkbox :value="selectedAll" @input="selectMatrices"/>
      </template>

      <template #cell(actions)="{ item: matrix, index }">
        <matrices-table-actions
            :index="index"
            :matrix="matrix"
            :set-matrix="setMatrix"
            :copy-matrix="copyMatrix"
            :destroy-matrix="destroyMatrix"
        />
      </template>

      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'id'" class="position-relative">
          <pcg-checkbox v-model="data.item.selected" class="ml-2"/>
        </div>
      </template>
    </prk-table>
    <portal to="matrix-pagination">
      <pcg-pagination
          v-if="pagination.totalPages"
          :page-count="pagination.totalPages"
          :total-records="pagination.totalRecords"
          v-model="pagination.currentPage"
          @input="goToPage"
          class="mt-4 prk-pagination"
      />
    </portal>
    <base-beam v-if="selectedMatrices.length > 0">
      <selected-elements-beam
          :selected-length="selectedMatrices.length"
          type="matrix"
          @remove:elements="removeMatrices"
      />
    </base-beam>
  </div>
</template>

<script>
import PrkTable from '../PrkTable'
import SelectedElementsBeam from '../beams/SelectedElementsBeam'
import MatricesTableActions from './matrices_table/MatricesTableActions'
import usePagination from '../../composables/usePagination'

export default {
  name: 'MatricesTable',
  setup (props, { root }) {
    const { pagination, setPagination } = usePagination(root)
    return {
      pagination,
      setPagination,
    }
  },
  props: {
    filters: { required: true, type: Object },
  },
  components: {
    MatricesTableActions,
    SelectedElementsBeam,
    PrkTable,
  },
  jsonapi: {
    matrices: {
      config: ({ url }) => ({
        method: 'get',
        url: url,
      }),
      variables () {
        return {
          url: this.url,
        }
      },
      skip () {
        return !this.url.length
      },
      update (matrices, requestInfo) {
        this.matrixSpinner = false
        this.setPagination(requestInfo.raw.data)
        return matrices.map(matrix => {
          return {
            ...matrix,
            selected: false,
          }
        })
      },
    },
  },
  data () {
    return {
      matrices: [],
      matrix: null,
      index: -1,
      url: '',
      matrixSpinner: true,
    }
  },
  computed: {
    selectedAll () {
      return this.selectedMatrices.length === this.matrices.length
    },
    selectedMatrices () {
      return this.matrices.filter(matrix => matrix.selected)
    },
  },
  watch: {
    pagination: {
      deep: true,
      handler () {
        this.$emit('update:current-page', this.pagination.currentPage)
      },
    },
    filters: {
      deep: true,
      handler () {
        if (this.filters.page && this.filters.page !== this.pagination.currentPage) {
          this.pagination.currentPage = this.filters.page
        }
      },
    },
  },
  async created () {
    this.$watch(vm => [vm.filters], this.$_.debounce(async function () {
      this.url = this.url = `matrices?${this.$qs.stringify({
        serializer: 'list',
        filters: this.filters,
        paginate: true,
        page: this.filters.page,
      })}`
    }, 200), { deep: true, immediate: true })

    this.EXCLUDED_FIELDS = [
      'facultyId',
      'courseNameId',
      'courseId',
      'typeId',
      'kindId',
      'studyProfileId',
      'specialityId',
      'syllabusTemplateId',
      'academicYear',
      'matrixType',
      'selected',
      'course',
      'skipValidation',
    ]
  },
  methods: {
    copyMatrix (matrix) {
      const copyMatrix = this.$_.cloneDeep(matrix)
      const date = this.$moment().format('MM DD YYYY, h:mm:ss')
      copyMatrix.name = `${copyMatrix.name}-${this.$t('general.replica')} ${date}`
      this.$emit('copy:matrix', this.$_.pick(
        copyMatrix,
        'name',
        'facultyId',
        'courseNameId',
        'courseId',
        'typeId',
        'kindId',
        'studyProfileId',
        'academicYear',
        'specialityId',
        'matrixType',
        'syllabusTemplateId',
      ))
    },

    async destroyMatrix (matrix, index) {
      try {
        const res = await this.$removeSwal()
        if (!res.value) return

        await this.$api.destroy(`matrices/${matrix.id}`)
        this.$removeItem(this.matrices, index)
      } catch (e) {
        this.$showError(this.$getErrors(e.errors))
      }
    },

    async removeMatrices () {
      try {
        const res = await this.$removeSwal()
        if (!res.value) return

        const ids = this.selectedMatrices.map(matrix => matrix.id)
        await this.$api.destroy('matrices/destroy_all', { params: { ids } })
        this.$removeItems(this.matrices, ids)
      } catch (e) {
        this.$showError(this.$getErrors(e.errors))
      }
    },

    selectMatrices (selected) {
      selected ? this.$selectAll(this.matrices) : this.$unselectAll(this.matrices)
    },

    setMatrix (matrix, index) {
      this.matrix = matrix
      this.index = index
    },

    thAttr (value, key) {
      return key === 'id' ? { style: 'width:3em; text-align: end' } : {}
    },

    goToPage () {
      this.matrixSpinner = true
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
