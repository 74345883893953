<template>
  <div>
    <label class="label w-100 pcg-the-darkest-gray-color font-weight-bold">
      <slot/>
    </label>
    <b-row v-if="fillContent && editMode" align-v="center" no-gutters class="pr-5">
      <b-col>
        <prk-live-search class="literature"
                     :clear-btn="false"
                     v-model="literature"
                     :options="literatureOptions"
                     v-on:ls-checked="addLiteratureToVuex"
        />
      </b-col>
      <literature-section-new-element
          @click="$emit('set:supplementary', supplementary)"
      />
    </b-row>
    <added-literatures
        :added-literature="addedLiterature"
        :literature-text="getLiteratureText"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import AddedLiteratures from './AddedLiteratures'
import LiteratureSectionNewElement from './LiteratureSectionNewElement'
import PrkLiveSearch from '../../../PrkLiveSearch'
import { fillStatus } from '../../../../mixins/syllabus/status'
import { inject } from '@vue/composition-api'

const { mapActions, mapGetters } = createNamespacedHelpers('syllabusFill')

export default {
  name: 'LiteratureSection',
  setup () {
    const editMode = inject('editMode', true)
    const status = inject('status')
    return { status, editMode }
  },
  components: {
    LiteratureSectionNewElement,
    AddedLiteratures,
    PrkLiveSearch,
  },
  mixins: [fillStatus],
  props: {
    supplementary: Boolean,
  },
  data () {
    return {
      literature: {
        id: null,
        value: null,
        valueEn: null,
      },
    }
  },
  computed: {
    ...mapGetters(['getLiteratures']),
    literatureOptions () {
      const result = {
        supplementary: this.supplementary,
      }
      return {
        path: 'literatures/search',
        params: result,
      }
    },
    addedLiterature () {
      return this.getLiteratures.filter(literature => literature.supplementary === this.supplementary)
    },
  },
  methods: {
    ...mapActions(['addLiterature']),
    addLiteratureToVuex () {
      if (this.literature.id) {
        const author = this.literature.value.split('|')[0].trim()
        const title = this.literature.value.split('|')[1].trim()
        const publishingHouse = this.literature.value.split('|')[2].trim()
        const placeOfPublication = this.literature.value.split('|')[3].trim()
        const year = this.literature.value.split('|')[4].trim()
        const literature = {
          author: author,
          id: this.literature.id,
          placeOfPublication: placeOfPublication,
          publishingHouse: publishingHouse,
          title: title,
          year: year,
        }
        this.addLiterature({
          ...literature,
          supplementary: this.supplementary,
        })
      }
      this.literature = {
        id: null,
        value: null,
        valueEn: null,
      }
    },
    getLiteratureText (literature) {
      // empty authors got ---
      const author = (literature.author && literature.author !== '---') ? literature.author : null
      const title = literature.title ? literature.title : null
      const publishingHouse = literature.publishingHouse ? literature.publishingHouse : null
      const placeOfPublication = literature.placeOfPublication ? literature.placeOfPublication : null
      const year = literature.year ? literature.year : null
      let literatureText = ''
      if (author) {
        literatureText = `${author},`
      }
      if (title) {
        literatureText += `${title}`
      }
      if (publishingHouse) {
        literatureText += `,${publishingHouse}`
      }
      if (placeOfPublication) {
        literatureText += `,${placeOfPublication}`
      }
      if (year) {
        literatureText += `,${year}`
      }
      return `${literatureText}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import 'app/javascript/prk/assets/stylesheets/vars';
.literature {
  margin-left: .1rem;
  font-weight: 400;
  font-size: $font-size-m;
  color: $pcg-gray;
}
</style>

<style lang="scss" scoped>
@import 'app/javascript/prk/assets/stylesheets/vars-contrast';
.contrast {
  .literature {
    color: $pcg-gray;
  }
}
</style>
