<template>
  <tinymce-editor :init="tinymceSettings"
                  :value="value"
                  @input="$emit('input', $event)"/>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import tinymce from 'tinymce/tinymce'
import 'tinymce/themes/silver'
import 'tinymce/themes/mobile'
import 'tinymce/plugins/lists'
import 'tinymce/plugins/autoresize'
import 'tinymce/plugins/code'
import 'tinymce/plugins/table'
import 'tinymce/plugins/link'
import 'tinymce/plugins/advlist'
import TinymceEditor from '@tinymce/tinymce-vue'
export default {
  name: 'prk-editor',
  components: { TinymceEditor },
  props: {
    value: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      tinymceSettings: {
        content_style: 'p { font-size: 12px; margin: 0 0 14px 0 }',
        content_css: '/tinymce/skins/content/default/content.css',
        skin_url: '/tinymce/skins/ui/oxide/',
        height: 150,
        plugins: 'autoresize code lists advlist link table',
        toolbar: 'code | bold italic underline hr forecolor backcolor | link unlink | undo redo | \n' +
            '            bullist numlist | outdent indent | alignleft aligncenter | alignright alignjustify |',
        menubar: 'tools insert edit table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
        branding: false,
        statusbar: false,
        invalid_elements: 'script',
      },
    }
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/vars';

</style>
