const footerNavbar = {
  namespaced: true,
  state: {
    items: []
  },
  getters: {
    items: state => {
      return state.items
    }
  },
  mutations: {
    setItems (state, items) {
      state.items = items
    }
  },
  actions: {
    setItems ({ commit }, payload) {
      commit('setItems', payload)
    },
    clearItems ({ commit }) {
      commit('setItems', [])
    }
  }
}

export { footerNavbar }
