<template>
  <fragment>
    <pcg-box>
      <permission-path
          v-for="(path, index) in paths"
          :key="path.id" :statuses="statuses"
          v-bind.sync="paths[index]"
          @remove-path="removePath"
      />
      <div class="d-flex justify-content-center">
        <pcg-btn class="mt-3" @click="addPath">
          {{ $t('general.add_path') }}
        </pcg-btn>
      </div>
    </pcg-box>
    <base-beam class="d-flex justify-content-end">
      <pcg-btn @click="save">
        {{ $t('general.save') }}
      </pcg-btn>
    </base-beam>
  </fragment>
</template>

<script>
import { statuses } from '../../helpers/statuses'
import PermissionPath from './PermissionPath'

export default {
  name: 'PermissionList',
  components: {
    PermissionPath,
  },
  data () {
    return {
      statuses: statuses,
      paths: [],
    }
  },
  jsonapi: {
    paths: {
      config: { method: 'get', url: 'permission_paths' },
      fetchPolicy: 'no-cache',
      update (paths) {
        return this.$_.cloneDeep(paths)
      },
    },
  },
  computed: {
    checkIfFilled () {
      const pathToCheck = this.paths[this.paths.length - 1]
      if (!pathToCheck) return true
      return (pathToCheck.to !== '' && pathToCheck.from !== '' && pathToCheck.roles.length > 0)
    },
  },
  methods: {
    addPath () {
      if (this.paths.length > 0) {
        if (this.checkIfFilled || this.paths.length === 0) {
          const path = {
            id: `${this.$uuidv4()}__new`,
            from: 'to_fill',
            to: 'to_fill',
            roles: [],
          }
          this.paths.push(path)
        } else {
          this.$toastr.e(this.$t('views.permissions.errors.complete_path'))
        }
      } else {
        const path = {
          id: `${this.$uuidv4()}__new`,
          from: 'to_fill',
          to: 'to_fill',
          roles: [],
        }
        this.paths.push(path)
      }
    },
    async removePath (id) {
      try {
        if (!id.match(/__new/)) {
          await this.$api.destroy(`permission_paths/${id}`)
        }
        const index = this.paths.findIndex(p => p.id === id)
        if (~index) {
          this.paths.splice(index, 1)
        }
      } catch (e) {
        this.$showError(this.$getErrors(e.errors))
      }
    },
    async save () {
      if (this.checkIfFilled) {
        const state = this.createState()
        try {
          await this.$api.post('permission_paths/update_or_create_all',
            {
              permission_paths: this.paths,
              state: state,
            },
          )
          const { data: paths } = await this.$jsonapi.queries.paths.refetch()
          this.paths = this.$_.cloneDeep(paths)
          this.$toastr.s(this.$t('views.permissions.success.save'))
        } catch (e) {
          this.$showError(this.$getErrors(e.errors))
        }
      } else {
        this.$toastr.e(this.$t('views.permissions.errors.complete_path'))
      }
    },
    createState () {
      const state = {}
      const groupedPaths = this.$_.groupBy(this.paths, 'to')
      for (const key of Object.keys(groupedPaths)) {
        state[key] = groupedPaths[key].map(path => (path.from))
      }
      return state
    },
  },
}
</script>
