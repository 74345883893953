export default function (root) {
  const colgroupDefinition = (key) => {
    switch (key) {
      case 'ects':
        return '8%'
      case 'actions':
        return '5%'
      default:
        return ''
    }
  }

  const customFields = [
    {
      key: 'ects',
      label: root.$t('components.prk_syllabus.table.ects'),
      tdClass: ['align-middle'],
    },
    {
      key: 'leading',
      label: root.$t('components.prk_syllabus.table.lecturers'),
      tdClass: ['align-middle'],
    },
    {
      key: 'specialPlan',
      label: root.$t('components.prk_syllabus.table.from_plan'),
      tdClass: ['align-middle'],
    },
    {
      key: 'actions',
      label: '',
      tdClass: ['align-middle'],
    },
  ]

  const excludedFields = [
    'id',
    'ects',
    'progress',
    'characteristicEffects',
    'relationships',
    'parentId',
    'specialPlan',
    'semester',
    'semesterNumber',
    'lecturers',
    // 'selected',
  ]
  const getSubjectFormId = (customId) => {
    return customId.split('|')[1]
  }

  return {
    colgroupDefinition,
    customFields,
    excludedFields,
    getSubjectFormId,
  }
}
