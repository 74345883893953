<template>
  <div class="d-flex justify-content-end">
    <pcg-btn variant="additional" size="small" @click="addResource">
        <span class="font-weight-bold">
          {{ $t(`general.${buttonName}`) }}
        </span>
    </pcg-btn>
  </div>
</template>

<script>
export default {
  name: 'DictionariesAddResourceButton',
  props: {
    addResource: { type: Function, default: () => {} },
    buttonName: { required: true, type: String },
  },
}
</script>
