<template>
  <div class="d-flex align-items-center flex-wrap flex-sm-nowrap">
    <base-icon width="20" height="20" :iconColor="iconColor">
      <icon-document/>
    </base-icon>
    <span class="text-uppercase font-weight-bold
                 pcg-main-color ml-2 prk-fz-16"
    >
      {{ $tc('general.new_study_plan') }}
    </span>
    <div class="ml-sm-5 mt-3 mt-sm-0 d-flex align-items-center">
      <pcg-checkbox :value="$attrs.specialKind" @input="$emit('update:specialKind', $event)">
        <span
            class="font-weight-bold"
            :class="processColor"
        >
          {{ $t('general.special_kind') }} 0
        </span>
      </pcg-checkbox>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StudyPlanHeader',
  computed: {
    ...mapGetters('page', ['contrast']),
    iconColor () {
      return this.contrast ? '#008A39' : '#253B80'
    },
    processColor () {
      return {
        'pcg-main-active-color': this.specialKind,
        'pcg-gray-color': !this.specialKind,
      }
    },
  },
}
</script>
