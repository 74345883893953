<template>
  <div>
    <div v-if="$jsonapi.loading" class="text-center text-danger py-3 pcg-white-background-color">
      <pcg-spinner variant="small"/>
    </div>
    <prk-table
        v-else
        :items="studyPlans"
        :show-headline="false"
        :excluded-fields="EXCLUDED_FIELDS"
        :custom-fields="CUSTOM_FIELDS"
    >
      <template v-slot:head(id)>
        <pcg-checkbox :value="selectedAll" @input="selectPlans"/>
      </template>

      <template #cell(id)="{ item: plan }">
        <study-plans-table-checkbox
            :plan="plan"
            :select-plan="selectPlan"
        />
      </template>

      <template #cell(actions)="{ item: plan, index }">
        <study-plans-table-actions
            :study-plan="plan"
            @remove-plan="removePlan(plan, index)"
        />
      </template>

      <template #cell(details)="{ item: plan, detailsShowing }">
        <base-arrow
            :show="detailsShowing"
            class="ml-2"
            @click="showDetails(plan)"
        />
      </template>

      <template #row-details="{ item: plan }">
        <study-plans-table-terms
            :study-plan="plan"
            @row-selected="selectSemester"
            @row-clicked="studyPlan = plan"
        />
      </template>
    </prk-table>

    <portal to="study-plan-pagination">
      <pcg-pagination
          v-if="pagination.totalPages"
          :page-count="pagination.totalPages"
          :total-records="pagination.totalRecords"
          v-model="pagination.currentPage"
          class="mt-4 prk-pagination"
      />
    </portal>

    <prk-beam-study-plan-list
        v-if="selectedPlanAndTermCount > 0"
        :count="selectedPlanAndTermCount"
        :study-plans="studyPlans"
        @remove-rows="removePlans"
        @selected="$emit('selected', $event)"
    />
  </div>
</template>

<script>
import PrkTable from '../PrkTable'
import StudyPlansTableTerms from './study_plans_table/StudyPlansTableTerms'
import PrkBeamStudyPlanList from '../beams/PrkBeamStudyPlanList'
import StudyPlansTableActions from './study_plans_table/StudyPlansTableActions'
import StudyPlansTableCheckbox from './study_plans_table/StudyPlansTableCheckbox'

import usePagination from '../../composables/usePagination'
import useSemesters from '../../composables/useSemesters'

import { studyPlansTable } from '../../mixins/plans/study_plans_table'
import { mapState, mapActions } from 'vuex'

export default {
  name: 'StudyPlansTable',
  setup (_, { root }) {
    const { pagination, setPagination } = usePagination(root)
    const { updateSemestersHandler } = useSemesters(root)
    return { pagination, setPagination, updateSemestersHandler }
  },
  components: {
    StudyPlansTableCheckbox,
    StudyPlansTableActions,
    PrkBeamStudyPlanList,
    StudyPlansTableTerms,
    PrkTable,
  },
  props: {
    query: { required: true, type: String },
    filters: { required: true, type: Object },
    refetch: { required: true, type: Boolean },
  },
  mixins: [studyPlansTable],
  data () {
    return {
      selectedAllPlans: false,
    }
  },
  computed: {
    ...mapState('studyPlans', ['sidekiqStatus', 'planName']),
    ...mapState('semesters', ['semesterProperty']),
    selectedPlans () {
      return this.studyPlans.filter(plan => plan.selected)
    },
    selectedPlanAndTermCount () {
      const selectedPlanCount = this.selectedPlans.length
      let selectedTermCount = 0
      this.studyPlans.forEach(plan => {
        selectedTermCount += plan.semesters.filter(sem => sem.selected).length
      })
      return selectedPlanCount + selectedTermCount
    },
    selectedAll () {
      return this.selectedPlans.length === this.studyPlans.length
    },
    selectedSemesters () {
      return this.selectedPlans.filter(plan => !plan.toggleDetails)
        .map(plan => plan.semesters).flat()
    },
  },
  watch: {
    'studyPlan.semesters': {
      handler () {
        this.studyPlan.selected = this.studyPlan.semesters.every(sem => sem.selected)
      },
      deep: true,
    },
    async studyPlans () {
      const plan = this.studyPlans.find(plan => plan.name === this.planName)
      if (!plan) return

      const selector = `tr[data-pk="${plan.id}"]`
      await this.$nextTick()
      await this.$nextTick()
      const tr = document.querySelector(selector)?.closest('.pcg-table-narrow-row')
      if (tr) tr.classList.add('prk-background-success')
    },
    refetch () {
      if (!this.refetch) return

      this.$jsonapi.queries.studyPlans.refetch()
      this.setUpdatedPlans()
      this.$emit('update:refetch', false)
    },
    semesterProperty () { this.updateSemesters() },
  },
  created () {
    this.setUpdatedPlans()
  },
  methods: {
    ...mapActions('studyPlans', ['setPlan', 'setUpdatedPlans']),
    ...mapActions('semesters', ['setSemesterProperty']),
    selectPlans (selected) {
      selected ? this.$selectAll(this.studyPlans) : this.$unselectAll(this.studyPlans)
    },

    selectPlan (plan, selected) {
      selected ? this.$selectAll([plan]) : this.$unselectAll([plan])
    },

    selectSemester (index, selected) {
      this.studyPlan.semesters[index].selected = selected
    },

    showDetails (plan) {
      plan._showDetails = !plan._showDetails
      this.setPlan(plan)
    },

    async removePlan (plan, index) {
      try {
        const res = await this.$removeSwal()
        if (!res.value) return

        await this.$api.destroy(`study_plans/${plan.id}`)
        this.$removeItem(this.studyPlans, index)
      } catch (e) {
        this.$showError(this.$getErrors(e.errors))
      }
    },

    async removePlans () {
      try {
        const res = await this.$removeSwal()
        if (!res.value) return

        const ids = this.selectedPlans.map(plan => plan.id)
        await this.$api.destroy('study_plans/destroy_all', { params: { ids: ids } })
        this.$removeItems(this.studyPlans, ids)
      } catch (e) {
        this.$showError(this.$getErrors(e.errors))
      }
    },

    async updateSemesters () {
      await this.updateSemestersHandler(this.selectedSemesters, this.semesterProperty)
      this.setSemesterProperty('')
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
}
</style>
