<template>
  <pcg-modal
      :action="() => $bvModal.hide('standardEffectsModal')"
      :btn-title="$t('general.close')"
      icon="pcg-icon-document"
      modal-id="standardEffectsModal"
      scrollable
      size="xl"
      :title="`${$t('components.prk_modals.courses_effect.title')} - ${standardName}`"
      @bv::modal::show="standardEffects = []"
      @shown="getEffects"
  >
    <b-row>
      <b-col>
        <div class="pcg-slide-tabs mb-4_5">
          <button
              v-for="(category, index) in categories"
              ref="categoriesRef" :key="index"
              class="pcg-slide-tab-btn"
              @click="changeCategory(index, $event, { url: url })"
          >
            {{ category.text }}
          </button>
        </div>
      </b-col>
    </b-row>
    <prk-table
        v-if="standardEffects.length > 0"
        :items="mapStandardEffects"
        :show-headline="false"
        :excluded-fields="['id', 'toggleDetails', 'categoryId']"
        :fields-props="{ tdClass: tdClass }"
    >
      <template v-slot:head(details)/>
      <template v-slot:head(effects)>
        {{ $t('components.prk_courses.reference') }}
      </template>
      <template v-slot:table-colgroup="{ fields }">
        <prk-colgroup
            :columns="['description', 'enDescription']"
            :fields="fields" width="50%"
        />
      </template>

      <template v-slot:content="{ header, data }">
        <template v-if="header.key === 'description'">
          <prk-animated-description :description="data.item.description"/>
        </template>
        <template v-else-if="header.key === 'enDescription'">
          <prk-animated-description :description="data.item.enDescription"/>
        </template>
        <base-arrow
            v-else-if="header.key === 'details'"
            class="effects-arrows"
            :show="data.item.toggleDetails"
            @click="showText(data, $event)"
        />
      </template>
    </prk-table>

  </pcg-modal>
</template>

<script>
import PrkTable from '../../PrkTable'
import PrkAnimatedDescription from '../../PrkAnimatedDescription'
import useEffects from '../../../composables/useEffects'
import useExpandText from '../../../composables/useExpandText'
import PrkColgroup from '../../PrkColGroup'

export default {
  name: 'CoursesEffectModal',
  setup (props, { root, refs }) {
    const {
      categories,
      changeCategory,
      getAllEffects,
      items: standardEffects,
    } = useEffects({
      root,
      refs,
    })
    const { expandAll, tdClass, showText } = useExpandText('standard-effects')
    return {
      categories,
      changeCategory,
      expandAll,
      getAllEffects,
      showText,
      standardEffects,
      tdClass,
    }
  },
  components: {
    PrkColgroup,
    PrkTable,
    PrkAnimatedDescription,
  },
  props: {
    standardId: {
      required: true,
      type: String,
    },
    standardName: String,
  },
  data () {
    return {
      url: '',
    }
  },
  computed: {
    mapStandardEffects () {
      return this.standardEffects.map(standardEffect => {
        return {
          id: standardEffect.id,
          ...this.$_.omit(standardEffect, 'effects', 'selected'),
        }
      })
    },
  },
  methods: {
    async getEffects () {
      this.url = `standards/${this.standardId}/standard_effects`
      await this.getAllEffects({ url: this.url })
    },
  },
}
</script>
