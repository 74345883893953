<template>
  <div class="hidden"></div>
</template>

<script>
import Cookies from 'js-cookie'
import { mapGetters } from 'vuex'

export default {
  name: 'prkOpenIdFrontChannelLogout',
  data () {
    return {
      iframeId: 'prk-iframe-check-session',
      sessionIframe: null,
    }
  },
  computed: {
    ...mapGetters('environment', ['env']),
  },
  methods: {
    addIFrameToWindow () {
      const sessionIframe = window.document.createElement('iframe')
      sessionIframe.id = this.iframeId
      sessionIframe.style.display = 'none'
      sessionIframe.src = `${this.env.prkOpIssuer}/connect/checksession`
      window.document.body.appendChild(sessionIframe)
      return sessionIframe
    },
    iframeMessageEvent (event) {
      if (this.sessionIframe && event.origin === this.env.prkOpOrigin) {
        if (event.data === 'error') {
          // TODO what to do in this case?
        } /* else if (event.data === 'changed') {
          this.$store.dispatch('auth/signOut')
            .then(response => {
              this.$toastr.s(this.$t('general.logged_out'))
              this.$router.push({ name: 'login_path' })
            })
            .catch(error => {
              this.$toastr.e(error.response.data.message)
            })
        } */
      }
    },
    startChecking () {
      this.checkStatus()
      setInterval(() => {
        this.checkStatus()
      }, 10000)
    },
    checkStatus () {
      const sessionState = Cookies.get('prkOpenIdSessionState')
      if (sessionState) {
        const text = this.env.prkOpIdentifier + ' ' + sessionState
        this.sessionIframe.contentWindow.postMessage(text, this.env.prkOpIssuer)
      }
    },
  },
  watch: {
    env: {
      deep: true,
      handler () {
        this.sessionIframe = this.addIFrameToWindow()
        window.addEventListener('message', this.iframeMessageEvent, false)
        this.startChecking()
      },
    },
  },
}
</script>

<style scoped lang="scss">
  @import "../assets/stylesheets/vars";

</style>
