export const studyPlansTable = {
  jsonapi: {
    studyPlans: {
      config: ({ page, url }) => ({
        method: 'get',
        url: url,
        params: {
          serializer: 'list',
          pagination: '1',
          page: page,
        },
      }),

      variables () {
        return {
          page: this.pagination.currentPage,
          url: this.url,
        }
      },
      skip () {
        return this.url.length === 0
      },
      update (studyPlans, requestInfo) {
        this.setPagination(requestInfo.raw.data)
        return studyPlans.map(plan => {
          return {
            id: plan.id,
            nameTokSubject: plan.name,
            faculty: plan.faculty,
            course: plan.courseName || '-',
            level: plan.type || '-',
            form: plan.kind || '-',
            selected: false,
            specialKind: plan.specialKind,
            u10Id: plan.u10Id,
            _showDetails: false,
            _syllabusCount: plan.syllabusCount,
            __type: plan.__type,
            semesters: this.$_.compact(plan.semesters)
              .map(sem => ({ ...sem, selected: false })),
          }
        })
      },
    },

    studyPlan: {
      config: ({ id }) => ({
        method: 'get',
        url: `study_plans/${id}`,
        params: {
          serializer: 'list',
        },
      }),
      variables () {
        return {
          id: this.id,
        }
      },
      skip () {
        return !this.id.length
      },
    },
  },

  data () {
    return {
      id: '',
      url: '',
      studyPlans: [],
      studyPlan: null,
    }
  },

  async created () {
    this.$watch(vm => [vm.query, vm.filters], this.$_.debounce(async function () {
      this.url = `study_plans?${this.$qs.stringify({
        query: this.query,
        filters: this.filters,
        elastic: 1,
      })}`
      this.$jsonapi.queries.studyPlans.refetch()
    }, 200), { deep: true, immediate: true })

    this.EXCLUDED_FIELDS = [
      'selected',
      'semesters',
      'specialKind',
      'u10Id',
      '__type',
    ]

    this.CUSTOM_FIELDS = [
      {
        key: 'details',
        label: '',
        tdClass: ['align-middle'],
      },
    ]
  },
}
