<template>
  <b-row class="pt-0">
    <b-col
        class="prk-pcg-fz-12 pcg-the-darkest-gray-color d-flex align-items-center mb-2 mb-sm-0"
        md="auto" sm="12">
      <div class="mr-3">
        {{ $t('general.category') }}
      </div>
    </b-col>
    <b-col md="auto" sm="12">
      <prk-select
          v-model="categoryId"
          class="d-inline"
          :options="categories"
          :default-option="{ text: $t('general.choose') }"
          :show-error="showError"
          :error-text="$t('general.errors.required')">
      </prk-select>
    </b-col>
  </b-row>
</template>

<script>
import PrkSelect from '../../PrkSelect'
import { inject } from '@vue/composition-api'
import { mapActions, mapState } from 'vuex'

export default {
  name: 'horizontal-select-categories',
  setup (_, { root }) {
    let categories = inject('categories', [])
    categories = root.$_.cloneDeep(categories.value)
    categories = categories.map(cat => ({
      value: cat.id, text: cat.name,
    }))
    return { categories }
  },
  components: { PrkSelect },
  props: {
    showError: { type: Boolean },
  },
  computed: {
    ...mapState('courseLearningOutcome', ['keu']),
    categoryId: {
      set (categoryId) {
        const catName = this.categories.find(cat => cat.value === categoryId).text
        this.setCategoryId(categoryId)
        this.setCategoryName(catName)
      },
      get () {
        return this.keu.categoryId
      },
    },
  },
  methods: {
    ...mapActions('courseLearningOutcome', ['setCategoryId', 'setCategoryName']),
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/stylesheets/vars';

::v-deep {
  .custom-class {
    color: $pcg-aqua-blue;
    font-size: 1rem;
    font-weight: bold;
  }

  @media only screen and (max-width: 576px) {
    .custom-class {
      font-size: 0.9rem !important;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {
    .custom-class {
      color: $pcg-aqua-blue;
    }
  }
}
</style>
