<template>
  <base-beam>
    <prk-beam>
      <template>
        {{ count }}
        <span class="text-lowercase d-inline-block mr-3">
          &nbsp; {{ $t('components.prk_syllabus.beam.selected_elements') }}
        </span>
        <pcg-btn
            size="small"
            variant="additional"
            @click="setSemesterProperty('active')"
        >
          <span class="text-lowercase">
            {{ $t('general.on_off') }}
          </span>
        </pcg-btn>
        <pcg-btn
            size="small"
            variant="additional"
            @click="setSemesterProperty('activeEffects')"
        >
          <span class="text-lowercase">
            {{ $t('general.on_off') }} {{ $t('general.teaching_effects') }}
          </span>
        </pcg-btn>

       <prk-group-prints
           :ids="semesterIds"
           resource="subjects"
           :resources="studyPlans"
       />

        <pcg-btn
            v-if="selectedPlans.length > 0"
            round
            :icon="iconRemove"
            text="delete"
            variant="danger"
            class="mr-3 prk-button"
            @click="$emit('remove-rows')"
        />
      </template>

      <template #mobile_beam>
        <div class="ml-4">
          {{ count }}
          <span class="text-lowercase mr-4">
            &nbsp; {{ $t('components.prk_syllabus.beam.selected_elements') }}
          </span>
        </div>
      </template>

      <template #options>
        <prk-group-prints
            :ids="semesterIds"
            resource="subjects"
            :resources="studyPlans"
        />

        <pcg-btn
            v-if="selectedPlans.length > 0"
            round
            :icon="iconRemove"
            text="delete"
            variant="danger"
            class="mr-3 prk-button"
            @click="$emit('remove-plans')"
        />
      </template>
    </prk-beam>
  </base-beam>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import PrkBeam from './PrkBeam'
import PrkGroupPrints from '../PrkGroupPrints'
import useIcon from '../../composables/useIcon'

const { mapActions } = createNamespacedHelpers('semesters')

export default {
  name: 'PrkBeamStudyPlanList',
  setup (_, { root }) {
    const { iconRemove } = useIcon(root)
    return { iconRemove }
  },
  components: { PrkGroupPrints, PrkBeam },
  props: {
    count: { required: true, type: Number },
    studyPlans: { required: true, type: Array },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    selectedPlans () {
      return this.studyPlans.filter(plan => plan.selected)
    },
    semesterIds () {
      const semesters = []
      this.studyPlans.forEach(plan => {
        semesters.push(...plan.semesters.filter(sem => sem.selected))
      })
      return semesters.map(sem => sem.id)
    },
  },
  watch: {
    count: {
      handler () {
        this.$emit('selected', this.count !== 0)
      },
      immediate: true,
    },
    contrast () {
      const { iconRemove } = useIcon(this.$root)
      this.iconRemove = iconRemove
    },
  },
  methods: {
    ...mapActions(['setSemesterProperty']),
  },
  destroyed () {
    this.$emit('selected', this.count === 0)
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  @media (max-width: $screen-xs-max) {
    .pcg-main-btn {
      padding: 0 1.5rem;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
