import api from '../../api'

export const pdf = {
  methods: {
    downloadPdf () {
      api.post('syllabuses/download_pdf', { id: this.syllabusId },
        { responseType: 'blob' }).then((response) => {
        this.$downloadFile(response, 'pdf', this.syllabus.name || this.syllabus.subject)
      })
    },
  },
}
