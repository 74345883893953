var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',{attrs:{"no-gutters":""}},[_c('pcg-box',{staticClass:"syllabus-box"},[_vm._t("header"),_vm._v(" "),_c('div',{staticClass:"contents mt-3"},[_vm._t("content",_vm._l((_vm.items),function(item,index){return _c('div',{key:index},[(item.type === 'checkbox' && item.visible)?[(item.descriptionAbove.length)?_c('h4',{staticClass:"font-weight-bold my-3",class:{ 'my-4': index === 0 }},[_vm._v("\n              "+_vm._s(item.descriptionAbove)+"\n            ")]):_vm._e(),_vm._v(" "),_c('pcg-checkbox',{staticClass:"font-weight-bold prk-fz-12",class:{
                'aqua-blue': item.selected,
                'mb-4': _vm.isLast(item, index)
              },on:{"input":function($event){return _vm.select(index, $event)}}},[_vm._v("\n              "+_vm._s(item.label)+"\n            ")]),_vm._v(" "),(item.descriptionUnder.length)?_c('h4',{staticClass:"font-weight-bold"},[_vm._v("\n              "+_vm._s(item.descriptionUnder)+"\n            ")]):_vm._e()]:_vm._e(),_vm._v(" "),((item.type === 'textarea' ||
                           item.type === 'input')  &&
                           item.visible)?[(item.descriptionAbove.length)?_c('h4',{staticClass:"font-weight-bold my-4"},[_vm._v("\n              "+_vm._s(item.descriptionAbove)+"\n            ")]):_vm._e(),_vm._v(" "),(item.type === 'input')?_c('div',{staticClass:"mb-4 prk-control"},[_c('pcg-text-input',{ref:_vm.setRef(item.type, index),refInFor:true,attrs:{"label":item.label,"type":item.type},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1):_c('div',{staticClass:"mb-4 prk-control"},[_c('label',{staticClass:"pcg-input-label"},[_vm._v(_vm._s(item.label))]),_vm._v(" "),_c('textarea-autosize',{ref:_vm.setRef(item.type, index),refInFor:true,staticClass:"text-area-autosize form-control pcg-field",attrs:{"type":"textarea","min-height":30},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}})],1),_vm._v(" "),(item.descriptionUnder.length)?_c('h4',{staticClass:"font-weight-bold mt-3"},[_vm._v("\n              "+_vm._s(item.descriptionUnder)+"\n            ")]):_vm._e()]:_vm._e(),_vm._v(" "),(item.type === 'table' && item.visible)?[(item.descriptionAbove.length)?_c('h4',{staticClass:"font-weight-bold my-3"},[_vm._v("\n              "+_vm._s(item.descriptionAbove)+"\n            ")]):_vm._e(),_vm._v(" "),_c('prk-table',{attrs:{"show-headline":false,"items":item.data},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
                           var fields = ref.fields;
return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({ width: '30%' })})})}},{key:"content",fn:function(ref){
                           var header = ref.header;
                           var data = ref.data;
return [(header.key !== 'actions')?_c('pcg-text-input',{staticClass:"min-width",attrs:{"show-label":false},model:{value:(data.item[header.key].value),callback:function ($$v) {_vm.$set(data.item[header.key], "value", $$v)},expression:"data.item[header.key].value"}}):_c('base-button-remove',{attrs:{"text":"delete_row"},on:{"click":function($event){return _vm.removeRow(data.index, item.data)}}})]}},{key:"custom-foot",fn:function(){return [_c('add-row',{attrs:{"items":item.data},on:{"addRow":function($event){return item.data.push($event)}}})]},proxy:true}],null,true)}),_vm._v(" "),(item.descriptionUnder.length)?_c('h4',{staticClass:"font-weight-bold mt-3"},[_vm._v("\n              "+_vm._s(item.descriptionUnder)+"\n            ")]):_vm._e()]:_vm._e()],2)}))],2)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }