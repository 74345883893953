<template>
  <fragment>
    <div v-b-popover.hover.top="ken.description"
         v-for="(ken, index) in kens"
         :key="index"
         class="pointer"
         style="width: max-content; line-height: 1.3rem"
    >
      {{ ken.symbol }}
    </div>
  </fragment>
</template>

<script>
export default {
  name: 'ObjectiveEffectsKens',
  props: {
    kens: { required: true, type: Array },
  },
}
</script>
