<template>
  <b-row class="mt-4 mb-3" align-v="center">
    <b-col lg="4" cols="12">
      <pcg-text-input
          :error-text="alreadyTaken ? $t('general.errors.already_taken') : $t('general.errors.required')"
          :label="$t('components.prk_dictionaries.standard_name')"
          :show-error="nameError || alreadyTaken"
          :value="name"
          @input="$emit('update:name', $event)"
      />
    </b-col>
    <b-col lg="4" cols="12">
      <pcg-search-select
          always-active
          :error-text="$t('general.errors.required')"
          :label="$t('components.prk_dictionaries.course_name')"
          :options="courseNames"
          :show-error="courseNameError"
          :value="courseNameId"
          show-label
          @input="$emit('update:course-name-id', $event)"
      />
    </b-col>
    <b-col lg="4" cols="12">
      <prk-select
          color="aqua"
          :default-option="defaultOption"
          :error-text="$t('general.errors.required')"
          :label="$tc('general.academic_year')"
          :options="academicYears"
          :show-error="academicYearError"
          @input="$emit('update:academic-year', $event)"
      />
    </b-col>
  </b-row>
</template>

<script>
import PrkSelect from '../../PrkSelect'
import useHelpers from '../../../composables/useHelpers'
import { ref } from '@vue/composition-api'

export default {
  name: 'StandardFields',
  setup (_, { root }) {
    const { generateAcademicYears } = useHelpers(root)
    const academicYears = ref(generateAcademicYears())
    const courseNames = ref([])
    return {
      academicYears,
      courseNames,
    }
  },
  components: {
    PrkSelect,
  },
  watch: {
    name () {
      this.$emit('clear-error')
    },
  },
  props: {
    academicYear: {
      required: true,
      type: String,
    },
    academicYearError: {
      required: true,
      type: Boolean,
    },
    courseNameId: {
      required: true,
      type: String,
    },
    courseNameError: {
      required: true,
      type: Boolean,
    },
    name: {
      required: true,
      type: String,
    },
    nameError: {
      required: true,
      type: Boolean,
    },
    alreadyTaken: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    defaultOption () {
      if (!this.academicYear.length) {
        return { text: this.$t('general.choose') }
      } else {
        return { text: this.academicYears.find(year => year.text.match(this.academicYear)).text }
      }
    },
  },
  async created () {
    const { data: courseNames } = await this.$api.get('course_names')
    this.courseNames = courseNames.map(courseName => {
      return {
        value: courseName.id,
        text: courseName.attributes.name,
      }
    })
  },
}
</script>
