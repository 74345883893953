import { render, staticRenderFns } from "./IconOptions.vue?vue&type=template&id=3499b664&scoped=true&"
import script from "./IconOptions.vue?vue&type=script&lang=js&"
export * from "./IconOptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3499b664",
  null
  
)

export default component.exports