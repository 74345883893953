import Vue from 'vue'
import Vuex from 'vuex'

import { auth } from './modules/auth'
import { footerNavbar } from './modules/footerNavbar'
import { notifications } from './modules/notifications'
import { sidebarMenu } from './modules/sidebarMenu'
import { comments } from './modules/comments'
import { syllabusTemplate } from './modules/syllabusTemplate'
import { semesters } from './modules/semesters'
import { subjects } from './modules/subjects'
import { personnel } from './modules/personnel'
import { error } from './modules/error'
import { studyPlans } from './modules/studyPlans'
import { courses } from './modules/courses'
import { syllabus } from './modules/syllabus'
import { syllabusFill } from './modules/syllabusFill'
import { matrices } from './modules/matrices'
import { courseLearningOutcome } from './modules/courseLearningOutcome'
import { coordinators } from './modules/coordinators'
import { instance } from './modules/instance'
import { environment } from './modules/env'
import { responsiblePersons } from './modules/responsiblePersons'
import { header, locale, menu, pageCover, page } from 'pcg-core-vue'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    footerNavbar,
    header,
    pageCover,
    notifications,
    locale,
    sidebarMenu,
    page,
    menu,
    comments,
    syllabusTemplate,
    semesters,
    subjects,
    personnel,
    error,
    studyPlans,
    courses,
    syllabus,
    syllabusFill,
    matrices,
    courseLearningOutcome,
    coordinators,
    instance,
    environment,
    responsiblePersons,
  },
})

export {
  store,
}
