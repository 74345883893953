<template>
  <div class="d-flex align-items-center pcg-main-active-color">
    <span class="prk-fz-24 pcg-icon-arrow-left pointer" @click="setYear(false)"/>
    <span class="prk-fz-12 font-weight-bold">{{ date.years1 }} / {{ date.years2 }}</span>
    <span class="prk-fz-24 pcg-icon-arrow-right pointer" @click="setYear(true)"/>
  </div>
</template>

<script>
export default {
  name: 'PrkDate',
  data () {
    return {
      date: {
        years1: null,
        years2: null,
      },
    }
  },
  created () {
    const academicYear = this.$route.query.academic_year
    if (academicYear) {
      const years = academicYear.split('/')
      this.date.years1 = +years[0]
      this.date.years2 = +years[1]
    } else {
      this.date.years1 = new Date().getFullYear()
      this.date.years2 = new Date().getFullYear() + 1
    }
  },
  methods: {
    setYear (add) {
      if (add) {
        this.date.years1 += 1
        this.date.years2 += 1
        this.$emit('update:academicYear', `${this.date.years1}/${this.date.years2}`)
      } else {
        this.date.years1 -= 1
        this.date.years2 -= 1
        this.$emit('update:academicYear', `${this.date.years1}/${this.date.years2}`)
      }
    },
  },
  watch: {
    date: {
      deep: true,
      immediate: true,
      handler () {
        this.$emit('update:academic-year', this.date)
      },
    },
  },
}
</script>

<style scoped lang="scss">
.pcg-icon-arrow-left, .pcg-icon-arrow-right {
  &:before {
    border: 1px solid;
    border-radius: 50%;
    margin-right: 0.5rem;
    margin-left: 0.5rem;
  }
}
</style>
