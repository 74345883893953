<template>
  <div>
    <template v-for="(field, property, index) in filteredFields">
      <b-row :key="index" align-v="center" class="my-4">
        <b-col cols="12" lg="3">
          <span class="font-weight-bold prk-fz-12 pcg-dark-gray-color">
            {{ $tc(`general.${$_.snakeCase(property)}`) }}
          </span>
        </b-col>
        <b-col cols="12" :lg="field.type === 'select' ? 'auto' : '5'">
          <prk-select
              v-if="field.type === 'select'"
              color="aqua"
              :default-option="defaultOption(field, property)"
              :error-text="$t(`views.study_plans.errors.${$_.snakeCase(property)}`)"
              :options="options(field, property)"
              :show-error="$v.fields[property].value.$error"
              @input="$emit('input',{ id: $event, property })"
          />
          <pcg-search-select
              v-else-if="field.type === 'autocomplete'"
              always-active
              :error-text="$t(`views.study_plans.errors.${$_.snakeCase(property)}`)"
              :options="options(field, property)"
              :show-error="$v.fields[property].value.$error"
              :value="field.value"
              @input="$emit('input',{ id: $event, property })"
              @focus="$emit('focus', property)"
          />
          <template v-else>
            <pcg-text-input
                :show-label="false"
                v-model.trim="$v.fields[property].value.$model"
                :show-error="showStudyPlanNameError"
                :error-text="studyPlanNameErrorText"
                @input="$emit('clear-error')"
            />
          </template>
        </b-col>
      </b-row>
      <prk-horizontal-line
          :width="{ value: -30, unit: 'px' }"
          :key="`${index}-hr`"
      />
    </template>
  </div>
</template>

<script>
import PrkSelect from '../../PrkSelect'
import PrkHorizontalLine from '../../syllabus/PrkHorizontalLine'
import { getStudyData } from '../../../mixins/getStudyData'

export default {
  name: 'NewStudyPlanFields',
  components: {
    PrkSelect,
    PrkHorizontalLine,
  },
  mixins: [getStudyData],
  props: {
    fields: {
      required: true,
      type: Object,
    },
    errors: {
      required: true,
      type: Object,
    },
  },
  inject: ['$v'],
  computed: {
    filteredFields () {
      if (this.fields.specialKind) {
        return this.$_.pick(this.fields, 'name', 'academicYear', 'faculty', 'courseName')
      } else {
        return this.$_.omit(this.fields, 'specialKind')
      }
    },
    studyPlanNameErrorText () {
      if (this.$v.fields.name.value.$error) {
        const key = 'views.study_plans.errors'
        return this.$t(`${key}.name_of_study_plan`)
      } else {
        return this.errors?.name
      }
    },
    showStudyPlanNameError () {
      if (this.errors.name) {
        return !!Object.keys(this.errors.name).length
      } else {
        return this.$v.fields.name.value.$error
      }
    },
  },
  watch: {
    'fields.faculty.value' (newFacultyId) {
      if (!newFacultyId.length) return
      const params = { course_names: { faculty_id: newFacultyId } }
      this.getData(['course_names'], null, params)
    },
  },
  created () {
    this.getData(['types', 'kinds', 'faculties'])
  },
  methods: {
    defaultOption (field, fieldKey) {
      const resource = this[this.$pluralize(fieldKey)] || field.data
      const text = resource.find(el => el.value === field.value)?.text
      if (field.value.length) {
        return { text: text, value: field.value }
      } else {
        return { text: this.$t('general.choose') }
      }
    },
    options (field, fieldKey) {
      if (this[this.$pluralize(fieldKey)]) {
        return this[this.$pluralize(fieldKey)]
      } else {
        return field.data
      }
    },
  },
}
</script>
