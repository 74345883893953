<template>
  <div class="d-flex">
    <pcg-btn
        round
        text="edit"
        class="mr-3 prk-button"
        :icon="iconEdit"
        @click="editMatrix(matrix)"
    />
    <icon-options :options="options" @click="setMatrix(matrix, index)"/>
  </div>
</template>

<script>
import IconOptions from '../../PrkIconOptions'
import useIcon from '../../../composables/useIcon'
import api from '../../../api'
import { mapGetters } from 'vuex'

export default {
  name: 'MatricesTableActions',
  setup (_, { root }) {
    const { iconEdit } = useIcon(root)
    return { iconEdit }
  },
  components: { IconOptions },
  props: {
    index: { required: true, type: Number },
    matrix: { required: true, type: Object },
    setMatrix: { required: true, type: Function },
    copyMatrix: { required: true, type: Function },
    destroyMatrix: { required: true, type: Function },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    options () {
      return [
        {
          text: this.$t('general.copy'),
          action: () => this.copyMatrix(this.matrix),
        },
        {
          text: this.$t('components.prk_matrices.settings.clo_effects_matrix'),
          action: () => this.downloadExcel(this.matrix),
        },
        {
          text: this.$t('general.remove'),
          action: () => this.destroyMatrix(this.matrix, this.index),
        },
      ]
    },
  },
  methods: {
    async downloadExcel () {
      api.post(`matrices/${this.matrix.id}/download_excel`, {},
        { responseType: 'blob' }).then((response) => {
        this.$downloadFile(response, 'xlsx', 'Matryca efektów KEU.xlsx')
      })
    },
    editMatrix (matrix) {
      this.$router.push({
        name: 'matrix_edit_path',
        params: { id: matrix.id },
        query: this.$_.pickBy({
          name: matrix.name,
          courseId: matrix.courseId,
        }, this.$_.identity),
      })
    },
  },
  watch: {
    contrast () {
      const { iconEdit } = useIcon(this.$root)
      this.iconEdit = iconEdit
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
