<template>
  <pcg-modal
      modal-id="newEffect"
      size="xl"
      :title="$t('components.prk_dictionaries.assign_learning_outcomes')"
      icon="fas fa-plus"
      :btn-title="$t('general.save')"
      :action="addSubEffects"
      @hidden="$emit('hidden')"
  >
    <div class="mb-2 subheader">
      {{ $t('components.prk_modals.course_effects.select_all') }}
    </div>
    <syllabus-sub-effects-modal-search @change:query="query = $event"/>

    <prk-table
        :show-headline="false"
        :items="onlyNotIncludedEffects"
        :excluded-fields="EXCLUDED_FIELDS"
        class="mt-3"
        disable-actions
        :fields-props="{ tdClass: tdClass }"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col-group
            :columns="['description', 'enDescription']"
            :fields="fields" width="70%"
        />
      </template>

      <template #head(id)>
        <pcg-checkbox :value="selectedAll" @input="select"/>
      </template>

      <template #head(details)/>

      <template #cell(id)="{ item: effect }">
        <pcg-checkbox v-model="effect.selected" />
      </template>

      <template #cell(description)="{ item: effect }">
        <prk-animated-description :description="effect.description"/>
      </template>

      <template #cell(enDescription)="{ item: effect }">
        <prk-animated-description :description="effect.enDescription"/>
      </template>

      <template #cell(details)="data">
        <base-arrow :show="data.item.toggleDetails"
                    @click="showText(data, $event)"
        />
      </template>

    </prk-table>
  </pcg-modal>
</template>

<script>
import PrkTable from '../../PrkTable'
import PrkAnimatedDescription from '../../PrkAnimatedDescription'
import ColGroup from '../../PrkColGroup'
import SyllabusSubEffectsModalSearch from './syllabus_sub_effects_modal/SyllabusSubEffectsModalSearch'
import useExpandText from '../../../composables/useExpandText'
import { inject } from '@vue/composition-api'

export default {
  name: 'syllabus-sub-effects-modal',
  setup () {
    const keks = inject('keks', [])
    const {
      tdClass,
      showText,
    } = useExpandText('prk-course-effects-modal')
    const syllabus = inject('syllabus', {})
    return {
      keks,
      showText,
      tdClass,
      syllabus,
    }
  },
  components: {
    SyllabusSubEffectsModalSearch,
    ColGroup,
    PrkAnimatedDescription,
    PrkTable,
  },
  data () {
    return {
      effects: [],
      query: '',
    }
  },
  watch: {
    categoryId (value) {
      this.effects = this.keks.filter(kek => kek.categoryId === value)
    },
  },
  computed: {
    selectedEffects () {
      return this.effects.filter(effect => effect.selected)
    },
    selectedAll () {
      return this.effects.length === this.selectedEffects.length
    },
    onlyNotIncludedEffects () {
      const keks = this.$store.state.courseLearningOutcome.keu.keks
      return this.effects.filter(effect => !keks.map(kek => kek.id)
        .includes(effect.id)).filter(effect => effect.symbol.match(this.query))
    },
    categoryId () {
      return this.$store.state.courseLearningOutcome.keu.categoryId
    },
  },
  methods: {
    select (selected) {
      selected ? this.$selectAll(this.effects) : this.$unselectAll(this.effects)
    },
    addSubEffects () {
      const keks = this.selectedEffects
      this.$store.dispatch('courseLearningOutcome/addKeks', keks)
      this.$unselectAll(this.effects)
      this.$bvModal.hide('newEffect')
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['selected', 'toggleDetails', 'categoryId']
    if (this.syllabus.language === 'English') {
      this.EXCLUDED_FIELDS.push('description')
    } else {
      this.EXCLUDED_FIELDS.push('enDescription')
    }
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

.subheader {
  color: $pcg-the-darkest-gray;
  font-weight: bold;
}

.label {
  color: $pcg-dark-gray;
  font-weight: bold;
}

@media only screen and (max-width: 998px) {
  .pcg-slide-tab-btn {
    padding: 0 .86rem !important;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .subheader {
    color: $pcg-the-darkest-gray;
  }

  .label {
    color: $pcg-dark-gray;
  }
}
</style>
