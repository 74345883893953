<template>
  <b-dropdown class="pcg-dropdown"
              menu-class="pcg-dropdown-option-container dropdown-menu"
              toggle-class="pcg-button pcg-main-btn pcg-normal-btn dropdown-toggle padding-sm">
    <template v-slot:button-content>
      <div class="d-flex justify-content-between">
        <slot name="name"></slot>
        <span class="pcg-icon-dropdown-arrow"></span>
      </div>
    </template>
    <slot></slot>
  </b-dropdown>
</template>

<script>
export default {
  name: 'PrkDropdown',
}
</script>

<style lang="scss" scoped>
  @import '../assets/stylesheets/vars';

  ::v-deep {
    .dropdown-menu.show {
      width: auto;
    }
    .btn-secondary:not(:disabled):not(.disabled):active,
    .btn-secondary:not(:disabled):not(.disabled).active,
    .show > .btn-secondary.dropdown-toggle {
      background-color: $pressed-color;
      box-shadow: 0 10px 20px rgba(22, 122, 165, .4);
    }
  }
</style>

<style lang="scss">
  @import "../assets/stylesheets/vars-contrast";

  .contrast {
    ::v-deep {
      .btn-secondary:not(:disabled):not(.disabled):active,
      .btn-secondary:not(:disabled):not(.disabled).active,
      .show > .btn-secondary.dropdown-toggle {
        background-color: $pressed-color;
        box-shadow: 0 10px 20px rgba(22, 122, 165, .4);
      }
    }
  }

  .pcg-icon-dropdown-arrow {
    font-size: 1.9rem !important;
  }

  .padding-sm {
    padding: 0 0.9rem !important;
  }
</style>
