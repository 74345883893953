export default function (root) {
  let contrast = false
  if (root) {
    contrast = root.$store.getters['page/contrast']
  }
  const iconBook = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    value: 'icon-book',
    type: 'component',
    width: '14',
    height: '16',
  }

  const iconCopy = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    value: 'icon-copy',
    type: 'component',
    width: '20',
    height: '22',
  }

  const iconEdit = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    height: '19',
    type: 'component',
    value: 'icon-edit',
    width: '15',
  }

  const iconRemove = {
    color: contrast ? '#00FA53' : '#00B4EB',
    height: '11',
    hoverColor: '#FC3939',
    type: 'component',
    value: 'icon-remove',
    width: '11',
  }

  const iconSendEmail = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    width: '41',
    height: '41',
    value: 'icon-email',
  }

  const iconPrintSyllabus = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-printer',
    width: '41',
    height: '42',
  }

  const iconUser = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-user',
    width: '16',
    height: '21',
  }

  const iconOptions = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-options',
    width: '16',
    height: '5',
  }

  const iconDrawingPin = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-drawing-pin',
    width: '14',
    height: '18',
  }

  const iconSave = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-save',
    width: '19',
    height: '19',
  }

  const iconPlus = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-plus',
    width: '19',
    height: '19',
  }

  const iconPdf = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-pdf',
    width: '18',
    height: '21',
  }

  const iconDoc = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-doc',
    width: '20',
    height: '21',
  }

  const iconSubjectEffect = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-subject-effect',
    width: '20',
    height: '19',
  }

  const iconClock = {
    color: contrast ? '#00FA53' : '#00B4EB',
    hoverColor: contrast ? '#00FA53' : '#00B4EB',
    type: 'component',
    value: 'icon-clock',
    width: '20',
    height: '19',
  }

  return {
    iconBook,
    iconClock,
    iconCopy,
    iconDoc,
    iconDrawingPin,
    iconEdit,
    iconOptions,
    iconPdf,
    iconPlus,
    iconPrintSyllabus,
    iconRemove,
    iconSave,
    iconSendEmail,
    iconUser,
    iconSubjectEffect,
  }
}
