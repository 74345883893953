<template>
  <div>
    <pcg-modal
        :action="saveCourseEffect"
        :btn-title="btnTitle"
        modal-id="courseEffectModal"
        size="lg"
        @hidden="$emit('hidden')"
    >
      <template #icon>
        <base-icon width="20" height="20" class="mr-3_5" :iconColor="iconColor">
          <icon-plus/>
        </base-icon>
        {{ title }}
      </template>

      <prk-horizontal-line :width="{ value: -28, unit: 'px' }" class="mb-3"/>
      <course-effect-categories
          :category-id="courseEffect.categoryId"
          :categories="categories"
          :touch-categories.sync="touchCategories"
          @update:category-data="updateCategory($event)"
      />
      <prk-horizontal-line :width="{ value: -28, unit: 'px' }" class="my-3"/>
      <effect-symbol
          :error="$v.courseEffect.symbol.$error"
          :active-category="category"
          :categories="categories"
          :symbol.sync="courseEffect.symbol"
      />
      <template v-if="!isStandardView">
        <prk-horizontal-line :width="{ value: -28, unit: 'px' }" class="my-3"/>
        <span class="prk-fz-12 mb-2 d-inline-block">
          {{ $t('components.prk_modals.course_effect.reference_effects') }}
        </span>
        <assign-general-effects
            v-if="!isStandardView"
            :category-id="categoryData.id"
            :effects.sync="courseEffect.effects"
            @update:touchCategories="touchCategories = true"
        />
      </template>
      <prk-horizontal-line :width="{ value: -28, unit: 'px' }" class="my-3"/>
      <course-effect-description
          :error="$v.courseEffect.description.$error"
          :description.sync="courseEffect.description"
      />

      <b-row align-h="end" class="pt-3 mr-0">
        <pcg-btn size="small" variant="additional" @click="en_desc = !en_desc">
          <span class="font-weight-bold">
            {{ en_desc ? $t('general.hide') : $t('general.add_en_description') }}
          </span>
        </pcg-btn>
      </b-row>

      <course-effect-description
          v-if="en_desc"
          :description.sync="courseEffect.enDescription"
      />
    </pcg-modal>

    <effect-for-general-effect
        v-if="!isStandardView"
        :category-id="categoryData.id"
        :characteristic-effect-ids="characteristicEffectIds"
        :error-text="$t('general.errors.required')"
        @add:effects="courseEffect.effects = $event"
    />
  </div>
</template>

<script>
import PrkHorizontalLine from '../../syllabus/PrkHorizontalLine'
import EffectForGeneralEffect from '../../dictionaries/modal/EffectForGeneralEffect'
import CourseEffectCategories from './course_effect/CourseEffectCategories'
import AssignGeneralEffects from './course_effect/AssignGeneralEffects'
import EffectSymbol from './course_effect/EffectSymbol'
import CourseEffectDescription from './course_effect/CourseEffectDescription'
import { required } from 'vuelidate/lib/validators'
import { inject } from '@vue/composition-api'
import { mapGetters } from 'vuex'

export default {
  name: 'PrkAddCourseEffectModal',
  setup () {
    const editMode = inject('editMode', false)
    const isStandardView = inject('isStandardView', false)
    return { editMode, isStandardView }
  },
  validations: {
    courseEffect: {
      categoryId: { required },
      symbol: { required },
      description: { required },
    },
  },
  components: {
    CourseEffectDescription,
    EffectSymbol,
    AssignGeneralEffects,
    EffectForGeneralEffect,
    PrkHorizontalLine,
    CourseEffectCategories,
  },
  data () {
    return {
      courseEffect: {
        customId: '',
        symbol: '',
        description: '',
        enDescription: '',
        range: '',
        categoryId: '',
        effects: [],
        selected: false,
        details: '',
        toggleDetails: false,
      },
      en_desc: false,
      touchCategories: false,
      categoryData: {
        id: '',
        index: -1,
      },
      category: {},
      firstEnter: true,
    }
  },
  props: {
    categories: {
      required: true,
      type: Array,
    },
    editedCourseEffect: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    iconColor () {
      return this.contrast ? '#008A39' : '#253B80'
    },
    btnTitle () {
      if (this.editMode) {
        return this.$t('general.update')
      } else {
        return this.$t('general.save')
      }
    },
    characteristicEffectIds () {
      return this.courseEffect.effects.map(effect => effect.id)
    },
    shortcut () {
      return this.category.shortcut || ''
    },
    title () {
      if (this.editMode) {
        return this.$t('general.edit_effect')
      } else {
        return this.$tc('general.add_effect')
      }
    },
  },
  watch: {
    categoryData: {
      deep: true,
      handler () {
        this.category = this.categories.find(cat => cat.value === this.categoryData.id) || {}
      },
    },
    editedCourseEffect: {
      deep: true,
      handler () {
        this.courseEffect = this.editedCourseEffect
      },
    },
  },
  methods: {
    async saveCourseEffect () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const clonedEffect = this.$_.cloneDeep(this.courseEffect)
        if (!this.editMode) clonedEffect.customId = this.$uuidv4()
        if (this.editMode) {
          this.$emit('update:course-effect', clonedEffect)
        } else {
          this.$emit('add:course-effect', clonedEffect)
        }
        this.$bvModal.hide('courseEffectModal')
        this.$emit('update:edit-mode', false)
      } else {
        this.touchCategories = true
      }
    },
    updateCategory (categoryData) {
      this.categoryData = categoryData
      this.courseEffect.categoryId = categoryData.id
      if (!(this.firstEnter && this.editMode)) {
        this.courseEffect.effects = []
      }
      this.firstEnter = false
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .tags-container {
    padding-top: 0;

    .tag {
      margin-bottom: 0;
    }
  }

  .pcg-button {
    margin: 0;
  }

  .pcg-textarea {
    height: 12rem;
  }
}
</style>
