<template>
  <base-button
      :text="text"
      @mouseover="iconHover = true"
      @mouseleave="iconHover = false"
      class="danger"
      v-on="$listeners"
  >
    <base-icon
        width="11"
        height="12"
        :iconColor="iconHover ? '#FC3939' : '#00B4EB'"
    >
      <icon-remove/>
    </base-icon>
  </base-button>
</template>

<script>
export default {
  name: 'BaseButtonRemove',
  props: {
    text: { required: true, type: String },
  },
  data () {
    return {
      active: false,
      iconHover: false,
    }
  },
  computed: {
    iconColor () {
      return this.active ? 'white' : '#00B4EB'
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  &.danger:hover {
    .pcg-button {
      background: rgba(252, 71, 71, 0.5) !important;
    }
  }
}
</style>
