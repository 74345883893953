import useHelpers from './useHelpers'

const { uuidv4 } = useHelpers()

export default function (root) {
  const defaultNumberOfTerms = [
    { text: 'I', value: 1 },
    { text: 'II', value: 2 },
    { text: 'III', value: 3 },
    { text: 'IV', value: 4 },
    { text: 'V', value: 5 },
    { text: 'VI', value: 6 },
    { text: 'VII', value: 7 },
    { text: 'VIII', value: 8 },
    { text: 'IX', value: 9 },
    { text: 'X', value: 10 },
    { text: 'XI', value: 11 },
    { text: 'XII', value: 12 },
  ]

  async function getSemesters (url, params = {}) {
    let { data: semesters } = await root.$api.get(url, params)
    semesters = semesters.map(semester => {
      return {
        id: semester.id,
        ...semester.attributes,
        relationships: semester.relationships,
      }
    })
    return semesters
  }

  const getSemesterDefaultObject = (numberOfTerms, index) => {
    return {
      id: uuidv4() + '__new',
      name: `${root.$tc('general.term', 1)} ${numberOfTerms[index].text}`,
      semesterType: index % 2 === 0 ? 'winter' : 'summer',
      semesterNumber: index + 1,
      relationships: {
        speciality: {
          data: { id: '', type: 'speciality' },
        },
        specialization: {
          data: { id: '', type: 'specialization' },
        },
      },
    }
  }

  const mapSemestersStudyPlanList = (semesters) => {
    return semesters.map(semester => {
      return {
        id: semester.id,
        nameOfTerm: semester.attributes.name,
        term: _getTermNumber(semester),
        typeOfTerm: root.$t(`general.${semester.attributes.semesterType}`),
        ects: semester.attributes.ects || '-',
        switch: semester.attributes.active,
        effects: semester.attributes.activeEffects,
        semesterNumber: semester.attributes.semesterNumber,
        studyPlanId: semester.relationships.studyPlan.data.id,
        selected: false,
        type: semester.type,
      }
    })
  }

  const updateSemestersHandler = async (selectedSemesters, semesterProperty) => {
    if (!semesterProperty.length) return
    try {
      selectedSemesters.forEach(sem => (sem[semesterProperty] = !sem[semesterProperty]))
      const semesters = selectedSemesters.map(sem => ({ id: sem.id, ...getSemesterParams(sem) }))
      await root.$api.put('semesters/update_all', { semester: { semesters } })
    } catch (e) {
      root.$showError(root.$getErrors(e.errors))
    }
  }

  const getSemesterParams = (semester) => {
    return {
      active: semester.active,
      active_effects: semester.activeEffects,
    }
  }

  const _getTermNumber = (semester) => {
    const semesterNumber = semester.attributes.semesterNumber
    return `${root.$tc('general.term', 1)} ${semesterNumber}`
  }

  return {
    getSemesters,
    getSemesterDefaultObject,
    mapSemestersStudyPlanList,
    defaultNumberOfTerms,
    updateSemestersHandler,
    getSemesterParams,
  }
}
