<template>
  <fragment>
    <pcg-comments-modal
        :comments="comments"
        :currentUser="{ id: userId }"
        modal-id="commentModal"
        adding-comments-enabled
        comments-list-enabled
        @commentWasAdded="addComment"
        @commentWasEdited="editComment($event)"
    >
      <template #avatar>
        <prk-avatar :avatar="avatar" :abbr="abbr" :width="44"
                    :height="44" without-letter-spacing/>
      </template>
    </pcg-comments-modal>
    <pcg-all-comments-modal
        :btn-title="$t('components.prk_modals.comments.add_comment')"
        :current-user="{ id: userId }"
        modal-id="allCommentsModal"
        :comments-list-enabled="status === 'to_verification'"
        :sections="commentsBySection"
        :title="$t('components.prk_modals.comments.name')"
        hideSectionWithoutComments
        @commentWasEdited="editComment($event)"
    >
      <template #avatar>
        <prk-avatar :avatar="avatar" :abbr="abbr" :width="44"
                    :height="44" without-letter-spacing/>
      </template>
    </pcg-all-comments-modal>
  </fragment>
</template>
<script>

import PrkAvatar from '../../PrkAvatar'
import { mapActions, mapGetters, mapState } from 'vuex'
import { inject } from '@vue/composition-api'

export default {
  name: 'syllabus-comments',
  setup () {
    const status = inject('status')
    return { status }
  },
  components: {
    PrkAvatar,
  },
  props: {
    sections: { required: true, type: Array },
  },
  data () {
    return {
      commentsBySection: [],
    }
  },
  computed: {
    ...mapState('auth', ['avatar', 'userId']),
    ...mapState('comments', ['allCommentCount', 'commentableType']),
    ...mapGetters('auth', ['abbr']),
    ...mapGetters('comments', ['comments']),
    difference () {
      return this.$_.cloneDeep(this.$store.state.comments.difference)
    },
  },
  watch: {
    difference (newDifference, oldDifference) {
      const comment = this.findComment(newDifference, oldDifference)
      if (this.commentableType === 'SyllabusBasicInformationRow') {
        const comments = this.commentsBySection[0].comments
        this.addOrUpdateComment(comments, comment)
      } else if (this.commentableType === 'Effect') {
        const section = this.commentsBySection.find(sec => sec.number === '3.2')
        const comments = section.comments
        this.addOrUpdateComment(comments, comment)
      } else {
        const section = this.commentsBySection.find(sec => sec.id === comment.commentableId)
        const comments = section.comments
        this.addOrUpdateComment(comments, comment)
      }
    },
    sections () {
      const comments = this.sections.map(section => {
        return {
          id: section.id,
          name: section.header,
          number: section.number,
          comments: section.comments,
        }
      })

      this.commentsBySection = this.$_.cloneDeep(comments)
    },
  },
  methods: {
    ...mapActions('comments', ['editComment', 'setCommentCount', 'setAllCommentCount']),
    addComment (commentInfo) {
      this.$store.dispatch('comments/addComment', commentInfo)
      this.setCommentCount(this.comments.length)
    },
    addOrUpdateComment (comments, comment) {
      const editedCommentIndex = comments.findIndex(com => com.id === comment.id)
      if (editedCommentIndex === -1) {
        comments.push(comment)
        this.setAllCommentCount(this.allCommentCount + 1)
      } else {
        comments[editedCommentIndex] = comment
      }
    },
    findComment (newDifference, oldDifference) {
      let comment = this.$_.differenceBy(newDifference, oldDifference, 'text')[0]
      if (!comment) comment = this.$_.last(newDifference)
      return comment
    },
  },
}
</script>
