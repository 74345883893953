<template>
  <b-row class="mt-3">
    <b-col cols="5">
      <pcg-text-input :label="$t('general.name')"
                      :value="$attrs.name"
                      @input="$emit('update:name', $event)"
                      :error-text="alreadyTaken ? $t('general.errors.already_taken') : $t('general.errors.required')"
                      :show-error="nameError"
      />
    </b-col>
    <b-col cols="5">
      <pcg-search-select
          show-label
          :label="$t('general.template')"
          always-active
          :disabled="disableTemplate"
          :value="$attrs.syllabusId"
          @input="$emit('update:syllabusId', $event)"
          :options="syllabusTemplates"
          :error-text="$t('general.errors.required')"
          :show-error="false"
      />
    </b-col>
  </b-row>
</template>
<script>

export default {
  name: 'matrix-inputs',
  data () {
    return {
      syllabusTemplates: [],
    }
  },
  props: {
    nameError: {
      required: true,
      type: Boolean,
    },
    alreadyTaken: {
      required: true,
      type: Boolean,
    },
    disableTemplate: Boolean,
  },
  methods: {
    async getTemplates () {
      const { data: templates } = await this.$api.get('syllabuses/index_templates')
      this.syllabusTemplates = templates.map(template => {
        return {
          text: template.attributes.name,
          value: template.id,
        }
      })
    },
  },
  created () {
    this.getTemplates()
  },
}
</script>
<style scoped lang="scss">
::v-deep {
  input[disabled] {
    font-size: 1rem;
    margin-top: 0.8rem;
    padding-left: 0 !important;
  }
}
</style>
