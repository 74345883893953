import ApiInstances from '../../api/instances'

export const instance = {
  namespaced: true,
  state: {
    instance: null,
  },
  getters: {
    instance: state => state.instance,
  },
  mutations: {
    SET_INSTANCE (state, instance) {
      state.instance = instance
    },
  },
  actions: {
    setInstance ({ commit }, payload) {
      ApiInstances.getInstance()
        .then(response => {
          let instance = {
            menuLogo: null,
            menuLogoNegative: null,
          }
          if (response.data.data) {
            instance = response.data.data.attributes
          }
          commit('SET_INSTANCE', instance)
        })
    },
  },
}
