// explanation why not use this._uid
// https://github.com/vuejs/vue/issues/5886#issuecomment-308625735
export default function (root = null) {
  function uuidv4 () {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
      function (c) {
        const r = Math.random() * 16 | 0
        const v = c === 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
  }

  const isEditRoute = (name = '') => {
    const regex = /edit/
    return regex.test(root.$route.name)
  }

  const generateAcademicYears = (range = 8, offset = 4) => {
    const academicYears = []
    let fullYear
    if (root.$route.query.academic_year) {
      fullYear = +root.$route.query.academic_year.split('/')[0]
    } else {
      fullYear = new Date().getFullYear()
    }
    for (let i = 0; i < range; i++) {
      academicYears.push({
        text: `${fullYear + offset - i - 1}/${fullYear + offset - i}`,
        value: `${fullYear + offset - i - 1}/${fullYear + offset - i}`,
      })
    }
    return academicYears
  }

  return {
    generateAcademicYears,
    isEditRoute,
    uuidv4,
  }
}
