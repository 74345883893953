import Vue from 'vue'
import VueCompositionApi, { ref } from '@vue/composition-api'
import { statuses } from '../helpers/statuses'

Vue.use(VueCompositionApi)

const states = ref({})
const loading = ref(false)

export default function (root) {
  const getStates = async () => {
    loading.value = true
    const { data } = await root.$api.get('states')
    states.value = data.attributes.states
    loading.value = false
  }
  const availableStatuses = (status) => {
    if (!states) return
    const available = []
    for (const [key, value] of Object.entries(states.value)) {
      if (Array.isArray(value)) {
        if (value.includes(status)) {
          available.push(root.$_.snakeCase(key))
        }
      } else {
        if (value === status) {
          available.push(root.$_.snakeCase(key))
        }
      }
    }
    return statuses.filter(status => available.includes(status.value))
  }

  return {
    availableStatuses,
    getStates,
    loading,
    statuses,
    states,
  }
}
