import useHelpers from './useHelpers'

export default function (root) {
  const { uuidv4 } = useHelpers()
  // ---------------------------------------------------------------------------------
  const mapEducators = (educators) => {
    return educators.map(educator => {
      return {
        id: educator.id,
        name: `${educator.attributes.firstName} ${educator.attributes.lastName}`,
        roles: educator.attributes.roles,
        abbr: educator.attributes.abbr,
        avatar: educator.attributes.avatar,
      }
    })
  }
  // ---------------------------------------------------------------------------------
  const mapKeks = (effects) => {
    return effects.map(effect => {
      return {
        id: effect.attributes.effectId,
        symbol: effect.attributes.symbol,
        description: effect.attributes.description,
        enDescription: effect.attributes.enDescription,
        categoryId: effect.attributes.categoryId,
        selected: false,
        toggleDetails: false,
        details: false,
      }
    })
  }
  // ---------------------------------------------------------------------------------
  const mapLecturers = (semesters) => {
    return semesters.map(semester => {
      semester.attributes.subjectForms.map(form => {
        form.lecturers.map(lecturer => {
          root.$store.dispatch('syllabusFill/addLeader', {
            value: lecturer.id,
            text: `${lecturer.firstName} ${lecturer.lastName}`,
          })
        })
      })
    })
  }
  // ---------------------------------------------------------------------------------
  const mapSections = (sectionsData) => {
    return sectionsData.map((section) => {
      return {
        id: section.id,
        ...section.attributes,
        elements: [],
        rows: [],
      }
    })
  }
  // ---------------------------------------------------------------------------------
  const mapSemesters = (semesters) => {
    const sems = semesters.map(semester => {
      const forms = semester.attributes.subjectForms
      return {
        id: semester.id,
        semester: semester.attributes.name,
        subjectForms: forms,
        semesterNumber: semester.attributes.semesterNumber,
      }
    })
    root.$store.dispatch('syllabusFill/setHasTeachingEffects', semesters[0].attributes.activeEffects)
    return sems
  }
  // ---------------------------------------------------------------------------------
  const setSubjectEffectsToVuex = (subjectEffects, newEffect = false) => {
    root.$store.dispatch('syllabusFill/setSubjectEffects', subjectEffects.map(effect => {
      return {
        id: newEffect ? `${uuidv4()}__new` : effect.id,
        categoryId: effect.attributes.categoryId,
        categoryName: effect.attributes.categoryName,
        educationalOutcome: effect.attributes.symbol,
        effectContentObj: effect.attributes.description,
        formOfClasses: effect.attributes.formOfClasses.map(form => ({
          value: form.id,
          text: form.name,
        })),
        formOfCredits: effect.attributes.formOfCredits.map(form => ({
          value: form.id,
          text: form.name,
        })),
        keks: effect.attributes.keks,
        kens: effect.attributes.kens,
        verificationMethod: effect.attributes.verificationMethod,
      }
    }))
  }
  // ---------------------------------------------------------------------------------
  const setDidacticMethodsToVuex = (didacticMethods, formOfClasses) => {
    const didactics = didacticMethods.map(method => {
      return {
        id: method.id,
        formOfClassId: method.attributes.formOfClassId,
        formOfClass: method.attributes.formOfClassName,
        text: method.attributes.text,
      }
    })
    root.$store.dispatch('syllabusFill/setDidacticMethods', didactics)
    if (didactics.length) return
    formOfClasses.value.forEach(form => {
      const index = didactics.findIndex(method => method.formOfClassId === form.value)
      if (!~index) {
        root.$store.dispatch('syllabusFill/addDidacticMethod',
          {
            formOfClassId: form.value,
            formOfClass: form.text,
            text: '',
          })
      }
    })
  }
  // ---------------------------------------------------------------------------------
  const setLeadersToVuex = (leaders) => {
    root.$store.dispatch('syllabusFill/setLeaders',
      leaders.map(leader => {
        return {
          value: leader.id,
          text: `${leader.attributes.firstName} ${leader.attributes.lastName}`,
        }
      }),
    )
  }
  // ---------------------------------------------------------------------------------
  const setLiteraturesToVuex = (literatures) => {
    root.$store.dispatch('syllabusFill/setLiteratures',
      literatures.map(literature => {
        return {
          ...root.$_.omit(literature.attributes.literature, 'year'),
          year: literature.attributes.literature.year || '',
        }
      }))
  }
  // ---------------------------------------------------------------------------------
  const setTeachingEffectsToVuex = async () => {
    const { data: teachingEffects } = await root.$api.get('effects?effect_type=teaching&active=true')
    await root.$store.dispatch('syllabusFill/setTeachingEffects', teachingEffects.map(effect => {
      return {
        id: effect.id,
        symbol: effect.attributes.symbol,
        description: effect.attributes.description,
        range: effect.attributes.range,
        selected: false,
        toggleDetails: false,
        details: '',
      }
    }))
  }
  // ---------------------------------------------------------------------------------
  const setWorkloadToVuex = (ownWork, workWithTeacher, semesters) => {
    root.$store.dispatch('syllabusFill/setPlanHours', planHours(semesters))
    root.$store.dispatch('syllabusFill/setEcts', etcs(semesters))
    root.$store.dispatch('syllabusFill/setWorkWithTeacher', workWithTeacher)
    root.$store.dispatch('syllabusFill/setOwnWork', ownWork)
  }

  const planHours = (semesters) => {
    return root.$_.sum(semesters.map(semester => semester.subjectForms.map(form => {
      return form.numberOfHours
    })).flat())
  }
  const etcs = (semesters) => {
    return root.$_.sum(semesters.map(semester => semester.subjectForms.map(form => {
      return form.ects
    })).flat())
  }
  // ---------------------------------------------------------------------------------

  return {
    mapEducators,
    mapKeks,
    mapLecturers,
    mapSections,
    mapSemesters,
    setDidacticMethodsToVuex,
    setLeadersToVuex,
    setLiteraturesToVuex,
    setSubjectEffectsToVuex,
    setTeachingEffectsToVuex,
    setWorkloadToVuex,
  }
}
