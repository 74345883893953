<template>
  <div class="d-flex flex-wrap">
    <pcg-btn
        v-if="!isStandardView"
        variant="additional"
        size="small"
        @click="$bvModal.show('standardPickerModal')"
    >
      <span class="font-weight-bold">
      {{ $t('components.prk_courses.settings.download') }}
      </span>
    </pcg-btn>
    <pcg-btn variant="additional" size="small" @click="expandOrCollapse">
      <span v-if="expandedAll" class="font-weight-bold">
        {{ $t('general.collapse_all') }}
      </span>
      <span v-else class="font-weight-bold">
        {{ $t('general.expand_all') }}
      </span>
    </pcg-btn>
    <pcg-btn v-if="course" class="font-weight-bold" size="small"
             variant="additional" v-b-modal.downloadModal>
      {{ $t('components.prk_courses.download') }}
    </pcg-btn>
    <b-row class="d-flex justify-content-end m-0 p-0">
      <pcg-btn class="font-weight-bold" size="small" v-b-modal.courseEffectModal>
        {{ nameOfEffectButton }}
      </pcg-btn>
    </b-row>
  </div>
</template>

<script>
import { inject } from '@vue/composition-api'
import useExpandText from '../../../composables/useExpandText'

export default {
  name: 'CourseEffectButtons',
  setup () {
    const isStandardView = inject('isStandardView', false)
    const { expandedAll } = useExpandText()
    return { expandedAll, isStandardView }
  },
  props: {
    course: { required: true, type: Boolean },
    filteredCourseEffects: { required: true, type: Array },
    expandAll: { required: true, type: Function },
    collapseAll: { required: true, type: Function },
  },
  computed: {
    nameOfEffectButton () {
      if (this.isStandardView) {
        return this.$tc('general.add_effect')
      } else {
        return this.$t('general.add_course_effect')
      }
    },
  },
  methods: {
    expandOrCollapse () {
      if (this.expandedAll) {
        this.collapseAll()
      } else {
        this.expandAll()
      }
    },
  },
}
</script>
