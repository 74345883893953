<template>
  <b-col cols="12" sm="4" class="mb-2 mb-sm-0">
    <b-row no-gutters>
      <b-col cols="12" lg="2">
        <pcg-switch :value="selectedAll" @input="selectEffects"/>
      </b-col>
      <b-col>
        <span class="label">
          {{ $t('general.select_all') }}
        </span>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
export default {
  name: 'CourseEffectsModalSelectAll',
  props: {
    selectedAll: { required: true, type: Boolean },
    selectEffects: { required: true, type: Function },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.label {
  color: $pcg-gray;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .label {
    color: $pcg-gray;
  }
}
</style>
