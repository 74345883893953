<template>
  <div>
    <transition v-if="showTextInput" name="fade">
      <basic-information-about-subject-input
          :value="$attrs.value"
          :type="type"
          @input="$emit('update:value', $event)"
      />
    </transition>
    <transition v-else-if="showSelect" name="fade">
      <basic-information-about-subject-select
          :value="$attrs.value"
          @input="$emit('update:value', $event)"
      />
    </transition>
    <transition v-else-if="showSearchSelect" name="fade">
      <basic-information-about-subject-search-select
          :information="$attrs.information"
      />
    </transition>
    <transition v-else-if="showSearchSelectUnits" name="fade">
      <basic-information-about-subject-search-select-unit
          :value="$attrs.value"
          @input="$emit('update:value', $event)"/>
    </transition>
    <basic-information-about-subject-text
        v-else
        :header="header"
        :value="$attrs.value"
        :information="$attrs.information"
    />
  </div>
</template>

<script>
import BasicInformationAboutSubjectInput from './shared/BasicInformationAboutSubjectInput'
import BasicInformationAboutSubjectSelect from './shared/BasicInformationAboutSubjectSelect'
import BasicInformationAboutSubjectSearchSelect from './shared/BasicInformationAboutSubjectSearchSelect'
import BasicInformationAboutSubjectText from './BasicInformationAboutSubjectText'
import BasicInformationAboutSubjectSearchSelectUnit from './shared/BasicInformationAboutSubjectSearchSelectUnit.vue'
import { inject } from '@vue/composition-api'

export default {
  name: 'ToFillContentBasicInformation',
  setup () {
    const status = inject('status')
    const editMode = inject('editMode', true)
    return {
      status,
      editMode,
    }
  },
  components: {
    BasicInformationAboutSubjectText,
    BasicInformationAboutSubjectSearchSelect,
    BasicInformationAboutSubjectSelect,
    BasicInformationAboutSubjectInput,
    BasicInformationAboutSubjectSearchSelectUnit,
  },
  props: {
    data: {
      required: true,
      type: Object,
    },
    header: {
      required: true,
      type: Object,
    },
  },
  computed: {
    type () {
      return this.$attrs.elementType
    },
    show () {
      return ['to_fill', 'during_fill', 'to_improve'].includes(this.status) && this.editMode
    },
    showTextInput () {
      const cond1 = ['input', 'textarea'].includes(this.type) && this.header.key === 'value'
      const cond2 = this.show
      return cond1 && cond2
    },
    showSelect () {
      const cond1 = this.$attrs.information === 'language' && this.header.key === 'value'
      const cond2 = this.show
      return cond1 && cond2
    },
    showSearchSelect () {
      const cond1 = this.$attrs.information === 'leading_persons' && this.header.key === 'value'
      const cond2 = this.show
      return cond1 && cond2
    },
    showSearchSelectUnits () {
      const cond1 = this.$attrs.information === 'row_3' && this.header.key === 'value'
      const cond2 = this.show
      return cond1 && cond2
    },
  },
}
</script>
