<template>
  <div class="pcg-slide-tabs btn-group">
    <button v-for="(category, index) in categories"
            ref="categoriesRef" :key="index"
            class="pcg-slide-tab-btn"
            :class="{ 'active': activeIndex === index }"
            @click="click(index, $event)"
    >
      {{ category.text }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'Categories',
  props: {
    categories: { required: true, type: Array },
    changeCategory: { type: Function },
    args: { type: Object, default: () => ({}) },
  },
  data () {
    return {
      categoryId: '',
      activeIndex: -1,
    }
  },
  methods: {
    click (index, event) {
      if (this.args.emit) {
        this.toggleCategoryId(index)
        this.$emit('update:category-id', this.categoryId)
      } else {
        this.changeCategory(index, event, this.args)
      }
    },
    toggleCategoryId (index) {
      if (this.activeIndex === index && this.categoryId.length) {
        this.categoryId = ''
        this.activeIndex = -1
      } else {
        this.categoryId = this.categories[index].id
        this.activeIndex = index
      }
    },
  },
}
</script>

<style scoped lang="scss">
.pcg-slide-tabs {
  border-radius: initial;
}

@media screen and (min-width: 768px) {
  .pcg-slide-tabs.btn-group {
    display: inline-flex;
    border-radius: 100px;
  }
}
</style>
