import Env from '../../api/env'

const environment = {
  namespaced: true,
  state: {
    env: null,
    status: 'pending',
  },
  getters: {
    env: state => state.env,
    status: state => state.status,
  },
  mutations: {
    setEnv (state, env) {
      state.env = env
      if (env) {
        state.status = 'loaded'
      } else {
        state.status = 'pending'
      }
    },
  },
  actions: {
    fetchEnv ({ commit, dispatch, state }) {
      return new Promise((resolve, reject) => {
        Env.getEnv()
          .then(response => {
            commit('setEnv', response.data.env)
            resolve(response)
          })
          .catch(error => {
            commit('setEnv', null)
            reject(error)
          })
      })
    },
  },
}

export {
  environment,
}
