<template>
  <b-row no-gutters class="pr-5" :class="{ 'mt-3': status === fillContent && editMode }">
    <b-col>
      <ul class="list">
        <template v-for="(literature, index) in addedLiterature">
          <li class="d-flex align-items-center justify-content-between" :key="index">
            <div class="d-flex align-items-center">
              <div class="index">
                {{ index + 1 }}
              </div>
              <span class="publication">
                  {{ literatureText(literature) }}
                </span>
            </div>
            <i v-if="fillContent && editMode"
               class="pcg-icon-cross"
               @click="removeLiterature({ id: literature.id, customId: literature.customId } )"
            />
          </li>
          <prk-pcg-horizontal-line
              :key="`${index}-hr`"
              :width="{ value: 0, unit: 'px' }"
          />
        </template>
      </ul>
    </b-col>
  </b-row>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import PrkPcgHorizontalLine from '../../PrkHorizontalLine'
import { fillStatus } from '../../../../mixins/syllabus/status'
import { inject } from '@vue/composition-api'
const { mapActions } = createNamespacedHelpers('syllabusFill')

export default {
  name: 'added-literatures',
  setup () {
    const editMode = inject('editMode', true)
    const status = inject('status')
    return { status, editMode }
  },
  components: { PrkPcgHorizontalLine },
  mixins: [fillStatus],
  props: {
    addedLiterature: Array,
    literatureText: Function,
  },
  methods: {
    ...mapActions(['removeLiterature']),
  },
}
</script>
<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

.list {
  list-style: none;

  .index {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 2px 15px;
    background: $pcg-lightest-gray;
    border-radius: 5px;
    margin: 12px 0;
    width: 37px;
    height: 26px;
    font-size: 0.86rem;
  }

  .publication {
    margin-left: 1rem;
    color: $pcg-dark-blue;
    font-size: 0.8rem;
    line-height: 14px;
  }

  .pcg-icon-cross {
    color: $pcg-gray;
    font-size: 0.71rem;
    cursor: pointer;
  }
}

</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .list {
    .index {
      background: $pcg-lightest-gray;
    }

    .publication {
      color: $pcg-dark-blue;
    }

    .pcg-icon-cross {
      color: $pcg-gray;
    }
  }
}
</style>
