<template>
  <fragment>
    <h4 v-if="item.descriptionAbove.length"
        class="font-weight-bold mt-3 mb-0" v-html="item.descriptionAbove">
    </h4>
    <slot/>
    <h4 v-if="item.descriptionUnder.length"
        class="font-weight-bold mt-3 mb-0"
        v-html="item.descriptionUnder">
    </h4>
  </fragment>
</template>

<script>
export default {
  name: 'SectionContent',
  props: {
    item: {
      required: true,
      type: Object,
    },
  },
}
</script>
