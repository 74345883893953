<template>
  <fragment>
    <div v-if="avatar">
      <b-img
          class="mr-2"
          rounded="circle"
          :src="avatar"
          :height="height"
          :width="width"
          alt="person">
      </b-img>
      <span v-if="text.length" :class="textClass">
      {{ text }}
      </span>
    </div>
    <div v-else class="d-flex align-items-center">
      <div class="avatar pcg-main-active-color mr-2" :class="transparent" :style="widthAndHeight">
        <span class="font-weight-bold" :style="{ letterSpacing: withoutLetterSpacing && '-1px' }">
          {{ abbr }}
        </span>
      </div>
      <span v-if="text.length" :class="textClass">
      {{ text }}
      </span>
    </div>
  </fragment>
</template>

<script>
export default {
  name: 'PrkAvatar',
  props: {
    avatar: { type: [String, null] },
    abbr: { required: true, type: String },
    hideBackground: { type: Boolean, default: false },
    text: { type: String, default: '' },
    textClass: { default: '', type: [String, Array] },
    width: { default: 25, type: Number },
    height: { default: 25, type: Number },
    withoutLetterSpacing: { default: false, type: Boolean },
  },
  computed: {
    transparent () {
      return { transparent: this.hideBackground }
    },
    widthAndHeight () {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  min-height: 30px;
  min-width: 30px;
  background: $pcg-the-lightest-gray;
  span {
    letter-spacing: 1px;
  }
}

.transparent {
  background: transparent;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .avatar {
    background: $pcg-the-lightest-gray;
  }
}
</style>
