<template>
  <fragment>
    <section-content :item="item">
      <div class="mt-1">{{ item.label }}</div>
    </section-content>
    <prk-horizontal-line
        v-if="showHorizontalLine"
        :width="{ value: -30, unit: 'px' }"
        class="my-3"
    />
  </fragment>
</template>
<script>
import PrkHorizontalLine from '../PrkHorizontalLine'
import SectionContent from './to_show_content/SectionContent'

export default {
  name: 'to-show-checkbox',
  components: { SectionContent, PrkHorizontalLine },
  props: {
    item: { required: true, type: Object },
    showHorizontalLine: { required: true, type: Boolean },
  },
}
</script>
