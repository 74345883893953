<template>
  <div class="br-5 box-without-content">
    <syllabus-box :index="section.number">
      <template #header>
        <preview-header :index="section.number" :show-headling-index="false">
          <div class="d-flex align-items-center">
            {{ section.header }}
            <comment-modal-button
                modal-id="commentModal"
                :comment-count="section.commentCount"
                @input="setComments(section, 'SyllabusSection')"
            />
          </div>
        </preview-header>
      </template>
    </syllabus-box>
  </div>
</template>

<script>
import SyllabusBox from './PrkSyllabusBox'
import PreviewHeader from './preview_syllabus/PreviewHeader'
import CommentModalButton from '../PrkCommentModalButton'
import useComments from '../../composables/useComments'
import { inject } from '@vue/composition-api'

export default {
  name: 'SyllabusEmptySubsection',
  setup (props, { root }) {
    const status = inject('status')
    const { setComments } = useComments(root, props)
    return { setComments, status }
  },
  components: {
    PreviewHeader,
    SyllabusBox,
    CommentModalButton,
  },
  props: { section: Object },
}
</script>

<style scoped lang="scss">
.box-without-content {
  ::v-deep {
    .box {
      padding-bottom: 1rem !important;
      padding-top: 1rem !important;

      hr {
        display: none;
      }
    }
  }
}
</style>
