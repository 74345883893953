import { computed } from '@vue/composition-api'

export default function (matrixSubjectNames) {
  const selectedAll = computed(() => {
    if (matrixSubjectNames.value.length === 0) return false
    return _selectedSubjectNames.value.length === matrixSubjectNames.value.length
  })

  const selectedSubjectNameIds = computed(() => {
    return _selectedSubjectNames.value.map(item => item.id)
  })

  const _selectedSubjectNames = computed(() => {
    return matrixSubjectNames.value.filter(item => item.selected)
  })

  return {
    selectedAll,
    selectedSubjectNameIds,
  }
}
