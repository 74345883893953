<template>
  <div>
    <b-row class="mt-1" align-v="center">
      <b-col>
        <categories ref="allCategories"
                    :categories="categories"
                    :change-category="changeCategory"
                    :args="{ offline: true }"
        />
      </b-col>
      <course-effect-buttons
          :collapse-all="collapseAll"
          :course="course"
          :expand-all="expandAll"
          :filtered-course-effects="filteredCourseEffects"
      />
    </b-row>

    <b-row class="mt-4">
      <b-col>
        <div class="directional-effects-outcomes-list">
          <transition name="c-fade">

            <prk-table
                v-if="filteredCourseEffects.length > 0"
                :excluded-fields="EXCLUDED_FIELDS"
                :fields-props="{ thAttr: thAttr, tdClass: tdClass }"
                :items="filteredCourseEffects"
                :headers-props="headers"
                disable-auto-headers
                primary-key="customId"
                :show-headline="false"
            >
              <template v-slot:table-colgroup="{ fields }">
                <prk-colgroup
                    :columns="['description', 'enDescription']"
                    :fields="fields" width="28%"
                />
              </template>

              <template v-slot:head(customId)>
                <pcg-checkbox :value="selectedAll" @input="select"/>
              </template>
              <template v-slot:head(details)/>

              <template v-slot:head(effects)>
                {{ $t('components.prk_courses.reference') }}
              </template>

              <template #cell(customId)="{ item: courseEffect}">
                <pcg-checkbox v-model="courseEffect.selected"/>
              </template>

              <template v-slot:content="{ header, data }">
                <div v-if="header.key === 'symbol'">
                  <span>{{ data.item.symbol }}</span>
                </div>
                <div v-else-if="header.key === 'effects'">
                  <template v-for="effect in data.item.effects">
                    <div :key="effect.id" class="pl-2">{{ effect.symbol }}</div>
                  </template>
                </div>
                <template v-else-if="header.key === 'description'">
                  <prk-animated-description :description="data.item.description"/>
                </template>
                <template v-else-if="header.key === 'enDescription'">
                  <prk-animated-description :description="data.item.enDescription"/>
                </template>
                <base-arrow
                    v-else-if="header.key === 'details'"
                    class="effects-arrows"
                    :show="data.item.toggleDetails"
                    @click="showDescription(data, $event)"
                />

                <template v-else-if="header.key === 'actions'">
                 <course-effect-actions
                     :course-effect="data.item"
                     @update:editMode="editMode = $event"
                     @update:editedCourseEffect="editedCourseEffect = $event"
                     @remove:course-effect="$emit('remove:course-effect', $event)"
                 />
                </template>
              </template>
            </prk-table>
          </transition>
        </div>
      </b-col>
    </b-row>

    <previous-year-modal
        v-if="!isStandardView"
        :action="assignEffects"
        :default-course-name-id="course.courseNameId"
        :default-faculty-id="course.facultyId"
    />

    <prk-add-course-effect-modal
        :categories="categories"
        :edit-mode.sync="editMode"
        :edited-course-effect="editedCourseEffect"
        :number-of-effects="(filteredCourseEffects.length + 1).toString()"
        :key="rerenderAddCourseEffectModal"
        @add:course-effect="$emit('add:course-effect', $event)"
        @update:course-effect="$emit('update:course-effect', $event)"
        @hidden="hideCourseEffectModal"
    />
  </div>
</template>

<script>
import PrkTable from '../PrkTable'
import PrkAddCourseEffectModal from '../courses/modal/PrkAddCourseEffectModal'
import PreviousYearModal from '../courses/modal/PreviousYearModal'
import Categories from './Categories'
import CourseEffectButtons from './course_effects/CourseEffectButtons'
import PrkAnimatedDescription from '../PrkAnimatedDescription'
import CourseEffectActions from './course_effects/CourseEffectActions'
import PrkColgroup from '../PrkColGroup'
import useIcon from '../../composables/useIcon'
import useEffects from '../../composables/useEffects'
import useExpandText from '../../composables/useExpandText'
import { ref, provide, inject, computed } from '@vue/composition-api'
import { mapGetters } from 'vuex'

export default {
  name: 'CourseEffects',
  setup (props, { root, refs }) {
    const { iconEdit, iconRemove } = useIcon(root)
    const {
      activeCategoryIndex,
      categories,
      changeCategory,
      checkedAll,
      getSelected,
    } = useEffects({
      root,
      refs,
    })
    const editMode = ref(false)

    const filteredCourseEffects = computed(() => {
      if (activeCategoryIndex.value === null) {
        return props.courseEffects
      } else {
        return props.courseEffects.filter(effect => {
          return effect.categoryId === categories.value[activeCategoryIndex.value].value
        })
      }
    })

    provide('editMode', editMode)
    provide('courseEffects', filteredCourseEffects)

    const isStandardView = inject('isStandardView', false)
    const { collapseAll, expandAll, initObserver, tdClass, showText } = useExpandText('course-effects')

    return {
      activeCategoryIndex,
      categories,
      changeCategory,
      checkedAll,
      collapseAll,
      editMode,
      expandAll,
      filteredCourseEffects,
      getSelected,
      iconEdit,
      iconRemove,
      isStandardView,
      initObserver,
      showText,
      tdClass,
    }
  },
  components: {
    PrkColgroup,
    CourseEffectActions,
    CourseEffectButtons,
    Categories,
    PreviousYearModal,
    PrkTable,
    PrkAddCourseEffectModal,
    PrkAnimatedDescription,
  },
  props: {
    courseEffects: {
      type: Array,
      required: true,
    },
    course: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      editedCourseEffect: {},
      rerenderAddCourseEffectModal: 0,
      url: '',
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    headers () {
      const headers = ['customId', 'symbol', 'description', 'enDescription', 'effects', 'details']
      if (this.isStandardView) {
        return headers.filter(header => header !== 'effects')
      } else {
        return headers
      }
    },
    selectedCourseEffects () {
      return this.courseEffects.filter(couEff => couEff.selected)
    },
    selectedAll () {
      return this.selectedCourseEffects.length === this.courseEffects.length
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['categoryId', 'effectId', 'id', 'selected', 'toggleDetails', 'range', 'effectType']
    if (this.isStandardView) this.EXCLUDED_FIELDS.push('effects')
  },
  methods: {
    hideCourseEffectModal () {
      this.rerenderAddCourseEffectModal += 1
      this.editMode = false
    },
    select (selected) {
      selected ? this.$selectAll(this.courseEffects) : this.$unselectAll(this.courseEffects)
    },
    async assignEffects (id) {
      const { included: course } = await this.$api.get(`courses/${id}`)
      const currentCourseEffectIds = this.$_.map(this.courseEffects, 'id')
      const symbols = this.$_.map(this.courseEffects, 'symbol')
      course.filter(data => {
        return data.type === 'course_effect' || data.type === 'standard_effect'
      }).forEach(effect => {
        if (!(currentCourseEffectIds.includes(effect.id) || symbols.includes(effect.attributes.symbol))) {
          const courseEffect = {
            customId: this.$uuidv4(),
            symbol: effect.attributes.symbol,
            categoryId: effect.attributes.categoryId,
            description: effect.attributes.description,
            enDescription: effect.attributes.enDescription,
            range: effect.attributes.range,
            toggleDetails: false,
            selected: false,
            effects: effect.attributes.effects.map(characteristic => {
              return {
                id: characteristic.id,
                symbol: characteristic.symbol,
                description: characteristic.description,
              }
            }),
          }
          courseEffect.effects = this.$_.compact(courseEffect.effects)
          this.$emit('add:course-effect', courseEffect)
          // if (courseEffect.effects && courseEffect.effects.length > 0) {
          //   this.$emit('add:course-effect', courseEffect)
          // }
        }
      })
    },
    showDescription (data, event) {
      this.initObserver()
      this.showText(data, event)
    },
    thAttr (value, key) {
      if (key === 'effects') {
        return {
          style: 'line-height: 1.8rem; width:9.5rem; transform: translateY(6px)',
        }
      } else if (key === 'symbol') {
        return {
          style: 'width: 8rem',
        }
      } else {
        return {}
      }
    },
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-table-container {
    border-radius: 15px 15px 0 0;

    .pcg-table-content {
      border-radius: 15px 15px 0 0;
    }
  }

  @media only screen and (max-width: 575px) {
    .pcg-slide-tab-btn {
      padding: 0 .86rem !important;
    }
  }
}

.pre-wrap {
  white-space: pre-wrap;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
