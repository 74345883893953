<template>
  <div class="d-flex align-items-center">
    <span v-if="header.key === 'information'" class="font-weight-bold">
      {{ label(information) }}
    </span>
    <basic-information-about-subject-value
        :header="header"
        :information="information"
        :value="normalizedValue"
    />
    <div v-if="showQuestionMark"
         v-b-popover.hover.top="courseCodeText"
         class="question ml-3 font-weight-bold">
      ?
    </div>
  </div>
</template>
<script>
import BasicInformationAboutSubjectValue from './BasicInformationAboutSubjectValue'
import { mapGetters } from 'vuex'

export default {
  name: 'basic-information-about-subject-text',
  components: { BasicInformationAboutSubjectValue },
  props: {
    header: { required: true, type: Object },
    information: { required: true, type: String },
    value: { type: [Number, String, Array], default: '' },
  },
  computed: {
    ...mapGetters('environment', ['env']),
    showQuestionMark () {
      return this.header.key === 'information' && this.information === 'subject_code'
    },
    courseCodeText () {
      return this.$t('components.prk_syllabus.table.course_code_info')
    },
    normalizedValue () {
      return [null, undefined].includes(this.value) ? '-' : this.value
    },
  },
  methods: {
    label (header) {
      let key = ''
      if (this.env.classCard && (header === 'subject_code' || header === 'row_2' || header === 'row_3' || header === 'row_4' || header === 'row_5' || header === 'row_12')) {
        key = `components.prk_syllabus.basic_information.${header}_class_card`
      } else {
        key = `components.prk_syllabus.basic_information.${header}`
      }
      return this.$t(key)
    },
  },
}
</script>

<style scoped>
.question {
  cursor: pointer;
}
</style>
