<script>
export default {
  name: 'PrkColgroup',
  functional: true,
  props: {
    fields: { required: true, type: Array },
    columns: { required: true, type: Array },
    width: { required: true, type: String },
  },
  render (createElement, { props }) {
    return props.fields.map(field => {
      return createElement('col', {
        style: {
          width: props.columns.includes(field.key) ? props.width : '',
        },
      })
    })
  },
}
</script>
