<template>
  <div>
    <div class="syllabus-header d-flex align-items-center">
      <slot v-if="showHeadlineIndex" name="component">
        <prk-index class="mr-3"
          :width="width"
          :height="height"
        >
          {{ index }}
        </prk-index>
      </slot>
      <h3 class="m-0 font-weight-bold" :class="{'prk-fz-24': syllabusHeader}">
        <slot />
      </h3>
      <slot name="content" />
    </div>
    <prk-pcg-horizontal-line
      :width="{ value: -2.14, unit: 'rem' }"
    />
  </div>
</template>

<script>
import PrkPcgHorizontalLine from '../syllabus/PrkHorizontalLine'
import PrkIndex from '../PrkIndex'

export default {
  name: 'PrkTableHeadline',
  components: {
    PrkIndex,
    PrkPcgHorizontalLine,
  },
  props: {
    width: { type: Object, default () { return this.width } },
    height: { type: Object, default () { return this.height } },
    index: { required: true, type: String },
    syllabusHeader: { type: Boolean, default: false },
    showHeadlineIndex: { type: Boolean, default: true },
  },
  inject: {
    width: { default: () => ({ value: 47, unit: 'px' }) },
    height: { default: () => ({ value: 26, unit: 'px' }) },
  },
}
</script>

<style scoped lang="scss">
@import "app/javascript/prk/assets/stylesheets/vars.scss";
.syllabus-header {
  margin-bottom: 1rem;
}
</style>
