import { ref, onBeforeMount } from '@vue/composition-api'

export default function (root) {
  const categories = ref([])

  const getCategories = async () => {
    const { data: response } = await root.$api.get('categories')
    categories.value = response.map(category => {
      return {
        id: category.id,
        name: category.attributes.name,
        shortcut: category.attributes.shortcut,
        description: category.attributes.description,
        shortcutSubjectEffect: category.attributes.shortcutSubjectEffect,
      }
    })
  }

  onBeforeMount(() => {
    getCategories()
  })

  return {
    categories,
  }
}
