const sidebarMenu = {
  namespaced: true,
  state: {
    sidebarMenuClasses: [],
  },
  getters: {
    sidebarMenuClasses: state => {
      return state.sidebarMenuClasses
    },
  },
  mutations: {
    setSidebarMenuClasses (state, classes) {
      state.sidebarMenuClasses = classes
    },
  },
  actions: {
    setSidebarMenuClasses ({ commit }, payload) {
      commit('setSidebarMenuClasses', payload)
    },
    clearSidebarMenuClasses ({ commit }) {
      commit('setSidebarMenuClasses', [])
    },
  },
}

export {
  sidebarMenu,
}
