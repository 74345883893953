<template>
  <div class="dictionaries-fields-list">
    <add-faculty v-b-modal.add-modal button-name="new_faculty"/>

    <prk-table
        v-if="items.length > 0"
        class="prk-shadow mt-4"
        :items="items"
        :excludedFields="['id']"
        :show-headline="false"
    >
      <template #cell(actions)="{ item, index }">
        <div class="d-flex justify-content-end align-items-center">
          <pcg-btn round text="edit" class="mr-3 prk-button" :icon="iconEdit"
                   @click="editResource(item.id, 'faculty')"/>
          <pcg-btn round :icon="iconRemove" text="delete"
                   class="mr-3 prk-button" variant="danger"
                   @click="destroyResource('faculties', item.id, index)"/>
          <show-courses :router-object="routerObject(item)" button-name="course"/>
        </div>
      </template>
    </prk-table>

    <structure-modal
        :action="action(faculty, 'faculties')"
        :btn-title="btnTitle"
        :editMode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="faculty"
        @hidden="hideModal('faculty')"
        @clear-error="errors[$event] = ''"
    />
  </div>
</template>

<script>
import PrkTable from '../../components/PrkTable'
import StructureModal from '../../components/dictionaries/shared/StructureModal'
import ShowCourses from '../../components/dictionaries/DictionariesShowNestedResourcesButton'
import AddFaculty from '../../components/dictionaries/DictionariesAddResourceButton'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import useIcon from '../../composables/useIcon'
import { dictionary } from '../../mixins/dictionary'
import { mapGetters } from 'vuex'

export default {
  name: 'DictionaryFaculties',
  setup (_, { root }) {
    const suffixes = { add: 'add_faculty', edit: 'edit_faculty', new: 'new_faculty' }
    const { items, setItems, removeRow, findItemById } = useList(root)
    const { iconEdit, iconRemove } = useIcon(root)
    const {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      title,
      updateResource,
    } = useDictionary(root, suffixes)
    return {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      iconEdit,
      iconRemove,
      items,
      removeRow,
      setItems,
      title,
      updateResource,
    }
  },
  components: {
    AddFaculty,
    ShowCourses,
    StructureModal,
    PrkTable,
  },
  mixins: [dictionary],
  jsonapi: {
    faculties: {
      config: { method: 'get', url: 'faculties' },
      update (faculties) {
        return faculties.map(faculty => {
          return this.$_.omit(faculty, '__type')
        })
      },
    },
  },
  data () {
    return {
      faculties: [],
      faculty: {
        name: '',
        [Symbol.for('_innerName')]: 'faculty',
      },
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    faculties () {
      this.setItems(this.faculties)
    },
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
  methods: {
    routerObject (item) {
      return {
        name: 'dictionaries_courses_path',
        params: {
          facultyId: item.id,
        },
        query: { faculty: item.name },
      }
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$tc('general.faculty', 2),
      })
    },
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-table-container {
    border-radius: 15px 15px 5px 5px;

    .pcg-table-content {
      border-radius: 15px 15px 5px 5px;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
