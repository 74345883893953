<template>
  <b-row class="mt-4">
    <b-col cols="auto">
      <prk-select
          :label="$tc('general.discipline', 1)"
          color="aqua"
          :default-option="{ text: $t('general.choose') }"
          :error-text="$t('general.errors.required')"
          :options="filteredDisciplines"
          :show-error="error"
          :value="disciplineId"
          @input="updateDiscipline"
      >
      </prk-select>
    </b-col>
  </b-row>
</template>

<script>
import PrkSelect from '../../../PrkSelect'
import { inject } from '@vue/composition-api'
export default {
  name: 'DisciplineSelect',
  setup () {
    const editMode = inject('editMode', false)
    const excludedDisciplineIds = inject('excludedDisciplineIds', [])
    return { editMode, excludedDisciplineIds }
  },
  components: {
    PrkSelect,
  },
  props: {
    disciplineId: { required: true, type: String },
    error: { required: true, type: Boolean },
    fieldId: { required: true, type: String },
  },
  jsonapi: {
    disciplines: {
      config: ({ fieldId }) => ({
        method: 'get',
        url: `disciplines?field_id=${fieldId}&active=true`,
      }),
      variables () {
        return {
          fieldId: this.fieldId,
        }
      },
      update (disciplines) {
        return disciplines.map(({ id, name }) => ({ value: id, text: name }))
      },
      skip () {
        return !this.fieldId.length
      },
    },
  },
  data () {
    return {
      disciplines: [],
    }
  },
  computed: {
    filteredDisciplines () {
      return this.disciplines.filter(dis => {
        return dis.value === this.disciplineId || !this.excludedDisciplineIds.includes(dis.value)
      })
    },
    disciplineExist () {
      return !!this.filteredDisciplines.find(dis => dis.value === this.disciplineId)
    },
  },
  watch: {
    disciplineExist () {
      if (!this.editMode) return
      if (!this.disciplineExist) this.$emit('clear:discipline-id')
    },
  },
  methods: {
    findDisciplineName (id) {
      return this.disciplines.find(dis => dis.value === id).text
    },
    updateDiscipline (id) {
      this.$emit('update:discipline', id, this.findDisciplineName(id))
    },
  },
}
</script>
