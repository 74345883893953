<template>
  <div>
    <div v-for="(item, index) in section.elements" :key="index"
         :class="[negativeMarginClass(item)]"
    >
      <h4 v-if="item.descriptionAbove.length"
          class="mt-3 mb-0"
          :class="{ 'positive-margin': item.elementType === 'table' }"
      >
        <span v-html="item.descriptionAbove"></span>
      </h4>
      <div v-if="item.elementType === 'text_field'">
        <label class="pcg-input-label mt-3">{{ item.label }}</label>
        <prk-editor :value="value(item)"
                    @input="input($event, item)"/>
      </div>
      <div v-else class="d-flex justify-content-between align-items-center">
        <component
            :is="mapComponent(item.elementType)"
            class="font-weight-bold mt-3"
            :class="{ 'w-100': ['one_line', 'text_field'].includes(item.elementType)}"
            :value="value(item)"
            @input="input($event, item)"
            :label="item.label"
            :item="isTable(item) ? item : null"
            :show-label="!!item.label.length"
            :type="mapInputType(item.elementType)"
            @addRow="item.value.value.push($event)"
        >
          <span :class="{ 'pcg-main-active-color': value(item)}">
            {{ item.label }}
          </span>
        </component>
      </div>
      <h4 v-if="item.descriptionUnder.length"
          class="mt-3 mb-0"
          :class="{ 'positive-margin': item.elementType === 'table' }"
      >
        <span v-html="item.descriptionUnder"></span>
      </h4>
      <prk-horizontal-line
          v-if="showHorizontalLine(item, index)"
          :width="item.elementType === 'table' ? {} : { value: -30, unit: 'px' }"
          class="my-3"
      />
    </div>
  </div>
</template>

<script>
import PrkEditor from '../../PrkEditor'
import SyllabusTable from './SyllabusTable'
import PrkHorizontalLine from '../PrkHorizontalLine'
import { inject } from '@vue/composition-api'

export default {
  name: 'ToFillContent',
  setup () {
    const status = inject('status')
    return {
      status,
    }
  },
  components: {
    PrkEditor,
    PrkHorizontalLine,
    SyllabusTable,
  },
  props: {
    section: {
      required: true,
      type: Object,
    },
  },
  methods: {
    input (e, item) {
      if (!item.value) return
      item.value.value = e.toString()
    },
    value (item) {
      if (item.elementType === 'checkbox') {
        return item.value && item.value.value === 'true'
      } else {
        return item.value && item.value.value
      }
    },
    isTable (item) {
      return item.elementType === 'table'
    },
    isLast (item, index) {
      return item.descriptionUnder.length &&
          index === this.visibleElements.length - 1
    },
    select (index, val) {
      this.section.elements[index].value = val
    },
    mapInputType (type) {
      return type === 'one_line' ? 'input' : 'textarea'
    },
    mapComponent (type) {
      switch (type) {
        case 'checkbox':
          return 'pcg-checkbox'
        case 'one_line':
        case 'text_field':
          return 'pcg-text-input'
        case 'table':
          return 'syllabus-table'
      }
    },
    showHorizontalLine (item, index) {
      const isNotLastElement = index !== this.section.elements.length - 1
      const hasDescriptionUnder = item.descriptionUnder
      const hasDescriptionAbove = this.section.elements[index + 1]?.descriptionAbove
      return isNotLastElement && (hasDescriptionUnder || hasDescriptionAbove)
    },
    flexClasses (item) {
      return { 'd-flex justify-content-between align-items-center': item.elementType !== 'table' }
    },
    negativeMarginClass (item) {
      return { 'negative-margin': item.elementType === 'table' }
    },
  },
}
</script>

<style scoped lang="scss">
.negative-margin {
  margin-left: -30px;
  margin-right: -30px;
}

.positive-margin {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
