<template>
  <div>
    <prk-table
        id="study-plan-table"
        :custom-fields="subjectsByForm.length > 0 ? CUSTOM_FIELDS : []"
        disable-actions
        :items="subjectsByForm"
        :excluded-fields="EXCLUDED_FIELDS"
        :show-headline="false"
        :busy="$jsonapi.loading"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: colgroupDefinition(field.key) }">
      </template>

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <pcg-spinner variant="small" />
        </div>
      </template>

      <template v-slot:head(specialPlan)>
        {{ $t('components.prk_syllabus.table.from_plan') }}
      </template>
      <template v-slot:content="{ header, data }">

        <template v-if="['formOfClasses', 'formOfCredit'].includes(header.key)">
          <span v-html="data.item[header.key]"/>
        </template>

        <template v-else-if="header.key === 'leading'">
          <didactic-initial
              :lecturers="data.item.lecturers"
              @click="subjectId = data.item.id"
          />
        </template>

        <template v-else-if="header.key === 'specialPlan'">
          <span v-html="data.item[header.key]"/>
        </template>
        <study-plan-table-actions
            v-else-if="header.key === 'actions'"
            :subject-by-form-id="data.item.id"
            :subjects="subjects"
            @set:subject-id="subjectId = $event"
            @set:subject-form-id="prepareSubjectForms($event)"
            @remove="removeSubject"
        />
      </template>

      <template v-if="showFooter && !$jsonapi.loading" v-slot:custom-foot>
        <study-plan-table-footer v-if="subjectsByForm.length" :items="subjectsByForm"/>
        <b-tr>
          <b-th colspan="8" :style="{
          paddingRight: '2.14rem',
          height: '0px'
        }">
            <hr class="m-0">
          </b-th>
        </b-tr>
      </template>
    </prk-table>

    <lecturers-modal :lecturers="lecturers" />

    <subject-form-modal
        :key="rerenderSubjectForm"
        modal-id="subject-form"
        :edited-subject="subject"
        :semester-id="semesterId"
        @hidden="rerenderSubjectForm += 1"
    />
    <pin-to-study-plan-modal
        v-if="$checkRouteName('study_plans_list_path')"
        :subject-id="subjectId"
    />
  </div>
</template>

<script>
import DidacticInitial from '../../syllabus/syllabuses_list/DidacticInitial'
import PrkTable from '../../PrkTable'
import SubjectFormModal from '../modals/PrkSubjectFormModal'
import StudyPlanTableActions from './study_plan_table/StudyPlanTableActions'
import LecturersModal from '../../shared_view/LecturersModal'
import useIcon from '../../../composables/useIcon'
import useStudyPlanTable from '../../../composables/useStudyPlanTable'
import { ref, provide } from '@vue/composition-api'
import { mapState, mapActions, mapGetters } from 'vuex'
import { studyPlanTable } from '../../../mixins/plans/study_plan_table'

export default {
  name: 'StudyPlanTable',
  setup (_, { root }) {
    const { iconEdit, iconRemove } = useIcon(root)
    const { colgroupDefinition } = useStudyPlanTable(root)
    const subjectFormId = ref('')

    provide('subjectFormId', subjectFormId)

    return {
      colgroupDefinition,
      iconEdit,
      iconRemove,
      subjectFormId,
    }
  },
  components: {
    LecturersModal,
    StudyPlanTableActions,
    PrkTable,
    SubjectFormModal,
    PinToStudyPlanModal: () => import('../modals/PrkPinToStudyPlanModal'),
    StudyPlanTableFooter: () => import('./study_plan_table/StudyPlanTableFooter'),
    DidacticInitial,
  },
  mixins: [studyPlanTable],
  props: {
    semesterId: { type: String, default: '' },
    semesterIds: { type: Array, default: () => ([]) },
    showFooter: { type: Boolean, default: false },
  },
  data () {
    return {
      rerenderSubjectForm: 0,
      subjectId: '',
      subjects: [],
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    ...mapState('subjects', ['newSubject', 'editedSubject', 'allSubjects']),
    subject () {
      return this.subjects.find(subject => subject.id === this.subjectId)
    },
    lecturers () {
      return this.subjectsByForm.find(subject => subject.id === this.subjectId)?.lecturers
    },
  },
  watch: {
    newSubject () {
      const subject = this.subjects.find(subject => {
        return subject.subjectName.name === this.newSubject.subjectName.name &&
            subject.semester.id === this.newSubject.semester.id && subject._destroy !== 1
      })
      if (subject) {
        this.$toastr.w(this.$t('views.study_plans.errors.semester.same_subject'))
      } else {
        this.subjects.push(this.newSubject)
        this.addSubject(this.newSubject)
      }
    },
    editedSubject () {
      const index = this.subjects.findIndex(sub => sub.id === this.editedSubject.id)
      this.$set(this.subjects, index, this.editedSubject)
      this.$store.dispatch('subjects/addOrUpdate', this.editedSubject)
    },
    semesterId () {
      this.subjects = this.allSubjects.filter(sub => {
        return sub.semester.id === this.semesterId
      })
    },
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
  methods: {
    ...mapActions('subjects', ['setSubjects', 'addSubject']),
    ...mapActions('semesters', ['setHasSubjects']),
    removeSubject (id) {
      const subject = this.subjects.find(sub => sub.id === id)
      this.$set(subject, '_destroy', 1)
      this.$store.dispatch('subjects/removeSubject', subject)
      if (!this.subjectsByForm.length && this.$checkRouteName('study_plans_edit_pat')) {
        this.setHasSubjects(false)
      }
      if (this.$checkRouteName('study_plans_list_path')) return
      this.setSemester()
    },
    setModifiedSubjects () {
      let subjects = this.subjects.filter(subject => {
        return subject._edited === 1 || subject.id.match('__new')
      })
      subjects = [...this.allSubjects, ...subjects]
      subjects = this.$_.uniqBy(subjects, 'id')
      if (subjects.length) this.setSubjects(subjects)
    },
    setCurrentSubjects () {
      const modifiedSubjectsById = this.allSubjects.filter(sub => {
        return sub.semester.id === this.semesterId
      })
      this.subjects = [
        ...this.$_.cloneDeep(modifiedSubjectsById),
        ...this.subjects.filter(sub => !modifiedSubjectsById.map(sub => sub.id).includes(sub.id)),
      ]
    },
    setSemester () {
      let semester = this.$store.getters['semesters/getSemesterById'](this.semesterId)
      semester = this.$_.cloneDeep(semester)
      semester._edited = 1
      this.$store.dispatch('semesters/editSemester', semester)
    },
    prepareSubjectForms (subjectFormId) {
      this.subject.subjectForms.filter(form => form.id === subjectFormId).forEach(form => {
        this.$set(form, 'hidden', false)
        form.hidden = false
      })
      this.subject.subjectForms.filter(form => form.id !== subjectFormId).forEach(form => {
        this.$set(form, 'hidden', true)
      })
      this.subjectFormId = subjectFormId
    },
  },
  destroyed () {
    this.$store.dispatch('subjects/setSubjects', [])
    this.$store.dispatch('subjects/setNewSubject', null)
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  &#study-plan-table.pcg-table-container {
    padding-left: 2.14rem;
  }
}

.drawing-pin {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $pcg-lightest-aqua-blue;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .drawing-pin {
    background: $pcg-lightest-aqua-blue;
  }
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
