import NavigationMenuStaticLinksMixin from './navigation_menu_static_links'

export default {
  mixins: [NavigationMenuStaticLinksMixin],
  computed: {
    allLinks () {
      return this.staticLinks
    },
    signedAndRoleCombined () {
      return [this.$store.getters['auth/currentRole'], this.$store.getters['auth/signedIn']]
    },
  },
  mounted () {
    this.$root.$on('toggleMobileMenu', () => {
      this.mobileMenuOpen = !this.mobileMenuOpen
    })
  },
}
