<template>
  <div>
    <prk-main-syllabus-header
        :duringEducation="header.duringEducation"
        :training-cycle="header.trainingCycle"
        :academic-year="header.academicYear"
        :status="header.status"
        :skip-validation="header.skipValidation"
        :show-training-cycle="header.showTrainingCycle"
        :chooseLanguage="false"
        class="mb-5"
        @set-status="$emit('set-status', $event)"
    >
      <template #image>
        <b-col cols="12" sm="auto">
          <img :src="header.logoUrl" alt="missing-logo.jpg"/>
        </b-col>
      </template>
      <template #persons>
        <persons/>
      </template>
    </prk-main-syllabus-header>
    <copy-syllabus-modal :get-syllabus="getSyllabus"/>
    <syllabus-sections :sections.sync="visibleSections"/>
  </div>
</template>

<script>
import PrkMainSyllabusHeader from '../PrkMainSyllabusHeader'
import Persons from '../PrkSyllabusHeaderPersons'
import SyllabusSections from './SyllabusSections'
import CopySyllabusModal from '../modals/CopySyllabusModal'

export default {
  name: 'SyllabusFill',
  components: {
    CopySyllabusModal,
    SyllabusSections,
    Persons,
    PrkMainSyllabusHeader,
  },
  props: {
    header: { required: true, type: Object },
    sections: { required: true, type: Array },
    getSyllabus: { required: true, type: Function },
  },
  data () {
    return {
      numberOfSection: 0,
      academicYear: '',
    }
  },
  watch: {
    sections: {
      handler (data) {
        this.$emit('update:sections', data)
      },
      deep: true,
    },
  },
  computed: {
    visibleSections () {
      return this.sections.filter(section => section.visible)
    },
  },
  created () {
    this.setSectionNumbers()
  },
  methods: {
    setSectionNumbers () {
      this.sections.map(section => {
        if (+section.number % 1 === 0) {
          this.numberOfSection += 1
          section.number = this.numberOfSection
        } else {
          section.number = `${this.numberOfSection}.${+section.number.slice(-1)}`
        }
      })
    },
  },
}
</script>

<style>
img {
  max-width: 250px;
  max-height: 200px;
}
</style>
