<template>
  <div id="coordinators-list">
    <transition-group name="fade" tag="div">
      <template v-for="(coordinator, index) in coordinators">
        <b-row :key="coordinator.value" align-v="center">
          <b-col class="ml-sm-4 mb-3 mb-sm-0" cols="12" sm="4">
            <prk-avatar
                :abbr="coordinator.abbr"
                :avatar="coordinator.avatar"
                :text="coordinator.text"
                text-class="prk-fz-12 d-inline-block ml-3"
            />
          </b-col>
          <b-col class="col-12 col-sm ml-sm-4">
            <div class="d-flex align-items-center justify-content-between">
              <prk-select
                  :options="coordinator.roles"
                  :default-option="coordinator.defaultOption"
                  color="aqua"
                  class="roles w-100 w-sm-auto"
              >
                <template v-slot="{ options }">
                  <educator-list-roles
                    :educator="coordinator"
                    :roles="options"
                    @update:educator="coordinator.defaultOption = $event"
                  />
                </template>
              </prk-select>
              <div class="mr-4" @click="$emit('coordinatorWasRemoved', index)">
                <i v-b-popover.hover.top="$t('general.delete_didactic')"
                   class="pcg-icon-cross ml-3"
                />
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row :key="`${index}-tags`">
          <b-col offset-sm="5">
            <prk-tags
                :value="showTags(coordinator)"
                @removed="removeTag($event, coordinator)"
                class="mt-3 mt-sm-0"
            />
          </b-col>
        </b-row>
        <prk-horizontal-line :key="`${index}-hr`" class="my-3"/>
      </template>
    </transition-group>
  </div>
</template>

<script>
import PrkSelect from '../../../PrkSelect'
import PrkHorizontalLine from '../../PrkHorizontalLine'
import PrkTags from '../../../PrkTags'
import EducatorListRoles from './educator_list/EducatorListRoles'
import PrkAvatar from '../../../PrkAvatar'
import useEducators from '../../../../composables/useEducators'

export default {
  name: 'CoordinatorsList',
  setup (_, { root }) {
    const { getDefaultOption } = useEducators(root)
    return { getDefaultOption }
  },
  components: {
    PrkAvatar,
    EducatorListRoles,
    PrkHorizontalLine,
    PrkSelect,
    PrkTags,
  },
  props: {
    coordinators: { required: true, type: Array, default: () => [] },
  },
  watch: {
    coordinators () {
      this.$emit('coordinatorsIsEmpty', !!this.coordinators.length)
    },
  },
  methods: {
    showTags (coordinator) {
      const selectedPositions = coordinator.roles.filter(el => el.selected)
      if (selectedPositions.length > 1) {
        return selectedPositions
      } else {
        return []
      }
    },
    removeTag (id, coordinator) {
      const item = coordinator.roles.find((item, index) => {
        return index === id
      })
      item.selected = false
      coordinator.defaultOption = this.getDefaultOption(coordinator.roles)
    },
  },
}
</script>

<style scoped lang="scss">
.tags-container {
  padding-top: 0.5rem;
}

::v-deep {
  &#coordinators-list .b-dropdown-form {
    outline: none !important;
  }
}
</style>
