export const general = {
  academic_year: 'Academic year | Academic years',
  accept: 'Accept',
  accepted: 'Accepted',
  accept_event: 'Accept event',
  accept_offer: 'Accept offer',
  accept_employer: 'Accept employer',
  accuracy: 'Accuracy',
  active: 'Active',
  action_cannot_be_reverted: 'Action cannot be reverted',
  add: 'Add',
  additional_section: 'Additional section',
  add_attachment: 'Add attachment',
  add_category: 'Add category',
  add_characteristic: 'Add characteristic',
  add_course: 'Add course',
  add_course_effect: 'Add course effect',
  add_discipline: 'Add discipline',
  add_effect: 'Add effect | Add effects',
  add_effect_of_general_characteristics: 'Add effect of general characteristics',
  add_en_description: 'Add description in English',
  add_event: 'Add event',
  add_faculty: 'Add faculty',
  add_field: 'Add field',
  add_form_of_class: 'Add form of classes',
  add_form_of_credit: 'Add form of credit',
  add_general_characteristics: 'Add general characteristics',
  add_general_effects: 'Add general effect | Add general effects',
  add_kind: 'Add kind',
  add_literature: 'Add literature',
  add_new_subject: 'Add new subject',
  add_path: 'Add path',
  add_offer: 'Add offer',
  add_profile: 'Add profile',
  add_semester: 'Add semester',
  add_teaching_effects: 'Add teaching effects',
  add_speciality: 'Add speciality',
  add_subject_form_name: 'Add subject form name',
  add_subject_name: 'Add subject name',
  add_subject_to_plan: 'Add subject to plan',
  add_standard: 'Add standard',
  add_standard_effect: 'Add standard effect',
  add_type: 'Add type',
  add_unit: 'Add unit',
  add_value: 'Add value',
  all: 'All',
  answer: 'Answer',
  apply: 'Apply',
  apprenticeship: 'Apprenticeship',
  archive: 'Archive',
  area: 'Area',
  are_you_sure: 'Are you sure?',
  author: 'Author',
  automatic_download: 'Automatic download',
  assign_effects_of_general_characteristics: 'Assign effects of general characteristics',
  assign_general_effects: 'Assign general effects',
  back: 'Back',
  back_to_edit: 'Back to edit',
  basic_literature: 'Basic literature',
  be_completed_by_didactic: 'To be completed by the didactic',
  cancel: 'Cancel',
  cannot_delete_course: 'You cannot delete a course that has specific learning outcomes assigned to it',
  category: 'Category',
  career_office_team: 'Ccareer office team',
  change: 'Change',
  change_history: 'Change history',
  change_order: 'Change order',
  channel: 'Channel',
  check_box: 'Checkbox',
  check_errors: 'Check errors',
  choose: 'Choose',
  choose_study_plan: 'Choose study plan',
  clear: 'Clear',
  clo: {
    default: 'Course learning outcomes (CLO)',
    list: 'list of available CLO',
  },
  close: 'Close',
  close_survey: 'Close survey',
  collapse: 'Collapse',
  collapse_all: 'Collapse all',
  company: 'Company',
  competence_deleted: 'Competence was deleted.',
  competence_updated: 'Competence was updated.',
  competences: 'Competences',
  confirm: 'Confirm',
  confirm_and_add_subject: 'Confirm and add subject',
  content: 'Content',
  coordinator: 'Coordinator',
  coordinators: 'Coordinator',
  coordinating_person: 'Coordinator',
  copy: 'Copy',
  copy_subject_effects: 'Copy the subject effects',
  copy_syllabus: 'Skopiuj sylabus',
  course: 'Course | Courses',
  course_name: 'Course name',
  course_not_found: 'No matching course found',
  course_effect: 'Course effect | Course effects',
  course_of_study: 'Course of study',
  currency: {
    pln: 'PLN',
  },
  current_version: 'Current version',
  create_new: 'Create new',
  create_study_plan: 'Create a study plan',
  date_added: 'date added',
  date_from: 'Date from',
  date_of_publication: 'date published',
  date_to: 'Date to',
  days_left: '| 1 day left | {count} days left | {count} days left',
  delete: 'Delete',
  delete_didactic: 'Delete didactic',
  delete_element: 'Delete element | Delete elements',
  delete_message: 'Delete message',
  departments: 'Department | Departments',
  description: 'Description',
  destroy_survey: 'Delete survey',
  dictionary_updated: 'Dictionary was updated.',
  dictionary_word_updated: 'Term was updated.',
  discard_changes: 'Discard changes',
  discipline: 'Discipline | Disciplines',
  download: 'Pobierz',
  download_doc: 'Download DOC file',
  download_excel: 'Download EXCEL file',
  download_pdf: 'Download PDF file',
  download_course_effects: 'Download course effects',
  download_effects_check_report: 'Download effects check report',
  download_general_effects: 'Printout of general effects for the course',
  ects: 'ECTS',
  edit: 'Edit',
  edit_category: 'Edit category',
  edit_course: 'Edit course',
  edit_course_effect: 'Edit course effect',
  edit_discipline: 'Edit discipline',
  edit_effect: 'Edit effect',
  edit_faculty: 'Edit faculty',
  edit_field: 'Edit field',
  edit_form_of_class: 'Edit form of classes',
  edit_form_of_credit: 'Edit form of credit',
  edit_kind: 'Edit kind',
  edit_literature: 'Edit literature',
  edit_profile: 'Edit profile',
  edit_speciality: 'Edit speciality',
  edit_study_plan: 'Edit study plan',
  edit_subject: 'Edit subject',
  edit_subject_form_name: 'Edit subject form name',
  edit_subject_name: 'Edit subject name',
  edit_type: 'Edit type',
  edit_unit_name: 'Edit unit',
  educator: 'Educator | Educators',
  educator_0: 'Coordinator',
  educator_1: 'Coordinator',
  educator_2: 'Supervising person',
  effects: 'Effects',
  end_date: 'End date',
  error_try_later: 'There was an unexpected error. Please try again later.',
  errors: {
    already_taken: 'This field is already taken',
    greater_0: 'Must be greater than 0',
    required: 'This field is required',
  },
  event: 'Event',
  event_type: {
    fair: 'Fair',
    training: 'Training',
    rally: 'Rally',
    other: 'Other',
  },
  events: {
    statuses: {
      choose: 'Choose',
      template: 'Template',
      accepted: 'Accepted',
      draft: 'Draft',
      finished: 'Finished',
      for_acceptance: 'Waiting',
      recent: 'Recent',
      rejected: 'Rejected',
      published: 'Published',
      to_fill: 'To fill',
      to_improve: 'To improve',
      to_verification: 'To verification',
    },
  },
  exam: 'Exam',
  exercises: 'Exercises',
  expand: 'Expand',
  expand_all: 'Expand all',
  export: 'Export',
  export_courses_effects: 'Download courses effects',
  export_subjects_effects: 'Download subjects effects',
  faculty: 'Faculty | Faculties',
  faculty_name: 'Faculty name',
  file_upload: 'Drag and drop or select a file',
  field: 'Field | Fields',
  field_of_study: 'Field of study',
  finish: 'End',
  finish_event: 'End event',
  finish_offer: 'End offer',
  forbidden: 'You do not have permission to perform this action.',
  form_of_credit: 'Form of credit',
  form_of_class: 'Form of classes | Forms of classes',
  form: 'form',
  from: 'from',
  full_time: 'Full-time',
  gallery: 'Gallery',
  generally_academic: 'Generally academic',
  general_effects: 'General effects',
  generate_syllabuses: 'Generate syllabus | Generate syllabuses',
  heading: 'Heading',
  hello_world: 'Hello world!',
  hide: 'Hide',
  hide_on_printout: 'Hide on printout',
  hide_section: 'Hide section',
  in: 'in',
  inactive: 'Inactive',
  import_plan: 'Import plan | Import plans',
  job: 'Job',
  kind: 'Kind | Kinds',
  kind_name: 'Kind',
  knowledge: 'Knowledge',
  lack: 'Lack',
  leading: 'Leading',
  lecture: 'Lecture',
  lecturers: 'Lecturers',
  levels: 'Level | Levels',
  localization: 'Location',
  logged_out: 'You were logged out',
  literatures: 'Literatures',
  supplementary_literature: 'Supplementary literature',
  make_copy: 'Copy',
  make_offer: 'Make an offer',
  matrix_saved: 'Matrix has been saved',
  matrix_subject_names: 'Matrix subjects',
  message_sent: 'Message was send',
  message_type: {
    communique: 'Communique',
    newsletter: 'Newsletter',
    sms: 'SMS',
  },
  method_verifying: 'A method of verifying the learning effect',
  modify_statuses: 'Modify statuses',
  must_be_greater_than_or_equal_to_0: 'must be greater than or equal to 0',
  name: 'Name',
  name_of_subject: 'Name of subject',
  name_of_study_plan: 'Name of study plan',
  name_and_surname: 'Name and surname',
  name_of_template: 'Name of template',
  new_course: 'New course',
  new_course_effect: 'New course effect',
  new_effect: 'New effect',
  new_faculty: 'New faculty',
  new_field: 'New field',
  new_form_of_class: 'New form of classes',
  new_form_of_credit: 'New form of credit',
  new_kind: 'New kind',
  new_literature: 'New literature',
  new_profile: 'New profile',
  new_speciality: 'New speciality',
  new_subject_form_name: 'New subject form name',
  new_subject_name: 'New subject name',
  new_type: 'New type',
  new_message: 'New message',
  new_plan: 'New plan',
  new_template: 'New template ',
  new_standard: 'New standard',
  new_standard_effect: 'New standard effect',
  new_study_plan: 'New study plan',
  new_subject: 'New subject',
  new_unit_name: 'New unit',
  no: 'No',
  no_access: 'No access',
  noname: 'Noname',
  not_number: 'Not a number',
  number: 'Number',
  number_of_hours: 'Number of hours',
  number_of_terms: 'Number of terms',
  objective_effects: 'Objective effects',
  on_off: 'On/Off',
  offer: 'Offer',
  offer_type: {
    job: 'Job',
    practice: 'Practice',
    training: 'Training',
  },
  offers: {
    statuses: {
      accepted: 'Accepted',
      draft: 'Draft',
      finished: 'Finished',
      for_acceptance: 'Waiting',
      recent: 'Recent',
      rejected: 'Rejected',
      published: 'Published',
    },
  },
  ok: 'Ok',
  or: 'or',
  other: 'Other',
  overwrite: 'Overwrite',
  part_time: 'part time',
  percent: 'Percent',
  pin_to_study_plan: 'Pin to study plan',
  plan_name: 'Plan name',
  place_of_publication: 'Place of publication',
  practice: 'Practice',
  preview: 'Preview',
  preview_saved_draft: 'Draft preview',
  previous_version: 'Previous version',
  print: 'Print',
  prk: 'Prk',
  process: 'Process',
  profile: 'Profile',
  publish: 'Publish',
  publishing_house: 'Publishing house',
  publish_survey: 'Publish survey',
  range: 'Range',
  recipient_type: {
    employers: 'Employers',
    students: 'Students',
    graduates: 'Graduates',
  },
  record_saved: 'Record has been saved',
  recipients_group: 'Recipients group',
  register: 'Register',
  reject: 'Reject',
  reject_changes: 'Reject changes',
  reject_event: 'Reject event',
  reject_offer: 'Reject offer',
  remote_work: 'Remote work',
  remove: 'Remove',
  remove_form_of_classes: 'Remove form of classes',
  remove_from_favorites: 'Remove from favorites',
  remove_selected: 'Remove selected',
  remove_term: 'Remove semester',
  responsible_person: 'Coordinator',
  responsible_person_syllabus: 'Responsible person',
  replica: 'Replica',
  role: 'Role',
  role_in_syllabus: 'Role in the syllabus',
  roles: {
    admin: 'administrator',
    administrator: 'administrator',
    career_office: 'career office',
    deanery: 'deanery',
    educator: 'educator',
    employer: 'administrative worker',
    graduate: 'graduate',
    student: 'student',
    supervisor: 'supervisor',
  },
  save: 'Save',
  save_changes: 'Save changes',
  save_course: 'Save course',
  save_draft: 'Save draft',
  search: 'Search',
  search_word: 'Search phrase...',
  see_less: 'show less',
  see_more: 'show more',
  select: 'Select',
  select_all: 'Select all',
  select_city: 'Select city',
  selected_effects: 'Selected effects',
  selected_methods: 'Selected methods',
  selected_roles: 'Selected roles',
  send: 'Submit',
  send_for_publication: 'Submit for publication',
  semester_name: 'Name of semester',
  semester_type: 'Type of semester',
  shortcut: 'Shortcut',
  shortcutSubjectEffect: 'Shortcut SE',
  show: 'Show',
  show_all: 'Show all',
  show_all_subjects: 'Show all subjects',
  show_changes: 'Show changes',
  show_more: 'SHOW MORE',
  see_subjects: 'See subjects',
  show_report: 'Show report',
  show_results: 'Show results',
  skills: 'Skills',
  skip: 'Skip',
  specialization: 'Specialization',
  specialization_name: 'Specialization',
  speciality: 'Specialty',
  speciality_name: 'Speciality',
  special_kind: 'Study plan',
  standard_effect: 'Standard effect | Standard effects',
  standard_matrix: 'Standard matrix',
  start_date: 'Start date',
  status: 'Status | Statuses',
  statuses: {
    choose: 'Choose',
    during_fill: 'During fill',
    to_fill: 'To fill',
    to_improve: 'To improve',
    to_verification: 'To verification',
    accepted: 'Accepted',
  },
  steps: {
    next: 'Next',
    previous: 'Previous',
    publish: 'Save and publish',
    send_for_publication: 'Save and submit for publication',
  },
  study_plan: 'Study plan',
  study_plan_type: 'Type',
  study_profile: 'Profile | Profiles',
  subjects: 'Subject | Subjects',
  subject_effects: 'Subject effect | Subject effects',
  subject_code: 'Subject code',
  subject_form_name: 'Subject form name',
  subject_name: 'Subject name',
  suggest: 'Suggest',
  supervisor: 'Supervisor',
  sum: 'Sum',
  summer: 'Summer',
  survey: 'Survey',
  surveys: {
    attributes: {
      anonymous: 'Anonymous',
      graduate_fate: 'Fate od graduates',
      requires_answer: 'Required answers',
    },
    statuses: {
      draft: 'Draft',
      published: 'Published',
      completed: 'Ended',
      closed: 'Closed',
    },
  },
  switch: 'On | Off',
  symbol: 'Symbol',
  syllabuses: 'Syllabus | Syllabuses',
  syllabus_matrix: 'Matrix',
  syllabus_saved: 'Syllabus has been saved',
  syllabus_subject_name: 'Class name',
  synchronize: 'Sync with study plan',
  teaching_effects: 'Teaching effects',
  teaching_effects_off: 'Teaching effects are turned off',
  template: 'Template',
  template_name: 'Template name',
  text_section: 'Text',
  term: 'Term | Terms',
  time_to_end: 'remaining time',
  title: 'Title',
  training: 'Training',
  training_cycle: 'Training cycle',
  training_cycle_start: 'Year of commencement of the training cycle',
  to: 'to',
  type: 'Type',
  type_name: 'Type',
  type_of_term: 'Type of term',
  fellow: 'Type',
  unexpected_error: 'There was an unexpected error.',
  unit_names: 'Units',
  university_syllabus: 'University syllabus',
  university_wide_matrix: 'University wide matrix',
  unregister: 'Unsubscribe',
  update: 'Update',
  update_plan: 'Update plan',
  update_plans: 'Update plans',
  when: 'when',
  where: 'wher',
  winter: 'Winter',
  words: 'Phrases',
  work_on_site: 'Onsite work',
  yes: 'Yes',
  year: 'Year',
  year_and_term: 'Year and term',
  yearbook_of_course_learning_outcomes: 'Yearbook of the KEU',
}
