<template>
    <div class="courses">
        <transition name="slide" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
export default {
  name: 'Courses',
}
</script>

<style scoped>

</style>
