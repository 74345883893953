export const matrices = {
  namespaced: true,
  state: {
    modified: false,
  },
  mutations: {
    SET_MODIFIED (state, modified) {
      state.modified = modified
    },
  },
  actions: {
    setModified ({ commit }, modified) {
      commit('SET_MODIFIED', modified)
    },
  },
}
