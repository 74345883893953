<template>
  <prk-table
      :items="items"
      :show-headline="false"
      :excluded-fields="excludedFields"
      :fields-props="{ tdClass: ['py-4'] }"
      :custom-fields="customFields"
  >
    <template v-slot:table-colgroup="{ fields }">
      <col v-for="field in fields" :key="field.key"
           :style="{ width: field.key === 'id' ? '5%' : '' }"
      >
    </template>
    <template v-slot:head(id)>
      <pcg-checkbox
          @input="select"
          :value="checkedAll"
      >
      </pcg-checkbox>
    </template>
    <template v-slot:head(user)>
      {{ $t('general.name_and_surname') }}
    </template>
    <template v-slot:head(roles)>
      {{ $t('general.role') }}
    </template>
    <template v-slot:content="{ header, data }">
      <pcg-checkbox
          v-if="header.key === 'id'"
          v-model="data.item.selected"
      >
      </pcg-checkbox>
      <template v-else-if="header.key === 'user'">
        <div class="d-flex align-items-center">
          <prk-avatar :avatar="data.item.user.avatar" :abbr="data.item.user.abbr" :text="data.item.user.text"/>
        </div>
      </template>
      <template v-else-if="header.key === 'roles'">
        <prk-main-select
            :options="data.item.roles"
            :default-option="defaultRole(data.item.roles)"
            color="aqua"
            class="w-auto mr-3 mr-sm-0"
            @input="setRole(data.item.id, $event)"
        />
      </template>
      <base-arrow
          v-else-if="header.key === 'details'"
          :show="data.detailsShowing"
          @click="data.item._showDetails = !data.item._showDetails"
      />
      <div v-else
           class="prk-fz-12"
           :class="{ 'pcg-main-active-color': data.item.selected }"
      >
        {{ data.item[header.key] }}
      </div>
    </template>
    <template v-slot:row-details="{ item }">
      <personnel-details :item="item"/>
    </template>
  </prk-table>
</template>

<script>
import PrkTable from '../PrkTable'
import PrkMainSelect from '../PrkSelect'
import PersonnelDetails from './PersonnelDetails'
import PrkAvatar from '../PrkAvatar'
import useList from '../../composables/useList'
import useHelpers from '../../composables/useHelpers'

export default {
  name: 'PersonnelTable',
  setup (props, { root }) {
    const {
      checkedAll,
      getSelected,
      items,
      setItems,
      select,
      removeRow,
    } = useList(root)
    const { uuidv4 } = useHelpers()
    return {
      checkedAll,
      getSelected,
      items,
      setItems,
      select,
      uuidv4,
      removeRow,
    }
  },
  components: {
    PersonnelDetails,
    PrkMainSelect,
    PrkTable,
    PrkAvatar,
  },
  data () {
    return {
      excludedFields: ['_showDetails', 'selected', 'subItems'],
    }
  },
  async created () {
    const response = await this.$api.get('employees')
    const roles = response.meta.roles
    const employees = response.data.map(employee => {
      return {
        id: employee.id,
        user: {
          text: `${employee.attributes.firstName} ${employee.attributes.lastName}`,
          avatar: employee.attributes.avatar,
          abbr: employee.attributes.abbr,
        },
        roles: Object.keys(this.$_.pick(roles, 'admin', 'educator', 'student', 'employer')).map(key => {
          return {
            text: this.$_.upperFirst(this.$t(`general.roles.${
                this.$_.snakeCase(key)}`)),
            value: roles[key],
            selected: !!employee.attributes.roles.find(role => role === key),
          }
        }),
        selected: false,
        _showDetails: false,
        subItems: Array(4).fill(null).map(() => {
          return {
            courseCode: this.$_.shuffle(['E/I/O.1', 'E/I/EP/C.6', 'E/I/EP/C-1.2b'])[0],
            name_of_subject: this.$_.shuffle(['Mikroekonomia', 'Matematyka'])[0],
            positions: [
              { text: this.$t('general.responsible_person'), value: 0, selected: false },
              { text: this.$t('general.supervisor'), value: 1, selected: false },
              { text: this.$t('general.coordinator'), value: 2, selected: false },
            ],
            defaultOption: { text: this.$t('general.choose') },
          }
        }),
      }
    })
    this.setItems(employees)
  },
  computed: {
    showDetails () {
      return this.items.map(item => item._showDetails)
    },
    customFields () {
      return [{ key: 'details', label: '', tdClass: ['align-middle'] }]
    },
  },
  watch: {
    showDetails () {
      this.$nextTick(() => {
        const detailsTable = document.querySelector('.b-table-details')
        const detailsTableContent = detailsTable?.querySelector('.pcg-table-content')
        // eslint-disable-next-line no-unused-expressions
        detailsTableContent?.classList?.add('pcg-the-lightest-gray-background-color')
      })
    },
  },
  methods: {
    defaultRole (roles) {
      return roles.find(role => role.selected) ||
          { text: this.$t('general.choose'), value: 0 }
    },
    setRole (employeeId, role) {
      this.$api.put(`employees/${employeeId}`, {
        roleMask: role,
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .dropdown {
    width: auto !important;
  }

  .b-table-details {
    .pcg-table-container {
      td {
        padding-left: 100px !important;
      }
    }
  }
}
</style>
