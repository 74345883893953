export const studyPlanTable = {
  jsonapi: {
    subjects: {
      config: ({ studyPlanId, semesterId }) => ({
        method: 'get',
        url: 'subjects?serializer=form',
        params: {
          semester_id: semesterId,
          study_plan_id: studyPlanId,
        },
      }),
      fetchPolicy: 'no-cache',
      variables () {
        return {
          studyPlanId: this.studyPlanId,
          semesterId: this.semesterId,
        }
      },
      skip () {
        if (this.$checkRouteName('study_plans_edit_path')) {
          return !this.isStudyPlanIdExist || !this.isSemesterIdExist
        } else {
          return !this.isStudyPlanIdExist && !this.isSemesterIdExist
        }
      },
      update (subjects) {
        return subjects.map(sub => {
          return {
            ...sub,
            _edited: 0,
            _destroy: 0,
          }
        })
      },
    },
  },
  computed: {
    subjectsByForm () {
      return this.subjects.filter(subject => {
        if (this.semesterIds.length > 0 && this.semesterId.length === 0) {
          return this.semesterIds.includes(subject.semester.id)
        }
        if (this.semesterId.length) {
          return this.subjects
        }
      }).map(subject => {
        return subject.subjectForms.map(subjectForm => {
          return {
            id: `${subject.id}|${subjectForm.id}`,
            name: subject.subjectName.name,
            formOfClass: subjectForm.formOfClassName,
            formOfCredit: subjectForm.formOfCreditName,
            numberOfHours: subjectForm.numberOfHours,
            ects: subjectForm.ects,
            semesterNumber: subject.semester.semesterNumber,
            lecturers: subjectForm.lecturers,
            parentId: subject.parentId,
            specialPlan: subject.specialPlan,
            speciality: (this.$checkRouteName('study_plans_edit_path') ||
            this.$checkRouteName('study_plans_new_path'))
              ? null : subject.semester.speciality?.name,
            hasMatrices: subject.hasMatrices,
            _destroy: subjectForm._destroy || subject._destroy,
          }
        })
      }).flat().filter(form => form._destroy !== 1)
        .sort((a, b) => a.name.localeCompare(b.name))
    },
    isSemesterIdExist () {
      return this.semesterId.length !== 0 && !this.semesterId.match('__new')
    },
    isStudyPlanIdExist () {
      return this.studyPlanId.length !== 0 && !this.studyPlanId.match('__new')
    },
    studyPlanId () {
      return this.$store.getters['studyPlans/planId']
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['id', 'lecturers', 'semesterNumber', 'parentId', '_destroy', 'hasMatrices']
    const showSpeciality = this.$checkRouteName('study_plans_edit_path') ||
      this.$checkRouteName('study_plans_new_path')
    showSpeciality && this.EXCLUDED_FIELDS.push('speciality')

    this.$watch(vm => [vm.$jsonapi.loading], function () {
      if (this.$jsonapi.loading) return

      this.setModifiedSubjects()
      this.setCurrentSubjects()
    })

    this.CUSTOM_FIELDS = [
      {
        key: 'ects',
        label: this.$t('components.prk_syllabus.table.ects'),
        tdClass: ['align-middle'],
      },
      {
        key: 'leading',
        label: this.$t('components.prk_syllabus.table.lecturers'),
        tdClass: ['align-middle'],
      },
      {
        key: 'specialPlan',
        label: this.$t('components.prk_syllabus.table.from_plan'),
        tdClass: ['align-middle'],
      },
      {
        key: 'actions',
        label: '',
        tdClass: ['align-middle'],
      },
    ]
  },
}
