<template>
  <b-col class="col-lg-3 my-3 my-lg-0">
    <prk-select
        class="w-100"
        color="aqua"
        :default-option="{ text: $t('general.choose') }"
        :error-text="$t('views.study_plans.errors.formOfClass')"
        :include-blank="true"
        :label="$tc('general.form_of_class', 1)"
        :options="formOfClasses"
        :show-error="showError"
        :value="value"
        @input="updateFormOfClasses($event)"
    />
  </b-col>
</template>

<script>
import PrkSelect from '../../../../PrkSelect'

export default {
  name: 'FormOfClasses',
  components: {
    PrkSelect,
  },
  props: {
    index: { required: true, type: Number },
    formOfClass: { type: String },
  },
  inject: ['$v'],
  jsonapi: {
    formOfClassesData: {
      config: () => ({
        method: 'get',
        url: 'form_of_classes',
      }),
      fetchPolicy: 'no-cache',
    },
  },
  data () {
    return {
      formOfClassesData: [],
    }
  },
  computed: {
    value () {
      return this.formOfClasses.find(form => form.text === this.formOfClass)?.value ?? null
    },
    showError () {
      return Object.values(this.$v.subject.subjectForms.$each.$iter).filter(obj => {
        return !obj.$model.hidden
      })[this.index].formOfClassName.$error
    },
    formOfClasses () {
      return this.formOfClassesData.map(formOfClass => {
        return { text: formOfClass.name, value: formOfClass.id }
      })
    },
  },
  methods: {
    updateFormOfClasses (value) {
      const formOfClass = this.formOfClasses.find(formOfClass => formOfClass.value === value)
      if (value === null) {
        this.$emit('update:formOfClass', '')
        this.$emit('update:formOfClassId', '')
        this.$emit('update')
      } else {
        this.$emit('update:formOfClass', formOfClass.text)
        this.$emit('update:formOfClassId', formOfClass.value)
        this.$emit('update')
      }
    },
  },
}
</script>
