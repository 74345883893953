<template>
    <g>
        <path d="M14.7008 0.547852H3.14058C2.33746 0.548784 1.5675 0.868235 0.999604 1.43613C0.431712 2.00402 0.112261 2.77398 0.111328 3.5771L0.111328 15.1373C0.112261 15.9405 0.431712 16.7104 0.999604 17.2783C1.5675 17.8462 2.33746 18.1656 3.14058 18.1666H14.7008C15.5039 18.1656 16.2739 17.8462 16.8418 17.2783C17.4097 16.7104 17.7291 15.9405 17.7301 15.1373V3.5771C17.7285 2.77417 17.4089 2.00457 16.8411 1.43681C16.2733 0.869054 15.5037 0.549404 14.7008 0.547852V0.547852ZM16.0856 15.1373C16.0841 15.5041 15.9377 15.8555 15.6783 16.1149C15.419 16.3742 15.0676 16.5206 14.7008 16.5222H3.14058C2.77377 16.5206 2.42243 16.3742 2.16306 16.1149C1.90369 15.8555 1.75729 15.5041 1.75574 15.1373V3.5771C1.75729 3.21029 1.90369 2.85895 2.16306 2.59958C2.42243 2.34021 2.77377 2.19381 3.14058 2.19227H14.7008C15.0676 2.19381 15.419 2.34021 15.6783 2.59958C15.9377 2.85895 16.0841 3.21029 16.0856 3.5771V15.1373Z"/>
        <path d="M10.0836 6.77044L8.92547 5.6123L7.76381 6.77044L5.16211 9.37214L6.32377 10.5338L8.09269 8.76488V13.0944H9.73711V8.73082L11.5401 10.5338L12.6982 9.37214L10.0836 6.77044Z"/>
    </g>
</template>

<script>
export default {
  name: 'IconUpload',
}
</script>
