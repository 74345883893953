<template>
  <b-row align-v="center">
    <b-col cols="12" sm="4" class="mb-2 mb-sm-0 mt-4">
      <b-row no-gutters>
        <b-col>
          <pcg-search v-model="query"
                      :placeholder="$t('general.search')"
                      class="m-0"
          />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'SyllabusSubEffectsModalSearch',
  data () {
    return {
      query: '',
    }
  },
  created () {
    this.$watch(vm => [vm.query], this.$_.debounce(() => {
      this.$emit('change:query', this.query)
    }, 200))
  },
}
</script>
