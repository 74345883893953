<template>
  <prk-table :items="effects"
             :show-headline="false"
             :excluded-fields="['id']"
             class="sub-table"
             :fields-props="{ thClass: 'd-none'} "

  >
    <template v-slot:table-colgroup="{ fields }">
      <col v-for="field in fields" :key="field.key"
           :style="{ width: colgroup(field.key) }"
      >
    </template>

    <template v-slot:content="{header, data }">
      <div v-if="header.key === 'symbol'"
           class="prk-fz-12 ml-5">
        {{ data.item.symbol }}
      </div>

      <div v-else-if="header.key === 'description'"
           class="prk-fz-12">
        {{ data.item.description }}
      </div>
      <div v-else-if="header.key === 'category'"
           class="prk-fz-12">
        {{ data.item.category }}
      </div>
    </template>
  </prk-table>
</template>

<script>
import PrkTable from '../PrkTable'

export default {
  name: 'CourseGeneralEffectEffects',
  components: { PrkTable },
  props: {
    effects: {
      type: Array,
      required: true,
    },
  },
  methods: {
    colgroup (field) {
      switch (field) {
        case 'category':
          return '10%'
        case 'description':
          return '80%'
        case 'symbol':
          return '10%'
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.sub-table {
  ::v-deep {
    .pcg-table-container {
      background: $pcg-the-lightest-gray;
      .pcg-table-content {
        background: $pcg-the-lightest-gray;
        tbody {
          .pcg-table-narrow-row {
            td {
              border-bottom: 1px solid $pcg-lightest-gray !important;
            }
          }
        }
      }
    }
    .pcg-table-container, .pcg-table-content {
      border-radius: 0 !important;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .sub-table {
    ::v-deep {
      .pcg-table-container {
        background-color: $pcg-the-lightest-gray;
        .pcg-table-content {
          background-color: $pcg-the-lightest-gray;
        }
      }
    }
  }
}
</style>
