<template>
  <div>
    <pcg-modal
        modal-id="effects-modal"
        size="lg"
        :title="$t('components.prk_modals.subject_effect.name')"
        icon="pcg-icon-document"
        :btn-title="$t('components.prk_modals.subject_effect.confirm')"
        :action="saveSubjectEffect"
        @hidden="clear">

      <horizontal-select-categories :show-error="$v.keu.categoryId.$error"/>
      <description-effect :show-error="$v.keu.effectContentObj.$error"/>
      <syllabus-effects :click-add-effect="clickAddEffect"/>

    </pcg-modal>
    <syllabus-teaching-effects-modal/>
    <syllabus-sub-effects-modal :category-id="keu.categoryId"/>
  </div>
</template>

<script>
import HorizontalSelectCategories from './HorizontalSelectCategories'
import DescriptionEffect from './DescriptionEffect'
import { required } from 'vuelidate/lib/validators'
import SyllabusEffects from './SyllabusEffects'
import SyllabusSubEffectsModal from './SyllabusSubEffectsModal'
import { mapActions, mapState } from 'vuex'
import SyllabusTeachingEffectsModal from './SyllabusTeachingEffectsModal'

export default {
  name: 'prk-subject-effect-modal',
  components: {
    SyllabusTeachingEffectsModal,
    SyllabusSubEffectsModal,
    SyllabusEffects,
    DescriptionEffect,
    HorizontalSelectCategories,
  },
  validations: {
    keu: {
      categoryId: { required },
      effectContentObj: { required },
    },
  },
  props: {
    categories: {
      required: true,
      type: Array,
    },
  },
  methods: {
    ...mapActions('syllabusFill', ['addSubjectEffect', 'setSubjectEffect']),
    saveSubjectEffect () {
      this.$v.$touch()
      if (!this.$v.keu.$invalid) {
        const effect = {
          ...this.keu,
          id: this.editMode ? this.keu.id : `${this.$uuidv4()}__new`,
          educationalOutcome: this.editMode
            ? this.keu.educationalOutcome
            : this.setSymbolOfSubjectEffect(this.keu.categoryId),
          comments: [],
          commentCount: 0,
          verificationMethod: '',
        }
        this.editMode ? this.setSubjectEffect(effect) : this.addSubjectEffect(effect)
        this.$bvModal.hide('effects-modal')
      }
    },
    clickAddEffect () {
      this.$v.keu.categoryId.$touch()
      if (!this.$v.keu.categoryId.$invalid) this.$bvModal.show('newEffect')
    },
    clear () {
      this.$v.$reset()
      this.$store.dispatch('courseLearningOutcome/resetState')
    },
    setSymbolOfSubjectEffect (categoryId) {
      const categoryShortSubjectEffect = this.categories.find(cat => cat.id === categoryId).shortcutSubjectEffect
      const lastSymbol = this.subjectEffects.filter(effect => effect.categoryId === categoryId)
        .slice(-1)[0]?.educationalOutcome
      return `${categoryShortSubjectEffect}${this.$_.padStart((+lastSymbol?.slice(-2) + 1) || 1, 2, 0)}`
    },
  },
  computed: {
    ...mapState('courseLearningOutcome', ['keu', 'editMode']),
    ...mapState('syllabusFill', ['subjectEffects']),
  },
}
</script>
