<template>
  <pcg-modal
      :action="() => {}"
      btn-class="d-none"
      :btn-title="$t('general.close')"
      icon="pcg-icon-book"
      modal-id="syllabuses-list"
      size="md"
      :title="$tc('general.syllabuses', 2)"
      @shown="refetch"
  >
    <square-semester-select
        :count-semesters="semesters.length"
        @change="selectedSemesters = $event"/>
    <prk-table
        disable-actions
        :items="syllabuses"
        :show-headline="false"
        :excluded-fields="['semesterIds', '__type', 'id']"
    />
  </pcg-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkTable from '../../PrkTable'
import SquareSemesterSelect from '../../matrices/modals/SquareSemesterSelect'

const { mapState } = createNamespacedHelpers('studyPlans')

export default {
  name: 'StudyPlanSyllabusesListModal',
  components: {
    PrkTable,
    SquareSemesterSelect,
  },
  jsonapi: {
    syllabusesData: {
      config: ({ planId }) => ({
        method: 'get',
        url: `syllabuses?serializer=study_plan_list&study_plan_id=${planId}`,
      }),
      fetchPolicy: 'no-cache',
      variables () {
        return {
          planId: this.studyPlanId,
        }
      },
      skip () {
        return !this.studyPlanId?.length
      },
    },
  },
  data () {
    return {
      selectedSemesters: [],
      syllabusesData: [],
      init: false,
    }
  },
  computed: {
    ...mapState(['plan']),
    studyPlanId () {
      return this.plan?.id
    },
    semesters () {
      return this.plan?.semesters || []
    },
    syllabuses () {
      return this.syllabusesData.filter(syllabus => {
        return this.selectedSemesters.includes(...syllabus.semesterIds)
      })
    },
  },
  methods: {
    refetch () {
      if (!this.init) {
        this.init = true
        return
      }
      this.$jsonapi.queries.syllabusesData.refetch()
    },
  },
}
</script>
