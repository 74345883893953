<template>
  <div>
    <return path-name="dictionaries_general_effects_path"
            class="mb-1 d-inline-block"
    >
      {{ $t('components.prk_syllabus.return.general_effects') }}
    </return>
    <characteristic-effect-buttons
        :add-effect="addEffect"
        :characteristic-effects="items"
    />
    <b-row align-v="center" align-h="end" class="mt-3 mb-4 mr-0">
      <categories ref="allCategories"
                  :categories="categories"
                  :change-category="changeCategory"
                  :args="{ effectType: 'characteristic', generalEffectId }"
      />
    </b-row>

    <prk-table
        v-if="items.length > 0"
        class="mt-3 directional-effects prk-shadow"
        :excluded-fields="excludedFields"
        :fields-props="{ tdClass: tdClass }"
        :items="items"
        :show-headline="false"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: field.key === 'description' ? '70%' : '10%' }"
        >
      </template>
      <template v-slot:head(id)>
        <pcg-checkbox @input="select" :value="checkedAll"/>
      </template>
      <template v-slot:head(details)/>

      <template v-slot:content="{ header, data }">
        <pcg-checkbox v-if="header.key === 'id'" v-model="data.item.selected">
        </pcg-checkbox>
        <template v-else-if="header.key === 'description'">
          <prk-animated-description :description="data.item.description" />
        </template>
        <base-arrow v-else-if="header.key === 'details'"
                    class="effects-arrows"
                    :show="data.item.toggleDetails"
                    @click="showText(data, $event)"
        />

        <div v-else-if="header.key === 'actions'">
          <div class="d-flex justify-content-end">
            <pcg-btn round text="edit" class="mr-3 prk-button" :icon="iconEdit"
                     @click="editResource(data.item.id,
                     'effect')"/>
            <pcg-btn round :icon="iconRemove" text="delete"
                     class="mr-3 prk-button" variant="danger"
                     @click="destroyResource('characteristic_effects',
                     data.item.id, data.index)"/>
          </div>
        </div>
      </template>
    </prk-table>

    <form-effect-modal
        :action="action(effect, 'effects')"
        :btn-title="editMode ? $t('general.update') : `+ ${$t('general.new_effect')}`"
        :categories="categories"
        :edit-mode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="editMode ? $t('general.edit') : $t('general.new_effect')"
        v-bind.sync="effect"
        @clear-error="errors[$event] = ''"
        @hidden="hideModal('effect')"
    />
  </div>
</template>

<script>
import PrkTable from '../../components/PrkTable'
import Return from '../../components/base_components/Return'
import FormEffectModal
  from '../../components/dictionaries/modal/FormEffectModal'
import Categories from '../../components/shared_view/Categories'
import CharacteristicEffectButtons
  from '../../components/dictionaries/characteristic_effects/CharacteristicEffectButtons'
import useEffects from '../../composables/useEffects'
import useDictionary from '../../composables/useDictionary'
import useIcon from '../../composables/useIcon'
import useExpandText from '../../composables/useExpandText'
import { dictionary } from '../../mixins/dictionary'
import PrkAnimatedDescription from '../../components/PrkAnimatedDescription'
import { mapGetters } from 'vuex'

export default {
  name: 'DictionaryCharacteristicEffects',
  setup (props, { root, refs }) {
    const params = {
      effect_type: 'characteristic',
      general_effect_id: root.$route.params.generalEffectId,
    }
    const {
      checkedAll,
      items,
      select,
      categories,
      changeCategory,
      checkIfCanAdd,
      findItemById,
      removeRow,
      tmpCategoryId,
    } = useEffects({
      root,
      refs,
    }, params)
    const { iconEdit, iconRemove } = useIcon(root)
    const {
      addResource,
      canAdd,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      updateResource,
    } = useDictionary()
    const { showText, tdClass } = useExpandText('dictionary-characteristic-effects')

    return {
      addResource,
      canAdd,
      categories,
      changeCategory,
      checkedAll,
      checkIfCanAdd,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      iconEdit,
      iconRemove,
      items,
      removeRow,
      select,
      showText,
      tdClass,
      tmpCategoryId,
      updateResource,
    }
  },
  components: {
    PrkAnimatedDescription,
    CharacteristicEffectButtons,
    Categories,
    FormEffectModal,
    Return,
    PrkTable,
  },
  mixins: [dictionary],
  data () {
    return {
      effect: {
        symbol: '',
        description: '',
        category: '',
        categoryId: '',
        generalEffectId: '',
        selected: false,
        toggleDetails: false,
        effectType: 'characteristic',
        [Symbol.for('_innerName')]: 'effect',
      },
      effectWasAdded: false,
      wordsCount: 200,
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    excludedFields () {
      return ['selected', 'toggleDetails', 'categoryId', 'generalEffectId', 'active', 'effectType', 'range']
    },
    generalEffectId () {
      return this.$route.params.generalEffectId
    },
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
    'effect.categoryId': {
      handler () {
        if (this.effect.categoryId.length) {
          this.tmpCategoryId = this.effect.categoryId
          this.canAdd = this.checkIfCanAdd()
        }
      },
    },
  },
  mounted () {
    this.link = document.querySelector('[href="/dictionaries/general_effects"]')
    this.link.classList.add('active', 'link-active')
  },
  methods: {
    addEffect () {
      this.$bvModal.show('add-modal')
      this.effect.generalEffectId = this.generalEffectId
      this.effect.effectType = 'characteristic'
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: `${this.$route.query.generalEffect} - ${this.$route.query.type} - ${this.$route.query.profile}`,
      })
    },
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  beforeRouteLeave (to, from, next) {
    this.link.classList.remove('active', 'link-active')
    next()
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  .pcg-table-container {
    border-radius: 15px 15px 0 0;

    .pcg-table-content {
      border-radius: 15px 15px 0 0;
    }
  }
}

.label {
  color: $pcg-the-darkest-gray;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .label {
    color: $pcg-the-darkest-gray;
  }
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
