var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',{staticClass:"mt-1",attrs:{"align-v":"center"}},[_c('b-col',[_c('categories',{ref:"allCategories",attrs:{"categories":_vm.categories,"change-category":_vm.changeCategory,"args":{ offline: true }}})],1),_vm._v(" "),_c('course-effect-buttons',{attrs:{"collapse-all":_vm.collapseAll,"course":_vm.course,"expand-all":_vm.expandAll,"filtered-course-effects":_vm.filteredCourseEffects}})],1),_vm._v(" "),_c('b-row',{staticClass:"mt-4"},[_c('b-col',[_c('div',{staticClass:"directional-effects-outcomes-list"},[_c('transition',{attrs:{"name":"c-fade"}},[(_vm.filteredCourseEffects.length > 0)?_c('prk-table',{attrs:{"excluded-fields":_vm.EXCLUDED_FIELDS,"fields-props":{ thAttr: _vm.thAttr, tdClass: _vm.tdClass },"items":_vm.filteredCourseEffects,"headers-props":_vm.headers,"disable-auto-headers":"","primary-key":"customId","show-headline":false},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return [_c('prk-colgroup',{attrs:{"columns":['description', 'enDescription'],"fields":fields,"width":"28%"}})]}},{key:"head(customId)",fn:function(){return [_c('pcg-checkbox',{attrs:{"value":_vm.selectedAll},on:{"input":_vm.select}})]},proxy:true},{key:"head(details)",fn:function(){return undefined},proxy:true},{key:"head(effects)",fn:function(){return [_vm._v("\n              "+_vm._s(_vm.$t('components.prk_courses.reference'))+"\n            ")]},proxy:true},{key:"cell(customId)",fn:function(ref){
var courseEffect = ref.item;
return [_c('pcg-checkbox',{model:{value:(courseEffect.selected),callback:function ($$v) {_vm.$set(courseEffect, "selected", $$v)},expression:"courseEffect.selected"}})]}},{key:"content",fn:function(ref){
var header = ref.header;
var data = ref.data;
return [(header.key === 'symbol')?_c('div',[_c('span',[_vm._v(_vm._s(data.item.symbol))])]):(header.key === 'effects')?_c('div',[_vm._l((data.item.effects),function(effect){return [_c('div',{key:effect.id,staticClass:"pl-2"},[_vm._v(_vm._s(effect.symbol))])]})],2):(header.key === 'description')?[_c('prk-animated-description',{attrs:{"description":data.item.description}})]:(header.key === 'enDescription')?[_c('prk-animated-description',{attrs:{"description":data.item.enDescription}})]:(header.key === 'details')?_c('base-arrow',{staticClass:"effects-arrows",attrs:{"show":data.item.toggleDetails},on:{"click":function($event){return _vm.showDescription(data, $event)}}}):(header.key === 'actions')?[_c('course-effect-actions',{attrs:{"course-effect":data.item},on:{"update:editMode":function($event){_vm.editMode = $event},"update:editedCourseEffect":function($event){_vm.editedCourseEffect = $event},"remove:course-effect":function($event){return _vm.$emit('remove:course-effect', $event)}}})]:_vm._e()]}}],null,false,1105327440)}):_vm._e()],1)],1)])],1),_vm._v(" "),(!_vm.isStandardView)?_c('previous-year-modal',{attrs:{"action":_vm.assignEffects,"default-course-name-id":_vm.course.courseNameId,"default-faculty-id":_vm.course.facultyId}}):_vm._e(),_vm._v(" "),_c('prk-add-course-effect-modal',{key:_vm.rerenderAddCourseEffectModal,attrs:{"categories":_vm.categories,"edit-mode":_vm.editMode,"edited-course-effect":_vm.editedCourseEffect,"number-of-effects":(_vm.filteredCourseEffects.length + 1).toString()},on:{"update:editMode":function($event){_vm.editMode=$event},"update:edit-mode":function($event){_vm.editMode=$event},"add:course-effect":function($event){return _vm.$emit('add:course-effect', $event)},"update:course-effect":function($event){return _vm.$emit('update:course-effect', $event)},"hidden":_vm.hideCourseEffectModal}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }