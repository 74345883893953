import api from '../../api'

export const doc = {
  methods: {
    downloadDoc () {
      api.post('syllabuses/download_doc', { id: this.syllabusId },
        { responseType: 'blob' }).then((response) => {
        this.$downloadFile(response, 'docx', this.syllabus.name || this.syllabus.subject)
      })
    },
  },
}
