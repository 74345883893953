import { ref, onUnmounted } from '@vue/composition-api'
const expandedAll = ref(false)

export default function (className = '') {
  const collapseAll = () => {
    const arrows = document.querySelectorAll('.effects-arrows.pcg-icon-arrow-up')
    arrows.forEach(arrow => arrow.click())
  }

  const expandAll = () => {
    const arrows = document.querySelectorAll('.effects-arrows.pcg-icon-arrow-down')
    arrows.forEach(arrow => arrow.click())
  }

  const showText = (data, event) => {
    data.item.toggleDetails = !data.item.toggleDetails

    const cellNodes = event.target.closest('tr').querySelectorAll(`.${className}`)
    cellNodes.forEach(cell => {
      const description = cell.querySelector('.description')
      const wrapper = cell.querySelector('.wrapper')

      description.style.height = '25px'
      wrapper.classList.toggle('text-truncate')

      _setCellHeight(data, { wrapper, description })
    })
  }

  const tdClass = (_, key) => {
    if (['enDescription', 'description'].includes(key)) {
      return [
        'py-4',
        className,
        'max-width-0',
      ]
    } else {
      return ['py-4']
    }
  }

  const initObserver = () => {
    const arrows = document.querySelectorAll('.effects-arrows')
    const config = { attributes: true, childList: true, subtree: true }
    const observer = new MutationObserver(function (_, observer) {
      expandedAll.value = _allUp(arrows)
      observer.disconnect()
    })
    arrows.forEach(arrow => {
      observer.observe(arrow, config)
    })
  }

  const _setCellHeight = (data, htmlElements = {}) => {
    const { description, wrapper } = htmlElements
    const height = wrapper.getBoundingClientRect().height
    if (data.item.toggleDetails) {
      description.style.height = `${height}px`
    } else {
      description.style.height = '25px'
    }
  }

  const _allUp = (arrows) => {
    return Array.from(arrows).every(arrow => arrow.classList.contains('pcg-icon-arrow-up'))
  }

  onUnmounted(() => {
    expandedAll.value = false
  })

  return {
    expandedAll,
    collapseAll,
    expandAll,
    initObserver,
    tdClass,
    showText,
  }
}
