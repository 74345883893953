import { createNamespacedHelpers } from 'vuex'
import { numeric, minValue } from 'vuelidate/lib/validators'

const { mapState, mapGetters, mapActions } = createNamespacedHelpers('syllabusFill')

const sumValidator = (value, vm) => {
  const ects = vm.elements[4].average_number_of_hours.value
  const sumOfHours = vm.elements[3].average_number_of_hours.value
  return sumOfHours <= ects * 30
}

export const syllabusWorkload = {
  props: {
    section: {
      required: true,
      type: Object,
    },
  },
  validations: {
    validateSumHours: { sumValidator, numeric },
    ownWork: { minValue: minValue(0) },
    workWithTeacher: { minValue: minValue(0) },
  },
  methods: {
    ...mapActions(['setOwnWork', 'setWorkWithTeacher']),
    tdClass (value, key) {
      if (key === 'average_number_of_hours') {
        return ['align-middle', 'py-4', 'text-center', 'line-height']
      } else {
        return ['align-middle', 'py-4']
      }
    },
    thAttr (value, key) {
      if (key === 'average_number_of_hours') {
        return {
          style: 'text-align: center',
        }
      } else {
        return {}
      }
    },
    setWorkload (index, value) {
      index === 1 ? this.setWorkWithTeacher(value) : this.setOwnWork(value)
    },
  },
  computed: {
    ...mapState(['ownWork', 'workWithTeacher', 'planHours', 'ects', 'planHours']),
    ...mapGetters(['getSumOfHours']),
    validateSumHours () {
      return +this.elements[1].average_number_of_hours.value
    },
    elements () {
      return [
        {
          hours: {
            value: this.$t('components.prk_syllabus.workload.contact_hours'),
            type: 'text',
          },
          average_number_of_hours: {
            value: this.planHours,
            type: 'text',
          },
        },
        {
          hours: {
            value: this.$t('components.prk_syllabus.workload.other'),
            type: 'text',
          },
          average_number_of_hours: {
            value: '',
            type: 'input',
          },
        },
        {
          hours: {
            value: this.$t('components.prk_syllabus.workload.no_contact_hours'),
            type: 'text',
          },
          average_number_of_hours: {
            value: '',
            type: 'input',
          },
        },
        {
          hours: {
            value: this.$t('components.prk_syllabus.workload.sum'),
            type: 'text',
          },
          average_number_of_hours: {
            value: this.getSumOfHours,
            type: 'text',
          },
        },
        {
          hours: {
            value: this.$t('components.prk_syllabus.workload.ects'),
            type: 'text',
          },
          average_number_of_hours: {
            value: this.ects,
            type: 'text',
          },
        },
      ]
    },
  },
}
