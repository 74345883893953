export default {
  install: function (Vue) {
    Vue.prototype.$getErrors = function (errors) {
      const allErrors = {}
      for (const key in errors) {
        allErrors[key] = errors[key][0]
      }
      return allErrors
    }

    Vue.prototype.$showError = function (errors) {
      if (Object.values(errors).length) {
        this.$toastr.e((Object.values(errors)[0]))
      } else {
        this.$toastr.e(this.$t('general.error_try_later'))
      }
    }

    Vue.prototype.$showWarning = function (errors) {
      if (Object.values(errors).length) {
        this.$toastr.w((Object.values(errors)[0]))
      } else {
        this.$toastr.w(this.$t('general.error_try_later'))
      }
    }
  },
}
