<template>
  <div :class="{ hiddenSection: !$attrs.visible }">
    <b-row align-v="end" align-h="between">
      <b-col v-if="showHeadlineIndex" cols="auto" class="number-box">
        {{ +$attrs.number }}
      </b-col>
      <b-col cols="10" sm="10" md="10" lg="10" xl="8">
        <div class="position-relative pb-3">
          <div class="prk-fz-12 heading">
            {{ $t('components.prk_syllabus.section.heading') }}
            <img v-b-popover.hover.top="headerTooltip"
                 :src="require('../../assets/images/icons/info.svg')"/>
            <img v-if="!$attrs.showOnThePrintout"
                 v-b-popover.hover.top="$t('general.hide_on_printout')" class="ml-2"
                 :src="require('../../assets/images/icons/no-print.svg')"/>
          </div>
          <pcg-text-input @blur="$emit('update:header', $event.target.value)" :value="$attrs.header"/>
        </div>
      </b-col>

      <b-col cols="auto" class="d-flex  align-items-center justify-content-end">
        <div class="d-flex flex-nowrap hide-section">
          {{ $t('general.hide_section') }}
          <pcg-switch @input="$emit('update:visible', $event)" :value="$attrs.visible"/>
        </div>

        <prk-dropdown
            class="settings"
            v-b-popover.hover.top="$t('components.prk_syllabus.tooltip.options')">
          <template #name>
            <img class="svg" :src="require('../../assets/images/icons/settings.svg')"/>
          </template>

          <template #default>
            <pcg-dropdown-item @click.native="$emit('update:showOnThePrintout', !$attrs.showOnThePrintout)">
              <div v-if="$attrs.showOnThePrintout">
                {{ $t('components.prk_syllabus.setting.hide_printout') }}
              </div>
              <div v-else>
                {{ $t('components.prk_syllabus.setting.show_printout') }}
              </div>
            </pcg-dropdown-item>
            <pcg-dropdown-item @click.native="showDescription">
              <div v-if="isDescriptionVisible">
                {{ $t('components.prk_syllabus.setting.remove_description') }}
              </div>
              <div v-else>
                {{ $t('components.prk_syllabus.setting.add_description') }}
              </div>
            </pcg-dropdown-item>
            <pcg-dropdown-item @click.native="restore">
              {{ $t('components.prk_syllabus.setting.restore') }}
            </pcg-dropdown-item>
          </template>
        </prk-dropdown>
        <i class="pcg-icon-cross close"
           v-if="$attrs.additionalSection"
           v-b-popover.hover.top="$t('components.prk_syllabus.tooltip.remove')"
           @click="$emit('close-section')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import PrkDropdown from '../PrkDropdown'

export default {
  name: 'PrkTableHeader',
  components: { PrkDropdown },
  props: {
    showHeadlineIndex: { type: Boolean, default: true },
  },
  data () {
    return {
      newValue: true,
      isDescriptionVisible: false,
    }
  },
  computed: {
    headerTooltip () {
      const generalKey = 'general.additional_section'
      const key = this.$te(this.langHeaderKey) ? this.langHeaderKey : generalKey

      return `${this.$t('components.prk_syllabus.tooltip.heading')}
              ${this.$t(key)}`
    },
    langHeaderKey () {
      return `components.prk_syllabus.sections.number_${
          this.$attrs.number?.replace('.', '_')}`
    },
  },
  methods: {
    restore () {
      if (this.$te(this.langHeaderKey)) {
        this.$emit('update:header', this.$t(this.langHeaderKey))
      } else {
        this.$emit('update:header', this.$t('general.additional_section'))
      }
    },
    showDescription () {
      this.isDescriptionVisible = !this.isDescriptionVisible
      this.$emit('add-description', this.isDescriptionVisible)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';
@import 'app/javascript/prk/assets/stylesheets/custom';

::v-deep {
  .switch {
    width: 32px;
    margin-top: 0 !important;
    margin-left: 0.8rem;
  }

  .form-group {
    margin-top: 0.8rem;
  }

  .show > .btn-secondary.dropdown-toggle {
    background-color: $pcg-aqua-blue;
  }

  .pcg-main-btn {
    background-color: $pcg-white;
    box-shadow: 0 3px 10px rgba(32, 19, 19, 0.2);
  }

  .pcg-main-btn:hover, .pcg-main-btn:focus {
    background-color: $pcg-white;
  }

  .pcg-icon-dropdown-arrow {
    color: $pcg-aqua-blue;
  }

  .show .pcg-icon-dropdown-arrow {
    color: $pcg-white;
  }

  .show .svg {
    filter: brightness(0) invert(1);
  }
}

.number-box {
  margin-bottom: 1.4rem;
  width: 3.357rem;
  height: 1.857rem;
  font-size: 0.857rem;
  line-height: 1.857rem;
  text-align: center;
  background-color: $pcg-lightest-gray;
  border-radius: 5px;
}

.hide-section {
  margin-right: .5rem;
  color: $main-active-color;
  font-weight: bold;
}

.settings {
  margin-bottom: 0.4rem;
}

.heading {
  position: absolute;
  top: -1rem;
  color: $pcg_the_darkest_gray;

  img {
    margin-bottom: 1px;
    cursor: pointer;
    width: 1.7rem;
    margin-left: 0.2rem;
  }
}

.close {
  cursor: pointer;
  font-size: 0.76rem;
  margin-left: 1rem;
}

.hiddenSection {

  .number-box {
    background-color: $pcg-dark-gray;
  }

  /deep/ .pcg-field {
    color: $pcg_light_gray;
    background-color: $pcg-lightest-gray;
  }

  .hide-section {
    color: $pcg-gray;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {
    .show > .btn-secondary.dropdown-toggle {
      background-color: $pcg-aqua-blue;
    }

    .pcg-main-btn {
      background-color: $pcg-white;
    }

    .pcg-main-btn:hover, .pcg-main-btn:focus {
      background-color: $pcg-white;
    }

    .pcg-icon-dropdown-arrow {
      color: $pcg-aqua-blue;
    }

    .show .pcg-icon-dropdown-arrow {
      color: $pcg-white;
    }
  }

  .number-box {
    background-color: $pcg-lightest-gray;
  }

  .hide-section {
    color: $main-active-color;
  }

  .heading {
    color: $pcg-the-darkest-gray;
  }

  .hiddenSection {

    .number-box {
      background-color: $pcg-dark-gray;
    }

    /deep/ .pcg-field {
      color: $pcg-light-gray;
      background-color: $pcg-lightest-gray;
    }

    .hide-section {
      color: $pcg-gray;
    }
  }
}
</style>
