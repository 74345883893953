<template>
 <g v-if="contrast">
   <path d="M40.1426 20.085C40.1426 24.0406 38.9696 27.9074 36.772 31.1964C34.5743 34.4853 31.4508 37.0488 27.7962 38.5625C24.1417 40.0763 20.1204 40.4724 16.2408 39.7007C12.3612 38.929 8.7975 37.0241 6.00045 34.2271C3.2034 31.43 1.29858 27.8664 0.526878 23.9868C-0.244827 20.1071 0.151241 16.0858 1.66499 12.4313C3.17875 8.77677 5.7422 5.6532 9.03118 3.45557C12.3202 1.25794 16.187 0.0849609 20.1426 0.0849609C25.4469 0.0849609 30.534 2.1921 34.2847 5.94282C38.0354 9.69355 40.1426 14.7806 40.1426 20.085V20.085Z" fill="#003204"/>
   <path d="M10.6543 13.9175V26.2523H29.6309V13.9175H10.6543ZM27.4974 15.0566L20.1418 21.345L12.7911 15.0566H27.4974ZM11.8237 15.7443L16.8952 20.0849L11.8237 24.4206V15.7443ZM12.7853 25.1156L17.779 20.8427L20.1418 22.8639L22.5079 20.8427L27.5016 25.1156H12.7853ZM28.4607 24.4206L23.3893 20.0849L28.4607 15.7467V24.4206Z" fill="#00FA53"/>
 </g>
  <g v-else>
    <path d="M40.1426 20.085C40.1426 24.0406 38.9696 27.9074 36.772 31.1964C34.5743 34.4853 31.4508 37.0488 27.7962 38.5625C24.1417 40.0763 20.1204 40.4724 16.2408 39.7007C12.3612 38.929 8.7975 37.0241 6.00045 34.2271C3.2034 31.43 1.29858 27.8664 0.526878 23.9868C-0.244827 20.1071 0.151241 16.0858 1.66499 12.4313C3.17875 8.77677 5.7422 5.6532 9.03118 3.45557C12.3202 1.25794 16.187 0.0849609 20.1426 0.0849609C25.4469 0.0849609 30.534 2.1921 34.2847 5.94282C38.0354 9.69355 40.1426 14.7806 40.1426 20.085V20.085Z" fill="#ECF9FD"/>
    <path d="M10.6543 13.9175V26.2523H29.6309V13.9175H10.6543ZM27.4974 15.0566L20.1418 21.345L12.7911 15.0566H27.4974ZM11.8237 15.7443L16.8952 20.0849L11.8237 24.4206V15.7443ZM12.7853 25.1156L17.779 20.8427L20.1418 22.8639L22.5079 20.8427L27.5016 25.1156H12.7853ZM28.4607 24.4206L23.3893 20.0849L28.4607 15.7467V24.4206Z" fill="#00B4EB"/>
  </g>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IconEmail',
  computed: {
    ...mapGetters('page', ['contrast']),
  },
}
</script>
