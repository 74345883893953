<template>
  <b-row v-if="!isStandardView && studyPlans.length">
    <b-col class="prk-fz-12" cols="12">
      {{ $t('components.prk_modals.add_discipline.course') }}
    </b-col>
    <b-col class="prk-fz-14 font-weight-bold">
      <div v-for="plan in studyPlans" :key="plan.id">
        {{ Object.values($_.omit(plan, 'id', 'profile')).join(', ') }}
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { inject } from '@vue/composition-api'

export default {
  name: 'DisciplineMetric',
  setup () {
    const isStandardView = inject('isStandardView', false)
    const course = inject('course')
    return { isStandardView, course }
  },
  jsonapi: {
    studyPlans: {
      config: ({ courseId }) => ({
        method: 'get',
        url: `disciplines/study_plans?course_id=${courseId}`,
      }),
      variables () {
        return {
          courseId: this.course.id,
        }
      },
      skip () {
        return !this.course.id.length
      },
      update (studyPlans) {
        const props = ['name', 'facultyName', 'courseName', 'kindName', 'typeName']
        return studyPlans.map(studyPlan => {
          return {
            id: studyPlan.id,
            ...this.$_.pick(studyPlan, ...props),
            studyProfile: this.course.studyProfile,
            academicYear: studyPlan.academicYear,
          }
        })
      },
    },
  },
  data () {
    return {
      studyPlans: [],
    }
  },
}
</script>
