export default {
  install: function (Vue) {
    Vue.prototype.$checkRouteName = function (name) {
      return this.$route.name === name
    }

    Vue.prototype.$isEditRoute = function () {
      const regex = /edit/
      return regex.test(this.$route.name)
    }
  },
}
