<template>
  <pcg-modal
      modal-id="downloadModal"
      :title="$t('components.prk_modals.effects_picker.name')"
      :description="$t('components.prk_modals.effects_picker.description')"
      :btn-title="$t('components.prk_modals.effects_picker.btn_title')"
      :action="checkValidation"
      size="lg"
  >
    <prk-table
        :items="matrices"
        :show-headline="false"
        :excluded-fields="[]"
        class="mb-3"
    >
      <template v-slot:table-colgroup="{ fields }">
        <prk-col-group
            :columns="['id']"
            :fields="fields" width="5%"
        />
      </template>
      <template v-slot:head(id) />
      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'id'">
          <pcg-radio
              name="matrix"
              style="transform: translateY(-13px)"
              :show-label="false"
              :options="[{ value: data.item.id }]"
              v-model="selectedMatrixId"
          />
        </div>
      </template>
    </prk-table>
    <MatrixPreviousYearEffectsMode :mode.sync="mode" />
  </pcg-modal>
</template>

<script>
import PrkTable from '../../PrkTable'
import MatrixPreviousYearEffectsMode from './MatrixPreviousYearEffectsMode'
import PrkColGroup from '../../PrkColGroup'
import { inject } from '@vue/composition-api'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'MatrixPreviousYearEffects',
  setup () {
    const matrixFilters = inject('matrixFilters')
    return {
      matrixFilters,
    }
  },
  components: {
    MatrixPreviousYearEffectsMode,
    PrkColGroup,
    PrkTable,
  },
  props: {
    action: Function,
  },
  validations: {
    selectedMatrixId: {
      required,
    },
  },
  jsonapi: {
    matrices: {
      config: ({ url }) => ({
        method: 'get',
        url: url,
      }),
      variables () {
        return {
          url: this.url,
        }
      },
      skip () {
        return !this.url.length
      },
      update (matrices) {
        const currentIndex = matrices.findIndex(matrix => matrix.id === this.matrixFilters.id)
        if (~currentIndex) matrices.splice(currentIndex, 1)
        return matrices
      },
      error () {
        this.$toastr.e(this.$t('general.check_errors'))
      },
    },
  },
  data () {
    return {
      matrices: [],
      selectedMatrixId: null,
      url: '',
      mode: '0',
    }
  },
  methods: {
    checkValidation () {
      if (!this.$v.$invalid) {
        this.action(this.selectedMatrixId, this.mode)
      } else {
        this.$toastr.e(this.$t('views.matrix.have_to_choose_matrix'))
      }
    },
  },
  watch: {
    matrixFilters () {
      const filters = {
        filters: {
          faculty_id: this.matrixFilters.facultyId,
          course_name_id: this.matrixFilters.courseNameId,
          // study_profile_id: this.matrixFilters.studyProfileId,
          // type_id: this.matrixFilters.typeId,
          // kind_id: this.matrixFilters.kindId,
        },
      }
      this.url = `matrices?${this.$qs.stringify(filters)}&serializer=previous_effects&paginate=false`
    },
  },
}
</script>
