<template>
  <div class="courses-new">
    <return
        path-name="courses_list_path"
        :query="$route.query"
    >
      {{ $t('components.prk_courses.back') }}
    </return>

    <course-base-info :course="course"/>
    <standard-picker-modal
        :course-name="course.name"
        @update:course-effects="mergeCourseEffects"
    />

    <div v-if="course" class="mt-4">
      <b-tabs class="mb-5" @input="resetSelected">
        <b-tab :title="$t('components.prk_courses.clo')"
               @click="kek = true"
        >
          <course-effects
              active
              course
              :course-effects="course.courseEffects"
              @add:course-effect="addCourseEffect($event)"
              @update:course-effect="updateCourseEffect($event)"
              @remove:course-effect="removeCourseEffect($event)"
          />
        </b-tab>
        <b-tab :title="$t('general.general_characteristics')"
               @click="kek = false"
        >
          <general-effects
              course
              :general-effects="course.generalEffects"
              @add:general-effect="addGeneralEffects($event)"
              @remove:general-effect="removeGeneralEffect($event)"
              @general-effects-was-selected="resources = $event"
          />
        </b-tab>
        <b-tab :title="$tc('general.discipline', 2)"
               @click="kek = false"
        >
          <disciplines
              :disciplines="course.disciplines"
              @add:discipline="addDiscipline($event)"
              @set:leading-discipline="setLeadingDiscipline($event)"
              @update:discipline="updateDiscipline"
              @remove:discipline="removeDiscipline"
          />
        </b-tab>
      </b-tabs>
    </div>
    <base-beam>
      <courses-beam
          :list="false" :action="save" :kek="kek"
          :selected-course-effects="selectedCourseEffects.length"
          :editMode="$isEditRoute()"
          :resources="resources"
          :customText="$t('general.save')"
          @remove:course-effects="removeCourseEffects(selectedCourseEffects)"
      />
    </base-beam>
  </div>
</template>

<script>
import StandardPickerModal from '../../components/courses/modal/StandardPickerModal'
import Disciplines from '../../components/shared_view/Disciplines'
import CourseEffects from '../../components/shared_view/CourseEffects'
import Return from '../../components/base_components/Return'
import GeneralEffects from '../../components/shared_view/GeneralEffects'
import CoursesBeam from '../../components/courses/CoursesBeam'
import CourseBaseInfo from '../../components/courses/CourseBaseInfo'
import useCourses from '../../composables/useCourses'
import { provide } from '@vue/composition-api'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('courses')

export default {
  name: 'CourseForm',
  setup (props, { root }) {
    const {
      addCourseEffect,
      addDiscipline,
      addGeneralEffects,
      course,
      getCourse,
      removeCourseEffect,
      removeCourseEffects,
      removeDiscipline,
      removeGeneralEffect,
      removedCourseEffectsIds,
      saveCourse,
      setLeadingDiscipline,
      updateCourseEffect,
      updateDiscipline,
    } = useCourses(root)

    provide('course', course)

    return {
      addCourseEffect,
      addDiscipline,
      addGeneralEffects,
      course,
      getCourse,
      removeCourseEffect,
      removeCourseEffects,
      removeDiscipline,
      removeGeneralEffect,
      removedCourseEffectsIds,
      saveCourse,
      setLeadingDiscipline,
      updateCourseEffect,
      updateDiscipline,
    }
  },
  components: {
    StandardPickerModal,
    CourseBaseInfo,
    Return,
    CourseEffects,
    Disciplines,
    GeneralEffects,
    CoursesBeam,
  },
  data () {
    return {
      kek: true,
      resources: [], // for generate printouts
    }
  },
  computed: {
    ...mapState({
      courseData: 'course',
    }),
    saveMethod () {
      return this.$isEditRoute() ? 'put' : 'post'
    },
    url () {
      const url = `courses/${this.$route.params.id}`
      return this.$isEditRoute() ? url : 'courses'
    },
    selectedCourseEffects () {
      return this.course.courseEffects.filter(courseEffect => courseEffect.selected)
    },
  },
  async created () {
    if (this.$route.params.id) {
      const url = `courses/${this.$route.params.id}`
      await this.getCourse(url)
    } else {
      this.prepareCourse()
    }
    this.course.courseEffects = this.course.courseEffects.sort((a, b) => a.symbol.localeCompare(b.symbol, undefined, { numeric: true, sensitivity: 'base' }))
  },
  methods: {
    ...mapActions(['setCourse']),
    mergeCourseEffects (courseEffects) {
      const symbols = this.$_.map(courseEffects, 'symbol')
      this.course.courseEffects = this.course.courseEffects.sort((a, b) => a.symbol.localeCompare(b.symbol, undefined, { numeric: true, sensitivity: 'base' })).filter(courseEffect => {
        return !symbols.includes(courseEffect.symbol)
      })
      // this.course.courseEffects = this.course.courseEffects.filter(courseEffect => {
      //   return courseEffect.effectType !== 'standard'
      // })
      this.course.courseEffects.push(...courseEffects)
      this.course.courseEffects = this.course.courseEffects.sort((a, b) => a.symbol.localeCompare(b.symbol, undefined, { numeric: true, sensitivity: 'base' }))
    },
    prepareCourse () {
      const courseData = localStorage.getItem('courseData')
      if (!courseData) {
        localStorage.setItem('courseData', JSON.stringify(this.courseData))
      } else {
        this.setCourse(JSON.parse(courseData))
      }
      for (const key in this.courseData) {
        this.course[key] = this.courseData[key]
      }
    },
    async save () {
      const params = {
        course_id: this.course.id,
        course_effect_ids: this.removedCourseEffectsIds,
      }
      try {
        if (this.course.id.length) {
          await this.$api.post('course_effects/assign_to_matrices', params)
        }
        const isSavedCourse = await this.saveCourse(
          this.url,
          this.saveMethod,
          {
            $v: null,
            resource: 'course',
          })
        if (isSavedCourse) {
          await this.$router.push({
            name: 'courses_list_path',
            query: { academic_year: this.$route.query.academic_year },
          })
        }
      } catch (e) {
        this.$toastr.Add({
          title: e.errors.effect ? e.errors.effect.symbol : '',
          msg: e.errors.message,
          type: 'error',
        })
        this.removedCourseEffectsIds = []
        await this.getCourse(`courses/${this.$route.params.id}`)
      }
    },
    // TODO powtórzenie w StudyPlanFor
    setHeader () {
      let subtitleKey = ''
      let name = ''
      if (this.$isEditRoute()) {
        subtitleKey = 'views.header.courses.edit'
        name = this.$route.query.course
      } else {
        subtitleKey = 'views.header.courses.new'
      }
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.courses.title'),
        subtitle: `${this.$t(subtitleKey)} ${name}`,
      })
    },
    resetSelected () {
      this.course.courseEffects.forEach(couEff => (couEff.selected = false))
      this.course.generalEffects.forEach(genEff => (genEff.selected = false))
      this.course.disciplines.forEach(dis => (dis.selected = false))
    },
  },
  destroyed () {
    localStorage.removeItem('courseData')
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>
