import { reactive, provide, computed, ref } from '@vue/composition-api'
import useSortEffects from './useSortEffects'

export default function (root) {
  const errors = ref([])
  const removedCourseEffectsIds = ref([])
  const course = reactive({
    id: '',
    name: '',
    academicYear: '',
    facultyId: '',
    faculty: '',
    studyProfileId: '',
    studyProfile: '',
    typeId: '',
    type: '',
    leadingDisciplineId: '',
    specialityId: '',
    speciality: '',
    courseNameId: '',
    courseName: '',
    fellow: '',
    disciplines: [],
    generalEffects: [],
    courseEffects: [],
  })

  const { sortEffects } = useSortEffects(root)

  provide('courseNameId', computed(() => course.courseNameId))
  provide('courseId', computed(() => course.id))
  provide('facultyId', computed(() => course.facultyId))
  provide('typeId', computed(() => course.typeId))
  provide('studyProfileId', computed(() => course.studyProfileId))
  provide('specialityId', computed(() => course.specialityId))
  provide('courseNameId', computed(() => course.courseNameId))
  provide('standard', computed(() => course.fellow === 'Standard'))
  provide('generalEffectIds', computed(() => {
    return course.generalEffects.map(effect => effect.id)
  }))

  const addCourseEffect = (effect) => {
    course.courseEffects.push(effect)
    course.courseEffects = course.courseEffects.sort((a, b) => a.symbol.localeCompare(b.symbol, undefined, { numeric: true, sensitivity: 'base' }))
  }

  const addDiscipline = (discipline) => {
    course.disciplines.push(discipline)
  }

  const addGeneralEffects = (effects) => {
    root.$store.dispatch('courses/setModified', true)
    course.generalEffects.push(effects)
    course.generalEffects = course.generalEffects.flat()
  }

  const getCourse = async (url) => {
    const response = await root.$api.get(url)
    const pickedAttributes = ['name', 'faculty', 'facultyId', 'academicYear', 'courseNameId',
      'courseName', 'speciality', 'specialityId', 'type', 'typeId', 'studyProfile', 'studyProfileId', 'fellow']
    course.id = response.data.id
    for (const attribute of pickedAttributes) {
      course[attribute] = response.data.attributes[attribute]
    }

    const disciplines = response.included.filter(data => {
      return data.type === 'course_discipline'
    })
    const courseEffects = response.included.filter(data => {
      return data.type === 'course_effect' || data.type === 'standard_effect'
    })
    const generalEffects = response.included.filter(data => {
      return data.type === 'general_effect'
    })
    const effects = response.included.filter(data => {
      return data.type === 'effect'
    })

    course.disciplines = _mapDisciplines(disciplines)
    course.generalEffects = _mapGeneralEffects(generalEffects)
    course.courseEffects = _mapCourseEffects(courseEffects)
    course.courseEffects = sortEffects(course.courseEffects)
    _mapEffects(effects)
    const leadingDisciplineId = response.data.attributes.leadingDisciplineId
    course.leadingDisciplineId = _getLeadingDiscipline(leadingDisciplineId)
  }

  const getCourses = async (facultyId = '', parameters) => {
    const params = root.$_.omitBy({ filters: { faculty_id: facultyId, ...parameters } }, root.$_.isEmpty)
    let { data: courses } = await root.$api.get(`courses?${root.$qs.stringify(params)}`)
    courses = courses.map(course => ({ id: course.id, ...course.attributes }))
    return courses
  }

  const mapCoursesForSearch = (courses) => {
    return courses.map(course => {
      const studyProfile = `${course.studyProfile};`.replace('null;', '')
      const speciality = `${course.speciality};`.replace('null;', '')
      return {
        text: `${course.name}; ${course.type}; ${studyProfile} ${speciality} ${course.academicYear}`,
        value: course.id,
      }
    })
  }

  const removeGeneralEffect = (id) => {
    const index = course.generalEffects.findIndex(generalEffect => {
      return generalEffect.id === id
    })
    course.generalEffects.splice(index, 1)
  }

  const removeCourseEffect = (customId) => {
    const index = course.courseEffects.findIndex(courseEffect => {
      return courseEffect.customId === customId
    })
    const courseEffect = course.courseEffects.splice(index, 1)
    removedCourseEffectsIds.value.push(courseEffect[0].id)
    removedCourseEffectsIds.value = root.$_.compact(removedCourseEffectsIds.value)
  }

  const removeCourseEffects = (removedCourseEffects) => {
    course.courseEffects = root.$_.difference(course.courseEffects, removedCourseEffects)
    removedCourseEffectsIds.value.push(...removedCourseEffects.map(ce => ce.id))
  }

  const removeDiscipline = (id) => {
    const index = course.disciplines.findIndex(dis => {
      return dis.disciplineId === id
    })
    course.disciplines.splice(index, 1)
    course.leadingDisciplineId = _getLeadingDiscipline(course.leadingDisciplineId)
  }

  const saveCourse = async (url, method = 'post', args) => {
    if (args.$v) args.$v.$touch()
    if (args.$v === null || !args.$v.$invalid) {
      const newCourse = {
        ...root.$_.omit(course, 'disciplines', 'generalEffectIds', 'courseEffects'),
        disciplines: course.disciplines.map(dis => {
          return {
            disciplineId: dis.disciplineId,
            percent: dis.percent,
          }
        }),
        generalEffectIds: root.$_.map(course.generalEffects, 'id'),
        courseEffects: course.courseEffects.map(courseEffect => {
          return {
            ...root.$_.omit(courseEffect, 'effects'),
            effectIds: root.$_.map(courseEffect.effects, 'id'),
          }
        }),
      }
      newCourse.name = newCourse.name.trim()
      try {
        await root.$api[method](url, { [args.resource]: newCourse })
        return true
      } catch (e) {
        errors.value = root.$getErrors(e.errors)
        if (Object.keys(errors.value)[0] === 'generalEffects') {
          await _reloadGeneralEffects()
        }
        if (args.resource === 'standard' &&
          Object.keys(errors.value)[0] === 'name') return
        root.$showError(root.$getErrors(e.errors))
      }
    }
  }

  const setLeadingDiscipline = (id) => {
    const discipline = course.disciplines.find(dis => {
      return dis.disciplineId === id
    })
    discipline.leadingDiscipline = true
    course.leadingDisciplineId = id
  }

  const updateCourseEffect = (effect) => {
    const effectIndex = _findCourseEffectIndex(effect)
    root.$set(course.courseEffects, effectIndex, effect)
  }

  const updateDiscipline = (payload) => {
    const [discipline, oldDisciplineId] = payload
    const disciplineIndex = _findDisciplineIndex(discipline, oldDisciplineId)
    root.$set(course.disciplines, disciplineIndex, discipline)
  }

  const _getLeadingDiscipline = (leadingDisciplineId) => {
    const discipline = course.disciplines.find(dis => dis.disciplineId === leadingDisciplineId)
    if (discipline) {
      return leadingDisciplineId
    } else {
      return ''
    }
  }

  const _findCourseEffectIndex = (effect) => {
    return course.courseEffects.findIndex(courseEffect => {
      return courseEffect.customId === effect.customId
    })
  }

  const _findDisciplineIndex = (discipline, oldDisciplineId) => {
    return course.disciplines.findIndex(dis => {
      return dis.disciplineId === oldDisciplineId
    })
  }

  const _mapCourseEffects = (effects) => {
    return effects.map(courseEffect => {
      return {
        id: courseEffect.id,
        customId: root.$uuidv4(),
        selected: false,
        toggleDetails: false,
        ...courseEffect.attributes,
        details: '',
      }
    })
  }

  const _mapDisciplines = (disciplines) => {
    const omittedFields = ['fieldName', 'disciplineName', 'active']
    return disciplines.map(discipline => {
      return {
        id: discipline.id,
        disciplineName: discipline.attributes.disciplineName,
        fieldName: discipline.attributes.fieldName,
        ...root.$_.omit(discipline.attributes, omittedFields),
      }
    })
  }

  const _mapGeneralEffects = (effects) => {
    const pickedFields = ['name', 'shortcut', 'color', 'type', 'studyProfile']
    return effects.map(generalEffect => {
      return {
        id: generalEffect.id,
        ...root.$_.pick(generalEffect.attributes, pickedFields),
        selected: false,
        _showDetails: false,
        effects: [],
      }
    })
  }

  const _mapEffects = (effects) => {
    effects.forEach(effect => {
      const generalEffect = course.generalEffects.find(ge => ge.id === effect.attributes.generalEffectId)
      generalEffect.effects.push({
        id: effect.id,
        ...root.$_.pick(effect.attributes, ['symbol', 'description', 'category']),
      })
    })
  }

  const _reloadGeneralEffects = async () => {
    const res = await root.$api.get('general_effects', {
      course_id: course.id,
    })
    course.generalEffects = _mapGeneralEffects(res.data)
    _mapEffects(res.included)
  }

  return {
    addCourseEffect,
    addDiscipline,
    addGeneralEffects,
    course,
    errors,
    saveCourse,
    getCourse,
    getCourses,
    mapCoursesForSearch,
    removeCourseEffect,
    removeCourseEffects,
    removeDiscipline,
    removeGeneralEffect,
    removedCourseEffectsIds,
    setLeadingDiscipline,
    updateCourseEffect,
    updateDiscipline,
  }
}
