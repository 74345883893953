<!--TODO - refaktoryzacja v-if'ow-->
<template>
  <div>
    <syllabus-box class="mt-0">
      <template #content>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <prk-date
                class="mb-2 mb-xl-0"
                @update:academicYear="updateFilters($event, 'academic_year')"
            />
          </b-col>
        </b-row>
        <b-row no-gutters class="mb-3">
          <template v-if="filtersType === 'plan'">
            <b-col cols="12" lg="3" xl="auto">
              <div class="d-flex align-items-end h-100">
                <pcg-search
                    class="mb-3 mb-lg-0"
                    :class="{ 'w-100': $mq !== 'lg' && $mq !== 'xl' }"
                    :placeholder="$t('general.search')"
                    @input="$emit('update:query', $event)"
                />
              </div>
            </b-col>
            <b-col>
              <b-row align-h="end" align-v="center">
                <b-col cols="12" lg="auto">
                  <pcg-btn
                      class="ml-xl-2 mb-3 mb-lg-0"
                      :class="{ 'w-100': $mq !== 'xl' && $mq !== 'lg',
                        'w-90': $mq === 'lg'
                      }"
                      variant="additional"
                      v-b-modal.importPlans>
              <span class="font-weight-bold">
                {{ $tc('general.import_plan') }}
              </span>
                  </pcg-btn>
                </b-col>
                <b-col cols="12" lg="auto" class="text-right">
                  <router-link :to="{ name: 'study_plans_new_path', query: $route.query }">
                    <pcg-btn
                        class="mb-3 mb-lg-0"
                        :class="{ 'w-100': $mq !== 'xl' && $mq !== 'lg',
                          'w-90': $mq === 'lg'
                        }"
                        variant="additional"
                        @click="$store.dispatch('studyPlans/setPlan', null)"
                    >
                <span class="font-weight-bold">
                  {{ $t('general.create_study_plan') }}
                </span>
                    </pcg-btn>
                  </router-link>
                </b-col>
              </b-row>
            </b-col>
          </template>
          <b-col v-else-if="filtersType === 'matrix'" lg="6" cols="12">
            <div>
              <pcg-btn
                  class="mb-3 mb-xl-0 font-weight-bold"
                  :class="{ 'w-100': $mq !== 'xl' && $mq !== 'lg',
                          'w-90': $mq === 'lg'
                        }"
                  v-b-modal.newMatrixModal
                  variant="additional">
                {{ $t('components.prk_matrices.new_matrix') }}
              </pcg-btn>
            </div>
          </b-col>
        </b-row>
        <b-row v-if="filtersType !== 'standard'">
          <b-row v-if="filtersType === 'course'" class="mb-4 w-100">
            <b-col lg="3" cols="12"
                   class="d-lg-flex align-items-end mt-lg-0 mr-lg-2">
              <div>
                <pcg-btn
                    class="mb-3 mb-xl-0 font-weight-bold"
                    :class="{ 'w-100': $mq !== 'xl' && $mq !== 'lg', 'w-90': $mq === 'lg'}"
                    v-b-modal.newCourseModal
                    variant="additional">
                  {{ $t('general.add_course') }}
                </pcg-btn>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" lg="6" xl="auto" class="mt-3">
              <faculty-filter
                :faculties="faculties"
                @filter:faculty="updateFilters($event, 'faculty_id')"
              />
            </b-col>
            <b-col cols="12" lg="6" xl="auto" class="mt-3">
              <course-filter
                :course-names="courseNames"
                @filter:course="updateFilters($event, 'course_name_id')"
              />
            </b-col>
            <b-col cols="12" lg="6" xl="auto" class="mt-3">
              <prk-main-select
                  color="aqua"
                  :label="$tc('general.type')"
                  :options="types"
                  :includeBlank="true"
                  :value="$route.query.type_id"
                  @input="$emit('update:filters', $event, 'type_id')"
              />
            </b-col>
            <b-col v-if="filtersType !== 'course'"
                   cols="12" lg="6" xl="auto" class="mt-3">
              <prk-main-select
                  :label="$tc('general.kind', 1)"
                  color="aqua"
                  :options="kinds"
                  :includeBlank="true"
                  :value="$route.query.kind_id"
                  @input="$emit('update:filters', $event, 'kind_id')"
              />
            </b-col>
            <b-col v-if="filtersType === 'course'"
                   cols="12" lg="6" xl="auto" class="mt-3">
              <prk-main-select
                  :label="$tc('general.profile')"
                  color="aqua"
                  :options="studyProfiles"
                  :includeBlank="true"
                  :value="$route.query.study_profile_id"
                  @input="$emit('update:filters', $event, 'study_profile_id')"
              />
            </b-col>
            <b-col v-if="['course', 'plan'].includes(filtersType)"
                   cols="12" lg="6" xl="auto" class="mt-3">
              <prk-main-select
                  color="aqua"
                  :label="$t('general.speciality')"
                  :options="specialities"
                  :includeBlank="true"
                  :value="$route.query.speciality_id"
                  @input="$emit('update:filters', $event, 'speciality_id')"
              />
            </b-col>
            <b-col v-if="filtersType === 'plan'"
                   cols="12" lg="6" xl="auto" class="mt-3">
              <prk-main-select
                  color="aqua"
                  :label="$t('general.fellow')"
                  :options="type"
                  :includeBlank="true"
                  :value="$route.query.study_plan_type"
                  @input="$emit('update:filters', $event, 'study_plan_type')"
              />
            </b-col>
            <b-col
                v-if="filtersType === 'matrix'"
                cols="12" lg="6" xl="auto"
                class="mt-3">
              <prk-main-select
                  :label="$tc('general.profile')"
                  color="aqua"
                  :includeBlank="true"
                  :options="studyProfiles"
                  :value="$route.query.study_profile_id"
                  @input="$emit('update:filters', $event, 'study_profile_id')"
              />
            </b-col>
            <b-col v-if="filtersType === 'matrix'" cols="12" lg="6" xl="auto"
                   class="mt-3">
              <prk-main-select
                  :label="$tc('general.speciality')"
                  color="aqua"
                  :includeBlank="true"
                  :options="specialities"
                  :value="$route.query.speciality_id"
                  @input="$emit('update:filters', $event, 'speciality_id')"
              />
            </b-col>
          </b-row>
        </b-row>
        <b-row v-if="filtersType === 'standard'">
          <b-col lg="3" cols="12" class="d-flex align-items-end">
            <div>
              <router-link :to="{ name: 'dictionaries_standards_new_path', query: {standard: true}}">
                <pcg-btn :class="{ 'w-100': $mq !== 'xl' && $mq !== 'lg', 'w-90': $mq === 'lg' }"
                         variant="additional">
                  <span class="font-weight-bold">
                    {{ $t('general.add_standard') }}
                  </span>
                </pcg-btn>
              </router-link>
            </div>
          </b-col>
        </b-row>
      </template>
    </syllabus-box>
  </div>
</template>

<script>
import SyllabusBox from './syllabus/PrkSyllabusBox'
import PrkMainSelect from './PrkSelect'
import PrkDate from './PrkDate'
import { getStudyData } from '../mixins/getStudyData'
import FacultyFilter from './filters/FacultyFilter'
import CourseFilter from './filters/CourseFilter'

export default {
  name: 'PrkListFilter',
  components: {
    CourseFilter,
    FacultyFilter,
    PrkDate,
    PrkMainSelect,
    SyllabusBox,
  },
  mixins: [getStudyData],
  props: {
    filtersType: {
      required: true,
      validator (value) {
        return ['plan', 'course', 'matrix', 'standard'].includes(value)
      },
    },
  },
  watch: {
    '$route.query.faculty_id': {
      handler (facultyId) {
        if (!facultyId) {
          this.courseNames = []
          return
        }

        this.getData(['course_names'], null, {
          course_names: { faculty_id: facultyId },
        })

        const query = Object.assign({}, this.$route.query)
        delete query.course_name_id
        delete query.type_id
        delete query.kind_id
        delete query.speciality_id
        this.$router.replace({ query }).catch(() => {})
      },
      immediate: true,
    },
    '$route.query.course_name_id': {
      handler (courseNameId) {
        if (!courseNameId) {
          this.types = []
          return
        }

        const query = Object.assign({}, this.$route.query)
        delete query.type_id
        delete query.kind_id
        delete query.speciality_id
        this.$router.replace({ query }).catch(() => {})

        this.getData(['types'], null, {
          types: { course_name_id: courseNameId },
        })
      },
      immediate: true,
    },
    '$route.query.type_id': {
      handler (typeId) {
        if (!typeId) {
          this.kinds = []
          this.specialities = []
          return
        }

        const query = Object.assign({}, this.$route.query)
        this.$router.replace({ query }).catch(() => {})

        if (this.filtersType === 'course') {
          delete query.speciality_id
          let courseNameId = this.$route.query.course_name_id
          this.getData(['specialities'], null, {
            specialities: { type_id: typeId, course_name_id: courseNameId },
          })
        } else {
          delete query.kind_id

          this.getData(['kinds'], null, {
            kinds: { type_id: typeId },
          })
        }
      },
      immediate: true,
    },
    '$route.query.kind_id': {
      handler (kindId) {
        if (!kindId) {
          this.specialities = []
          return
        }

        const query = Object.assign({}, this.$route.query)
        delete query.speciality_id
        this.$router.replace({ query }).catch(() => {})
        let courseNameId = this.$route.query.course_name_id
        this.getData(['specialities'], null, {
          specialities: { kind_id: kindId, course_name_id: courseNameId },
        })
      },
      immediate: true,
    },
  },
  created () {
    if (['course', 'matrix'].includes(this.filtersType)) {
      this.getData(['faculties', 'study_profiles'])
    } else {
      this.getData(['faculties'])
    }
    const unwatch = this.$watch(vm => vm.specialities, function () {
      this.specialities = this.$_.unionBy(this.specialities, 'text')
      unwatch()
    })
  },
  methods: {
    updateFilters (value, field) {
      this[this.$_.camelCase(field)] = value
      this.$emit('update:filters', value, field)
    },
  },
  data () {
    return {
      facultyId: '',
      courseNameId: '',
      defaultOption: { text: this.$t('general.choose') },
      type: [
        {
          text: this.$t('general.winter'),
          value: 'winter',
        },
        {
          text: this.$t('general.summer'),
          value: 'summer',
        },
      ],
    }
  },
}
</script>

<style scoped lang="scss">
.pcg-button {
  margin: 0;
}

.pcg-search {
  margin-bottom: 0;
}

::v-deep {
  .syllabus-box.box-container .box {
    box-shadow: none;
    border-radius: 15px 15px 0 0;

    .contents {
      margin-top: 0 !important;
    }
  }
}
</style>
