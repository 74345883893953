import PrkSelect from '../components/PrkSelect'
import useModal from '../composables/useModal'
import { getStudyData } from './getStudyData'

export const newMatrixModal = {
  setup () {
    const { defaultOption, input } = useModal()
    return { defaultOption, input }
  },
  components: {
    PrkSelect,
  },
  mixins: [getStudyData],
}
