export const dictionary = {
  methods: {
    action (resource, url, params) {
      if (this.editMode) {
        return () => {
          this.updateResource(resource, url)
        }
      } else {
        return () => {
          this.addResource(resource, url, params)
        }
      }
    },

    hideModal (resourceName, omit = []) {
      this.editMode = false
      this.errors = {}
      delete this[resourceName].id
      this.clearModel(this[resourceName], omit)
    },
  },
}
