import api from '../api'
import ObjectError from '../errors/objectError'

const ApiClient = {
  async query (resource, params) {
    try {
      const { data: response } = await api.get(`${resource}`, {
        params,
      })
      return response
    } catch (err) {
      throw new ObjectError(err.response.data.message)
    }
  },

  async get (resource, params = {}) {
    try {
      const { data: response } = await api.get(`${resource}`, { params: params })
      return response
    } catch (err) {
      throw new ObjectError('Creating error', err.response.data)
    }
  },

  async post (resource, params, config = {}) {
    try {
      const { data: response } = await api.post(`${resource}`, params, config)
      return response
    } catch (err) {
      throw new ObjectError('Creating error', err.response.data)
    }
  },

  async update (resource, params) {
    try {
      const { data: response } = await api.put(`${resource}`, params)
      return response
    } catch (err) {
      throw new ObjectError('Updating error', err.response.data)
    }
  },

  async put (resource, params) {
    try {
      const { data: response } = await api.put(`${resource}`, params)
      return response
    } catch (err) {
      throw new ObjectError('Updating error', err.response.data)
    }
  },

  async destroy (resource, params) {
    try {
      const { data: response } = await api.delete(resource, params)
      return response
    } catch (err) {
      throw new ObjectError('Destroy error', err.response.data)
    }
  },
}

export default {
  install: function (Vue) {
    Object.defineProperty(Vue.prototype, '$api', { value: ApiClient })
  },
}
