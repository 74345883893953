<template>
  <base-beam>
    <prk-beam>
      <template>
        <div class="mr-2 d-flex align-items-center justify-content-end h-100">
          <pcg-btn class="mr-3 prk-button" :icon="iconSave" round text="save" @click="publish(false)"/>
          <pcg-btn class="mr-3 prk-button" :icon="iconPlus" round text="add_section" @click="addNewSection"/>
        </div>

        <pcg-btn class="font-weight-bold" size="small" @click="showPreview">
          {{ $t('components.prk_beam.preview_template') }}
        </pcg-btn>
      </template>

      <template #mobile_beam>
        <div class="d-flex">
          <pcg-btn class="mr-3 prk-button" :icon="iconSave" round text="save" @click="publish(false)"/>
          <pcg-btn class="mr-3 prk-button" :icon="iconPlus" round text="add_section" @click="addNewSection"/>
        </div>
      </template>

      <template #options>
        <pcg-btn class="font-weight-bold mr-3" size="small" variant="additional" @click="showPreview">
          {{ $t('components.prk_beam.preview_template') }}
        </pcg-btn>
      </template>
    </prk-beam>
  </base-beam>
</template>

<script>
import PrkBeam from './PrkBeam'
import useIcon from '../../composables/useIcon'
import { createNamespacedHelpers, mapGetters } from 'vuex'

const { mapActions } = createNamespacedHelpers('syllabusTemplate')
export default {
  name: 'PrkBeamSyllabusesNew',
  setup (_, { root }) {
    const { iconPlus, iconSave } = useIcon(root)
    return { iconPlus, iconSave }
  },
  components: { PrkBeam },
  props: {
    sections: { required: true, type: Array },
    syllabus: { required: true, type: Object },
    addNewSection: { required: true, type: Function },
  },
  methods: {
    ...mapActions(['setSections', 'setSyllabus']),
    publish (value) {
      this.$emit('publish', value)
    },
    showPreview () {
      this.setSyllabus(this.syllabus)
      this.setSections(this.sections)
      this.$router.push('/syllabuses/preview')
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    contrast () {
      const { iconPlus, iconSave } = useIcon(this.$root)
      this.iconPlus = iconPlus
      this.iconSave = iconSave
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
