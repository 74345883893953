<template>
  <div>
    <div class="d-sm-flex justify-content-end">
      <pcg-btn variant="additional" size="small" v-b-modal.add-modal
               class="w-100 w-sm-auto m-0 font-weight-bold">
        + {{ $t('general.add') }}
      </pcg-btn>
    </div>

    <prk-table
        v-if="items.length > 0"
        class="prk-shadow mt-3"
        :excluded-fields="excludedFields"
        :items="items"
        :show-headline="false"
        :fields-props="{ thClass: 'pt-4_5'}"
    >
      <template v-slot:table-colgroup="{ fields }">
        <prk-col-group
            :columns="['id']"
            :fields="fields" width="5%"
        />
      </template>

      <template v-slot:head(id)>
        <pcg-checkbox :value="selectedAll" @input="selectGeneralEffects"/>
      </template>

      <template #cell(id)="{ item: generalEffect }">
        <pcg-checkbox :value="generalEffect.selected"
                      @input="selectGeneralEffect(generalEffect, $event)"
        />

      </template>

      <template v-slot:head(active)/>
      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'name'" class="d-flex align-items-center">
          <div class="prk-color-status mr-3 position-static"
               v-b-popover.hover.top="$t(colorPopoverLang)"
               :style="{backgroundColor: data.item.color}">
          </div>
          {{ data.item.name }}
        </div>
        <div v-else-if="header.key === 'actions'">
          <div class="d-flex justify-content-end align-items-center">
            <div
                class="d-flex justify-content-start align-items-end mr-5 w-100">
              <active-switch
                  :active.sync="data.item.active"
                  :update-resource="updateResource"
                  url="general_effects"
                  :item="data.item"
              />
            </div>
            <pcg-btn v-for="(button, index) in buttons" :key="index"
                     size="small" class="m-0 mr-3 font-weight-bold"
                     variant="additional"
                     @click="button.action(data.item.id, data.item.name, data.item.studyProfile, data.item.type)"
            >
              <span class="font-weight-bold prk-fz-12">
                {{ button.text }}
              </span>
            </pcg-btn>
            <pcg-btn round text="edit" class="mr-3 prk-button" :icon="iconEdit"
                     @click="editResource(data.item.id,
                     'generalEffect')"/>
            <pcg-btn round :icon="iconRemove" text="delete"
                     class="mr-3 prk-button" variant="danger"
                     @click="destroyResource('general_effects',
                     data.item.id, data.index)"/>
            <fields-modal :id="data.item.id"
                          :title="`${data.item.name} - ${$tc('general.field', 2)}`"
            />
          </div>
        </div>
      </template>
    </prk-table>

    <prk-color-modal
        :action="action(generalEffect, 'general_effects')"
        :btn-title="editMode ? $t('general.update') : $t('general.add')"
        :edit-mode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="editMode ? $t('general.edit') : $t('general.add')"
        v-bind.sync="generalEffect"
        @hidden="hideModal('generalEffect')"
        @clear-error="errors[$event] = ''"
    />

    <dictionary-general-effects-beam
        v-if="selectedGeneralEffects.length > 0"
      :general-effects="selectedGeneralEffects"
    />
  </div>
</template>

<script>
import PrkColorModal from '../../components/syllabus/modals/PrkColorModal'
import PrkTable from '../../components/PrkTable'
import FieldsModal from '../../components/dictionaries/modal/FieldsModal'
import DictionaryGeneralEffectsBeam from '../../components/beams/DictionaryGeneralEffectsBeam'
import ActiveSwitch from '../../components/dictionaries/ActiveSwitch'
import PrkColGroup from '../../components/PrkColGroup'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import useIcon from '../../composables/useIcon'
import { dictionary } from '../../mixins/dictionary'
import api from '../../api'
import { mapGetters } from 'vuex'

export default {
  name: 'DictionaryGeneralEffects',
  setup (props, { root }) {
    const {
      items, setItems, removeRow, findItemById,
    } = useList(root)
    const { iconEdit, iconRemove } = useIcon(root)
    const {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      updateResource,
    } = useDictionary(root)
    return {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      iconEdit,
      iconRemove,
      items,
      removeRow,
      setItems,
      updateResource,
    }
  },
  components: {
    DictionaryGeneralEffectsBeam,
    ActiveSwitch,
    FieldsModal,
    PrkTable,
    PrkColorModal,
    PrkColGroup,
  },
  mixins: [dictionary],
  data () {
    return {
      excludedFields: [
        'active',
        'color',
        'shortcut',
        'studyProfileId',
        'typeId',
        'effects',
        'selected',
      ],
      generalEffect: {
        active: false,
        color: '',
        shortcut: '',
        name: '',
        type: '',
        typeId: '',
        studyProfile: '',
        studyProfileId: '',
        [Symbol.for('_innerName')]: 'generalEffect',
      },
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    selectedGeneralEffects () {
      return this.items.filter(generalEffect => generalEffect.selected)
    },
    selectedAll () {
      return this.selectedGeneralEffects.length === this.items.length
    },
    colorPopoverLang () {
      return 'views.header.dictionary.basic_characteristics'
    },
    buttons () {
      return [
        {
          text: this.$tc('general.field', 2),
          action: (id) => {
            this.$bvModal.show(id)
          },
        },
        {
          text: this.$t('general.effects'),
          action: (id, name, profile, type) => {
            this.$router.push({
              name: 'dictionaries_characteristic_effects_path',
              params: { generalEffectId: id },
              query: {
                generalEffect: name,
                type: type,
                profile: profile,
              },
            })
          },
        },
      ]
    },
  },
  created () {
    this.getGeneralEffects()
  },
  methods: {
    selectGeneralEffects (selected) {
      selected ? this.$selectAll(this.items) : this.$unselectAll(this.items)
    },
    selectGeneralEffect (generalEffect, selected) {
      selected ? this.$selectAll([generalEffect]) : this.$unselectAll([generalEffect])
    },
    async getGeneralEffects () {
      let { data: generalEffects } = await this.$api.get('general_effects')
      generalEffects = generalEffects.map(generalEffect => {
        return {
          id: generalEffect.id,
          name: generalEffect.attributes.name,
          type: generalEffect.attributes.type,
          typeId: generalEffect.attributes.typeId,
          ...generalEffect.attributes,
          selected: false,
          [Symbol.for('_innerName')]: 'generalEffect',
        }
      })
      this.setItems(generalEffects)
    },

    print (url, params) {
      for (const generalEffect of this.items) {
        api.post(url, { id: generalEffect.id },
          { responseType: 'blob' }).then((response) => {
          this.$downloadFile(
            response,
            params.type,
            generalEffect.name,
          )
        })
      }
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$t('views.header.dictionary.general_effects'),
      })
    },
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}

</script>

<style scoped lang="scss">
::v-deep {
  .pcg-table-container {
    border-radius: 15px;

    .pcg-table-content {
      border-radius: 15px;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
