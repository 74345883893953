export const responsiblePersons = {
  namespaced: true,
  state: {
    responsiblePersons: [],
  },
  mutations: {
    SET_RESPONSIBLE_PERSONS (state, responsiblePersons) {
      state.responsiblePersons = responsiblePersons
    },
  },
  actions: {
    setResponsiblePersons ({ commit }, responsiblePersons) {
      commit('SET_RESPONSIBLE_PERSONS', responsiblePersons)
    },
  },
}
