<template>
  <pcg-modal
      :modal-id="modalId"
      size="md"
      :title="titleModal"
      icon="fas fa-plus"
      :btn-title="btnTitle"
      :action="action"
      @change="changeNumberOfRows"
      @hidden="$emit('hidden')">

    <b-row>
      <b-col cols="12" sm="6" class="mb-3 mb-sm-0">
        <pcg-text-input
            :value="$attrs.symbol"
            :label="$t('general.symbol')"
            :error-text="errors.symbol"
            :show-error="!!errors.symbol"
            @input="input('symbol', $event)"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <pcg-text-input
            ref="textarea"
            class="mt-4"
            type="textarea"
            :value="$attrs.description"
            :label="$t('general.description')"
            :error-text="errors.description"
            :show-error="!!errors.description"
            @input="input('description', $event)"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="12" sm="6" class="mb-3 mb-sm-0">
        <pcg-text-input
            class="mt-4"
            :value="$attrs.range"
            :label="$t('general.range')"
            :error-text="errors.range"
            :show-error="!!errors.range"
            @input="input('range', $event)"
        />
      </b-col>
    </b-row>
  </pcg-modal>
</template>

<script>

import { dictionaryModals } from '../../../mixins/dictionary_modals'
import useModal from '../../../composables/useModal'

export default {
  name: 'EffectFormModal',
  mixins: [dictionaryModals],
  setup () {
    const { input } = useModal()
    return {
      input,
    }
  },
  methods: {
    changeNumberOfRows () {
      this.$refs.textarea.$el
        .querySelector('textarea')
        .setAttribute('rows', '10')
    },
  },
}
</script>
