<template>
  <b-row class="choice mt-2 ml-4">
    <b-col cols="1">
      <pcg-radio name="mode"
                 :label="$t('general.skip')"
                 :value="$attrs.mode"
                 :options="[{ value: '0' }]"
                 @input="$emit('update:mode', $event)"
      />
    </b-col>
    <b-col cols="1">
      <pcg-radio name="mode"
                 :label="$t('general.overwrite')"
                 :options="[{ value: '1' }]"
                 :value="$attrs.mode"
                 @input="$emit('update:mode', $event)"
      />
    </b-col>
    <b-col cols="1">
      <pcg-radio name="mode"
                 :label="$t('general.add')"
                 :options="[{ value: '2' }]"
                 :value="$attrs.mode"
                 @input="$emit('update:mode', $event)"
      />
    </b-col>
  </b-row>
</template>
<script>
export default {
  name: 'MatrixPreviousYearEffectsMode',
}
</script>
<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';
.choice {
  ::v-deep {
    .pcg-input-label {
      color: $pcg-dark-gray;
      font-weight: 300;
      font-size: .86rem;
    }

    .pcg-radio {
      top: -20px;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .choice {
    ::v-deep {
      .pcg-input-label {
        color: $pcg-dark-gray;
      }
    }
  }
}
</style>
