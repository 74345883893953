<template>
  <b-col cols="12" class="mt-4">
    <prk-select
        color="aqua"
        :default-option="defaultOption('courseNames')"
        :label="$tc('general.course', 1)"
        :error-text="courseNameError"
        :show-error="!!courseNameError"
        :options="courseNames"
        :value="$attrs.courseName"
        @input="input('courseName', $event)"
    />
  </b-col>
</template>

<script>
import { newMatrixModal } from '../../../../mixins/new_matrix_modal'

export default {
  name: 'NewMatrixCourseName',
  mixins: [newMatrixModal],
  props: {
    facultyId: { type: String },
    courseNameError: { required: true, type: String },
    copied: { type: Boolean, default: false },
  },
  watch: {
    facultyId () {
      this.getCourseNames()
    },
    copied: {
      handler () {
        if (!this.copied || !this.facultyId) return
        this.getCourseNames()
      },
      immediate: true,
    },
  },
  methods: {
    getCourseNames () {
      const params = { course_names: { faculty_id: this.facultyId } }
      this.getData(['course_names'], null, params)
    },
  },
}
</script>
