<template>
  <b-row>
    <b-col cols="2" v-for="(group, index) in [responsible, coordinators, supervisors]" :key="index">
      <div class="position prk-fz-12 font-weight-bold">
        {{ $t(`general.educator_${index}`) }}
      </div>
      <b-row v-for="(educator, _index) in group" :key="_index">
        <div class="prk-fz-14 name font-weight-bold">
          <prk-avatar :avatar="educator.avatar" :abbr="educator.abbr" :text="educator.name"/>
        </div>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { inject } from '@vue/composition-api'
import PrkAvatar from '../PrkAvatar'

export default {
  name: 'PrkSyllabusHeaderPersons',
  components: { PrkAvatar },
  setup () {
    const educators = inject('educators', [])
    return {
      educators,
    }
  },
  computed: {
    responsible () {
      return this.educators.filter(educator => educator.roles.includes('responsible_person'))
    },
    supervisors () {
      return this.educators.filter(educator => educator.roles.includes('supervisor'))
    },
    coordinators () {
      return this.educators.filter(educator => educator.roles.includes('coordinator'))
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.position {
  color: $pcg-dark-gray;
}

.name {
  color: $pcg-the-darkest-gray;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .position {
    color: $pcg-dark-gray;
  }

  .name {
    color: $pcg-the-darkest-gray;
  }
}
</style>
