var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('fragment',[_c('prk-table',{attrs:{"custom-fields":_vm.CUSTOM_FIELDS,"excluded-fields":_vm.EXCLUDED_FIELDS,"items":_vm.items,"show-headline":false},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return [_c('prk-colgroup',{attrs:{"columns":['id', 'details'],"fields":fields,"width":"5%"}})]}},{key:"head(id)",fn:function(){return [_c('pcg-checkbox',{attrs:{"value":_vm.checkedAll},on:{"input":function($event){return _vm.select('', { deep: true })}}})]},proxy:true},{key:"head(details)",fn:function(){return undefined},proxy:true},{key:"cell(id)",fn:function(data){return [_c('syllabus-table-checkbox',{attrs:{"data":data,"items":_vm.items,"select":_vm.select},model:{value:(data.item.selected),callback:function ($$v) {_vm.$set(data.item, "selected", $$v)},expression:"data.item.selected"}})]}},{key:"cell(actions)",fn:function(ref){
var matrix = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('syllabus-list-remove-syllabuses',{attrs:{"matrix":matrix,"index":index},on:{"remove:syllabuses":function($event){return _vm.removeAll(index, matrix, $event)}}})],1)]}},{key:"cell(details)",fn:function(ref){
var matrix = ref.item;
var detailsShowing = ref.detailsShowing;
return [_c('base-arrow',{attrs:{"show":detailsShowing},on:{"click":function($event){matrix._showDetails = !matrix._showDetails}}})]}},{key:"row-details",fn:function(row){return [_c('syllabuses-list-details',{key:row.item.id,attrs:{"sub-items":row.item.syllabuses,"matrix-id":row.item.id},on:{"unChecked":function($event){_vm.checkedAll = false}}})]}}])}),_vm._v(" "),(_vm.items.length > 0)?_c('portal',{attrs:{"to":"syllabus-pagination"}},[(_vm.pagination.totalPages)?_c('pcg-pagination',{staticClass:"mt-4 prk-pagination",attrs:{"page-count":_vm.pagination.totalPages,"total-records":_vm.pagination.totalRecords},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}}):_vm._e()],1):_vm._e(),_vm._v(" "),_c('objective-effects-modal',{attrs:{"matrices":_vm.items}}),_vm._v(" "),_c('syllabus-list-comments',{attrs:{"syllabusId":_vm.syllabusId}}),_vm._v(" "),(_vm.selectedSyllabuses.length > 0)?_c('prk-beam-syllabuses-list',{staticStyle:{"z-index":"99"},attrs:{"selected-syllabuses":_vm.selectedSyllabuses},on:{"remove:syllabuses":function($event){_vm.removeHandler = $event}}}):_vm._e(),_vm._v(" "),_c('confirm-deletion-syllabuses',{attrs:{"filled-syllabuses":_vm.filledSyllabuses},on:{"save":function($event){return _vm.removeSyllabuses(true)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }