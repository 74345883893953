<template>
  <b-col sm="12" md="6" class="mt-3">
    <prk-select
        color="aqua"
        :default-option="defaultOption('academicYears')"
        :error-text="academicYearError"
        :label="$tc('general.academic_year', 1)"
        :options="academicYears"
        :show-error="!!academicYearError"
        :value="$attrs.academicYear"
        @input="input('academicYear', $event)"
    />
  </b-col>
</template>

<script>
import useHelpers from '../../../../composables/useHelpers'
import { newMatrixModal } from '../../../../mixins/new_matrix_modal'

export default {
  name: 'NewMatrixAcademicYear',
  setup (_, { root }) {
    const { generateAcademicYears } = useHelpers(root)
    return { generateAcademicYears }
  },
  mixins: [newMatrixModal],
  props: {
    academicYearError: { required: true, type: String },
  },
  data () {
    return {
      academicYears: [],
    }
  },
  created () {
    this.academicYears = this.generateAcademicYears()
  },

}
</script>
