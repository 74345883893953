<template>
  <pcg-modal
    modal-id="file-type"
    size="sm"
    :btn-title="$t('general.download')"
    :title=" $t('components.prk_modals.file_type.title')"
    icon="pcg-icon-document"
    :action="downloadFile"
  >
    <pcg-radio
        v-model="fileType"
        name="File type"
        :show-label="false"
        :options="options"
    />
  </pcg-modal>
</template>

<script>
export default {
  name: 'FileType',
  props: {
    download: { required: true, type: Function },
  },
  data () {
    return {
      fileType: null,
      options: [
        { text: 'Pdf', value: 'pdf' },
        { text: 'Doc', value: 'doc' },
      ],
    }
  },
  methods: {
    downloadFile () {
      if (this.fileType) {
        this.$bvModal.hide('file-type')
        this.download(this.fileType)
        this.fileType = null
      } else {
        this.$toastr.w(this.$t('components.prk_modals.file_type.warning'))
      }
    },
  },
}
</script>
