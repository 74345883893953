import { reactive, computed, ref, provide, watch } from '@vue/composition-api'
import useModalValidation from './useModalValidation'
import useHelpers from './useHelpers'

const { setErrors } = useModalValidation()
const { uuidv4 } = useHelpers()

export default function (root) {
  const matrixSubjectNameIds = ref([])
  const errors = ref([])
  const loading = ref(false)
  const edited = ref(false)
  const initMatrix = ref(false)
  const matrix = reactive({
    id: '',
    syllabusId: '',
    name: '',
    faculty: '',
    facultyId: '',
    course: '',
    courseNameId: '',
    kind: '',
    kindId: '',
    academicYear: '',
    years: '',
    studyProfile: '',
    studyProfileId: '',
    type: '',
    typeId: '',
    specialityId: '',
    skipValidation: '',
    matrixSubjectNames: [],
    studyPlans: [],
    semesterIds: [],
  })

  const studyPlanIds = computed(() => {
    return matrix.studyPlans.map(plan => plan.id)
  })
  const subjectNameIds = computed(() => {
    return matrix.matrixSubjectNames.map(matrixSubjectName => matrixSubjectName.subjectNameId)
  })
  const matrixFilters = computed(() => {
    return root.$_.pick(matrix,
      ['id', 'facultyId', 'courseNameId', 'studyProfileId', 'specialityId', 'kindId', 'typeId'])
  })

  provide('matrixFilters', matrixFilters)
  provide('loading', loading)
  provide('edited', edited)

  const watchChanges = computed(() => {
    return matrix.matrixSubjectNames.map(x => x.courseEffectsSubItems)
  })

  watch(() => [watchChanges.value, matrix.matrixSubjectNames.length], () => {
    if (initMatrix.value) {
      edited.value = true
    }
    initMatrix.value = true
  }, { deep: true })

  const getMatrix = async (url) => {
    loading.value = true
    const response = await root.$api.get(url)
    _setMatrixProperty(response)
    loading.value = false
    edited.value = false
    initMatrix.value = false
  }

  const addStudyPlan = (studyPlan) => {
    matrix.studyPlans.push(studyPlan)
  }

  const removeStudyPlan = (studyPlanId) => {
    const index = matrix.studyPlans.findIndex(studyPlan => studyPlan.id === studyPlanId)
    const semesterIds = matrix.studyPlans[index].semesters.map(semester => semester.id)

    semesterIds.forEach(semesterId => {
      root.$_.remove(matrix.semesterIds, () => {
        return matrix.semesterIds.includes(semesterId)
      })
      _findMatrixSubjectNameToDestroy(semesterId)
    })
    matrix.studyPlans.splice(index, 1)
  }

  const addSemester = (studyPlanId, semesterId) => {
    if (!matrix.semesterIds.includes(semesterId)) {
      matrix.semesterIds.push(semesterId)
    } else {
      return
    }
    const studyPlan = matrix.studyPlans.find(studyPlan => studyPlan.id === studyPlanId)
    const semester = studyPlan.semesters.find(semester => semester.id === semesterId)
    const semesterData = root.$_.pick(semester, ['id', 'specialization', 'speciality'])
    semester.subjectNames.forEach(subjectName => {
      _createMatrixSubjectName(subjectName.subjectNameId, subjectName.name, semesterData).then()
    })
  }

  const removeSemester = (studyPlanId, id) => {
    const index = matrix.semesterIds.findIndex(semesterId => semesterId === id)
    matrix.semesterIds.splice(index, 1)
    _findMatrixSubjectNameToDestroy(id)
  }

  const addAllSemesters = (studyPlanId, semesterIds) => {
    semesterIds.forEach(semesterId => addSemester(studyPlanId, semesterId))
  }

  const removeAllSemesters = (studyPlanId, semesterIds) => {
    semesterIds.forEach(semesterId => removeSemester(studyPlanId, semesterId))
  }

  const removeMatrixSubjectNames = () => {
    matrixSubjectNameIds.value.forEach(matrixSubjectNameId => {
      const index = matrix.matrixSubjectNames
        .findIndex(el => el.customId === matrixSubjectNameId)
      matrix.matrixSubjectNames.splice(index, 1)
    })
    matrixSubjectNameIds.value = []
  }

  const addEffects = (index, effect) => {
    matrix.matrixSubjectNames[index].courseEffectsSubItems.push({
      id: effect.id,
      symbol: effect.symbol,
      description: effect.description,
      toggleDetails: false,
    })
  }

  const removeCourseEffect = (effectId, customId) => {
    const matrixSubjectName = matrix.matrixSubjectNames
      .find(matrixSubjectName => matrixSubjectName.customId === customId)
    const index = matrixSubjectName.courseEffectsSubItems.findIndex(effect => effect.id === effectId)
    matrixSubjectName.courseEffectsSubItems.splice(index, 1)
  }

  const removeAllEffects = (index) => {
    matrix.matrixSubjectNames[index].courseEffectsSubItems = []
  }

  const saveMatrix = async (url, args) => {
    if (args.$v) args.$v.$touch()
    if (!args.$v.$invalid) {
      loading.value = true
      const matrixData = {
        name: matrix.name,
        skipValidation: matrix.skipValidation,
        syllabusId: matrix.syllabusId,
        studyPlanIds: studyPlanIds.value,
        semesterIds: matrix.semesterIds,
        matrixSubjectNames: matrix.matrixSubjectNames.map(data => {
          if (data.id !== '') {
            return {
              id: data.id,
              subjectNameId: data.subjectNameId,
              semesterIds: data.semesterIds,
              effectIds: data.courseEffectsSubItems.map(effect => effect.id),
            }
          } else {
            return {
              subjectNameId: data.subjectNameId,
              semesterIds: data.semesterIds,
              effectIds: data.courseEffectsSubItems.map(effect => effect.id),
            }
          }
        }),
      }
      try {
        await root.$api.put(url, { matrix: matrixData })
        root.$toastr.s(root.$t('general.matrix_saved'))
      } catch (e) {
        errors.value = setErrors(e.errors)
        _showErrors(errors.value)
      }
    }
  }

  const _setMatrixProperty = (response) => {
    const matrixData = response.data
    matrix.id = matrixData.id
    for (const attribute in matrixData.attributes) {
      matrix[attribute] = matrixData.attributes[attribute]
    }
    const studyPlans = response.included.filter(data => data.type === 'study_plan')
    const matrixSubjectNames = response.included.filter(data => data.type === 'matrix_subject_name')
    const semesters = response.included.filter(data => data.type === 'semester')
    matrix.studyPlans = _mapStudyPlans(studyPlans)
    _mapSemesters(semesters)
    matrix.matrixSubjectNames = _mapMatrixSubjectNames(matrixSubjectNames)
  }

  const _findMatrixSubjectNameToDestroy = (semesterId) => {
    const matrixSNWhichContainsSemesterId = matrix.matrixSubjectNames
      .filter(matrixSubjectName => matrixSubjectName.semesterIds.includes(semesterId))
    matrixSNWhichContainsSemesterId.forEach(msn => {
      const index = msn.semesterIds.indexOf(semesterId)
      msn.semesterIds.splice(index, 1)
      if (msn.semesterIds.length === 0) {
        matrixSubjectNameIds.value.push(msn.customId)
      }
    })
    removeMatrixSubjectNames()
  }

  const _mapStudyPlans = (studyPlans) => {
    const pickedAttributes = ['name', 'faculty', 'courseName', 'kind',
      'type', 'academicYear', 'subjectNameIds']
    return studyPlans.map(studyPlan => {
      return {
        id: studyPlan.id,
        ...root.$_.pick(studyPlan.attributes, pickedAttributes),
        _showDetails: false,
        semesters: [],
      }
    })
  }

  const _mapSemesters = (semesters) => {
    semesters.map(semester => {
      const studyPlanIndex = matrix.studyPlans
        .findIndex(studyPlan => studyPlan.id === semester.relationships.studyPlan.data.id)
      const pickedAttributes = ['name', 'semesterNumber', 'specialization', 'speciality', 'subjectNames']
      if (~studyPlanIndex) {
        matrix.studyPlans[studyPlanIndex].semesters.push({
          id: semester.id,
          ...root.$_.pick(semester.attributes, pickedAttributes),
          selected: matrix.semesterIds.includes(semester.id),
        })
      }
    })
  }

  const _mapMatrixSubjectNames = (matrixSubjectNames) => {
    const pickedAttributes = [
      'subjectNameId',
      'semesterNumbers',
      'semesterIds',
      'syllabusBySemester',
      'hasSyllabuses',
      'hasAcceptedSyllabuses',
    ]
    const mappedMatrixSubjectNames = matrixSubjectNames.map(matrixSubjectName => {
      return {
        id: matrixSubjectName.id,
        customId: uuidv4(),
        subject: matrixSubjectName.attributes.name,
        _showDetails: false,
        selected: false,
        courseEffects: '',
        speciality: matrixSubjectName.attributes.specialityName,
        specialization: matrixSubjectName.attributes.specializationName,
        courseEffectsSubItems: matrixSubjectName.attributes.effects.map(data => {
          return {
            id: data.id,
            symbol: data.symbol,
            description: data.description,
            toggleDetails: false,
          }
        }),
        ...root.$_.pick(matrixSubjectName.attributes, pickedAttributes),
      }
    })
    mappedMatrixSubjectNames.filter(subjectName => subjectName.hasSyllabuses).forEach(subjectName => {
      subjectName._rowVariant = 'success'
    })
    return mappedMatrixSubjectNames
  }

  const _createMatrixSubjectName = async (subjectNameId, subjectNameName, semester) => {
    const index = matrix.matrixSubjectNames.findIndex(matrixSubjectName =>
      matrixSubjectName.subjectNameId === subjectNameId &&
            matrixSubjectName.specialization === semester.specialization &&
            matrixSubjectName.speciality === semester.speciality,
    )
    if (!~index) {
      matrix.matrixSubjectNames.push({
        customId: uuidv4(),
        subjectNameId: subjectNameId,
        subject: subjectNameName,
        _showDetails: false,
        selected: false,
        hasSyllabus: false,
        courseEffects: '',
        semesterIds: [semester.id],
        speciality: semester.speciality,
        specialization: semester.specialization,
        semesterNumbers: [semester.semesterNumber],
        courseEffectsSubItems: [],
      })
      matrix.matrixSubjectNames.sort((a, b) => a.subject.localeCompare(b.subject))
    } else {
      const matrixSubjectName = matrix.matrixSubjectNames[index]
      if (!matrixSubjectName.semesterIds.includes(semester.id)) {
        matrixSubjectName.semesterIds.push(semester.id)
      }
    }
  }

  const _showErrors = (errors) => {
    for (const key in errors) {
      let title = ''
      const suffix = root.$_.snakeCase(root.$pluralize(key, 1))
      if (root.$te(`general.${suffix}`)) {
        title = root.$tc(`general.${suffix}`)
      }
      root.$toastr.e(errors[key], title)
    }
  }

  const mapNumber = (number) => {
    switch (number) {
      case 1:
        return 'I'
      case 2:
        return 'II'
      case 3:
        return 'III'
      case 4:
        return 'IV'
      case 5:
        return 'V'
      case 6:
        return 'VI'
      case 7:
        return 'VII'
      case 8:
        return 'VIII'
      case 9:
        return 'IX'
      case 10:
        return 'X'
      case 11:
        return 'XI'
      case 12:
        return 'XII'
    }
  }

  return {
    addAllSemesters,
    addEffects,
    addSemester,
    addStudyPlan,
    errors,
    getMatrix,
    mapNumber,
    matrix,
    matrixSubjectNameIds,
    removeAllEffects,
    removeAllSemesters,
    removeCourseEffect,
    removeMatrixSubjectNames,
    removeSemester,
    removeStudyPlan,
    saveMatrix,
    studyPlanIds,
    subjectNameIds,
  }
}
