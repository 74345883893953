<template>
 <pcg-modal
     modal-id="confirm-assignment-educators"
     size="sm"
     :btn-title="$t('general.yes')"
     btn-class="w-50"
     :action="save"
 >
   <div class="prk-fz-16 font-weight-bold pcg-the-darkest-gray-color mb-3">
     {{ $t('components.confirm_assignment_educators.text') }}
   </div>
   <template #button-before>
     <pcg-btn
         class="w-50" variant="additional"
         @click="hide"
     >
       {{$t ('general.no') }}
     </pcg-btn>
   </template>
 </pcg-modal>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'ConfirmAssignmentEducators',
  methods: {
    ...mapActions('coordinators', ['setShowWarning']),
    save () {
      this.setShowWarning(false)
      this.$emit('save')
      this.$bvModal.hide('confirm-assignment-educators')
    },
    hide () {
      this.$bvModal.hide('coordinators-group')
      this.$bvModal.hide('confirm-assignment-educators')
    },
  },
}
</script>
