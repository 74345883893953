var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('prk-table',{attrs:{"items":_vm.items,"show-headline":false,"excluded-fields":_vm.excludedFields,"fields-props":{ tdClass: ['py-4'] },"custom-fields":_vm.customFields},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'id' ? '5%' : '' })})})}},{key:"head(id)",fn:function(){return [_c('pcg-checkbox',{attrs:{"value":_vm.checkedAll},on:{"input":_vm.select}})]},proxy:true},{key:"head(user)",fn:function(){return [_vm._v("\n    "+_vm._s(_vm.$t('general.name_and_surname'))+"\n  ")]},proxy:true},{key:"head(roles)",fn:function(){return [_vm._v("\n    "+_vm._s(_vm.$t('general.role'))+"\n  ")]},proxy:true},{key:"content",fn:function(ref){
var header = ref.header;
var data = ref.data;
return [(header.key === 'id')?_c('pcg-checkbox',{model:{value:(data.item.selected),callback:function ($$v) {_vm.$set(data.item, "selected", $$v)},expression:"data.item.selected"}}):(header.key === 'user')?[_c('div',{staticClass:"d-flex align-items-center"},[_c('prk-avatar',{attrs:{"avatar":data.item.user.avatar,"abbr":data.item.user.abbr,"text":data.item.user.text}})],1)]:(header.key === 'roles')?[_c('prk-main-select',{staticClass:"w-auto mr-3 mr-sm-0",attrs:{"options":data.item.roles,"default-option":_vm.defaultRole(data.item.roles),"color":"aqua"},on:{"input":function($event){return _vm.setRole(data.item.id, $event)}}})]:(header.key === 'details')?_c('base-arrow',{attrs:{"show":data.detailsShowing},on:{"click":function($event){data.item._showDetails = !data.item._showDetails}}}):_c('div',{staticClass:"prk-fz-12",class:{ 'pcg-main-active-color': data.item.selected }},[_vm._v("\n      "+_vm._s(data.item[header.key])+"\n    ")])]}},{key:"row-details",fn:function(ref){
var item = ref.item;
return [_c('personnel-details',{attrs:{"item":item}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }