<template>
  <div>
    <div class="prk-shadow br-15 mt-4">
      <prk-list-filter
          :query.sync="query"
          filters-type="plan"
          @update:filters="updateFilters"
      />
      <study-plans-table
          :query="query"
          :filters="filters"
          :refetch.sync="refetch"
          @selected="selected = $event"
      />
      <import-plans-modal
          :current-year="filters.academic_year"
          @imported="imported = true"
          @import:filters="importFilters = $event"
      />
      <study-plan-syllabuses-list-modal/>
      <update-plan-beam
          v-if="!selected"
          :import-filters="importFilters"
          :imported="imported"
          :current-year="filters.academic_year"
          @set:imported="imported = $event"
          @refetch="refetch = true"
      />
      <study-plan-history/>
      <prk-subjects-modal/>
    </div>
    <portal-target name="study-plan-pagination" />
  </div>
</template>

<script>
import PrkListFilter from '../../components/PrkListFilter'
import StudyPlansTable from '../../components/plans/StudyPlansTable'
import ImportPlansModal from '../../components/plans/modals/PrkImportPlansModal'
import StudyPlanSyllabusesListModal from '../../components/plans/modals/StudyPlanSyllabusesListModal'
import UpdatePlanBeam from '../../components/beams/UpdatePlanBeam'
import StudyPlanHistory from '../../components/plans/modals/StudyPlanHistoryModal'
import PrkSubjectsModal from '../../components/plans/modals/PrkSubjectsModal'
import useLecturers from '../../composables/useLecturers'
import useFilters from '../../composables/useFilters'

export default {
  name: 'StudyPlanList',
  setup (props, { root }) {
    const { getLecturers } = useLecturers(root)
    const {
      filters,
      updateFilters,
    } = useFilters(root)

    return {
      filters,
      getLecturers,
      updateFilters,
    }
  },
  components: {
    StudyPlanHistory,
    UpdatePlanBeam,
    PrkListFilter,
    StudyPlansTable,
    ImportPlansModal,
    StudyPlanSyllabusesListModal,
    PrkSubjectsModal,
  },
  channels: {
    StudyPlanChannel: {
      connected () {},
      rejected () {},
      received (data) {
        if (data.body) {
          this.$toastr.w(this.$t('views.study_plans.warning.import'))
        } else {
          this.$toastr.s(this.$t('views.study_plans.success.import'))
          this.refetch = true
        }
      },
      disconnected () {},
    },
  },
  data () {
    return {
      query: '',
      importFilters: {},
      imported: false,
      selected: false,
      refetch: false,
    }
  },
  async created () {
    const academicYear = this.$route.query.academic_year || this.filters.academic_year
    this.$router.push({
      path: this.$route.path,
      query: { academic_year: academicYear, ...this.$_.omit(this.filters, 'academic_year') },
    }).catch(() => {})
  },
  mounted () {
    this.$cable.subscribe({
      channel: 'StudyPlanChannel',
    })
  },
}
</script>
