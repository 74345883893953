export const dictionaryModals = {
  props: {
    action: {
      type: Function,
      default: () => ({}),
    },
    btnTitle: {
      type: String,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    modalId: {
      type: String,
      required: true,
    },
    titleModal: {
      type: String,
      required: true,
    },
  },
}
