<template>
  <b-col sm="12" md="6" class="mt-3">
    <prk-select
        color="aqua"
        :default-option="defaultOption('types')"
        :error-text="typeError"
        :label="$t('components.prk_modals.add_matrix.level')"
        :options="types"
        :show-error="!!typeError"
        :value="$attrs.type"
        @input="input('type', $event)"
    />
  </b-col>
</template>

<script>
import { newMatrixModal } from '../../../../mixins/new_matrix_modal'

export default {
  name: 'NewMatrixType',
  mixins: [newMatrixModal],
  props: {
    courseNameId: { required: true, type: String },
    typeError: { required: true, type: String },
  },
  watch: {
    courseNameId () {
      this.getData(['types'], null, { types: { course_name_id: this.courseNameId } })
    }
  }
}
</script>
