<template>
  <g>
    <path v-if="contrast" d="M20.1426 40.085C31.1883 40.085 40.1426 31.1307 40.1426 20.085C40.1426 9.03927 31.1883 0.0849609 20.1426 0.0849609C9.09688 0.0849609 0.142578 9.03927 0.142578 20.085C0.142578 31.1307 9.09688 40.085 20.1426 40.085Z" fill="#00FA53"/>
    <path v-else d="M20.1426 40.085C31.1883 40.085 40.1426 31.1307 40.1426 20.085C40.1426 9.03927 31.1883 0.0849609 20.1426 0.0849609C9.09688 0.0849609 0.142578 9.03927 0.142578 20.085C0.142578 31.1307 9.09688 40.085 20.1426 40.085Z" fill="#ECF9FD"/>
    <path d="M25.1886 18.1535V14.9398L22.1447 11.7925H15.0969V18.1535H12.8257V26.0364H15.1671V27.1096H25.1184V26.0364H27.4598V18.1535H25.1886ZM23.4598 16.1739V18.1535H16.812V13.5174H20.892L20.8823 16.1749L23.4598 16.1739ZM15.1671 22.8647V24.3154H14.5506V19.8686H25.735V24.3164H25.1184V22.8647H15.1671ZM16.7506 25.5125V24.5417H23.535V25.5125H16.7506Z" />
  </g>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IconPrinter',
  computed: {
    ...mapGetters('page', ['contrast']),
  },
}
</script>

<style scoped>

</style>
