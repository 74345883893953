<template>
  <div class="dictionaries-fields-list">
    <div class="d-sm-flex justify-content-end">
      <pcg-btn variant="additional" v-b-modal.add-modal size="small"
               class="w-100 w-sm-auto m-0 font-weight-bold"
      >
        + {{ $t('general.new_form_of_class') }}
      </pcg-btn>
    </div>

    <prk-table
        v-if="items.length > 0"
        class="prk-shadow mt-4"
        :items="items"
        :excludedFields="['id']"
        :show-headline="false"
    >
      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'actions'">
          <div class="d-flex justify-content-end align-items-center">
            <pcg-btn round text="edit" class="mr-3 prk-button" :icon="iconEdit"
                     @click="editResource(data.item.id,
                     'formOfClass')"/>
            <pcg-btn round :icon="iconRemove" text="delete"
                     class="mr-3 prk-button" variant="danger"
                     @click="destroyResource('form_of_classes',
                     data.item.id, data.index)"/>
          </div>
        </div>
      </template>
    </prk-table>

    <structure-modal
        :action="action(formOfClass, 'form_of_classes')"
        :btn-title="btnTitle"
        :editMode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="formOfClass"
        @hidden="hideModal('formOfClass')"
        @clear-error="errors[$event] = ''"
    />
  </div>
</template>

<script>
import PrkTable from '../../components/PrkTable'
import StructureModal from '../../components/dictionaries/shared/StructureModal'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import useIcon from '../../composables/useIcon'
import { dictionary } from '../../mixins/dictionary'
import { mapGetters } from 'vuex'

export default {
  name: 'FormOfClasses',
  setup (props, { root }) {
    const {
      items, setItems, removeRow, findItemById,
    } = useList(root)
    const { iconEdit, iconRemove } = useIcon(root)
    const {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      updateResource,
    } = useDictionary()
    return {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      iconEdit,
      iconRemove,
      items,
      removeRow,
      setItems,
      updateResource,
    }
  },
  components: {
    StructureModal,
    PrkTable,
  },
  mixins: [dictionary],
  data () {
    return {
      formOfClass: {
        name: '',
        [Symbol.for('_innerName')]: 'formOfClass',
      },
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    title () {
      if (this.editMode) {
        return this.$t('general.edit_form_of_class')
      } else {
        return this.$t('general.new_form_of_class')
      }
    },
    btnTitle () {
      if (this.editMode) {
        return this.$t('general.update')
      } else {
        return this.$t('general.add_form_of_class')
      }
    },
  },
  async created () {
    await this.$store.dispatch('subjects/getFormOfClasses')
    const formOfClasses = this.$store.state.subjects.formOfClasses.map(formOfClass => {
      return { id: formOfClass.value, name: formOfClass.text }
    })
    this.setItems(this.$_.cloneDeep(formOfClasses))
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$tc('general.form_of_class', 2),
      })
    },
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-table-container {
    border-radius: 15px 15px 5px 5px;

    .pcg-table-content {
      border-radius: 15px 15px 5px 5px;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
