import { app } from '../../index'

export const studyPlans = {
  namespaced: true,
  state: {
    plan: null,
    planName: '',
    sidekiqStatus: '',
    modified: false,
    updatedPlans: [],
  },

  getters: {
    updatedPlanCount (state) {
      return state.updatedPlans.length
    },
    planId (state) {
      return app.$route.query.study_plan_id ||
        state.plan?.id || ''
    },
  },

  mutations: {
    SET_PLAN (state, plan) {
      state.plan = this.$app.$_.cloneDeep(plan)
    },
    SET_SIDEKIQ_STATUS (state, status) {
      state.sidekiqStatus = status
    },
    SET_PLAN_NAME (state, planName) {
      state.planName = planName
    },
    SET_MODIFIED (state, modified) {
      state.modified = modified
    },
    SET_UPDATED_PLANS (state, payload) {
      if (this.$app.$_.isEmpty(payload)) {
        state.updatedPlans = []
        return
      }

      const vm = this
      const attributes = ['facultyId', 'courseNameId', 'academicYear', 'kindId', 'typeId', 'u10Id']
      state.updatedPlans = payload.studyPlans.map(plan => {
        return { id: plan.id, ...this.$app.$_.pick(plan.attributes, attributes) }
      }).filter(plan => {
        if (!payload.filters) return true
        const keys = Object.keys(vm.$app.$_.omitBy(payload.filters, vm.$app.$_.isEmpty))
        const comparedPlan = vm.$app.$_.pick(plan, keys)
        return vm.$app.$_.isEqual(comparedPlan, vm.$app.$_.pick(payload.filters, keys))
      })
    },
  },

  actions: {
    setPlan ({ commit }, plan) {
      commit('SET_PLAN', plan)
    },
    setPlanName ({ commit }, planName) {
      commit('SET_PLAN_NAME', planName)
    },
    setModified ({ commit }, modified) {
      commit('SET_MODIFIED', modified)
    },
    async setUpdatedPlans ({ commit }, payload = {}) {
      if (payload.reset) {
        commit('SET_UPDATED_PLANS', [])
      } else {
        const { data: studyPlans } = await this.$app.$api.get('study_plans', {
          updated: '1',
          academic_year: payload.currentYear,
        })
        commit('SET_UPDATED_PLANS', { studyPlans, ...payload })
      }
    },
  },
}
