<template>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5 0H2.5C1.97005 0.00158273 1.46225 0.212794 1.08752 0.587524C0.712794 0.962255 0.501583 1.47005 0.5 2V16H2.5V2H14.5V0ZM13.5 4H6.5C5.9713 4.00264 5.4652 4.21454 5.09229 4.58932C4.71937 4.96411 4.51 5.4713 4.51001 6L4.5 20C4.49999 20.5287 4.70936 21.0359 5.08228 21.4107C5.45519 21.7855 5.96129 21.9974 6.48999 22H17.5C18.0299 21.9984 18.5377 21.7872 18.9125 21.4125C19.2872 21.0377 19.4984 20.5299 19.5 20V10L13.5 4ZM6.5 20V6H12.5V11H17.5V20H6.5Z" />
</template>

<script>
// width="20" height="22"
export default {
  name: 'IconCopy',
}
</script>

<style scoped>

</style>
