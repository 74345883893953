<template>
  <g>
    <path
      d="M10.9519 0.999939H8.88297V10.6476L6.3381 8.08319L4.89856 9.49932L9.90507 14.5409L14.9389 9.50582L13.4929 8.04027L10.9519 10.5812V0.999939Z"
    />
    <path
      d="M19.753 19H0.24707V9.54611H2.50585V16.7399H17.4942V9.54611H19.753V19Z"
    />
  </g>
</template>

<script>
export default {
  name: 'IconDownload',
}
</script>
