<template>
  <pcg-popover
      :placement="placement"
      :triggers="triggers"
      class="prk"
      v-on="$listeners"
  >
    <slot>
      <i :class="[icon]"/>
    </slot>
    <template v-slot:popoverBody>
      {{ $t(`components.prk_syllabus.buttons.${text}`) }}
    </template>
  </pcg-popover>
</template>

<script>
export default {
  name: 'BaseButton',
  props: {
    icon: {
      type: String,
      default: '',
    },
    text: {
      required: true,
      type: String,
    },
    placement: {
      type: String,
      default: 'top',
    },
    triggers: {
      type: String,
      default: 'hover',
    },
  },
}
</script>

<style scoped lang="scss">
</style>
