<template>
  <div class="d-flex">
    <prk-icon-options :options="options"/>
  </div>
</template>

<script>
import PrkIconOptions from '../../../PrkIconOptions'
import { pdf } from '../../../../mixins/syllabus/pdf'

export default {
  name: 'StudentActions',
  components: { PrkIconOptions },
  mixins: [pdf],
  computed: {
    options () {
      return [
        // {
        //   text: this.$i18n.t('components.prk_syllabus.buttons.download_doc'),
        //   action: () => {
        //   },
        // },
        {
          text: this.$i18n.t('components.prk_syllabus.buttons.download_pdf'),
          action: this.downloadPdf,
        },
        // {
        //   text: this.$i18n.t('components.prk_syllabus.buttons.download_excel'),
        //   action: () => {
        //   },
        // },
      ]
    },
  },
}
</script>
