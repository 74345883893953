<template>
  <fragment>
    <prk-download-modal
        modal-id="copy-syllabus"
        :btn-title="$t('general.copy')"
        :title="$t('general.copy_syllabus')"
        :size="modalSize"
        :action="copy"
    >
      <div class="d-flex justify-content-center">
        <b-col cols="4" align-self="center">
          <prk-select
              v-model="academicYear"
              :options="academicYears"
              :default-option="defaultOption"
          />
        </b-col>
      </div>
      <prk-table
          v-if="syllabuses.length"
          :show-headline="false"
          :items="syllabuses"
          :excluded-fields="[]"
          :busy="$jsonapi.loading"
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <pcg-spinner variant="small" />
          </div>
        </template>

        <template #head(id)/>

        <template #cell(id)="{ item: syllabus }">
          <pcg-radio
              v-model="copiedSyllabusId"
              :name="syllabus.name"
              class="d-flex align-items-center"
              style="transform: translateY(-13px)"
              :options="[{ value: syllabus.id }]"
          />
        </template>
      </prk-table>
    </prk-download-modal>
    <confirm-copying-syllabus @save="copySyllabus"/>
  </fragment>
</template>

<script>
import PrkDownloadModal from '../../courses/modal/PrkDownloadModal'
import PrkSelect from '../../PrkSelect'
import PrkTable from '../../PrkTable'
import ConfirmCopyingSyllabus from './ConfirmCopyingSyllabus'
import useHelpers from '../../../composables/useHelpers'
import { inject } from '@vue/composition-api'

export default {
  name: 'CopySyllabusModal',
  setup (_, { root }) {
    const { generateAcademicYears } = useHelpers(root)
    const academicYears = generateAcademicYears()
    const syllabus = inject('syllabus', {})

    return { academicYears, syllabus }
  },
  components: {
    ConfirmCopyingSyllabus,
    PrkDownloadModal,
    PrkSelect,
    PrkTable,
  },
  props: {
    getSyllabus: {
      required: true,
      type: Function,
    },
  },
  jsonapi: {
    syllabuses: {
      config: ({ academicYear }) => ({
        method: 'get',
        url: `syllabuses?serializer=copy_syllabuses&academic_year=${academicYear}`,
      }),
      variables () {
        return {
          academicYear: this.academicYear,
        }
      },
      skip () {
        return !this.academicYear.length
      },
      update (syllabuses) {
        return syllabuses.map(syllabus => {
          return {
            id: syllabus.id,
            name: syllabus.name,
            ...this.$_.pick(
              syllabus.studyPlan,
              'faculty', 'courseName',
              'kind', 'type',
            ),
            speciality: syllabus.speciality,
            semesterType: this.$t(`general.${syllabus.semesterType}`),
          }
        })
      },
    },
  },
  data () {
    return {
      copiedSyllabusId: '',
      academicYear: '',
      syllabuses: [],
    }
  },
  computed: {
    defaultOption () {
      return { text: this.$t('general.choose') }
    },
    modalSize () {
      return this.syllabuses.length ? 'lg' : 'md'
    },
  },
  methods: {
    copy () {
      if (!this.copiedSyllabusId.length) {
        this.$toastr.w(this.$t('views.syllabus.copy.no_syllabus_selected'))
        return
      }
      this.$bvModal.show('confirm-copying-syllabus')
    },
    async copySyllabus (copyMethod) {
      try {
        await this.$api.post('syllabuses/copy', {
          id: this.syllabus.id,
          cloned_syllabus_id: this.copiedSyllabusId,
          filters: {
            with_subject_effects: copyMethod,
          },
        })
        this.$bvModal.hide('copy-syllabus')
        this.getSyllabus(this.$route.params.id)
      } catch (e) {
        this.$showError(e.errors)
      }
    },
  },
}
</script>
