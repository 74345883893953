<template>
  <div class="dictionaries-fields-list">
    <div class="d-sm-flex justify-content-end">
      <pcg-btn variant="additional" v-b-modal.add-modal size="small"
               class="w-100 w-sm-auto m-0 font-weight-bold"
      >
        + {{ $t('general.new_kind') }}
      </pcg-btn>
    </div>

    <dictionary-table
        :edit-resource="editResource"
        :destroy-resource="destroyResource"
        :items="items"
        resource-name="kind"
        url="kinds"
    />

    <structure-modal
        :action="action(kind, 'kinds')"
        :btn-title="btnTitle"
        :editMode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="kind"
        @hidden="hideModal('kind')"
        @clear-error="errors[$event] = ''"
    />
  </div>
</template>

<script>
import StructureModal from '../../components/dictionaries/shared/StructureModal'
import DictionaryTable
  from '../../components/dictionaries/shared/DictionaryTable'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import { dictionary } from '../../mixins/dictionary'

export default {
  name: 'DictionaryKinds',
  setup (props, { root }) {
    const {
      items, setItems, removeRow, findItemById,
    } = useList(root)
    const {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      updateResource,
    } = useDictionary()
    return {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      errors,
      editResource,
      findItemById,
      items,
      removeRow,
      setItems,
      updateResource,
    }
  },
  components: {
    DictionaryTable,
    StructureModal,
  },
  mixins: [dictionary],
  data () {
    return {
      kind: {
        name: '',
        [Symbol.for('_innerName')]: 'kind',
      },
    }
  },
  computed: {
    title () {
      if (this.editMode) {
        return this.$t('general.edit_kind')
      } else {
        return this.$t('general.new_kind')
      }
    },
    btnTitle () {
      if (this.editMode) {
        return this.$t('general.update')
      } else {
        return this.$t('general.add_kind')
      }
    },
  },
  created () {
    this.getKinds()
  },
  methods: {
    async getKinds () {
      let { data: kinds } = await this.$api.get('kinds')
      kinds = kinds.map(kind => {
        return {
          id: kind.id,
          name: kind.attributes.name,
        }
      })
      this.setItems(kinds)
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$t('views.header.dictionary.kind'),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>
