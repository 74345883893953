<template>
  <pcg-modal
      :action="action"
      :btn-title="btnTitle"
      icon="fas fa-plus"
      :modal-id="modalId"
      size="md"
      :title="titleModal"
      @hidden="$emit('hidden')"
  >
    <div class="d-flex align-items-center">
      <pcg-text-input
          class="w-100 mb-4_5"
          :label="$t('general.name')"
          :error-text="errors.name"
          :show-error="!!errors.name"
          :value="$attrs.name"
          @input="input('name', $event)"
      />
    </div>
    <new-field-modal-general-effects
       @set:general-effect-ids="emit"
    />
  </pcg-modal>
</template>

<script>
import useModal from '../../../composables/useModal'
import { dictionaryModals } from '../../../mixins/dictionary_modals'
import NewFieldModalGeneralEffects from './new_field_modal/NewFieldModalGeneralEffects'

export default {
  name: 'NewFieldModal',
  setup () {
    const { input } = useModal()
    return { input }
  },
  components: {
    NewFieldModalGeneralEffects,
  },
  mixins: [dictionaryModals],
  methods: {
    emit (generalEffectIds) {
      this.$emit('set:general-effect-ids', generalEffectIds)
    },
  },
}
</script>
