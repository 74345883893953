<template>
  <div class="prk-shadow br-15 mt-5">
    <return
        path-name="study_plans_list_path"
        :query="{ academic_year: $route.query.academic_year }"
        id="return-study-plans"
        class="mb-3 d-inline-block position-absolute"
        :modified-form="modified"
    >
      {{ $t('components.prk_syllabus.return.plans_list') }}
    </return>
    <syllabus-box>
      <template #header>
        <study-plan-header v-bind:specialKind.sync="fields.specialKind"/>
      </template>

      <template #content>
        <prk-horizontal-line :width="{ value: -30, unit: 'px' }"/>
        <new-study-plan-fields
            :errors="errors"
            :fields="fields"
            @clear-error="errors.name = ''"
            @input="setField($event)"
        />
        <number-of-terms
            :specialKind="fields.specialKind"
            @change="semesterId = $event"
        />
        <new-subject :semester-id="semesterId" :specialKind="fields.specialKind"/>
      </template>
    </syllabus-box>
    <study-plan-table studyPlanForm show-footer :semester-id="semesterId"/>
    <base-beam>
      <div class="d-flex justify-content-end align-items-center h-100">
        <portal-target v-if="['lg', 'xl'].includes($mq)" name="studyPlanFormBeam"/>
        <pcg-btn size="small" @click="submit">
          {{ $t('general.save') }}
        </pcg-btn>
      </div>
    </base-beam>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SyllabusBox from '../../components/syllabus/PrkSyllabusBox'
import PrkHorizontalLine from '../../components/syllabus/PrkHorizontalLine'
import NumberOfTerms from '../../components/plans/new_study_plan/NumberOfTerms'
import NewStudyPlanFields from '../../components/plans/new_study_plan/NewStudyPlanFields'
import StudyPlanHeader from '../../components/plans/new_study_plan/StudyPlanHeader'
import NewSubject from '../../components/plans/new_study_plan/NewSubject'
import StudyPlanTable from '../../components/plans/new_study_plan/StudyPlanTable'
import Return from '../../components/base_components/Return'
import useModalValidation from '../../composables/useModalValidation'
import useStudyPlans from '../../composables/useStudyPlans'
import useLecturers from '../../composables/useLecturers'
import useHelpers from '../../composables/useHelpers'
import { studyPlanFormValidations } from '../../mixins/validations/study_plan_form_validations'

const { mapState, mapActions } = createNamespacedHelpers('studyPlans')

export default {
  name: 'StudyPlanForm',
  setup (_, { root }) {
    const { setErrors } = useModalValidation()
    const { getStudyPlan, addStudyPlan, updateStudyPlan } = useStudyPlans(root)
    const { getLecturers } = useLecturers(root)
    const { generateAcademicYears } = useHelpers(root)

    return {
      addStudyPlan,
      generateAcademicYears,
      getLecturers,
      getStudyPlan,
      setErrors,
      updateStudyPlan,
    }
  },
  components: {
    StudyPlanTable,
    NewSubject,
    StudyPlanHeader,
    NewStudyPlanFields,
    NumberOfTerms,
    PrkHorizontalLine,
    SyllabusBox,
    Return,
  },
  mixins: [studyPlanFormValidations],
  provide () {
    return {
      $v: this.$v,
      inNewStudyPlan: true,
      submit: this.submit,
    }
  },
  data () {
    return {
      errors: { name: '' },
      fields: {
        name: {
          type: 'input',
          value: '',
        },
        academicYear: {
          value: '',
          type: 'select',
          data: [],
        },
        faculty: {
          type: 'autocomplete',
          value: '',
        },
        courseName: {
          type: 'autocomplete',
          value: '',
        },
        type: {
          type: 'select',
          value: '',
        },
        kind: {
          type: 'select',
          value: '',
        },
        studyPlanType: {
          type: 'select',
          value: '',
          data: [
            { text: this.$t('general.winter'), value: 'winter' },
            { text: this.$t('general.summer'), value: 'summer' },
          ],
        },
        specialKind: false,
      },
      semesterId: '',
    }
  },
  computed: {
    ...mapState(['modified']),
    isSpecialKind () {
      return this.fields.specialKind
    },
    studyPlanId () {
      return this.$store.getters['studyPlans/planId']
    },
    successKey () {
      if (this.$isEditRoute()) {
        return 'views.study_plans.success.updated'
      } else {
        return 'views.study_plans.success.added'
      }
    },
    url () {
      if (this.$isEditRoute()) {
        return `study_plans/${this.studyPlanId}`
      } else {
        return 'study_plans'
      }
    },
  },
  async created () {
    this.fields.academicYear.data = this.generateAcademicYears()

    if (!this.studyPlanId) return
    await this.setFields()

    const unwatch = this.$watch('fields', () => {
      this.setModified(true)
      unwatch()
    }, { deep: true })
  },
  methods: {
    ...mapActions(['setPlanName', 'setModified']),

    // TODO powtórzenie w CourseForm
    setHeader () {
      let subtitleKey = ''
      if (this.$isEditRoute()) {
        subtitleKey = 'views.header.study_plan.edit'
      } else {
        subtitleKey = 'views.header.study_plan.new'
      }
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.study_plan.title'),
        subtitle: this.$t(subtitleKey),
      })
    },

    setField (payload) {
      this.fields[payload.property].value = payload.id
    },

    async setFields () {
      const { studyPlan, included } = await this.getStudyPlan(this.studyPlanId, {
        serializer: 'form',
      })
      this.fields.name.value = studyPlan.attributes.name
      this.fields.specialKind = studyPlan.attributes.specialKind
      const fields = ['faculty', 'course_name', 'type', 'kind']
      this.fields.academicYear.value = studyPlan.attributes.academicYear.toString()
      this.fields.studyPlanType.value = studyPlan.attributes.studyPlanType
      if (this.fields.specialKind) {
        const faculty = included.find(assoc => assoc.type === 'faculty')
        this.fields.faculty.value = faculty.id
        const courseName = included.find(assoc => assoc.type === 'course_name')
        this.fields.courseName.value = courseName.id
        return
      }

      // eslint-disable-next-line consistent-this
      const vm = this
      fields.forEach(field => {
        const structure = included.find(assoc => assoc.type === field)
        vm.fields[this.$_.camelCase(field)].value = structure.id
      })
    },

    async submit () {
      if (this.$v.fields.$invalid) {
        this.$v.fields.$touch()
        this.$toastr.e(this.$t('views.error.general_error'))
      } else {
        try {
          if (this.$isEditRoute()) {
            await this.updateStudyPlan(this.fields, this.url)
          } else {
            await this.addStudyPlan(this.fields, this.url)
            this.setPlanName(this.fields.name.value)
          }
          await this.$router.push({
            name: 'study_plans_list_path',
            query: this.$_.omit(this.$route.query, 'study_plan_id'),
          })
          this.$toastr.s(this.$t(this.successKey))
        } catch (e) {
          this.errors = this.$getErrors(e.errors)
          this.$showError(this.$getErrors(e.errors))
        }
      }
    },
  },

  beforeRouteEnter (to, from, next) {
    next(async vm => {
      vm.setHeader()
    })
  },
  destroyed () {
    this.setModified(false)
  },
}
</script>

<style scoped lang="scss">

#return-study-plans {
  top: -3rem;
}

::v-deep {
  .pcg-table-container {
    border-radius: 0 0 15px 15px;

    .pcg-table-content {
      border-radius: 15px;
    }
  }

  .box-container.syllabus-box .box {
    border-radius: 15px 15px 0 0;
  }
}
</style>
