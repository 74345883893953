<template>
  <fragment>
    <base-beam v-if="updatedPlanCount > 0">
      <prk-beam>
        <div class="d-flex align-items-center">
          {{ $t('views.study_plans.update_plan_beam.changes', { count: updatedPlanCount }) }}
          <pcg-btn size="small" @click="updatePlans">
            {{ $t('general.update_plans') }}
          </pcg-btn>
          <pcg-btn size="small" @click="discardChanges">
            {{ $t('general.discard_changes') }}
          </pcg-btn>
        </div>
      </prk-beam>
    </base-beam>
    <confirm-plan-updates
        :assigned-subjects="subjectAssignedToMatrices"
        @save="updateAll"
    />
  </fragment>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkBeam from './PrkBeam'
import ConfirmPlanUpdates from '../plans/modals/ConfirmPlanUpdates'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('studyPlans')

export default {
  name: 'UpdatePlanBeam',
  components: { PrkBeam, ConfirmPlanUpdates },
  props: {
    importFilters: { required: true, type: Object },
    imported: { required: true, type: Boolean },
    currentYear: { required: true, type: String },
  },
  data () {
    return {
      subjectAssignedToMatrices: [],
    }
  },
  jsonapi: {
    subjectAssignedToMatrices: {
      config: { method: 'get', url: 'subjects/check_updated_subjects' },
    },
  },
  computed: {
    ...mapState(['updatedPlans']),
    ...mapGetters(['updatedPlanCount']),
  },
  watch: {
    imported: {
      async handler (newImported) {
        if (!newImported) return
        await this.setUpdatedPlans()
        if (this.updatedPlans.length > 0) {
          this.$toastr.w(this.$t('views.study_plans.import.plans_updated'))
          this.$jsonapi.queries.subjectAssignedToMatrices.refetch()
        }
        this.$emit('set:imported', false)
      },
    },
    currentYear: {
      async handler () {
        if (this.updatedPlanCount > 0) await this.setUpdatedPlans()
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions(['setUpdatedPlans']),
    async discardChanges () {
      await this.$api.destroy('study_plans/discard_changes')
      this.$toastr.s(this.$t('views.study_plans.update_plan_beam.discard_changes'))
      await this.setUpdatedPlans({ reset: true })
    },
    updatePlans () {
      if (this.subjectAssignedToMatrices.length) {
        this.$bvModal.show('confirm-plan-updates')
      } else {
        this.updateAll()
      }
    },
    async updateAll () {
      await this.$api.post('study_plans/update_all')
      this.$emit('refetch')
      this.$toastr.s(this.$t('views.study_plans.update_plan_beam.updated_plans'))
    },
  },
}
</script>
