<template>
  <pcg-btn
      class="m-0"
      size="small"
      variant="additional"
      @click="$router.push(routerObject)"
  >
    <span class="font-weight-bold prk-fz-12">
      {{ $tc(`general.${buttonName}`, 2) }}
    </span>
  </pcg-btn>
</template>

<script>
export default {
  name: 'DictionariesShowNestedResourcesButton',
  props: {
    routerObject: { required: true, type: Object },
    buttonName: { required: true, type: String },
  },
}
</script>
