<template>
  <prk-table
      v-if="env && env.formOfCredits"
      :syllabus-header="true"
      :show-headline-index="false"
      :items="preview ? dumbEffects : subjectEffects"
      :headline-index="section.number"
      :excluded-fields="EXCLUDED_FIELDS"
      :responsive="['xs', 'sm', 'md'].includes($mq)"
      disable-actions
      :form-of-credits="env.formOfCredits == 1"
  >
    <template #headline-text>
      <div class="d-flex align-items-center">
        {{ section.header }}
        <comment-modal-button
            modal-id="commentModal"
            :comment-count="section.commentCount"
            @input="setComments(section, 'SyllabusSection')"
        />
      </div>
    </template>

    <template v-slot:content="{ header, data }">
      <div v-if="header.key === 'symbol'">{{ data.item[header.key] }}</div>
      <div v-if="header.key === 'effectContent'">{{ data.item[header.key] }}</div>

      <template v-if="fillContent && editMode">
        <div v-if="header.key === 'formOfCredits'" class="d-flex align-items-end">
          <prk-assessment-methods-select
              v-if="formOfCredits.length"
              :effect-id="data.item.id"
              :forms="data.item.formOfCredits"
              :form-options="formOfCredits"
              credit
          />
        </div>
        <div v-else-if=" ['formOfClasses', 'formOfClass'].includes(header.key)">
          <prk-assessment-methods-select
              v-if="formOfClasses.length"
              :effect-id="data.item.id"
              :forms="data.item.formOfClasses || data.item.formOfClass"
              :form-options="formOfClasses"
          />
        </div>
      </template>

      <template v-else>
        <div v-if="header.key === 'formOfClasses'">
          <div v-for="(form, index) in data.item.formOfClasses" :key="index">
            <span>{{ form.text }}</span>
          </div>
        </div>
        <div v-else-if="header.key === 'formOfCredits'">
          <div v-for="(form, index) in data.item.formOfCredits" :key="index">
            <span>{{ form.text }}</span>
          </div>
        </div>
      </template>
    </template>

    <!--    <template #cell(actions)="{ item: subjectEffect }">-->
    <!--      <prk-assessment-methods-actions :subject-effect="subjectEffect"/>-->
    <!--    </template>-->
  </prk-table>
</template>

<script>
import PrkAssessmentMethodsSelect from './PrkAssessmentMethodsSelect'
import PrkTable from '../../../PrkTable'
import CommentModalButton from '../../../PrkCommentModalButton'
import { inject } from '@vue/composition-api'
import useComments from '../../../../composables/useComments'
import useSortEffects from '../../../../composables/useSortEffects'
import { mapGetters, mapActions } from 'vuex'
import { fillStatus } from '../../../../mixins/syllabus/status'

export default {
  name: 'AssessmentMethods',
  setup (props, { root }) {
    const status = inject('status')
    const dumbEffects = inject('effects', [])
    const editMode = inject('editMode', true)
    const preview = inject('preview', false)
    const { setComments } = useComments(root, props)
    const { sortEffects } = useSortEffects(root)
    return {
      editMode,
      preview,
      dumbEffects,
      status,
      setComments,
      sortEffects,
    }
  },
  components: {
    CommentModalButton,
    PrkTable,
    PrkAssessmentMethodsSelect,
  },
  mixins: [fillStatus],
  inject: ['statusesDataCompleted'],
  props: {
    section: { type: Object, required: true },
  },
  jsonapi: {
    formOfClasses: {
      config: { method: 'get', url: 'form_of_classes' },
      update (formOfClasses) {
        return formOfClasses.map(({ id, name }) => ({ value: id, text: name }))
      },
      skip () {
        return this.$checkRouteName('syllabuses_preview_path')
      },
    },
    formOfCredits: {
      config: { method: 'get', url: 'form_of_credits' },
      update (formOfCredits) {
        return formOfCredits.map(({ id, name }) => ({ value: id, text: name }))
      },
      skip () {
        return this.$checkRouteName('syllabuses_preview_path')
      },
    },
  },
  data () {
    return {
      formOfClasses: [],
      formOfCredits: [],
    }
  },
  computed: {
    ...mapGetters('syllabusFill', ['getSubjectEffects']),
    ...mapGetters('environment', ['env']),
    subjectEffects () {
      return this.sortEffects(this.getSubjectEffects.map(effect => {
        return {
          id: effect.id,
          categoryName: effect.categoryName,
          symbol: effect.educationalOutcome,
          effectContent: effect.effectContentObj,
          formOfClasses: effect.formOfClasses,
          formOfCredits: effect.formOfCredits,
        }
      }))
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['id', 'categoryName']
  },
  methods: {
    ...mapActions('syllabusFill', ['setFormOfClass']),
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  .basic-information .pcg-table-header-row {
    display: none;
  }

  .dropdown .btn {
    background: $pcg-white !important;
    color: $pcg-aqua-blue !important;
    box-shadow: 0 3px 10px rgba(32, 19, 19, 0.2);
    margin: 0;
  }

  &.assessment .b-dropdown-form {
    outline: none !important;
  }
}

</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {
    .basic-information .pcg-table-header-row {
      display: none;
    }

    .dropdown .btn {
      background: white !important;
      color: $pcg-aqua-blue !important;
    }
  }
}
</style>
