<template>
  <pcg-modal
      :action="importPlans"
      :btn-class="working ? 'd-none' : ''"
      :btn-title="$t('components.prk_modals.plans.save')"
      icon="pcg-icon-document"
      modal-id="importPlans"
      :no-close-on-backdrop="working"
      size="md"
      :title="$tc('general.import_plan', 2)"
      @shown="init"
      @hidden="clear"
      class="prk-modal-content"
  >
    <div v-if="working" class="d-flex justify-content-center">
      <pcg-spinner />
    </div>
    <b-row v-else>
      <b-col class="mb-3" cols="12">
<!--        TODO should be move to another component like in PrkNewMatrixModal. All prk-select-->
        <prk-select
            v-model="studyPlans.facultyId"
            color="aqua"
            :default-option="defaultOption"
            :error-text="$t('views.study_plans.errors.import.faculty')"
            :label="$tc('general.faculty')"
            :options="faculties"
            :show-error="$v.studyPlans.facultyId.$error"
            required
        />
      </b-col>
      <b-col class="mb-3" cols="12">
        <prk-select
            v-model="studyPlans.courseNameId"
            color="aqua"
            :default-option="{ text: '---' }"
            :error-text="$t('views.study_plans.errors.import.course')"
            :label="$tc('general.course_name', 1)"
            :options="courseNames"
            :show-error="$v.studyPlans.courseNameId.$error"
            required
        />
      </b-col>
      <b-col class="mb-3" cols="12">
        <prk-select
            v-model="studyPlans.academicYear"
            color="aqua"
            :default-option="defaultOption"
            :error-text="$t('views.study_plans.errors.import.academic_year')"
            :label="$tc('general.academic_year', 1)"
            :options="academicYears"
            :show-error="$v.studyPlans.academicYear.$error"
            required
        />
      </b-col>
      <b-col class="mb-3" cols="12">
        <prk-select
            v-model="studyPlans.typeId"
            color="aqua"
            :default-option="defaultOption"
            :include-blank="true"
            :label="$tc('general.type', 1)"
            :options="types"
        />
      </b-col>
      <b-col class="mb-3" cols="12">
        <prk-select
            v-model="studyPlans.kindId"
            color="aqua"
            :default-option="defaultOption"
            :include-blank="true"
            :label="$tc('general.kind', 1)"
            :options="kinds"
        />
      </b-col>
    </b-row>
  </pcg-modal>
</template>

<script>
import PrkSelect from '../../PrkSelect'
import useHelpers from '../../../composables/useHelpers'
import { required } from 'vuelidate/lib/validators'
import { getStudyData } from '../../../mixins/getStudyData'

export default {
  name: 'PrkImportPlansModal',
  setup (_, { root }) {
    const { generateAcademicYears } = useHelpers(root)
    return { generateAcademicYears }
  },
  validations: {
    studyPlans: {
      facultyId: { required },
      courseNameId: { required },
      academicYear: { required },
    },
  },
  components: {
    PrkSelect,
  },
  mixins: [getStudyData],
  props: {
    currentYear: { required: true, type: String },
  },
  data () {
    return {
      defaultOption: { text: this.$t('general.choose') },
      academicYears: [],
      studyPlans: {
        facultyId: '',
        courseNameId: '',
        academicYear: '',
        typeId: '',
        kindId: '',
      },
      importStatus: '',
    }
  },
  computed: {
    working () {
      return this.importStatus === 'working'
    },
  },
  watch: {
    importStatus () {
      if (['working', 'queued'].includes(this.importStatus)) return
      if (this.importStatus === 'complete') {
        const filters = this.$_.cloneDeep(this.studyPlans)
        this.$emit('imported')
        this.$emit('import:filters', filters)
        this.$bvModal.hide('importPlans')
      } else if (['failed', 'interrupted'].includes(this.importStatus)) {
        this.$toastr.e(this.$t('views.study_plans.errors.import.failed'))
      } else {
        this.$toastr.e(this.$t('views.study_plans.errors.import_unexpected_error'))
      }
    },
    'studyPlans.facultyId' (newFacultyId) {
      if (!newFacultyId.length) return

      this.getData(['course_names'], null, {
        course_names: { faculty_id: newFacultyId, from_u10: 1 }
      })
      this.types = []
      this.kinds = []

      this.studyPlans.courseNameId = ''
      this.studyPlans.typeId = ''
      this.studyPlans.kindId = ''

    },
    'studyPlans.courseNameId' (newCourseNameId) {
      if(!newCourseNameId.length) return

      this.getData(['types'], null, {
        types: {
          course_name_id: newCourseNameId, from_u10: 1
        }
      })
      this.kinds = []

      this.studyPlans.typeId = ''
      this.studyPlans.kindId = ''
    },
    'studyPlans.typeId' (newTypeId) {
      if(!newTypeId || !newTypeId.length) {
        this.kinds = []
        this.studyPlans.kindId = ''
        return
      }

      this.getData(['kinds'], null, {
        kinds: { type_id: newTypeId, from_u10: 1 }
      })
      this.studyPlans.kindId = ''
    }
  },
  methods: {
    checkStatus (jobId) {
      // eslint-disable-next-line consistent-this
      const vm = this
      const url = `study_plans/check_import?job_id=${jobId}`
      setTimeout(async function run () {
        try {
          const { studyPlanImportStatus: importStatus } = await vm.$api.get(url)
          if (['working', 'queued'].includes(importStatus)) {
            setTimeout(run, 2000)
          } else {
            vm.importStatus = importStatus
          }
        } catch (e) {
          console.log(e.errors)
        }
      }, 2000)
    },
    clear () {
      this.$v.$reset()
      this.clearModel(this.studyPlans)
    },
    async importPlans () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      this.importStatus = 'working'
      const { studyPlanJobId: jobId } = await this.$api.post('study_plans/import', this.studyPlans)
      this.checkStatus(jobId)
    },
    init () {
      this.getData(['faculties'], null, { faculties: { from_u10: 1 } })
      this.academicYears = this.generateAcademicYears()
      this.studyPlans.academicYear = this.currentYear
    },
  },
}
</script>
