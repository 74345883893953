<template>
    <g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M0.890625 11.6143C0.890625 11.062 1.33834 10.6143 1.89062 10.6143H12.8906C13.4429 10.6143 13.8906 11.062 13.8906 11.6143V15.6143C13.8906 16.1665 13.4429 16.6143 12.8906 16.6143H1.89062C1.33834 16.6143 0.890625 16.1665 0.890625 15.6143V11.6143ZM11.1864 14.2105C11.2853 14.2878 11.3347 14.3957 11.3347 14.5342C11.3347 14.6565 11.2889 14.7518 11.1972 14.8202C11.1072 14.8867 10.984 14.92 10.8276 14.92C10.583 14.92 10.4058 14.8705 10.2961 14.7716C10.1882 14.6709 10.1342 14.5153 10.1342 14.3049H9.18463C9.18463 14.5639 9.24937 14.7932 9.37887 14.9928C9.51016 15.1907 9.7044 15.3471 9.96158 15.4622C10.2206 15.5755 10.5092 15.6322 10.8276 15.6322C11.279 15.6322 11.6342 15.5342 11.8932 15.3381C12.1522 15.1421 12.2817 14.8723 12.2817 14.5288C12.2817 14.099 12.0694 13.7617 11.645 13.5172C11.4705 13.4164 11.2475 13.3229 10.9759 13.2366C10.7044 13.1485 10.5146 13.0639 10.4067 12.983C10.2988 12.9003 10.2448 12.8085 10.2448 12.7078C10.2448 12.5927 10.2934 12.4983 10.3905 12.4246C10.4894 12.349 10.6234 12.3113 10.7925 12.3113C10.9598 12.3113 11.0919 12.3553 11.1891 12.4434C11.288 12.5316 11.3374 12.6557 11.3374 12.8157H12.2817C12.2817 12.5765 12.2196 12.3643 12.0955 12.1791C11.9714 11.992 11.797 11.8481 11.5721 11.7474C11.3491 11.6467 11.0964 11.5963 10.8141 11.5963C10.5245 11.5963 10.2637 11.6431 10.0317 11.7366C9.79972 11.8284 9.61987 11.9578 9.49217 12.1251C9.36628 12.2906 9.30333 12.4821 9.30333 12.6997C9.30333 13.1368 9.55782 13.4803 10.0668 13.7303C10.2233 13.8076 10.4247 13.8876 10.6711 13.9704C10.9175 14.0531 11.0892 14.1331 11.1864 14.2105ZM4.25042 12.956L4.89249 11.6503H5.97429L4.8763 13.5981L6.00397 15.5782H4.91137L4.25042 14.2482L3.58947 15.5782H2.49957L3.62454 13.5981L2.52925 11.6503H3.60835L4.25042 12.956ZM7.26112 14.8498H8.90946V15.5782H6.31421V11.6503H7.26112V14.8498Z" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.74219 0.119141H14.6833L19.5876 5.02349V20.1191H3.74219V17.6145H5.176V18.7568H18.1519H18.2301V6.08049H13.7026V1.55296H5.176V9.6145H3.74219V0.119141ZM17.2485 4.64668L15.0601 2.45827V4.64668H17.2485Z" />
    </g>
</template>

<script>
export default {
  name: 'IconExcel',
}
</script>
