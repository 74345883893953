export const syllabusTemplate = {
  namespaced: true,
  state: {
    syllabus: {},
    sections: [],
    returnUrl: '',
  },
  getters: {
    comments (state) {
      return state.sections.map(section => section.comments).flat()
    },
    getReturnUrl (state) {
      return state.returnUrl.length ? state.returnUrl : localStorage.getItem('returnUrl')
    },
  },
  mutations: {
    SET_RETURN_URL (state, url) {
      state.returnUrl = url
      localStorage.setItem('returnUrl', url)
    },
    SET_SECTIONS (state, sections) {
      state.sections = sections
    },
    SET_SYLLABUS (state, syllabus) {
      state.syllabus = syllabus
    },
  },
  actions: {
    setReturnUrl ({ commit }, returnUrl) {
      commit('SET_RETURN_URL', returnUrl)
    },
    setSyllabus ({ commit }, syllabus) {
      commit('SET_SYLLABUS', syllabus)
      localStorage.setItem('syllabus', JSON.stringify(syllabus))
    },
    setSections ({ commit }, sections) {
      commit('SET_SECTIONS', sections)
      localStorage.setItem('sections', JSON.stringify(sections))
    },
  },
}
