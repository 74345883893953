<template>
  <div>
    <prk-table
        class="sub-table"
        :excluded-fields="excludedFields"
        :fields-props="{ thClass: 'd-none', tdClass: tdClass } "
        :items="items"
        :show-headline="false"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: colgroup(field.key) }"
        >
      </template>
      <template v-slot:content="{header, data }">

        <div v-if="header.key === 'symbol'"
             class="prk-fz-12 ml-5">
          {{ data.item.symbol }}
        </div>

        <template v-else-if="header.key === 'description'">
          <prk-animated-description :description="data.item.description"/>
        </template>

        <div v-else-if="header.key === 'actions'"
             class="d-flex justify-content-end">
          <base-arrow
              :show="data.item.toggleDetails"
              class="pr-2"
              @click="showText(data, $event)"
          />
          <b-btn-close class="outline-none" @click="removeCourseEffect(data.item.id)"/>
        </div>
      </template>

    </prk-table>
    <div class="w-100 d-flex justify-content-center">
      <pcg-btn size="small" v-b-modal="modalId" @click="$emit('currentCustomId', customId)">
        <div class="font-weight-bold">{{ $t('components.prk_matrices.add_effect') }}</div>
      </pcg-btn>
    </div>
  </div>
</template>

<script>
import PrkTable from '../PrkTable'
import useList from '../../composables/useList'
import useExpandText from '../../composables/useExpandText'
import PrkAnimatedDescription from '../PrkAnimatedDescription'

export default {
  name: 'MatricesDetails',
  setup (props, { root }) {
    const {
      items,
      setItems,
      removeRow,
      removeSwal,
    } = useList(root)
    const { tdClass, showText } = useExpandText('matrices-details')
    return {
      items,
      setItems,
      removeRow,
      removeSwal,
      showText,
      tdClass,
    }
  },
  components: {
    PrkAnimatedDescription,
    PrkTable,
  },
  data () {
    return {
      wordsCount: 160,
      excludedFields: ['id', 'toggleDetails'],
    }
  },
  props: {
    customId: {
      type: String,
      required: true,
    },
    modalId: {
      type: String,
      required: true,
    },
    subItems: {
      type: Array,
      required: true,
    },
  },
  created () {
    this.setItems(this.subItems)
  },
  watch: {
    subItems: {
      deep: true,
      handler (newVal) {
        this.setItems(newVal)
        if (newVal.length === 0) {
          this.$emit('hide-row', this.customId)
        }
      },
    },
  },
  methods: {
    async removeCourseEffect (effectId) {
      const response = await this.removeSwal()
      if (response.value) {
        this.$emit('courseEffectRemoved', effectId, this.customId)
      }
    },
    colgroup (field) {
      switch (field) {
        case 'description':
          return '80%'
        case 'actions':
          return '8%'
        case 'symbol':
          return '12%'
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.sub-table {
  ::v-deep {
    .pcg-table-container {
      background: $pcg-the-lightest-gray;
      .pcg-table-content {
        background: $pcg-the-lightest-gray;
        tbody {
          .pcg-table-narrow-row {
            td {
              border-bottom: 1px solid $pcg-lightest-gray !important;
            }
          }
        }
      }
    }
    .pcg-table-container, .pcg-table-content {
      border-radius: 0 !important;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .sub-table {
    ::v-deep {
      .pcg-table-container {
        background-color: $pcg-the-lightest-gray;
        .pcg-table-content {
          background-color: $pcg-the-lightest-gray;
        }
      }
      .pcg-table-container, .pcg-table-content {
        border-radius: 0 !important;
      }
    }
  }
}
</style>
