export default function () {
  const getTrainingCycle = (academicYear, semesterCount) => {
    const beginningOfTheTrainingCyclePreviousYear = +academicYear.split('/')[0]
    const beginningOfTheTrainingCycleNextYear = +academicYear.split('/')[1]
    const endOfTheTrainingCyclePreviousYear = beginningOfTheTrainingCyclePreviousYear + Math.ceil(semesterCount / 2 - 1)
    const endOfTheTrainingCycleNextYear = beginningOfTheTrainingCycleNextYear + Math.ceil(semesterCount / 2 - 1)
    return `${academicYear} - ${endOfTheTrainingCyclePreviousYear}/${endOfTheTrainingCycleNextYear}`
  }

  return {
    getTrainingCycle,
  }
}
