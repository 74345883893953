export const getStudyData = {
  data () {
    return {
      faculties: [],
      courses: [],
      courseNames: [],
      kinds: [],
      types: [],
      studyProfiles: [],
      specialities: [],
      subjectNames: [],
      structures: [
        'faculties',
        'courses',
        'course_names',
        'kinds',
        'types',
        'study_profiles',
        'specialities',
      ],
    }
  },
  methods: {
    async saveData (response, reference) {
      if (reference === null) return
      reference.splice(0, reference.length)
      response.forEach(el => {
        reference.push({
          text: el.attributes.name,
          value: el.id,
        })
      })
    },

    selectModel (model) {
      switch (model) {
        case 'faculties':
          return this.faculties
        case 'courses':
          return this.courses
        case 'course_names':
          return this.courseNames
        case 'kinds':
          return this.kinds
        case 'types':
          return this.types
        case 'study_profiles':
          return this.studyProfiles
        case 'specialities':
          return this.specialities
        case 'subject_names':
          return this.subjectNames
        default:
          return null
      }
    },

    async getDataFromAPI (model, params = {}) {
      const { data: response } = await this.$api.query(model, params)
      if (!response) return
      try {
        await this.saveData(response, this.selectModel(model))
      } catch (e) {
        console.log(e.errors)
      }
    },

    async getData (structures = null, except = null, params = {}) {
      if (!structures || !(structures instanceof Array)) {
        structures = this.structures
      }

      if (except && except instanceof Array) {
        structures = structures.filter(structure => !except.includes(structure))
      }

      for (const model of structures) {
        await this.getDataFromAPI(model, params[model])
      }
    },

    clearModel (refModel) {
      Object.entries(refModel).forEach(([key, value]) => {
        refModel[key] = ''
      })
    },
  },
}
