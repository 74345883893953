export const coordinators = {
  namespaced: true,
  state: {
    coordinators: [],
    showWarning: false,
  },
  mutations: {
    SET_COORDINATORS (state, coordinators) {
      state.coordinators = coordinators
    },
    SET_SHOW_WARNING (state, showWarning) {
      state.showWarning = showWarning
    },
  },
  actions: {
    setCoordinators ({ commit }, coordinators) {
      commit('SET_COORDINATORS', coordinators)
    },
    setShowWarning ({ commit }, showWarning) {
      commit('SET_SHOW_WARNING', showWarning)
    },
  },
}
