<template>
  <g v-if="contrast">
    <path d="M15.4593 15.5986H0.825195V17.5011H15.4593V15.5986Z" fill="#00FA53" />
    <path d="M13.2447 4.5453L9.73741 0.935547L8.43497 2.28189L2.314 8.58433L0.854483 10.077L0.844727 10.0868L0.854483 10.0965V11.7843V13.6868H4.34326L4.35302 13.6965L4.36278 13.6868L5.81351 12.1941L11.9374 5.89164L13.2447 4.5453ZM3.594 11.7746H2.69351V10.8575L9.73741 3.61847L10.6281 4.53555L3.594 11.7746Z" fill="#00FA53" />
  </g>
  <g v-else>
    <path d="M15.4593 15.5986H0.825195V17.5011H15.4593V15.5986Z" fill="#00B4EB" />
    <path d="M13.2447 4.5453L9.73741 0.935547L8.43497 2.28189L2.314 8.58433L0.854483 10.077L0.844727 10.0868L0.854483 10.0965V11.7843V13.6868H4.34326L4.35302 13.6965L4.36278 13.6868L5.81351 12.1941L11.9374 5.89164L13.2447 4.5453ZM3.594 11.7746H2.69351V10.8575L9.73741 3.61847L10.6281 4.53555L3.594 11.7746Z" fill="#00B4EB" />
  </g>
</template>

<script>
import { mapGetters } from 'vuex'

// width: 15, height: 19
export default {
  name: 'IconEdit',
  computed: {
    ...mapGetters('page', ['contrast']),
  },
}
</script>
