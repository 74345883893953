<template>
  <div class="d-flex">
    <pcg-btn class="mr-2 prk-button"
             :icon="iconEdit"
             round
             text="edit"
             @click="editEffect(effect)"
    />
    <pcg-btn round
             :icon="iconRemove"
             text="delete"
             variant="danger"
             class="prk-button"
             @click="removeEffect(effect)"
    />
  </div>
</template>

<script>
import useIcon from '../../../composables/useIcon'
import { inject } from '@vue/composition-api'
import { mapGetters } from 'vuex'

export default {
  name: 'ObjectiveEffectsActions',
  setup (_, { root }) {
    const status = inject('status')
    const { iconEdit, iconRemove } = useIcon(root)
    return { status, iconEdit, iconRemove }
  },
  props: {
    effect: { required: true, type: Object },
    editEffect: { required: true, type: Function },
    removeEffect: { required: true, type: Function },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
