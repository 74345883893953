<template>
    <g>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.19141 10.6143C0.639122 10.6143 0.191406 11.062 0.191406 11.6143V15.6143C0.191406 16.1665 0.639121 16.6143 1.19141 16.6143H12.1914C12.7437 16.6143 13.1914 16.1665 13.1914 15.6143V11.6143C13.1914 11.062 12.7437 10.6143 12.1914 10.6143H1.19141ZM2.8075 14.2346V15.5443H1.87695V11.6842H3.41726C3.71242 11.6842 3.97312 11.739 4.19935 11.8486C4.42735 11.9564 4.60321 12.111 4.72693 12.3125C4.85241 12.5122 4.91516 12.7394 4.91516 12.9939C4.91516 13.3703 4.77995 13.6717 4.50953 13.8979C4.24088 14.1224 3.87149 14.2346 3.40136 14.2346H2.8075ZM2.8075 13.5161H3.41726C3.59754 13.5161 3.73452 13.4711 3.82819 13.3809C3.92363 13.2908 3.97135 13.1635 3.97135 12.9992C3.97135 12.8189 3.92275 12.6748 3.82554 12.567C3.72833 12.4592 3.59577 12.4044 3.42787 12.4027H2.8075V13.5161ZM5.41357 15.5443V11.6842H6.65695C6.99807 11.6842 7.30471 11.762 7.5769 11.9175C7.84908 12.0713 8.06117 12.2895 8.21317 12.5723C8.36694 12.8534 8.4447 13.1688 8.44647 13.5188V13.6964C8.44647 14.0499 8.37136 14.3672 8.22112 14.6482C8.07266 14.9274 7.86234 15.1466 7.59015 15.3057C7.31974 15.463 7.01751 15.5425 6.68346 15.5443H5.41357ZM6.34412 12.4027V14.8285H6.66756C6.93444 14.8285 7.13946 14.7339 7.28262 14.5448C7.42578 14.3539 7.49736 14.0711 7.49736 13.6964V13.5294C7.49736 13.1565 7.42578 12.8754 7.28262 12.6863C7.13946 12.4972 6.9309 12.4027 6.65695 12.4027H6.34412ZM9.85422 14.0066H11.3548V13.2908H9.85422V12.4027H11.5059V11.6842H8.92368V15.5443H9.85422V14.0066Z"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M1.89453 0.119141H12.8356L17.7399 5.02349V20.1191H1.89453V17.6145H3.32835V18.7568H16.3042H16.3825V6.08049H11.8549V1.55296H3.32835V9.6145H1.89453V0.119141ZM1.89453 16.6145H3.32835V14.2348H2.80701V15.5445H1.89453V16.6145ZM1.89453 11.6844V10.6145H3.32835V11.6844H1.89453ZM3.32835 12.4029H2.80701V13.5164H3.32835V12.4029ZM15.4008 4.64668L13.2124 2.45827V4.64668H15.4008Z"/>
    </g>
</template>

<script>
//  width: '18', height: '21',
export default {
  name: 'IconPdf',
}
</script>
