export const personnel = {
  namespaced: true,
  state: {
    personnel: [
      {
        fullName: 'Dr. hab. Mariola Grzebyk',
        value: 1,
        avatar: 'https://picsum.photos/300/150/?image=41',
        abbr: 'MG',
      },
      {
        fullName: 'Dr. Adam Mróz',
        value: 2,
        avatar: 'https://picsum.photos/300/150/?image=42',
        abbr: 'AM',
      },
      {
        fullName: 'Mgr Zygmunt Podolski',
        value: 3,
        avatar: 'https://picsum.photos/300/150/?image=43',
        abbr: 'ZP',
      },
    ],
  },
  getters: {
    getPersonnelBySubjectId: (state) => (id) => {
      const personnelBySubject = []
      state.personnel.forEach(personnel => {
        const foundAssociation = personnel.relationships.subjects.data
          .find(subject => { return subject.id === id })
        if (foundAssociation) {
          personnelBySubject.push(personnel)
        }
      })
      return personnelBySubject
    },
  },
  mutations: {
    SET_PERSONNEL (state, personnel) {
      state.personnel = personnel
    },
  },
  actions: {
    setPersonnel ({ commit }, personnel) {
      commit('SET_PERSONNEL', personnel)
    },
  },
}
