<template>
  <b-col cols="12" lg="auto" class="mt-3 mt-lg-0">
    <div class="d-flex justify-content-center justify-content-sm-end">
      <pcg-btn class="w-100 w-lg-auto m-0" size="small" @click="insert">
          <span class="prk-fz-12">
            {{ $t('general.add_semester') }}
          </span>
      </pcg-btn>
    </div>
  </b-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('semesters')

export default {
  name: 'NewSubjectAddSemester',
  props: {
    semesterId: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters(['getSemesterById', 'getSemesterIndex']),
  },
  methods: {
    ...mapActions(['insertSemester']),
    insert () {
      let semester = this.getSemesterById(this.semesterId)
      const index = this.getSemesterIndex(this.semesterId)

      semester = this.$_.cloneDeep(semester)
      semester.id = `${this.$uuidv4()}__new`
      semester.specialization = null
      semester.speciality = null

      this.insertSemester({ semester, index })
      this.$store.dispatch('studyPlans/setModified', true)
    },
  },
}
</script>
