<template>
  <pcg-search-select
      always-active
      :error-text="$t('general.errors.required')"
      :label="$tc('general.faculty', 1)"
      :options="faculties"
      :show-error="facultyError"
      show-label
      :value="facultyId"
      @input="setFaculty"
  />
</template>
<script>
import { getStudyData } from '../../../mixins/getStudyData'

export default {
  name: 'new-course-faculty',
  mixins: [getStudyData],
  props: {
    facultyError: { required: true, type: Boolean },
  },
  data () {
    return {
      facultyId: '',
    }
  },
  async created () {
    await this.getData(['faculties'])
  },
  methods: {
    setFaculty (facultyId) {
      this.facultyId = facultyId
      this.$emit('update:faculty', facultyId)
    },
  },
}
</script>
