<template>
  <prk-download-modal
      modal-id="standardPickerModal"
      :title="$t('components.prk_modals.standard_picker.name')"
      :description="$t('components.prk_modals.standard_picker.description')"
      :btn-title="$t('components.prk_modals.standard_picker.name')"
      :action="getStandardEffects"
  >
    <template>
      <b-row align-h="center" class="mt-4 mb-2">
        <b-col cols="auto">
          <prk-select
              color="aqua"
              :options="standards"
              :default-option="{ text: nameOfStandard }"
              @input="standardId = $event"
          >
          </prk-select>
        </b-col>
      </b-row>
    </template>
  </prk-download-modal>
</template>

<script>
import PrkDownloadModal from './PrkDownloadModal'
import PrkSelect from '../../PrkSelect'

export default {
  name: 'StandardPickerModal',
  components: {
    PrkDownloadModal,
    PrkSelect,
  },
  props: {
    courseName: { required: true, type: String },
  },
  jsonapi: {
    standards: {
      config: ({ url }) => ({
        method: 'get',
        url: url,
      }),
      variables () {
        return {
          url: this.url,
        }
      },
      update (standards) {
        return standards.map(standard => {
          return {
            value: standard.id,
            text: standard.name,
          }
        })
      },
    },
    standardEffects: {
      config: ({ url }) => ({
        method: 'get',
        url: url,
      }),
      variables () {
        return {
          url: this.standardEffectsUrl,
        }
      },
      fetchPolicy: 'no-cache',
      skip () {
        return !this.standardEffectsUrl.length
      },
      update (standardEffects) {
        return standardEffects.map(standardEffect => {
          return {
            ...this.$_.omit(standardEffect, 'id', '__type'),
            customId: this.$uuidv4(),
            toggleDetails: false,
            selected: false,
          }
        })
      },
      error (e) {
        this.$showError(this.$getErrors(e.errors))
      },
    },
  },
  data () {
    return {
      standardId: '',
      standards: [],
      standardEffects: [],
      standardEffectsUrl: '',
      url: '',
    }
  },
  computed: {
    nameOfStandard () {
      return this.$t('components.prk_modals.standard_picker.name_of_standard')
    },
  },
  watch: {
    standardEffects () {
      this.$emit('update:course-effects', this.standardEffects)
    },
    courseName: {
      handler () {
        this.url = `standards?${this.$qs.stringify({
          filters: { course_name: this.courseName },
        })}`
      },
      immediate: true,
    },
  },
  methods: {
    async getStandardEffects () {
      if (this.standardId.length) {
        this.standardEffectsUrl = `standard_effects?standard_id=${this.standardId}`
        this.$bvModal.hide('standardPickerModal')
      } else {
        this.$toastr.i(this.$t('views.courses.standard_effects.info'))
      }
      this.standardId = ''
    },
  },
}
</script>
