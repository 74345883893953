<template>
  <pcg-modal
      :action="action"
      :btn-title="btnTitle"
      icon="fas fa-plus"
      :modal-id="modalId"
      size="md"
      :title="titleModal"
      @hidden="$emit('hidden')"
  >
    <div class="d-flex align-items-center flex-wrap">
      <template v-for="(_, key) in this.attributes">
        <b-col :key="key" v-if="key !== 'id'" cols="12">
          <pcg-text-input
              class="w-100 mb-4_5"
              :label="label(key)"
              :error-text="errors[key]"
              :show-error="!!errors[key]"
              :value="$attrs[key]"
              @input="input(key, $event)"
          />
        </b-col>
      </template>
    </div>
    <slot></slot>
  </pcg-modal>
</template>

<script>
import useModal from '../../../composables/useModal'
import { dictionaryModals } from '../../../mixins/dictionary_modals'

export default {
  name: 'StructureModal',
  setup (props) {
    const { input } = useModal(props.errors)
    return { input }
  },
  mixins: [dictionaryModals],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    attributes () {
      return this.$_.pickBy(this.$attrs, x => typeof (x) !== 'boolean')
    },
  },
  methods: {
    label (key) {
      key = this.$_.snakeCase(key)
      return this.$t(`general.${key}`)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .form-group {
    margin-bottom: 1rem !important;
  }
}
</style>
