<template>
  <path
    d="M13.675 11.1519V0.943522C13.675 0.539442 13.352 0.214355 12.9505 0.214355H3.04948C1.44962 0.214355 0.151611 1.52078 0.151611 3.13102V12.8532C0.151611 14.4635 1.44962 15.7699 3.04948 15.7699H12.9505C13.352 15.7699 13.675 15.4448 13.675 15.0407V14.5546C13.675 14.3268 13.5694 14.1202 13.4063 13.9865C13.2796 13.5186 13.2796 12.1848 13.4063 11.717C13.5694 11.5863 13.675 11.3797 13.675 11.1519ZM4.01544 4.28554C4.01544 4.18528 4.09694 4.10324 4.19655 4.10324H10.596C10.6956 4.10324 10.7771 4.18528 10.7771 4.28554V4.89317C10.7771 4.99343 10.6956 5.07547 10.596 5.07547H4.19655C4.09694 5.07547 4.01544 4.99343 4.01544 4.89317V4.28554ZM4.01544 6.22998C4.01544 6.12972 4.09694 6.04769 4.19655 6.04769H10.596C10.6956 6.04769 10.7771 6.12972 10.7771 6.22998V6.83762C10.7771 6.93788 10.6956 7.01991 10.596 7.01991H4.19655C4.09694 7.01991 4.01544 6.93788 4.01544 6.83762V6.22998ZM11.6646 13.8255H3.04948C2.51519 13.8255 2.08352 13.391 2.08352 12.8532C2.08352 12.3185 2.5182 11.881 3.04948 11.881H11.6646C11.6073 12.4006 11.6073 13.3059 11.6646 13.8255Z"/>
</template>

<script>
export default {
  name: 'IconBook',
}
</script>
