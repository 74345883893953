<!-- TODO zmiejsz objętość pliku przenosząc niektóre elementy do osobnych komponentów -->
<template>
  <div>
    <b-row align-v="center" :class="rowClasses">
      <b-col cols="auto" class="mb-3">
        <span class="d-inline-block mr-2">
          {{ $t('general.type_of_term') }}
        </span>
        <span class="prk-fz-14 font-weight-bold pcg-main-color">
          {{ $t(`general.${semesterType}`) }}
        </span>
      </b-col>
      <new-subject-specialties :semester="semester" />
      <new-subject-specializations :semester="semester" />
    </b-row>
    <portal :disabled="!['lg', 'xl'].includes($mq)" to="studyPlanFormBeam">
      <b-row align-h="end">
        <new-subject-remove-semester
            v-if="!specialKind"
            :semester-id="semesterId"
        />
        <new-subject-add-semester
            v-if="!specialKind"
            :semester-id="semesterId"
        />
        <new-subject-add-subject @rerender="test += 1"/>
      </b-row>
    </portal>
    <subject-form-modal
        modal-id="add-subject"
        :semester-id="semesterId"
        :key="rerenderSubjectFormKey"
        @hidden="rerenderSubjectFormKey += 1"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import SubjectFormModal from '../modals/PrkSubjectFormModal'
import NewSubjectAddSemester from '../new_subject/NewSubjectAddSemester'
import NewSubjectAddSubject from '../new_subject/NewSubjectAddSubject'
import NewSubjectSpecializations from '../new_subject/NewSubjectSpecializations'
import NewSubjectSpecialties from '../new_subject/NewSubjectSpecialties'
import NewSubjectRemoveSemester from '../new_subject/NewSubjectRemoveSemester'

const { mapGetters } = createNamespacedHelpers('semesters')

export default {
  name: 'NewSubject',
  components: {
    NewSubjectRemoveSemester,
    NewSubjectSpecialties,
    NewSubjectSpecializations,
    NewSubjectAddSubject,
    NewSubjectAddSemester,
    SubjectFormModal,
  },
  props: {
    semesterId: {
      required: true,
      type: String,
    },
    specialKind: {
      required: true,
      type: Boolean,
    },
  },
  data () {
    return {
      rerenderSubjectFormKey: 0,
    }
  },
  computed: {
    ...mapGetters(['getSemesterType', 'getSemesterById']),
    semesterType () {
      return this.getSemesterType(this.semesterId)
    },
    rowClasses () {
      return 'prk-fz-12 pcg-the-darkest-gray-color justify-content-between ' +
          'justify-content-lg-start'
    },
    semester () {
      return this.getSemesterById(this.semesterId)
    },
  },
}
</script>
