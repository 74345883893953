<template>
  <div class="templates-list mb-5">
    <syllabus-box>
      <template #header>
        <pcg-btn
            variant="additional"
            class="m-0 mb-4 w-100 w-sm-auto font-weight-bold"
            @click="showNewSyllabusForm"
        >
          {{ $t('general.new_template') }}
        </pcg-btn>
      </template>
    </syllabus-box>
    <prk-table
        :items="items"
        :excluded-fields="excludedFields"
        :show-headline="false"
        class="mb-4"
    >
      <template v-slot:head(id)>
        <pcg-checkbox :value="checkedAll" @input="select"/>
      </template>
      <template v-slot:content="{ header, data}">
        <pcg-checkbox
            v-if="header.key === 'id'"
            v-model="data.item.selected"
        />
        <div v-else-if="header.key === 'actions'" class="d-flex align-items-center justify-content-end">
          <pcg-switch v-model="data.item.archived"
                      class="ml-4 mr-2" style="width: 32px"
                      @input="archiveSyllabusTemplate(data.item.id, $event)"
          />
          <span class="ml-2 mr-3 font-weight-bold"
                :class="{ 'pcg-main-active-color': data.item.archived,
                                'pcg-gray-color': !data.item.archived }"
          >
                    {{ $t('general.archive') }}
                  </span>
          <pcg-btn round text="copy" class="mr-3 prk-button" :icon="iconCopy"
                   @click="copyTemplate(data.item)"/>
          <div style="width: 40px" class="mr-3">
            <pcg-btn round text="edit" class="prk-button" :icon="iconEdit"
                     @click="editTemplate(data.item)"/>
          </div>
          <div style="width: 40px">
            <pcg-btn v-if="data.item.canDestroy" round :icon="iconRemove" text="delete"
                     class="mr-4 prk-button" variant="danger"
                     @click="destroy(data.item.id, data.index)"/>
          </div>

        </div>
      </template>
    </prk-table>
    <base-beam v-if="getSelected(items).length > 0">
      <selected-elements-beam
          :selected-length="getSelected(items).length"
          type="syllabus-template"
          @remove:elements="destroy"
      />
    </base-beam>
  </div>
</template>

<script>
import PrkTable from '../../components/PrkTable'
import SyllabusBox from '../../components/syllabus/PrkSyllabusBox'
import SelectedElementsBeam from '../../components/beams/SelectedElementsBeam'
import useList from '../../composables/useList'
import useIcon from '../../composables/useIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'TemplatesList',
  setup (props, { root }) {
    const {
      checkedAll,
      items,
      getSelected,
      setItems,
      select,
      removeRow,
      removeRows,
    } = useList(root)
    const { iconCopy, iconEdit, iconRemove } = useIcon(root)
    return {
      checkedAll,
      iconCopy,
      iconEdit,
      iconRemove,
      items,
      getSelected,
      setItems,
      select,
      removeRow,
      removeRows,
    }
  },
  components: {
    PrkTable,
    SelectedElementsBeam,
    SyllabusBox,
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    syllabusIds () {
      return this.getSelected(this.items).map(syllabus => syllabus.id)
    },
    excludedFields () {
      return ['status', 'matrixSubjectNameId', 'matrixId', 'logo', 'canDestroy', 'selected', 'archived', 'language', 'showTrainingCycle']
    },
  },
  created () {
    this.getSyllabusTemplates()
  },
  methods: {
    async archiveSyllabusTemplate (id, archived) {
      await this.$api.put(`syllabuses/${id}/set_archived`, {
        archived,
      })
    },

    cleanAndPush (item, suffix) {
      localStorage.setItem('sections', JSON.stringify([]))
      localStorage.setItem('syllabus', JSON.stringify([]))
      this.$router.push(`/syllabuses/${item.id}/${suffix}`)
    },

    copyTemplate (item) {
      this.cleanAndPush(item, 'template_copy')
    },

    editTemplate (item) {
      this.cleanAndPush(item, 'template_edit')
    },

    async getSyllabusTemplates () {
      this.loading = true
      try {
        let { data: templates } = await this.$api.get('syllabuses/index_templates')
        templates = templates.map(template => {
          return {
            id: template.id,
            selected: false,
            ...template.attributes,
          }
        })
        this.setItems(templates)
      } catch (error) {
        setTimeout(() => {
          this.$toastr.e(this.$t('general.check_errors'))
        }, 1)
      } finally {
        this.loading = false
      }
    },

    async destroy (id, index) {
      this.loading = true
      const isSingleResource = !!id

      if (isSingleResource) {
        const removeHandler = async () => {
          await this.$api.destroy(`syllabuses/${id}`)
        }
        await this.removeRow(index, null, removeHandler)
      } else {
        const removeAllHandler = () => {
          this.$api.destroy('syllabuses/destroy_all', {
            params: {
              ids: this.syllabusIds,
            },
          })
        }
        this.removeRows(removeAllHandler)
      }
    },

    showNewSyllabusForm () {
      localStorage.setItem('sections', JSON.stringify([]))
      localStorage.setItem('syllabus', JSON.stringify([]))
      this.$router.push('/syllabuses/new')
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.syllabus.syllabuses_templates'),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => vm.setHeader())
  },
  watch: {
    contrast () {
      const { iconCopy, iconEdit, iconRemove } = useIcon(this.$root)
      this.iconCopy = iconCopy
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
.row .col {
  overflow-x: auto;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
