<template>
  <base-beam>
    <div class="d-flex align-items-center justify-content-end h-100">
      <download-pdf-button v-show="ids.length" :download-pdf="downloadPdf"/>
      <pcg-btn variant="additional" size="small" v-b-modal.file-type>
        {{ $t('general.download_effects_check_report') }}
      </pcg-btn>
      <pcg-btn size="small" @click="save">
        {{ $t('general.save') }}
      </pcg-btn>
      <pcg-btn size="small" variant="additional"
               v-b-modal.generateSyllabusModal
               :disabled="edited"
      >
        {{ $tc('general.generate_syllabuses', 2) }}
      </pcg-btn>
      <pcg-btn
          v-if="subjectsActive && matrixSubjectNameIds.length > 0"
          size="small" variant="additional"
          @click="remove"
      >
        {{ $t('general.remove_selected') }}
      </pcg-btn>
    </div>
    <file-type :download="downloadEffectsCheckReport" />
  </base-beam>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import DownloadPdfButton from '../DownloadPdfButton'
import { inject } from '@vue/composition-api'
import api from '../../api'
import FileType from '../modals/FileType'

const { mapActions } = createNamespacedHelpers('matrices')

export default {
  name: 'matrix-beam',
  setup () {
    const edited = inject('edited', false)
    return { edited }
  },
  components: { FileType, DownloadPdfButton },
  props: {
    matrixSubjectNameIds: Array,
    removeMatrixSubjectNames: Function,
    subjectsActive: Boolean,
    updateMatrix: Function,
    ids: { type: Array, default: () => ([]) },
  },
  methods: {
    ...mapActions(['setModified']),
    remove () {
      this.removeMatrixSubjectNames()
      this.setModified(true)
    },
    save () {
      this.updateMatrix(false)
      this.setModified(false)
    },
    downloadPdf () {
      const data = {
        ids: this.ids,
        matrix_id: this.$route.params.id,
      }

      api.post('matrix_subject_names/download_pdf', data,
        { responseType: 'blob' }).then((response) => {
        this.$downloadFile(response, 'pdf')
      })
    },
    downloadEffectsCheckReport (fileType) {
      const data = { matrix_id: this.$route.params.id, file_type: fileType }
      api.post('/effects/download_matrix_effects_check_report', data,
        { responseType: 'blob' }).then((response) => {
        this.$downloadFile(response, fileType, 'Raport - kontrola efektów')
      })
    },
  },
}
</script>
