<template>
  <div class="permission-path">
    <b-row>
      <b-col cols="10">
        <permission-roles
            :roles="$attrs.roles"
            @update:roles="$emit('update:roles', $event)"
        />

        <div class="mt-3 d-flex">
          <prk-statuses-select
              :status="$attrs.from"
              :options-data="statuses"
              class-name="light"
              @input="$emit('update:from', $event)"
          />
          <img :src="require('../../assets/images/icons/vector.svg')" alt="missing.icon" class="mx-5"/>
          <prk-statuses-select
              :status="$attrs.to"
              :options-data="statuses"
              class-name="light"
              @input="$emit('update:to', $event)"
          />
        </div>

      </b-col>
      <b-col cols="2" class="d-flex justify-content-end align-items-end">
        <remove-path @click="$emit('remove-path', $attrs.id)"/>
      </b-col>
    </b-row>
    <prk-horizontal-line class="my-4" :width="{ value: -28, unit: 'px' }"/>
  </div>
</template>

<script>
import PrkHorizontalLine from '../../components/syllabus/PrkHorizontalLine'
import PrkStatusesSelect from '../../components/PrkStatusesSelect'
import RemovePath from '../../components/permissions/RemovePath'
import PermissionRoles from '../../components/permissions/PermissionRoles'

export default {
  name: 'PermissionPath',
  components: {
    PermissionRoles,
    RemovePath,
    PrkStatusesSelect,
    PrkHorizontalLine,
  },
  props: {
    statuses: { required: true, type: Array },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .pcg-select {
    width: 210px;
  }
}
</style>
