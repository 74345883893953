<template>
  <div>
    <pcg-search-select
        always-active
        v-model="searchValue"
        :options="educators"
        @input="addLeaderToVuex"
        class="w-100 w-lg-50 pl-3 pl-lg-0"
    />
    <prk-tag
        class="pt-0 mt-4"
        :value="leaders"
        @removed="removeLeader"
    />
  </div>
</template>
<script>
import PrkTag from '../../../../../PrkTags'
import useEducators from '../../../../../../composables/useEducators'
import { mapActions, mapState } from 'vuex'
export default {
  name: 'basic-information-about-subject-search-select',
  setup (_, { root }) {
    const { mapEducators } = useEducators(root)
    return { mapEducators }
  },
  components: { PrkTag },
  props: {
    information: { required: true, type: String },
  },
  data () {
    return {
      educators: [],
      searchValue: '',
    }
  },
  computed: {
    ...mapState('syllabusFill', ['leaders']),
  },
  created () {
    if (this.information === 'leading_persons') this.getEducators()
  },
  methods: {
    ...mapActions('syllabusFill', ['addLeader', 'removeLeader']),
    addLeaderToVuex (id) {
      this.searchValue = ''
      const educator = this.educators.find(edu => edu.value === id)
      if (educator) this.addLeader(educator)
    },
    async getEducators () {
      const { data: educators } = await this.$api.get('educators')
      this.educators = this.mapEducators(educators)
    },
  },
}
</script>
