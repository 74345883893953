<template>
  <pcg-modal modal-id="downloadCourseEffectListModal"
             size="lg"
             :btn-title="$tc('general.add_effect', 2)"
             icon="pcg-icon-document"
             :title="$t('general.clo.list')"
             :action="addEffects"
  >
    <prk-table :items="items"
               :show-headline="false"
               :excluded-fields="['selected']"
               disable-actions
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: field.key === 'id' ? '5%' : '' }"
        >
      </template>
      <template v-slot:head(id)>
        <pcg-checkbox :value="checkedAll" @input="select"/>
      </template>
      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'id'">
          <pcg-checkbox v-model="data.item.selected"/>
        </div>
      </template>
    </prk-table>
    <template v-slot:button-before>
      <pcg-btn @click="$bvModal.hide('downloadCourseEffectListModal')" variant="additional">
        {{ $t('general.back') }}
      </pcg-btn>
    </template>
  </pcg-modal>
</template>

<script>
import PrkTable from '../../PrkTable'
import useList from '../../../composables/useList'
import { inject } from '@vue/composition-api'

export default {
  name: 'DownloadCourseEffectListModal',
  components: { PrkTable },
  setup (props, { root }) {
    const omittedId = inject('courseId')
    const {
      select,
      checkedAll,
      items,
      setItems,
      getSelected,
    } = useList(root)
    return {
      items,
      setItems,
      select,
      checkedAll,
      omittedId,
      getSelected,
    }
  },
  props: {
    courses: Array,
    action: Function,
  },
  watch: {
    courses (newVal) {
      this.setItems(newVal.filter(keu => keu.id !== this.omittedId))
    },
  },
  methods: {
    addEffects () {
      this.getSelected(this.items).forEach(item => this.action(item.id))
      this.$bvModal.hide('downloadCourseEffectListModal')
      this.$bvModal.hide('downloadModal')
    },
  },
}
</script>
