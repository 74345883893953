<template>
  <div class="dictionaries">
    <transition name="slide" mode="out-in">
      <router-view v-if="$route.fullPath.match('/dictionaries/standards')" />
      <router-view v-else :key="$route.fullPath" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Dictionary',
  destroyed () {
    localStorage.removeItem('facultyId')
    localStorage.removeItem('faculty')
  },
}
</script>
