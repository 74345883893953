<template>
  <b-col sm="12" md="6" class="mt-3">
    <prk-select
        color="aqua"
        :default-option="defaultOption('kinds')"
        :include-blank="true"
        :error-text="kindError"
        :label="$t('components.prk_modals.add_matrix.form')"
        :options="kinds"
        :show-error="!!kindError"
        :value="$attrs.kind"
        @input="input('kind', $event)"
    />
  </b-col>
</template>

<script>
import { newMatrixModal } from '../../../../mixins/new_matrix_modal'

export default {
  name: 'NewMatrixKind',
  mixins: [newMatrixModal],
  props: {
    typeId: { required: true, type: String },
    kindError: { required: true, type: String },
  },
  watch: {
    typeId () {
      this.getData(['kinds'], null, { kinds: { type_id: this.typeId } })
    }
  }
}
</script>
