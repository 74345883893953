<template>
  <div>
    <prk-select
        :options="formOptions"
        :default-option="defaultOption"
        color="aqua"
        ref="dropdown"
        class="assessment"
        style="width: max-content"
    >
      <template v-slot="{options}">
        <b-dd-form>
          <div
              v-for="(option,index) in options"
              :key="index"
              class="d-flex align-items-center py-3 pointer"
          >
            <pcg-checkbox :value="checkValue(option.value)"
                          @input="selectOption($event, option.value)"/>
            <span class="prk-fz-12 font-weight-bold pcg-gray-color text-nowrap">
                {{ option.text }}
            </span>
          </div>
        </b-dd-form>
      </template>
    </prk-select>

    <prk-tag
        :value="forms"
        class="mt-3 mt-sm-0"
        @removed="removeTag"
    />
  </div>
</template>

<script>
import PrkSelect from '../../../PrkSelect'
import PrkTag from '../../../PrkTags'
import { mapActions } from 'vuex'

export default {
  name: 'PrkAssessmentMethodsSelect',
  components: {
    PrkTag,
    PrkSelect,
  },
  props: {
    forms: Array,
    formOptions: Array,
    effectId: String,
    credit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    defaultOption () {
      if (this.forms.length === 1) {
        return { text: this.forms[0].text }
      } else if (this.forms.length > 1) {
        return { text: `${this.forms.length} ${this.$t('general.selected_methods')}` }
      } else {
        return { text: this.$t('general.choose') }
      }
    },
  },
  methods: {
    ...mapActions('syllabusFill', ['addForm', 'removeForm']),
    checkValue (id) {
      return this.forms.map(form => form.value).includes(id)
    },
    selectOption (selected, id) {
      if (selected) {
        const text = this.formOptions.find(credit => credit.value === id).text
        this.addForm({
          effectId: this.effectId,
          formOfCredit: this.credit,
          form: {
            text: text,
            value: id,
          },
        })
      } else {
        this.removeForm({
          formOfCredit: this.credit,
          effectId: this.effectId,
          formId: id,
        })
      }
    },
    removeTag (id) {
      this.removeForm({
        formOfCredit: this.credit,
        effectId: this.effectId,
        formId: id,
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .tags-container {
    padding-top: 0;
    margin: 0;

    .tag {
      margin-bottom: 0;
      margin-top: 0.55rem;
    }
  }
}
</style>
