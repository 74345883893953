import useDefaultOption from './useDefaultOption'
export default function (root) {
  const { getDefaultOption } = useDefaultOption(root, 'selected_roles')
  const mapEducators = (educators) => {
    educators = educators.map(educator => {
      return {
        text: `${educator.attributes.firstName} ${educator.attributes.lastName}`,
        value: educator.id,
        avatar: educator.attributes.avatar,
        abbr: educator.attributes.abbr,
        roles: _getRoles(educator.attributes.roles),
      }
    })
    educators.forEach(educator => (educator.defaultOption = getDefaultOption(educator.roles)))
    return educators
  }

  const getEducatorData = (addedEducators) => {
    return addedEducators.map(educator => {
      return {
        id: educator.value,
        roles: educator.roles.filter(role => role.selected).map(r => r.role),
      }
    })
  }

  const _getRoles = (educatorRoles) => {
    const ROLES = ['supervisor', 'responsible_person', 'coordinator']
    return ROLES.map((role, index) => {
      return {
        text: root.$t(`general.${role}`),
        value: index,
        selected: educatorRoles.includes(role),
        role: role,
      }
    })
  }

  return {
    getDefaultOption,
    getEducatorData,
    mapEducators,
  }
}
