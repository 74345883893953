<template>
  <b-col cols="12">
    <prk-select
        color="aqua"
        :default-option="defaultOption('courses')"
        :error-text="courseError"
        :label="$tc('general.yearbook_of_course_learning_outcomes', 1)"
        :options="courses"
        :show-error="!!courseError"
        :value="$attrs.course"
        @input="input('course', $event)"
    />
  </b-col>
</template>

<script>
import useCourses from '../../../../composables/useCourses'
import { newMatrixModal } from '../../../../mixins/new_matrix_modal'

export default {
  name: 'NewMatrixCourse',
  setup (props, { root }) {
    const { getCourses, mapCoursesForSearch } = useCourses(root)
    return { getCourses, mapCoursesForSearch }
  },
  mixins: [newMatrixModal],
  props: {
    courseError: { required: true, type: String },
    matrix: { required: true, type: Object },
    copied: { type: Boolean, default: false },
  },
  watch: {
    copied: {
      handler () {
        this.findCourses()
      },
      immediate: true,
    },
  },
  created () {
    this.$watch(vm => [
      vm.matrix.facultyId,
      vm.matrix.courseNameId,
      vm.matrix.typeId,
      vm.matrix.studyProfileId,
      vm.matrix.specialityId,
    ], async () => {
      this.courses = []
      await this.findCourses()
    })
  },
  methods: {
    checkValues () {
      return ['facultyId', 'courseNameId', 'studyProfileId', 'typeId'].every(el => {
        return this.matrix[el] !== ''
      })
    },
    async findCourses () {
      if (this.checkValues()) {
        const params = this.getCoursesParams()
        this.courses = await this.getCourses('', params)
        this.courses = this.mapCoursesForSearch(this.courses)
      }
    },
    getCoursesParams () {
      return {
        faculty_id: this.matrix.facultyId,
        course_name_id: this.matrix.courseNameId,
        type_id: this.matrix.typeId,
        study_profile_id: this.matrix.studyProfileId,
        speciality_id: this.matrix.specialityId,
      }
    },
  },
}
</script>
