<template>
  <div class="d-flex">
    <prk-select
        :options="languages"
        color="aqua"
        class="w-100 w-lg-auto pl-3 pl-lg-0"
        :default-option="languages[0]"
        :value="Number(value)"
        @input="$emit('input', $event)"
    />
  </div>
</template>
<script>
import PrkSelect from '../../../../../PrkSelect'
import { inject } from '@vue/composition-api'

export default {
  name: 'basic-information-about-subject-select',
  setup () {
    const languages = inject('languages')
    return { languages }
  },
  components: { PrkSelect },
  props: {
    value: { type: [String, Number], default: '' },
  },
}
</script>
