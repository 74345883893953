<template>
  <syllabus-box>
    <template #content>
      <b-row>
        <b-col cols="12" lg="6" xl="4">
          <pcg-search-select
              class="mb-3 subject-select"
              style="position: relative; bottom: .5rem;"
              color="aqua"
              autocomplete="on"
              always-active
              :placeholder="$t('general.choose')"
              show-label
              :include-blank="true"
              :default-option="{ text: $t('general.choose') }"
              v-model="coordinator"
              :label="$t('general.coordinators')"
              :options="filteredFilters.coordinators.values"
              @input="updateFilters($event, 'coordinator_id')"
          />
        </b-col>
        <b-col cols="12" lg="6" xl="4" class="d-flex justify-content-center">
          <prk-date
              class="mb-3 mb-lg-0"
              @update:academicYear="updateFilters($event, 'academic_year')"
          />
        </b-col>
        <b-col cols="12" lg="6" xl="4">
        </b-col>
      </b-row>
      <b-row class="d-flex flex-wrap">
        <b-col cols="12" lg="6" xl="4"
               v-for="key in Object.keys(filteredFilters).filter(key => key !== 'coordinators')"
               :key="key"
               class="mb-3">
          <pcg-search-select
              v-if="key === 'responsiblePersons'"
              autocomplete="on"
              always-active
              :placeholder="$t('general.choose')"
              show-label
              class="subject-select"
              color="aqua"
              :include-blank="true"
              :default-option="{ text: $t('general.choose') }"
              v-model="responsiblePerson"
              :label="$t('general.responsible_person_syllabus')"
              :options="filteredFilters.responsiblePersons.values"
              @input="updateFilters($event, `${$pluralize($_.snakeCase(key), 1)}_id`)"
          />
          <prk-select
              v-else-if="key === 'faculty'"
              color="aqua"
              :default-option="{ text: $t('general.choose') }"
              :include-blank="true"
              :value="facultyId"
              :label="$tc(`general.${$pluralize($_.snakeCase(key), 1)}`, 1)"
              :options="filteredFilters.faculty.values"
              @input="updateFilters($event, `${$pluralize($_.snakeCase(key), 1)}_id`)"
          />
          <prk-select
              v-else-if="key === 'courseName'"
              color="aqua"
              :default-option="{ text: $t('general.choose') }"
              :include-blank="true"
              :value="courseNameId"
              :label="$tc(`general.${$pluralize($_.snakeCase(key), 1)}`, 1)"
              :options="filteredFilters.courseName.values"
              @input="updateFilters($event, `${$pluralize($_.snakeCase(key), 1)}_id`)"
          />
          <pcg-search-select
              v-else-if="key === 'subjectName'"
              :options="filteredFilters.subjectName.values"
              autocomplete="on"
              always-active
              show-label
              v-model="subjectName"
              :placeholder="$t('general.choose')"
              @input="updateFilters($event, `${$pluralize($_.snakeCase(key), 1)}_id`)"
              class="subject-select"
              :label="$t('general.syllabus_subject_name')"
          />
          <prk-select
              v-else-if="key === 'studyProfile'"
              color="aqua"
              :default-option="{ text: $t('general.choose') }"
              :include-blank="true"
              :value="studyProfileId"
              :label="$tc(`general.${$pluralize($_.snakeCase(key), 1)}`, 1)"
              :options="filteredFilters.studyProfile.values"
              @input="updateFilters($event, 'study_profile_id')"
          />
          <pcg-search-select
              v-else-if="key === 'speciality'"
              :options="filteredFilters.speciality.values"
              autocomplete="on"
              always-active
              show-label
              v-model="speciality"
              :placeholder="$t('general.choose')"
              @input="updateFilters($event, `${$pluralize($_.snakeCase(key), 1)}_id`)"
              class="subject-select"
              :label="$tc(`general.${$pluralize($_.snakeCase(key), 1)}`, 1)"
          />
          <prk-select
              v-else-if="key === 'status'"
              class="statuses"
              color="aqua"
              :default-option="filteredFilters.status.values[0]"
              :value="status"
              :label="$tc('general.status')"
              :options="filteredFilters.status.values"
              @input="updateFilters($event, 'status')"
          >
            <template v-slot:button-text="{ text, className }">
              <status :status="text" :class-name="className"/>
            </template>
            <template v-slot:item-text="{ option }">
              <status :status="option.text" :class-name="option.class"/>
            </template>
          </prk-select>
          <prk-select
              v-else-if="key === 'semesterType'"
              color="aqua"
              :value="semesterType"
              :default-option="{ text: $t('general.choose') }"
              :label="$tc(`general.${$pluralize($_.snakeCase(key), 1)}`, 1)"
              :options="filteredFilters.semesterType.values"
              @input="updateFilters($event,'semester_type')"
          />
          <prk-select
              v-else-if="key === 'trainingCycle'"
              color="aqua"
              :value="trainingCycle"
              :default-option="{ text: $t('general.choose') }"
              :label="$tc('general.training_cycle_start')"
              :options="filteredFilters.trainingCycle.values"
              @input="updateFilters($event,'training_cycle')"
          />
        </b-col>
      </b-row>
    </template>
  </syllabus-box>
</template>

<script>
import SyllabusBox from '../PrkSyllabusBox'
import PrkSelect from '../../PrkSelect'
import Status from './syllabuses_list_details/Status'
import PrkDate from '../../PrkDate'
import { getStudyData } from '../../../mixins/getStudyData'
import { mapGetters } from 'vuex'
import { filtersByRole } from '../../../mixins/filters_by_role'

export default {
  name: 'SyllabusesListFilters',
  components: {
    PrkDate,
    Status,
    PrkSelect,
    SyllabusBox,
  },
  mixins: [getStudyData, filtersByRole],
  jsonapi: {
    coordinators: {
      config: {
        method: 'get',
        url: 'educators?responsible_persons=true',
      },
      update (educators) {
        return educators.map(educator => {
          return {
            text: `${educator.lastName} ${educator.firstName}`,
            value: educator.id,
          }
        })
      },
    },
    responsiblePersons: {
      config: {
        method: 'get',
        url: 'educators?coordinators=true',
      },
      update (leadingPersons) {
        return leadingPersons.map(leadingPerson => {
          return {
            text: `${leadingPerson.lastName} ${leadingPerson.firstName}`,
            value: leadingPerson.id,
          }
        })
      },
    },
    subjectNames: {
      config: {
        method: 'get',
        url: 'subject_names?raw=true',
      },
      update (subjectNames) {
        return subjectNames.map(subjectName => {
          return {
            text: subjectName.name,
            value: subjectName.id,
          }
        })
      },
    },
  },
  props: {
    syllabusFilters: { required: false, type: Object },
  },
  data () {
    return {
      facultyId: '',
      courseNameId: '',
      coordinator: '',
      coordinators: [],
      responsiblePerson: '',
      responsiblePersons: [],
      subjectNames: [],
      subjectName: '',
      speciality: '',
      studyProfileId: '',
      status: '',
      semesterType: '',
      trainingCycle: '',
    }
  },
  watch: {
    subjectNames () {
      this.filters.subjectName.values = this.subjectNames
    },
    coordinators () {
      this.filters.coordinators.values = this.coordinators
    },
    responsiblePersons () {
      this.filters.responsiblePersons.values = this.responsiblePersons
    },
    facultyId (newVal) {
      this.getData(null, ['faculties', 'courses'],
        {
          course_names: { faculty_id: newVal },
        })
      this.filters.courseName.values = this.courseNames
      this.filters.studyProfile.values = this.studyProfiles
      this.filters.speciality.values = this.specialities
    },
    courseNameId (newValue) {
      this.getData(['subject_names', 'specialities'], null, {
        subject_names: { course_name_id: newValue },
        specialities: { course_name_id: newValue },
      })
      this.filters.subjectName.values = this.subjectNames
    },
    syllabusFilters: {
      deep: true,
      handler () {
        this.updateSyllabusFilters()
      },
    },
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    filteredFilters () {
      const filters = Object.fromEntries(Object.entries(this.filters).filter(el => el[1].roles.includes(this.currentRole)))
      const newValue = { text: 'Wybierz', value: null }
      let exists = filters.subjectName.values.some(item => item.text === newValue.text && item.value === newValue.value)
      if (!exists) {
        filters.subjectName.values.unshift(newValue)
      }
      exists = filters.speciality.values.some(item => item.text === newValue.text && item.value === newValue.value)
      if (!exists) {
        filters.speciality.values.unshift(newValue)
      }
      exists = filters.coordinators.values.some(item => item.text === newValue.text && item.value === newValue.value)
      if (!exists) {
        filters.coordinators.values.unshift(newValue)
      }
      exists = filters.responsiblePersons.values.some(item => item.text === newValue.text && item.value === newValue.value)
      if (!exists) {
        filters.responsiblePersons.values.unshift(newValue)
      }
      const currentYear = new Date().getFullYear()
      const startYear = currentYear + 1
      const endYear = 2017

      for (let year = startYear; year >= endYear; year--) {
        const newCycle = { text: `${year}/${year + 1}`, value: `${year}/${year + 1}` }
        const cycleExists = filters.trainingCycle.values.some(item => item.text === newCycle.text && item.value === newCycle.value)
        if (!cycleExists) {
          filters.trainingCycle.values.push(newCycle)
        }
      }
      return filters
    },
  },
  async created () {
    await this.getData(['faculties'])
    this.filters.faculty.values = this.faculties
    setTimeout(() => {
      this.updateSyllabusFilters()
    }, 1000)
  },
  methods: {
    updateFilters (value, field) {
      if (field === 'subject_name_id') {
        const subjectName = this.subjectNames.find(subjectName => {
          return subjectName.value === value
        })
        if (subjectName.text === 'Wybierz') {
          this.subjectName = ''
          this.$emit('update:filters', null ?? '', 'subject_name')
        } else {
          this.subjectName = subjectName.value
          this.$emit('update:filters', subjectName?.text ?? '', 'subject_name')
        }
      } else if (field === 'speciality_id') {
        const speciality = this.specialities.find(speciality => {
          return speciality.value === value
        })
        if (speciality.text === 'Wybierz') {
          this.speciality = ''
          this.$emit('update:filters', null ?? '', 'speciality_id')
        } else {
          this.speciality = speciality.value
          this.$emit('update:filters', speciality?.value ?? '', 'speciality_id')
        }
      } else if (field === 'coordinator_id') {
        const coordinator = this.coordinators.find(coordinator => {
          return coordinator.value === value
        })
        if (coordinator.text === 'Wybierz') {
          this.coordinator = ''
          this.$emit('update:filters', null ?? '', 'coordinator_id')
        } else {
          this.coordinator = coordinator.value
          this.$emit('update:filters', coordinator?.value ?? '', 'coordinator_id')
        }
      } else if (field === 'responsible_person_id') {
        const responsiblePerson = this.responsiblePersons.find(responsiblePerson => {
          return responsiblePerson.value === value
        })
        if (responsiblePerson.text === 'Wybierz') {
          this.responsiblePerson = ''
          this.$emit('update:filters', null ?? '', 'responsible_person_id')
        } else {
          this.responsiblePerson = responsiblePerson.value
          this.$emit('update:filters', responsiblePerson?.value ?? '', 'responsible_person_id')
        }
      } else {
        this[this.$_.camelCase(field)] = value
        this.$emit('update:filters', value, field)
      }
    },
    text (dropdown) {
      return dropdown.defaultText || dropdown.values[dropdown.selected].text
    },
    select (dropdown, index) {
      dropdown.selected = index
      dropdown.defaultText = ''
    },
    updateSyllabusFilters () {
      if (this.syllabusFilters.coordinator_id) {
        this.coordinator = this.syllabusFilters.coordinator_id
      }
      if (this.syllabusFilters.responsible_person_id) {
        this.responsiblePerson = this.syllabusFilters.responsible_person_id
      }
      if (this.syllabusFilters.faculty_id) {
        this.facultyId = this.syllabusFilters.faculty_id
      }
      if (this.syllabusFilters.speciality_id) {
        this.speciality = this.syllabusFilters.speciality_id
      }
      if (this.syllabusFilters.status) {
        this.status = this.syllabusFilters.status
      }
      if (this.syllabusFilters.semester_type) {
        this.semesterType = this.syllabusFilters.semester_type
      }
      if (this.syllabusFilters.training_cycle) {
        this.trainingCycle = this.syllabusFilters.training_cycle
      }
      setTimeout(() => {
        if (this.syllabusFilters.course_name_id) {
          this.courseNameId = this.syllabusFilters.course_name_id
        }
        if (this.syllabusFilters.subject_name) {
          const foundSubject = this.subjectNames.find(subject => subject.text === this.syllabusFilters.subject_name)
          this.subjectName = foundSubject.value
        }
        if (this.syllabusFilters.study_profile_id) {
          this.studyProfileId = this.syllabusFilters.study_profile_id
        }
      }, 1000)
    },
  },
}
</script>

<style scoped lang="scss">
@import '../../../assets/stylesheets/vars';

::v-deep {
  .syllabus-box.box-container .box {
    box-shadow: none;
    border-radius: 0;
  }

  .pcg-select {
    .dropdown-menu {
      width: 100%;
    }
  }
}

.subject-select {
  ::v-deep .pcg-input::placeholder {
    font-weight: bold;
    color: $main-active-color;
  }
  ::v-deep .pcg-input {
    font-weight: bold;
    color: $main-active-color;
  }
}
</style>
