<template>
  <pcg-modal
      modal-id="confirm-copying-syllabus"
      size="sm"
      :btn-title="$t('general.yes')"
      btn-class="w-50"
      :action="save"
      @hidden="copyMethod = ''"
  >
    <section class="mb-3">
      <h1 class="prk-fz-18 font-weight-bold pcg-the-darkest-gray-color">
        {{ $t('components.prk_modals.confirm_copying_syllabus.text') }}
      </h1>
    </section>

    <section>
      <h2 class="prk-fz-14 font-weight-bold pcg-the-darkest-gray-color">
        {{ $t('views.syllabus.copy.method') }}:
      </h2>
      <confirm-copying-syllabus-copy-methods
          class="mt-3"
          @update:copy-method="copyMethod = $event"
      />
    </section>
    <template #button-before>
      <pcg-btn
          class="w-50" variant="additional"
          @click="hide"
      >
        {{$t ('general.no') }}
      </pcg-btn>
    </template>
  </pcg-modal>
</template>

<script>
import ConfirmCopyingSyllabusCopyMethods from './confirm_copying_syllabus/ConfirmCopyingSyllabusCopyMethods'

export default {
  name: 'ConfirmCopyingSyllabus',
  components: { ConfirmCopyingSyllabusCopyMethods },
  data () {
    return {
      copyMethod: '',
    }
  },
  methods: {
    save () {
      if (!this.copyMethod.length) {
        this.$toastr.w(this.$t('views.syllabus.copy.need_to_choose_copy_method'))
        return
      }
      this.$emit('save', this.copyMethod)
      this.$bvModal.hide('confirm-copying-syllabus')
      this.$bvModal.hide('copy-syllabus')
    },
    hide () {
      this.$bvModal.hide('confirm-copying-syllabus')
    },
  },
}
</script>
