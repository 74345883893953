<template>
  <div v-if="isAdministrator" class="d-flex align-items-center">
    <pcg-switch
        class="custom-switch"
        :value="$attrs.active"
        @input="update($event)"
    />
    <div class="ml-3 font-weight-bold pcg-main-active-color">
      <span v-if="$attrs.active">
        {{ $tc('general.active', 1) }}
      </span>
      <span v-else class="pcg-gray-color">
        {{ $tc('general.inactive', 1) }}
      </span>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const { mapGetters } = createNamespacedHelpers('auth')
export default {
  name: 'ActiveSwitch',
  props: {
    updateResource: { required: true, type: Function },
    url: { required: true, type: String },
    item: { required: true, type: Object },
  },
  computed: {
    ...mapGetters(['currentRole']),
    isAdministrator () {
      return this.currentRole === 'admin'
    },
  },
  methods: {
    update (event) {
      this.updateResource(this.item, this.url)
      this.$emit('update:active', event)
    },
  },
}
</script>
