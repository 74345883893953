export default {
  computed: {
    staticLinks () {
      return [
        {
          id: 13,
          name: this.$t('components.prk_navigation_menu.static_pages'),
          path: 'root_path',
          iconClass: 'fas fa-tools',
          roles: ['career_office'],
          sub: [
            {
              id: 1302,
              name: this.$t('components.prk_navigation_menu.components'),
              path: 'pcg_components_path',
              iconClass: 'pcg-icon-document',
              roles: ['career_office'],
              sub: []
            }
          ]
        }
      ]
    },
  }
}
