<template>
  <i class="pcg-icon-cross ml-3 pt-md-4 mt-5 mt-md-0"
     v-b-popover.hover.top="$t('general.remove_form_of_classes')"
     @click="removeForm"
  />
</template>

<script>
export default {
  name: 'RemoveSubjectForm',
  props: {
    editedSubjectForms: { required: true, type: Array },
  },
  methods: {
    removeForm () {
      if (this.editedSubjectForms.filter(form => !form._destroy).length === 1) {
        this.$toastr.e(this.$t('views.study_plans.errors.subject_form_must_exist'))
      } else {
        this.$emit('remove')
      }
    },
  },
}
</script>
