<template>
  <path d="M10.9067 7.62708L10.4818 3.52059H11.9474C12.4076 3.52059 12.7807 3.1475 12.7807 2.68726V1.02059C12.7807 0.560346 12.4076 0.187256 11.9474 0.187256H2.50292C2.04267 0.187256 1.66958 0.560346 1.66958 1.02059V2.68726C1.66958 3.1475 2.04267 3.52059 2.50292 3.52059H3.96844L3.54361 7.62708C1.85934 8.41017 0.558472 9.81437 0.558472 11.5761C0.558472 12.0364 0.931562 12.4095 1.39181 12.4095H6.11403V16.0208C6.11403 16.064 6.12406 16.1065 6.14337 16.1451L6.9767 17.8117C7.07882 18.016 7.37128 18.0164 7.47361 17.8117L8.30694 16.1451C8.32623 16.1065 8.33627 16.064 8.33628 16.0208V12.4095H13.0585C13.5188 12.4095 13.8918 12.0364 13.8918 11.5761C13.8918 9.79896 12.573 8.40184 10.9067 7.62708Z"/>
</template>

<script>
// width: 14, height: 18
export default {
  name: 'IconDrawingPin',
}
</script>
