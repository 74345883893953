export const views = {
  courses: {
    created_standard_based_on_course: 'Created standard based on course',
    course_exist: 'This course already exist',
    standard_effects: {
      info: 'You have not chosen any standard. If no standard has been matched then close the modal',
    },
  },
  dev_sessions: {
    dev_sessions_index: {
      title: 'Dev login',
    },
  },
  error: {
    general_error: 'Some fields were not filled. Check and correct errors.',
  },
  header: {
    courses: {
      new: 'New course',
      edit: 'Edit course',
      title: 'Courses',
    },
    dictionary: {
      basic_characteristics: 'Basic characteristics',
      category: 'Categories',
      course_effects: 'Course effects',
      edit_standard: 'Edytuj standard',
      faculties_and_courses: 'Faculties and courses',
      fields: 'Fields and disciplines',
      form_of_classes: 'Form of classes',
      form_of_credits: 'Form of credits',
      general_effects: 'General effects',
      kind: 'Forms of study',
      literatures: 'Literatures',
      new_standard: 'New standard',
      profiles: 'Profiles',
      specialities: 'Specialities',
      teaching_effects: 'Teaching effects',
      title: 'Dictionaries',
      types: 'Types',
      standard: 'Standard | Standards',
      standard_effects: 'Standard effects',
      subject_form_names: 'Subject form names',
      subject_names: 'Subject names',
      subjects_types: 'Subjects types',
      unit_names: 'Units',
    },
    matrices: 'Matrices',
    permission_list: 'Permissions',
    personnel_list: 'Staff (users)',
    study_plan: {
      new: 'New study plan',
      edit: 'Edit study plan',
      title: 'Study plan',
    },
    syllabus: {
      accepted: 'Accepted',
      copy_template: 'Copy template',
      edit_template: 'Edit template',
      list: 'Syllabuses',
      new_template: 'New template',
      syllabuses_templates: 'Syllabuses templates',
      to_fill: 'to fill',
      to_improve: 'to improve',
      to_verification: 'to verification',
    },
    syllabus_templates: {
      list: 'Syllabus templates',
    },
  },
  login: {
    companies_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elite. Sed auctor scelerisque lectus, eget maximus dolor.',
    companies_title: 'companies | company | companies | companies',
    copyrights: 'Copyrighting.',
    header: 'Academic Career Office',
    login_btn: 'Sign in',
    main_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elite. Sed auctor scelerisque lectus, eget maximus dolor.',
    offers_title: 'job offers | job offer | job offers | job offers',
    students_and_graduates_title: 'students and graduates | student or graduate | students and graduates | students and graduates',
  },
  matrix: {
    have_to_choose_matrix: 'You have to choose matrix',
  },
  permissions: {
    success: {
      save: 'Permissions successfully saved',
    },
    errors: {
      complete_path: 'Please complete the path',
    },
  },
  study_plans: {
    assign_course_to_study_plan: 'Courses assigned to the study plan',
    errors: {
      academic_year: 'Enter the academic year for which the plan is to be assigned',
      code: 'Enter the subject code',
      course_code: 'Enter the subject code',
      course: 'Enter the name of the course for which the plan should be assigned',
      course_name: 'Enter the name of the course for which the plan should be assigned',
      ects: {
        required: 'Enter the number of ECTS points for this subject',
        numeric: 'The number of points must be an integer and greater than or equal to 0',
      },
      faculty: 'Enter the department name to which the plan should be assigned',
      formOfClass: 'You have to choose the form of the subject',
      formOfCredit: 'You have to choose the form of credit',
      import: {
        academic_year: 'An academic year is required',
        course: 'Course is required',
        faculty: 'Faculty is required',
        failed: 'Failed to download plans',
      },
      import_unexpected_error: 'An unexpected error occurred while importing. Please try again later',
      kind: 'Enter the name of the form to which the plan is to be assigned',
      lecturer: 'There must be at least one lecturer',
      name_of_study_plan: 'Enter a name for the plan',
      name_of_subject: 'Enter a name for the subject',
      not_found: 'Study plan not found',
      number_of_hours: {
        required: 'Enter the number of hours',
        numeric: 'The number of hours must be an integer and greater than or equal to 0',
      },
      semester: {
        same_subject: 'A subject with the same name has already been added in a given semester',
      },
      study_plan_type: 'Enter what type is to be assigned to the plan',
      subject_already_added: 'The subject with the given name has already been added',
      subject_form_must_exist: 'There must be at least one form of subject',
      type: 'Enter the name of the level to which the plan is to be assigned',
    },
    subjects: {
      more_than_one_form: 'There is more than one form of a subject. The subject with all its forms will be deleted.',
    },
    import: {
      plans_updated: 'There have been changes in plans',
    },
    success: {
      added: 'The study plan has been created and added',
      import: 'The plan import was successful',
      updated: 'The study plan has been updated',
    },
    update_plan_beam: {
      discard_changes: 'The changes have been rejected',
      changes: 'There were changes to {count} plans',
      updated_plans: 'Updated plans',
    },
    updated_plan: 'Updated plan',
    warning: {
      import: 'No plans downloaded',
    },
  },
  syllabus: {
    copy: {
      method: 'Select a copy method',
      methods: {
        content_and_subject_effects: 'Content and subject effects',
        just_content: 'Just the content',
      },
      need_to_choose_copy_method: 'You need to choose a copy method',
      no_syllabus_selected: 'No syllabus selected',
    },
    header: {
      all_course_effects_assigned: 'All course effects have been assigned',
      copy_subject_effects: 'Successfully copied subject effects',
      course_effects_not_assigned: 'Course effects have not been assigned',
      syllabus_not_found: 'No syllabus found',
    },
    student_workload: {
      hours_not_match_to_ects: 'Total hours do not match the number of ECTS',
    },
    syllabus_list: {
      status: {
        not_to_fill: 'It cannot be deleted. Some of the syllabuses have a status other than to fill',
      },
    },
  },
  syllabus_list: {
    email: {
      success: 'Emails has been sent successfully',
    },
  },
  syllabus_show: {
    return_to_editing_template: 'Return to editing template',
  },
  syllabus_edit: {
    return: 'Return to syllabus list',
  },
}
