<template>
  <pcg-search
      class="m-0"
      :placeholder="$t('general.search')"
      :value="query"
      @input="updateQuery"
  />
</template>

<script>
export default {
  name: 'PrkSearch',
  props: {
    query: { required: true, type: String },
  },
  methods: {
    updateQuery: _.debounce(function (query) {
      this.$emit('update:query', query)
    }, 200),
  },
}
</script>
