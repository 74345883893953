<template>
  <g>
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M4.90486 1.53356V1.54151V4.27259V5.62941H6.26168H12.168H13.5249V4.27259V1.77266L16.858 5.11985V16.9188H14.5977V11.4862C14.5977 10.487 13.7877 9.67708 12.7886 9.67708H5.64124C4.64211 9.67708 3.83215 10.487 3.83215 11.4862V16.9188H1.57166V1.53356H4.90486ZM5.18897 16.9188H13.2408V11.4862C13.2408 11.2364 13.0384 11.0339 12.7886 11.0339H5.64124C5.39146 11.0339 5.18897 11.2364 5.18897 11.4862V16.9188ZM1.57166 18.2756H3.83215L5.18897 18.2757H13.2408H14.5977L16.858 18.2756H18.2148V16.9188V4.5595L13.8504 0.176743H1.57166H0.214844V1.53356V16.9188V18.2756H1.57166ZM6.26168 1.54151H12.168V4.27259H6.26168V1.54151Z"
         />
  </g>
</template>

<script>
// width: 19 height: 19
export default {
  name: 'IconSave',
}
</script>
