<template>
  <pcg-modal
      :action="action"
      :btn-title="btnTitle"
      icon="fas fa-plus"
      :modal-id="modalId"
      size="md"
      :title="titleModal"
      @hidden="$emit('hidden')"
  >
    <b-container>
      <b-row>
        <b-col>
          <prk-color-picker
              :label="$t('components.prk_modals.colors.color')"
              :error-text="errors.color"
              :selected-color="$attrs.color"
              :show-error="!!errors.color"
              @select="input('color', $event)"
          />
        </b-col>
        <b-col cols="9">
          <pcg-text-input
              :label="$t('components.prk_modals.colors.name')"
              :error-text="errors.name"
              :show-error="!!errors.name"
              :value="$attrs.name"
              @input="input('name', $event)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" lg="8">
          <pcg-text-input
              :label="$t('components.prk_modals.colors.shortcut')"
              :error-text="errors.shortcut"
              :show-error="!!errors.shortcut"
              :value="$attrs.shortcut"
              @input="input('shortcut', $event)"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12" lg="auto">
          <prk-main-select
              color="aqua"
              :default-option="defaultOption('types')"
              :error-text="errors.type"
              :label="$t('components.prk_modals.colors.level')"
              :options="types"
              :show-error="!!errors.type"
              @input="input('typeId', $event)"
          />
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col cols="12" lg="auto">
          <prk-main-select
              color="aqua"
              :default-option="defaultOption('studyProfiles')"
              :error-text="errors.studyProfile"
              :label="$t('components.prk_modals.colors.profile')"
              :options="studyProfiles"
              :show-error="!!errors.studyProfile"
              @input="input('studyProfileId', $event)"
          />
        </b-col>
      </b-row>
    </b-container>
  </pcg-modal>
</template>

<script>

import PrkColorPicker from './PrkColorPicker'
import PrkMainSelect from '../../PrkSelect'
import useModal from '../../../composables/useModal'
import { dictionaryModals } from '../../../mixins/dictionary_modals'
import { getStudyData } from '../../../mixins/getStudyData'

export default {
  name: 'prk-color-modal',
  setup () {
    const { defaultOption, input } = useModal()
    return {
      defaultOption,
      input,
    }
  },
  components: {
    PrkMainSelect,
    PrkColorPicker,
  },
  mixins: [getStudyData, dictionaryModals],
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  created () {
    this.getData(['types', 'study_profiles'])
  },
}
</script>
