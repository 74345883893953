<template>
    <path d="M2.28911 0.133789C1.9032 0.133789 1.52595 0.248226 1.20507 0.462629C0.884195 0.677031 0.634102 0.98177 0.486419 1.33831C0.338736 1.69485 0.300095 2.08717 0.375384 2.46567C0.450672 2.84417 0.636507 3.19185 0.90939 3.46473C1.18227 3.73761 1.52995 3.92345 1.90845 3.99873C2.28695 4.07402 2.67927 4.03538 3.03581 3.8877C3.39235 3.74002 3.69709 3.48992 3.91149 3.16905C4.12589 2.84817 4.24033 2.47092 4.24033 2.08501C4.23879 1.56799 4.03272 1.07258 3.66713 0.706994C3.30153 0.341403 2.80613 0.135333 2.28911 0.133789V0.133789ZM13.9964 0.133789C13.6105 0.133789 13.2333 0.248226 12.9124 0.462629C12.5915 0.677031 12.3414 0.98177 12.1937 1.33831C12.0461 1.69485 12.0074 2.08717 12.0827 2.46567C12.158 2.84417 12.3438 3.19185 12.6167 3.46473C12.8896 3.73761 13.2373 3.92345 13.6158 3.99873C13.9943 4.07402 14.3866 4.03538 14.7431 3.8877C15.0997 3.74002 15.4044 3.48992 15.6188 3.16905C15.8332 2.84817 15.9476 2.47092 15.9476 2.08501C15.9461 1.56799 15.74 1.07258 15.3744 0.706994C15.0089 0.341403 14.5134 0.135333 13.9964 0.133789V0.133789ZM8.14277 0.133789C7.75685 0.133789 7.37961 0.248226 7.05873 0.462629C6.73785 0.677031 6.48776 0.98177 6.34008 1.33831C6.19239 1.69485 6.15375 2.08717 6.22904 2.46567C6.30433 2.84417 6.49017 3.19185 6.76305 3.46473C7.03593 3.73761 7.38361 3.92345 7.7621 3.99873C8.1406 4.07402 8.53293 4.03538 8.88947 3.8877C9.24601 3.74002 9.55075 3.48992 9.76515 3.16905C9.97955 2.84817 10.094 2.47092 10.094 2.08501C10.0924 1.56799 9.88637 1.07258 9.52078 0.706994C9.15519 0.341403 8.65979 0.135333 8.14277 0.133789V0.133789Z" />
</template>

<script>
export default {
  name: 'IconOptions',
}
</script>

<style scoped>
</style>
