<template>
  <b-tr>
    <b-th :colspan="colspan">
      <b-td class="d-flex justify-content-center py-2">
        <pcg-btn @click="addRow" size="small" class="shadow-none m-0 my-2">
              <span class="font-weight-bold prk-fz-12">
                + {{ $t('components.prk_syllabus.buttons.add_new_table_row') }}
              </span>
        </pcg-btn>
      </b-td>
    </b-th>
  </b-tr>
</template>

<script>
export default {
  name: 'AddRow',
  props: {
    colspan: { type: String, default: '4' },
    items: { type: Array },
  },
  methods: {
    addRow () {
      const columns = this.getColumns()
      const obj = {}

      for (const column of columns) {
        obj[column] = ''
      }
      this.$emit('addRow', obj)
    },
    getColumns () {
      let columns = Object.keys(this.items[0])
      if ('label1' in this.items[0]) {
        columns = Object.keys(this.items[1])
      }
      return columns
    },
  },
}
</script>

<style scoped>

</style>
