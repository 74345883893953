<template>
  <pcg-modal
      modal-id="studyPlanListModal"
      :title="$t('general.study_plan')"
      size="md"
      icon="pcg-icon-document"
      :btn-title="$t('general.save')"
      :action="addPlanToMatrix"
  >
    <pcg-search-select
        v-model="selectedPlanId"
        show-label
        :label="$tc('general.study_plan', 1)"
        always-active
        :options="studyPlans"
        :error-text="$t('general.errors.required')"
        :show-error="$v.selectedPlanId.$error"
    />

  </pcg-modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AddStudyPlanModal',
  validations: {
    selectedPlanId: { required },
  },
  props: {
    matrixId: String,
    reload: Boolean,
    studyPlansIds: Array,
    academicYear: String,
  },
  data () {
    return {
      selectedPlanId: '',
      studyPlans: [],
      url: '',
    }
  },
  jsonapi: {
    studyPlans: {
      config: ({ url }) => ({
        method: 'get',
        url: url,
      }),
      variables () {
        return {
          url: this.url,
        }
      },
      update (studyPlans) {
        return studyPlans.map(studyPlan => {
          return {
            value: studyPlan.id,
            _showDetails: false,
            semesters: studyPlan.semesters.map(semester => {
              return { ...this.$_.omit(semester, '__type', 'studyPlan', 'activeEffects') }
            }),
            ...this.$_.omit(studyPlan, '__type', 'semesters'),
          }
        }).filter(studyPlan => !this.studyPlansIds.includes(studyPlan.value))
      },
    },
  },
  watch: {
    academicYear () {
      this.$jsonapi.queries.studyPlans.refetch()
    },
    reload () {
      this.$jsonapi.queries.studyPlans.refetch()
    },
  },
  created () {
    this.url = `matrices/${this.matrixId}/matching_plans?academic_year=${this.academicYear}`
  },
  methods: {
    async addPlanToMatrix () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        const studyPlan = this.$_.cloneDeep(this.studyPlans.find(studyPlan => {
          return studyPlan.value === this.selectedPlanId
        }))
        this.$emit('studyPlanAdded', this.selectedPlanId, studyPlan)
        this.$v.$reset()
        this.selectedPlanId = ''
      }
    },
  },
}
</script>
