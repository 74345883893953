<template>
  <pcg-search-select
      always-active
      class="mb-3 mb-sm-5"
      :options="educators"
      @input="addToListv2($event, addedEducators)"
  >
    <template v-slot="{ option }">
      <div class="dropdown-item d-flex align-items-center">
        <prk-avatar :avatar="option.avatar" :abbr="option.abbr"/>
        <span class="d-inline-block ml-3">
          {{ option.text }}
        </span>
      </div>
    </template>
  </pcg-search-select>
</template>

<script>
import PrkAvatar from '../../../PrkAvatar'
export default {
  name: 'CoordinatorsModalSearchCoordinators',
  components: {
    PrkAvatar,
  },
  props: {
    addToListv2: { required: true, type: Function },
    addedEducators: { required: true, type: Array },
    educators: { required: true, type: Array },
  },
}
</script>
