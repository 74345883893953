<template>
  <div class="acceptance-time">
    <pcg-btn round :text="acceptanceDateTime" class="prk-button" icon="far fa-clock"></pcg-btn>
  </div>
</template>

<script>
import useIcon from '../../../../../composables/useIcon'
import { mapGetters } from 'vuex'
export default {
  name: 'SyllabusAcceptanceTimeAdminActions',
  setup (_, { root }) {
    const { iconClock } = useIcon(root)
    return { iconClock }
  },
  props: {
    dateTime: { type: [String, Object], default: null },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    acceptanceDateTime () {
      if (!this.dateTime) {
        return this.$moment(new Date()).format('MM DD YYYY, h:mm:ss')
      } else {
        return this.$moment(this.dateTime).format('MM DD YYYY, h:mm:ss')
      }
    },
  },
  watch: {
    contrast () {
      const { iconClock } = useIcon(this.$root)
      this.iconClock = iconClock
    },
  },
}
</script>

<style lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';
.acceptance-time {
  .pcg-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .fa-clock {
    color: $pcg_aqua_blue;
    font-size: 1.5rem;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .fa-clock {
    color: $pcg_aqua_blue;
  }
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
