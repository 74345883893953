<template>
  <b-col sm="12" md="6" class="mt-3">
    <prk-select
        color="aqua"
        :default-option="defaultOption('syllabuses')"
        :error-text="syllabusError"
        :label="$t('general.template')"
        :options="syllabuses"
        :show-error="!!syllabusError"
        :value="$attrs.syllabusTemplate"
        @input="input('syllabusTemplate', $event)"
    />
  </b-col>
</template>

<script>
import { newMatrixModal } from '../../../../mixins/new_matrix_modal'

export default {
  name: 'NewMatrixSyllabus',
  mixins: [newMatrixModal],
  props: {
    syllabusError: { required: true, type: String },
  },
  data () {
    return {
      syllabuses: [],
    }
  },
  async created () {
    await this.getSyllabuses()
  },
  methods: {
    async getSyllabuses () {
      const { data: syllabuses } = await this.$api.get('syllabuses/index_templates', { not_archived: true })
      this.syllabuses = syllabuses.map(syllabus => {
        return {
          text: syllabus.attributes.name,
          value: syllabus.id,
        }
      })
    },
  },
}
</script>
