<template>
  <pcg-all-comments-modal
      :current-user="{ id: userId }"
      modal-id="allCommentsModal"
      :sections="commentBySections"
      @show="open = true"
      @hidden="open = false"
  >
    <template #avatar>
      <prk-avatar :avatar="avatar" :abbr="abbr" :width="44"
                  :height="44" without-letter-spacing/>
    </template>
  </pcg-all-comments-modal>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import PrkAvatar from '../../components/PrkAvatar'

export default {
  name: 'syllabus-list-comments',
  components: {
    PrkAvatar,
  },
  props: {
    syllabusId: { required: true, type: String },
  },
  jsonapi: {
    sections: {
      config: ({ syllabusId }) => ({
        method: 'get',
        url: `syllabus_sections?syllabus_id=${syllabusId}`,
        params: {
          serializer: 'syllabus_list',
        },
      }),
      variables () {
        return {
          syllabusId: this.syllabusId,
        }
      },
      skip () {
        return !this.syllabusId.length || !this.open
      },
    },
  },
  data () {
    return {
      sections: [],
      open: false,
    }
  },
  computed: {
    ...mapState('auth', ['avatar', 'userId']),
    ...mapGetters('auth', ['userId', 'abbr']),
    commentBySections () {
      if (!this.sections.length) return []

      const comments = this.sections.map(section => {
        return {
          id: section.id,
          name: section.header,
          number: section.number,
          comments: section.comments,
        }
      })
      return comments.filter(section => section.comments.length > 0)
    },
  },
}
</script>
