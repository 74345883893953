<template>
  <div class="d-flex flex-column">
    <span v-if="canShowHeader" :class="headerClasses">
      {{ getHeaderName }}
    </span>
    <span v-if="headerKey !== 'actions'" class="text-nowrap prk-fz-12">
      {{ headerValue }}
    </span>
  </div>
</template>

<script>
import { detailsTableLangKey } from '../../../../mixins/details_table_lang_key'

export default {
  name: 'StudyPlansTableTermsHeader',
  mixins: [detailsTableLangKey],
  props: {
    index: { required: true, type: Number },
    semester: { required: true, type: Object },
    headerKey: { required: true, type: String },
  },
  computed: {
    getHeaderName () {
      return this.$tc(`${this.langTableKey}.${this.$_.snakeCase(this.headerKey)}`)
    },
    canShowHeader () {
      return this.index === 0 && this.headerKey !== 'actions'
    },
    headerClasses () {
      return 'pcg-gray-color prk-fz-10 font-weight-bold text-nowrap'
    },
    headerValue () {
      return this.semester[this.headerKey] ?? '-'
    },
  },
}
</script>
