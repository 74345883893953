<template>
  <pcg-modal
      icon="fas fa-plus"
      :modal-id="id"
      size="md"
      :title="title"
      :action="() => {}"
      btnClass="d-none"
  >
    <prk-table :items="items" :show-headline="false"
               :excluded-fields="['id', '_showDetails']"
               :custom-fields="[{ key: 'actions', label: '', tdClass: ['align-end'] }]"
    >
      <template v-slot:head(name)>
        {{ $tc('general.field', 2) }}
      </template>
      <template v-slot:head(disciplines)>
        {{ $tc('general.discipline', 2) }}
      </template>
      <template v-slot:content="{ data, header }">
        <div v-if="header.key === 'disciplines'">
          <div v-for="discipline in data.item.disciplines" :key="discipline.disciplineId">
            <div>{{ discipline.name }}</div>
          </div>
        </div>

      </template>
    </prk-table>
  </pcg-modal>
</template>

<script>
import PrkTable from '../../PrkTable'
import useList from '../../../composables/useList'

export default {
  name: 'FieldsModal',
  setup (props, { root }) {
    const { items, setItems } = useList(root)
    return {
      items,
      setItems,
    }
  },
  components: {
    PrkTable,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
  },
  created () {
    this.getFields()
  },
  methods: {
    async getFields () {
      const params = { general_effect_id: this.id }
      let { data: fields } = await this.$api.get('fields', params)
      fields = fields.map(field => {
        return {
          id: field.id,
          name: field.attributes.name,
          _showDetails: true,
          disciplines: field.attributes.disciplines,
        }
      })
      this.setItems(fields)
    },
    addDiscipline () {
      this.$bvModal.hide('dictionaryNewElementModal')
    },
  },
}
</script>
