<template>
  <div v-on="$listeners">
    <svg xmlns="http://www.w3.org/2000/svg"
         :width="width"
         :height="height"
         :aria-labelledby="iconName"
         role="presentation"
         :viewBox="viewBox"
    >
      <g :fill="iconColor">
        <slot/>
      </g>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    iconName: {
      type: String,
      default: 'box',
    },
    width: {
      type: [Number, String],
      default: 16,
    },
    height: {
      type: [Number, String],
      default: 5,
    },
    iconColor: {
      type: String,
      default: '#00B4EB',
    },
    viewBox: {
      type: String,
    },
  },
}
</script>

<style scoped>
  svg {
    display: inline-block;
  }
</style>
