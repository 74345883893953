export const studyPlansTableTerms = {
  jsonapi: {
    semesters: {
      config: ({ studyPlanId }) => ({
        method: 'get',
        url: 'semesters',
        params: {
          study_plan_id: studyPlanId,
          serializer: 'list',
        },
      }),
      fetchPolicy: 'no-cache',
      variables () {
        return {
          studyPlanId: this.studyPlan.id,
        }
      },
      skip () {
        return this.studyPlan.id.length === 0
      },
      update (semesters) {
        return semesters.map(sem => {
          return {
            id: sem.id,
            nameOfTerm: sem.name,
            term: this.termNumber(sem),
            typeOfTerm: this.typeOfTerm(sem),
            specialityName: sem.specialityName,
            specializationName: sem.specializationName,
            ects: sem.ects,
            active: sem.active,
            activeEffects: sem.activeEffects,
            selected: false,
            hasSubjects: sem.hasSubjects,
          }
        })
      },
    },
  },
  data () {
    return {
      semesters: [],
      id: '',
    }
  },
  watch: {
    async id () {
      console.log('here')
      await this.updateEcts()
    },
  },
  created () {
    this.EXCLUDED_FIELDS = [
      'active',
      'activeEffects',
      'selected',
      'studyPlanId',
      '__type',
      'hasSubjects',
    ]
  },
  methods: {
    typeOfTerm (sem) {
      return this.$t(`general.${sem.semesterType}`)
    },
    termNumber (sem) {
      return sem.semesterNumber
    },
    async updateEcts () {
      if (!this.id.length) return
      const { data: semester } = await this.$api.get(`semesters/${this.id}`)
      const index = this.semesters.findIndex(sem => {
        return semester.id === sem.id
      })
      this.semesters[index].ects = semester.attributes.ects
      this.id = ''
    },
  },
}
