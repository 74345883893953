<template>
  <g>
    <path v-if="contrast"
        d="M12.9088 20.6841L7.86098 14.8509H0.759277V0.684113H19.5828V14.8509H13.6795L12.9088 20.6841ZM2.47013 13.1846H8.7164L11.7114 16.6843L12.2249 13.1846H17.872V2.35044H2.47013V13.1846Z"
        fill="#00FA53"/>
    <path v-else
          d="M12.9088 20.6841L7.86098 14.8509H0.759277V0.684113H19.5828V14.8509H13.6795L12.9088 20.6841ZM2.47013 13.1846H8.7164L11.7114 16.6843L12.2249 13.1846H17.872V2.35044H2.47013V13.1846Z"
          fill="#00B4EB"/>
  </g>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IconComments',
  computed: {
    ...mapGetters('page', ['contrast']),
  },
}
</script>

<style scoped>

</style>
