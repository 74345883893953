<template>
  <fragment>
    <div v-for="(item, index) in elements" :key="index"
         :class="[negativeMarginClass(item)]">
      <div v-if="showCheckbox(item)">
        <to-show-checkbox
            :show-horizontal-line="showHorizontalLine(item, index)"
            :item="item"/>
      </div>
      <template v-else-if="['text_field', 'one_line'].includes(item.elementType)">
        <section-content :item="item">
          <div class="pcg-fz-14 pcg-the-darkest-gray-color mt-3" v-html="item.value.value">
          </div>
        </section-content>
      </template>
      <div v-else-if="item.elementType === 'table'">
        <syllabus-table :item="item" :basic="true"/>
      </div>
    </div>
  </fragment>
</template>

<script>
import ToShowCheckbox from './ToShowCheckbox'
import SyllabusTable from '../syllabus_box/SyllabusTable'
import SectionContent from './to_show_content/SectionContent'

export default {
  name: 'to-show-content',
  components: {
    SectionContent,
    SyllabusTable,
    ToShowCheckbox,
  },
  props: {
    section: {
      required: true,
      type: Object,
    },
  },
  computed: {
    elements () {
      return this.section.elements
    },
  },
  methods: {
    showHorizontalLine (item, index) {
      const isNotLastElement = index !== this.elements.length - 1
      const hasDescriptionUnder = !!item.descriptionUnder
      const hasDescriptionAbove = !!this.elements[index + 1]?.descriptionAbove
      return isNotLastElement && (hasDescriptionUnder || hasDescriptionAbove)
    },
    negativeMarginClass (item) {
      return { 'negative-margin': item.elementType === 'table' }
    },
    flexClasses (item) {
      return { 'd-flex justify-content-between align-items-center': item.elementType !== 'table' }
    },
    showCheckbox (item) {
      return item.elementType === 'checkbox' && item.value.value === 'true'
    },
  },
}
</script>
<style scoped lang="scss">
.negative-margin {
  margin-left: -30px;
  margin-right: -30px;
}

.positive-margin {
  margin-left: 30px;
  margin-right: 30px;
}
</style>
