<template>
  <base-beam>
    <prk-beam>
      <template>
        {{ count }}
        <span class="text-lowercase mr-4">
          &nbsp; {{ $t('components.prk_syllabus.beam.selected_elements') }}
        </span>

        <pcg-btn variant="additional" size="small" v-b-modal.statuses-modal>
          {{ $t('general.modify_statuses') }}
        </pcg-btn>

        <pcg-btn variant="additional" size="small" v-b-modal.send-email-modal>
          {{ $t('general.send') }}
        </pcg-btn>

        <pcg-btn v-if="currentRole !== 'educator'"
                 v-b-modal.coordinators-group round :icon="iconUser"
                 :text="$tc('general.educator', 2)" class="mx-2 prk-button"
                 @click="prepareCoordinatorsConfiguration"
        />
      </template>

     <prk-group-prints
         :ids="syllabusIds"
         :resources="selectedSyllabuses"
         resource="syllabuses"
     />

     <syllabus-list-remove-syllabuses
         :selected-syllabuses="selectedSyllabuses"
         delete-selected
         @remove:syllabuses="$emit('remove:syllabuses', $event)"
     />

      <template #mobile_beam>
        <div class="ml-4">
          {{ count }}
          <span class="text-lowercase mr-4">
            &nbsp; {{ $t('components.prk_syllabus.beam.selected_elements') }}
          </span>
        </div>
      </template>

      <template #options>
        <prk-group-prints
            :ids="syllabusIds"
            :resources="selectedSyllabuses"
            resource="syllabuses"
        />

        <pcg-btn v-b-modal.coordinators-group round :icon="iconUser"
                 text="coordinators" class="mx-2 prk-button" />
      </template>
    </prk-beam>
  </base-beam>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import PrkBeam from './PrkBeam'
import PrkGroupPrints from '../PrkGroupPrints'
import SyllabusListRemoveSyllabuses from '../syllabus/syllabuses_list/SyllabusListRemoveSyllabuses'
import useIcon from '../../composables/useIcon'

export default {
  name: 'PrkBeamSyllabusesList',
  setup (_, { root }) {
    const { iconUser } = useIcon(root)
    return { iconUser }
  },
  components: {
    SyllabusListRemoveSyllabuses,
    PrkGroupPrints,
    PrkBeam,
  },
  props: {
    selectedSyllabuses: { required: true, type: Array },
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('page', ['contrast']),
    count () {
      return this.selectedSyllabuses.length
    },
    syllabusIds () {
      return this.selectedSyllabuses.map(syllabus => syllabus.id)
    },
  },
  methods: {
    ...mapActions('syllabus', ['setSyllabusId']),
    ...mapActions('coordinators', ['setShowWarning']),
    prepareCoordinatorsConfiguration () {
      this.setSyllabusId('')
      this.setShowWarning(true)
    },
  },
  watch: {
    contrast () {
      const { iconUser } = useIcon(this.$root)
      this.iconUser = iconUser
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
