<template>
  <pcg-text-input
      type="textarea"
      :label="$t('general.description')"
      :value="description"
      :error-text="$t('general.errors.required')"
      :show-error="error"
      @input="$emit('update:description', $event)"
  />
</template>

<script>

export default {
  name: 'CourseEffectDescription',
  props: {
    description: { type: String, default: '' },
    error: { required: false, type: Boolean },
  },
}
</script>
