export default function (root) {
  const getStudyPlan = async (studyPlanId, params = {}) => {
    const url = `study_plans/${studyPlanId}`
    const response = await root.$api.get(url, params)
    const studyPlan = response.data
    const included = response.included
    return { studyPlan, included }
  }

  const addStudyPlan = async (fields, url) => {
    await root.$api.post(url, {
      ..._createStudyPlanObject(fields),
    })
  }

  const updateStudyPlan = async (fields, url) => {
    await root.$api.put(url, {
      id: localStorage.getItem('studyPlanId'),
      ..._createStudyPlanObject(fields),
    })
  }

  const _createStudyPlanObject = (fields) => {
    return {
      study_plan: {
        name: fields.name.value,
        academic_year: fields.academicYear.value,
        faculty_id: fields.faculty.value,
        course_name_id: fields.courseName.value,
        type_id: fields.type.value,
        kind_id: fields.kind.value,
        study_plan_type: fields.studyPlanType.value,
        special_kind: fields.specialKind,
        semesters: _mapIncluded(),
      },
    }
  }

  const _mapIncluded = () => {
    const semesters = root.$store.state.semesters.allSemesters
    const subjects = root.$store.state.subjects.allSubjects.filter(subject => {
      return !(subject.id.match('__new') && subject._destroy === 1)
    })
    return semesters.map(sem => {
      return {
        speciality_id: sem.speciality?.id || null,
        specialization_id: sem.specialization?.id || null,
        ...root.$_.omit(sem, 'speciality', 'specialization'),
        subjects: subjects.filter(sub => {
          return sub.semester.id === sem.id && _getObjectState(sub)
        }).map(sub => {
          return {
            id: sub.id,
            name: sub.subjectName.name,
            _edited: sub._edited || 0,
            _destroy: sub._destroy || 0,
            subject_forms: sub.subjectForms.filter(form => {
              return _getObjectState(form)
            }),
          }
        }),
      }
    })
  }

  const _getObjectState = (obj) => {
    return obj.id.match('__new') || obj._edited === 1 || obj._destroy === 1
  }

  return {
    addStudyPlan,
    getStudyPlan,
    updateStudyPlan,
  }
}
