<template>
  <div class="pcg-select">
    <label
        v-if="label"
        :class="{ 'pcg-input-label-required': required }"
        class="pcg-input-label"
    >
      {{ label }}
    </label>
    <b-dropdown
        :disabled="disabled"
        lazy
        variant="light"
        class="bootstrap-select"
        @shown="active = true"
        @hidden="active = false"
        @toggle="$emit('toggle')"
    >
      <template v-slot:button-content>
        <div class="filter-option">
          <div
              class="filter-option-inner d-flex flex-nowrap justify-content-between">
            <div v-if="image" class="image">
              <b-img rounded="circle"
                     :src="getOption(selectedOption).path"
                     height="25"
                     width="25"
                     alt="person">
              </b-img>
            </div>
            <div :class="{'ml-3': image, 'aqua': color === 'aqua'}"
                 class="mr-3 custom-class" :style="{ maxWidth: '86%'}">
              <slot name="button-text"
                    v-bind:text="getOption(selectedOption).text"
                    v-bind:className="getOption(selectedOption).class"
              >
                <span class="ellipsis">
                  {{ getOption(selectedOption).text }}
                </span>
              </slot>
            </div>
            <i v-if="active"
               :class="`${getOption(selectedOption).class}-color`"
               class="fas fa-angle-up arrow"
            />
            <i v-else
               class="fas fa-angle-down arrow"
               :class="`${getOption(selectedOption).class}-color`"
            />
          </div>
        </div>
      </template>
      <slot v-bind:options="selectableOptions" v-bind:change="change">
        <b-dropdown-item v-for="(option, index) in selectableOptions"
                         :active="isOptionActive(option.value)"
                         :key="`${index}_${uuidv4()}`"
                         @click="change(option.value)"
                         v-on="$listeners"
        >
          <div class="d-flex flex-nowrap align-items-baseline">
            <div class="mr-2" v-if="image">
              <b-img rounded="circle"
                     :src="option.path"
                     height="25"
                     width="25"
                     alt="person">
              </b-img>
            </div>
            <slot name="item-text" v-bind:option="option">
              <span :class="option.class" class="prk-fz-12">
                {{ option.text }}
              </span>
            </slot>
          </div>
        </b-dropdown-item>
      </slot>
    </b-dropdown>
    <button v-if="showValueAddition" class="pcg-link add-value-link"
            @click="showModalSuggestion">
      {{ $t('general.add_value') }}
    </button>
    <div v-if="showError" class="pcg-error-messages">{{ errorText }}</div>
  </div>
</template>

<script>

import useHelpers from '../composables/useHelpers'

export default {
  name: 'prk-select',
  setup () {
    const { uuidv4 } = useHelpers()
    return { uuidv4 }
  },
  props: {
    color: {
      type: String,
      default: 'gray',
      validator (value) {
        return ['gray', 'aqua'].includes(value)
      },
    },
    options: {
      type: Array,
      default: () => [],
    },
    value: {
      default: null,
    },
    defaultOption: {
      type: Object,
      default: () => {
        return {
          text: null,
          value: null,
        }
      },
    },
    label: String,
    showError: {
      type: Boolean,
      default: false,
    },
    image: {
      type: Boolean,
      default: false,
    },
    errorText: String,
    required: Boolean,
    showValueAddition: Boolean,
    includeBlank: {
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    reset: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      active: false,
      selectedOption: this.value,
    }
  },
  methods: {
    showModalSuggestion () {
      this.$emit('showModalSuggestion')
    },
    getOption (value) {
      return this.selectableOptions.find(option => option.value === value) || this.defaultOption
    },
    change (value) {
      this.selectedOption = value
      this.$emit('input', value)
    },
    isOptionActive (value) {
      return this.selectedOption === value
    },
  },
  watch: {
    value (newValue) {
      this.selectedOption = newValue
    },
    defaultOption () {
      if (this.reset) this.selectedOption = ''
    },
    options () {
      if (this.options.length === 0) {
        this.selectedOption = null
      }
    },
  },
  computed: {
    includeBlankOption () {
      if (typeof this.includeBlank === 'boolean') {
        return {
          text: this.$t('general.choose'),
          value: null,
        }
      } else {
        return {
          text: this.includeBlank,
          value: null,
        }
      }
    },
    selectableOptions () {
      const filteredOptions = this.options.filter(e => e.value !== null)
      if (this.includeBlank === false) {
        return filteredOptions
      } else {
        return [this.includeBlankOption].concat(filteredOptions)
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '../assets/stylesheets/vars';

.pcg-select {
  ::v-deep .btn-light {
    width: 100%;
    text-align: left;

    .ellipsis {
      max-width: 100%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &:after {
      position: absolute !important;
      right: 1.5rem;
      top: 50%;
    }

    &:focus {
      box-shadow: $select-box-shadow;
    }
  }

  ::v-deep .dropdown-menu {
    width: auto;

    .dropdown-item {
      white-space: nowrap;
    }

    .dropdown-item.active {
      border-radius: calc(.25rem - 1px);
    }
  }

  .dropdown.show {
    ::v-deep .btn-light {
      background-color: $main-active-color !important;

      .custom-class {
        color: $pcg-white;
      }
    }
  }

  &.statuses .dropdown.show, &.light .dropdown.show {
    ::v-deep {
      .btn-light {
        background-color: $pcg-white !important;
      }
    }
  }

  .add-value-link {
    display: block;
    font-size: $font-size-s;
    margin-top: $pcg-m-xs;
    background-color: transparent;
    border: none;
    outline: none;
    padding: 0;
  }

  .filter-option-inner {
    /*overflow: hidden;*/
    margin-left: .86rem;
    text-overflow: ellipsis;
    width: calc(100% - 15px);
  }
}

::v-deep {
  button:after {
    display: none !important;
  }

  .pcg-select, .filter-option-inner {
    margin-left: 0 !important;
    width: auto !important;
  }
}

.arrow {
  font-size: 1.4rem;
  color: $pcg-aqua-blue;
  transform: translateY(-.214rem);
}

.image {
  transform: translateY(-.35rem);
}

.aqua {
  color: $pcg-aqua-blue;
  font-weight: bold;
}

.bootstrap-select.show {
  .aqua {
    color: $pcg-white;
  }

  .arrow {
    color: $pcg-white;
  }
}
</style>

<style scoped lang="scss">
@import "../assets/stylesheets/vars-contrast";

.contrast {
  .pcg-select {
    .dropdown.show {
      ::v-deep .btn-light {
        background-color: $main-active-color !important;

        .custom-class {
          color: $pcg-white;
        }
      }
    }

    &.statuses .dropdown.show, &.light .dropdown.show {
      ::v-deep {
        .btn-light {
          background-color: $pcg-white !important;
        }
      }
    }

    .arrow {
      color: $pcg-aqua-blue;
    }

    .aqua {
      color: $pcg-aqua-blue;
    }

    .bootstrap-select.show {
      .aqua {
        color: $pcg-white;
      }

      .arrow {
        color: $pcg-white;
      }
    }
  }
}
</style>

<style lang="scss">
@import '../assets/stylesheets/vars';

.pcg-select.statuses .dropdown-menu {
  .dropdown-item.active {
    background: $pcg-lightest-aqua-blue;
  }
}
</style>

<style lang="scss">
@import "../assets/stylesheets/vars-contrast";

.pcg-select.statuses .dropdown-menu {
  .dropdown-item.active {
    background: $pcg-lightest-aqua-blue;
  }
}
</style>
