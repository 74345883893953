<template>
  <div class="d-flex align-items-center">
    <div class="prk-color-status-flex" :class="className"/>
    <span class="ml-2 font-weight-bold" :class="classes">
      {{ $t(`general.statuses.${status}`) }}
    </span>
  </div>
</template>

<script>
export default {
  name: 'Status',
  props: {
    className: {
      type: String,
      default: '',
    },
    status: {
      required: true,
      type: String,
    },
  },
  computed: {
    classes () {
      return {
        'pcg-main-active-color': this.status === 'choose',
        [`${this.className}-color`]: this.status !== 'choose',
      }
    },
  },
}
</script>
