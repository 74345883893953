var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('prk-table',{attrs:{"id":"study-plan-table","custom-fields":_vm.subjectsByForm.length > 0 ? _vm.CUSTOM_FIELDS : [],"disable-actions":"","items":_vm.subjectsByForm,"excluded-fields":_vm.EXCLUDED_FIELDS,"show-headline":false,"busy":_vm.$jsonapi.loading},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({ width: _vm.colgroupDefinition(field.key) })})})}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('pcg-spinner',{attrs:{"variant":"small"}})],1)]},proxy:true},{key:"head(specialPlan)",fn:function(){return [_vm._v("\n      "+_vm._s(_vm.$t('components.prk_syllabus.table.from_plan'))+"\n    ")]},proxy:true},{key:"content",fn:function(ref){
var header = ref.header;
var data = ref.data;
return [(['formOfClasses', 'formOfCredit'].includes(header.key))?[_c('span',{domProps:{"innerHTML":_vm._s(data.item[header.key])}})]:(header.key === 'leading')?[_c('didactic-initial',{attrs:{"lecturers":data.item.lecturers},on:{"click":function($event){_vm.subjectId = data.item.id}}})]:(header.key === 'specialPlan')?[_c('span',{domProps:{"innerHTML":_vm._s(data.item[header.key])}})]:(header.key === 'actions')?_c('study-plan-table-actions',{attrs:{"subject-by-form-id":data.item.id,"subjects":_vm.subjects},on:{"set:subject-id":function($event){_vm.subjectId = $event},"set:subject-form-id":function($event){return _vm.prepareSubjectForms($event)},"remove":_vm.removeSubject}}):_vm._e()]}},(_vm.showFooter && !_vm.$jsonapi.loading)?{key:"custom-foot",fn:function(){return [(_vm.subjectsByForm.length)?_c('study-plan-table-footer',{attrs:{"items":_vm.subjectsByForm}}):_vm._e(),_vm._v(" "),_c('b-tr',[_c('b-th',{style:({
        paddingRight: '2.14rem',
        height: '0px'
      }),attrs:{"colspan":"8"}},[_c('hr',{staticClass:"m-0"})])],1)]},proxy:true}:null],null,true)}),_vm._v(" "),_c('lecturers-modal',{attrs:{"lecturers":_vm.lecturers}}),_vm._v(" "),_c('subject-form-modal',{key:_vm.rerenderSubjectForm,attrs:{"modal-id":"subject-form","edited-subject":_vm.subject,"semester-id":_vm.semesterId},on:{"hidden":function($event){_vm.rerenderSubjectForm += 1}}}),_vm._v(" "),(_vm.$checkRouteName('study_plans_list_path'))?_c('pin-to-study-plan-modal',{attrs:{"subject-id":_vm.subjectId}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }