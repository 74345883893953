<template>
  <pcg-modal
      modal-id="new-changes"
      size="sm"
      :btn-title="$t('general.yes')"
      btn-class="w-50"
      :action="action"
  >

    <div class="prk-fz-16 font-weight-bold pcg-the-darkest-gray-color mb-3">
      {{ $t('components.new_changes.text') }}
    </div>

    <template #button-before>
      <pcg-btn
          class="w-50" variant="additional"
          @click="hide"
      >
        {{$t ('general.no') }}
      </pcg-btn>
    </template>
  </pcg-modal>
</template>

<script>
export default {
  name: 'NewChanges',
  inject: {
    submit: { default: () => {} },
    push: { default: false },
  },
  props: {
    pathName: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
  },
  computed: {
    query () {
      return { academic_year: this.$route.query.academic_year }
    },
  },
  methods: {
    async action () {
      await this.submit()
      this.$bvModal.hide('new-changes')
      if (this.push) {
        const params = { name: this.pathName, path: this.path, query: this.query }
        await this.$router.push(params)
      }
    },
    hide () {
      this.$bvModal.hide('new-changes')
      const params = { name: this.pathName, path: this.path, query: this.query }
      this.$router.push(params)
    },
  },
}
</script>
