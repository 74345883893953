<template>
  <div class="stick">
    <pcg-table :items="subjectInformation" :fields="fields">
      <template #header></template>

      <template v-slot:cell(information)="data">
        <div class="prk-pcg-syllabus-information">
          {{ $t('components.prk_syllabus.workload.' + data.item.information) }}
        </div>
      </template>

      <template v-slot:cell(type)="data">
        <div class="d-flex align-content-center">
          <div v-if="data.item.type == 'refill'" class="prk-pcg-syllabus-type">
            <img
                :src="require('../../../../assets/images/icons/edit-inactive.svg')"
                class="edit-icon"/>
            {{ $t('components.prk_syllabus.section.fill_user') }}
          </div>
          <div v-else-if="data.item.type == 'automatic'"
               class="prk-pcg-syllabus-type">
            <img
                :src="require('../../../../assets/images/icons/cloud-inactive.svg')"
                class="cloud-icon"/>
            {{ $t('components.prk_syllabus.section.automatic_download') }}
          </div>
        </div>
      </template>

      <template v-slot:cell(switch)>
        <pcg-switch :value=true></pcg-switch>
      </template>
    </pcg-table>
  </div>

</template>

<script>
export default {
  name: 'PrkWorkloadElement',
  data () {
    return {
      subjectInformation: [
        {
          information: 'contact_hours',
          type: 'automatic',
        },
        {
          information: 'other',
          type: 'refill',
        },
        {
          information: 'no_contact_hours',
          type: 'refill',
        },
        {
          information: 'sum',
          type: 'refill',
        },
        {
          information: 'ects',
          type: 'automatic',
        },
      ],
    }
  },
  computed: {
    fields () {
      return [
        {
          key: 'information',
          label: this.$t('components.prk_syllabus.workload.header_1'),
        },
        {
          key: 'type',
          label: this.$t('components.prk_syllabus.workload.header_2'),
        },
      ]
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  th {
    color: $main-select-color;
    padding-bottom: 1rem;
  }

  td {
    width: 50%;
  }

  .pcg-table-content {
    padding: 0 !important;
  }

  .pcg-table-header-I {
    background-color: transparent !important;
  }

  .pcg-table-container {
    box-shadow: none !important;
  }

  .description-container {
    margin-top: 0;
  }

  .prk-pcg-box-cart-padding {
    padding-bottom: 0;
  }

  .prk-pcg-syllabus-type {
    padding: 22px 0 22px 0;
    font-style: italic;
    font-size: 0.857rem; // 12px
    line-height: 1.571rem; // 22px
  }
}

.stick {
  margin: 0 -2.142rem;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {
    th {
      color: $main-select-color;
    }
  }
}
</style>
