<template>
  <div id="personnel-details">
    <prk-table
        :items="item.subItems"
        :show-headline="false"
        :fields-props="{ thClass: 'd-none' }"
        :custom-fields="customFields"
        :excluded-fields="excludedFields"
    >
      <template v-slot:content="{ header, data }">
        <div v-if="header.key !== 'actions'"
             class="prk-fz-12 d-flex flex-column">
          <span class="pcg-gray-color prk-fz-10 font-weight-bold text-nowrap">
            {{
            $tc(`components.prk_syllabus.table.${$_.snakeCase(header.key)}`)
            }}
          </span>
          <span class="text-nowrap prk-fz-12">
            {{ data.item[header.key] }}
          </span>
        </div>
        <template v-if="header.key === 'actions'" >
          <div class="d-flex flex-column">
            <span class="pcg-gray-color prk-fz-10 font-weight-bold text-nowrap">
              {{ $t('general.role_in_syllabus') }}
            </span>
            <div class="d-flex align-items-center justify-content-between">
              <prk-select
                  ref="dropdown"
                  :options="data.item.positions"
                  :default-option="data.item.defaultOption"
                  color="aqua"
                  class="mr-3"
              >
                <template v-slot="{ options }">
                  <b-dd-form>
                    <div
                        v-for="(option, index) in options"
                        :key="index"
                        class="d-flex align-items-center py-3 pointer"
                    >
                      <pcg-checkbox
                          @input="selectRole(data)"
                          v-model="option.selected"
                      />
                      <span
                          class="prk-fz-12 font-weight-bold pcg-gray-color text-nowrap"
                      >
                        {{ option.text }}
                      </span>
                    </div>
                  </b-dd-form>
                </template>
              </prk-select>
              <pcg-btn
                  variant="additional"
                  size="small"
                  class="m-0"
              >
                <span class="prk-fz-12 font-weight-bold">
                  {{ $t('general.see_subjects') }}
                </span>
              </pcg-btn>
            </div>
            <prk-tags
                :value="showTags(data)"
                @removed="removeTag($event, data)"
                class="d-flex flex-column align-items-start"
            />
          </div>
        </template>
      </template>
    </prk-table>
  </div>
</template>

<script>
import PrkTable from '../PrkTable'
import PrkSelect from '../PrkSelect'
import PrkTags from '../PrkTags'

export default {
  name: 'PersonnelDetails',
  components: { PrkTable, PrkSelect, PrkTags },
  props: {
    item: { type: Object, default: () => ({}) },
  },
  data () {
    return {
      excludedFields: ['defaultOption', 'positions'],
    }
  },
  computed: {
    customFields () {
      return [{
        key: 'actions',
        label: '',
        tdClass: ['align-middle'],
        thClass: ['d-none'],
      }]
    },
  },
  methods: {
    selectRole (data) {
      const selectedPositions = data.item.positions.filter(el => el.selected)
      if (selectedPositions.length === 1) {
        data.item.defaultOption = { text: selectedPositions[0].text }
      } else if (selectedPositions.length > 1) {
        const text = `${selectedPositions.length} ${this.$t('general.selected_roles').toLocaleLowerCase()}`
        data.item.defaultOption = { text: text }
      } else {
        data.item.defaultOption = { text: this.$t('general.choose') }
      }
    },
    showTags (data) {
      const selectedPositions = data.item.positions.filter(el => el.selected)
      if (selectedPositions.length > 1) {
        return selectedPositions
      } else {
        return []
      }
    },
    removeTag (id, data) {
      const item = data.item.positions.find((item, index) => {
        return index === id
      })
      item.selected = false
      this.selectRole(data)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  &#personnel-details .b-dropdown-form {
    outline: none !important;
  }
}
</style>
