<template>
  <div class="d-flex justify-content-end flex-column flex-xl-row mx-4 w-100">
    <pcg-datetime-picker
        v-model="dateFrom"
        class="d-flex align-items-center mr-3 mb-3 mb-xl-0"
        :label="$t('general.date_from')"
        locale="pl"
        show-clear
        variant="date"
        :use-current="false"
        @show="detachCalendar"
        @hide="hide($event, 'dateFrom')"
    />
    <pcg-datetime-picker
        v-model="dateTo"
        class="d-flex align-items-center mr-3"
        :label="$t('general.date_to')"
        locale="pl"
        show-clear
        :use-current="false"
        variant="date"
        @show="detachCalendar"
        @hide="hide($event, 'dateTo')"
    />
  </div>
</template>

<script>
export default {
  name: 'FieldsEffectiveDate',
  props: {
    updateResource: { required: true, type: Function },
    url: { required: true, type: String },
    item: { required: true, type: Object },
  },
  data () {
    return {
      dateTo: this.item.dateTo,
      dateFrom: this.item.dateFrom,
      detached: false,
      oldDate: {
        value: '',
        type: '',
        watch: true,
        initialized: false,
      },
      position: {
        x: '',
        y: '',
      },
      unwatch: false,
    }
  },
  watch: {
    dateTo (newDate, oldDate) {
      if (this.checkDate(newDate, oldDate) || !this.detached) return
      this.$emit('update:dateTo', this.$moment(this.dateTo).format('DD-MM-YYYY'))
      if (this.oldDate.watch) this.updateResource(this.item, this.url)
      this.setOldDate(oldDate, 'dateTo')
    },
    dateFrom (newDate, oldDate) {
      if (this.checkDate(newDate, oldDate) || !this.detached) return
      if (this.dateFrom > this.dateTo && oldDate === undefined) {
        this.dateFrom = this.$moment(this.dateTo).toDate()
        this.unwatch = true
      } else if (this.unwatch) {
        this.unwatch = false
        return
      }
      this.$emit('update:dateFrom', this.$moment(this.dateFrom).format('DD-MM-YYYY'))
      if (this.oldDate.watch) this.updateResource(this.item, this.url)
      this.setOldDate(oldDate, 'dateFrom')
    },
    errors () {
      if (!(this.oldDate.initialized)) return
      if (this.oldDate.type === 'dateTo') {
        this.dateTo = this.oldDate.value || this.dateFrom
      } else {
        this.dateFrom = this.oldDate.value || this.dateTo
      }
      this.oldDate.watch = false
      this.oldDate.initialized = false
    },
  },
  methods: {
    attach (node) {
      const anchor = document.querySelector('.anchor')
      node.style.position = 'fixed'
      node.style.top = `${this.position.y}px`
      node.style.left = `${this.position.x}px`
      node.style.bottom = 'auto'
      node.style.right = 'auto'
      node.style.width = 'unset'
      anchor.appendChild(node)
    },
    checkDate (newDate, oldDate) {
      const newFormattedDate = this.$moment(newDate).format('DD-MM-YYYY')
      const oldFormattedDate = this.$moment(oldDate).format('DD-MM-YYYY')
      if ((newFormattedDate === oldFormattedDate) && oldDate !== undefined) return true
    },
    detach (node) {
      node.parentElement.removeChild(node)
    },
    detachCalendar (event) {
      const datePicker = event.target.querySelector('.bootstrap-datetimepicker-widget')
      if (datePicker === null) return
      this.setPosition(datePicker)
      this.detach(datePicker)
      this.attach(datePicker)
      this.detached = true
    },
    hide (event, type) {
      const inputDate = event.target.querySelector('input')
      if (inputDate.value.length) return
      if (type === 'dateFrom') this.$emit('update:dateFrom', '')
      else this.$emit('update:dateTo', '')
      this.updateResource(this.item, this.url)
      this.detached = false
    },
    setOldDate (oldDate, type) {
      this.oldDate.type = type
      if (type === 'dateFrom' && oldDate === undefined) {
        this.oldDate.value = this.$moment(this.dateTo).subtract(1, 'days').format('DD-MM-YYYY')
      } else {
        this.oldDate.value = oldDate
      }
      this.oldDate.watch = true
      this.oldDate.initialized = true
    },
    setPosition (target) {
      const selector = '.bootstrap-datetimepicker-widget.dropdown-menu.top'
      let offset = 0
      if (document.querySelector(selector)) offset = 40
      this.position.x = target.getBoundingClientRect().x
      this.position.y = target.getBoundingClientRect().y + offset
    },
  },
}
</script>

<style lang="scss" scoped>
.form-group.d-flex {
  min-width: 200px;
  ::v-deep {
    label {
      display: flex;
      align-items: center;
      margin: 0;
    }
  }
}
::v-deep {
  .datetimepicker-input {
    font-size: 0.857rem;
  }
}
</style>
