export const syllabus = {
  namespaced: true,
  state: {
    syllabusId: '',
    showWarning: true,
  },
  mutations: {
    SET_SYLLABUS_ID (state, syllabusId) {
      state.syllabusId = syllabusId
    },
    SET_SHOW_WARNING (state, showWarning) {
      state.showWarning = showWarning
    },
  },
  actions: {
    setSyllabusId ({ commit }, syllabusId) {
      commit('SET_SYLLABUS_ID', syllabusId)
    },
    setShowWarning ({ commit }, showWarning) {
      commit('SET_SHOW_WARNING', showWarning)
    },
  },
}
