var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pcg-modal',{attrs:{"modal-id":"course-effects-modal","size":"xl","title":_vm.title,"icon":"fas fa-plus","btn-title":_vm.$t('general.save'),"action":function () { _vm.action(_vm.selectedEffects) },"hide-footer":_vm.syllabusList},on:{"hide":_vm.clear}},[(!_vm.syllabusList)?_c('div',{staticClass:"mb-2 subheader"},[_vm._v("\n    "+_vm._s(_vm.$t('components.prk_modals.course_effects.select_all'))+"\n  ")]):_vm._e(),_vm._v(" "),_c('b-row',{attrs:{"align-v":"center"}},[(!_vm.syllabusList)?_c('course-effects-modal-select-all',{attrs:{"selected-all":_vm.selectedAll,"select-effects":_vm.selectEffects}}):_vm._e(),_vm._v(" "),_c('b-col',[_c('prk-search',{attrs:{"query":_vm.query},on:{"update:query":function($event){_vm.query=$event}}})],1),_vm._v(" "),_c('b-col',{staticClass:"d-flex",class:_vm.justifyContent},[_c('categories',{attrs:{"args":{ emit: true },"categories":_vm.categories},on:{"update:category-id":function($event){_vm.categoryId = $event}}})],1)],1),_vm._v(" "),_c('prk-table',{staticClass:"mt-3",attrs:{"show-headline":false,"items":_vm.effects,"excluded-fields":_vm.EXCLUDED_FIELDS,"fields-props":{ tdClass: _vm.tdClass }},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return [_c('prk-colgroup',{attrs:{"fields":fields,"width":"50%","columns":['description', 'enDescription']}})]}},{key:"head(id)",fn:function(){return [_c('pcg-checkbox',{attrs:{"value":_vm.selectedAll},on:{"input":_vm.selectEffects}})]},proxy:true},{key:"cell(id)",fn:function(ref){
var effect = ref.item;
return [_c('pcg-checkbox',{model:{value:(effect.selected),callback:function ($$v) {_vm.$set(effect, "selected", $$v)},expression:"effect.selected"}})]}},{key:"cell(description)",fn:function(ref){
var effect = ref.item;
return [_c('prk-animated-description',{attrs:{"description":effect.description}})]}},{key:"cell(enDescription)",fn:function(ref){
var effect = ref.item;
return [_c('prk-animated-description',{attrs:{"description":effect.enDescription}})]}},{key:"cell(actions)",fn:function(data){return [_c('base-arrow',{attrs:{"show":data.item.toggleDetails},on:{"click":function($event){return _vm.showText(data, $event)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }