<template>
  <div class="mb-5">
    <didactic-methods-content v-if="isDidacticMethods" :section="section"/>
    <syllabus-objective-effects v-else :section="section"/>
  </div>
</template>

<script>
import SyllabusObjectiveEffects from './SyllabusObjectiveEffects'
import { syllabusHeaderStatus } from '../../../../mixins/sylabus_header_status'
import { mainSection } from '../../../../mixins/main_section'
import DidacticMethodsContent from './PrkDidacticMethodsContent'

export default {
  name: 'EducationGoals',
  inheritAttrs: false,
  components: {
    DidacticMethodsContent,
    SyllabusObjectiveEffects,
  },
  mixins: [syllabusHeaderStatus, mainSection],
  props: {
    section: {
      required: true,
      type: Object,
    },
  },
  computed: {
    isDidacticMethods () {
      return this.section.detailedComponent === 'didactic-methods-content'
    },
  },
}
</script>
