<template>
  <pcg-modal
      :action="saveDiscipline"
      :btn-title="btnTitle"
      icon="fas fa-plus"
      modal-id="discipline-modal"
      size="lg"
      :title="title"
      @hidden="$emit('hidden')"
  >
    <discipline-metric/>

    <field-select
        :error="$v.discipline.fieldId.$error"
        :field-id="discipline.fieldId"
        @update:field="updateField"
    />

    <discipline-select
        :discipline-id="discipline.disciplineId"
        :error="$v.discipline.disciplineId.$error"
        :field-id="discipline.fieldId"
        @update:discipline="updateDiscipline"
        @clear:discipline-id="discipline.disciplineId = ''"
    />

    <discipline-percent
        :error="$v.discipline.percent.$error"
        :percent.sync="discipline.percent"
    />

    <template #button-before>
      <pcg-btn variant="additional"
               @click="$bvModal.hide('discipline-modal')"
      >
        {{ $t('general.cancel') }}
      </pcg-btn>
    </template>
  </pcg-modal>
</template>

<script>
import FieldSelect from './disciplines/FieldSelect'
import DisciplineSelect from './disciplines/DisciplineSelect'
import DisciplinePercent from './disciplines/DisciplinePercent'
import DisciplineMetric from './disciplines/DisciplineMetric'
import { required, between } from 'vuelidate/lib/validators'
import useDictionary from '../../../composables/useDictionary'

export default {
  name: 'PrkAddDisciplineModal',
  setup (_, { root }) {
    const suffixes = {
      add: 'save',
      edit: 'edit_discipline',
      new: 'add_discipline',
    }
    const {
      btnTitle,
      title,
    } = useDictionary(root, suffixes)
    return {
      btnTitle,
      title,
    }
  },
  components: {
    DisciplineMetric,
    DisciplinePercent,
    DisciplineSelect,
    FieldSelect,
  },
  data () {
    return {
      discipline: {
        disciplineId: '',
        fieldName: '',
        fieldId: '',
        disciplineName: '',
        percent: 0,
        leadingDiscipline: false,
      },
      oldDisciplineId: '',
    }
  },
  validations: {
    discipline: {
      percent: {
        required,
        between: between(0, 100),
      },
      disciplineId: { required },
      fieldId: { required },
    },
  },
  props: {
    editedDiscipline: {
      type: Object,
      default: () => ({}),
    },
    editMode: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    editedDiscipline: {
      handler () {
        this.discipline = this.editedDiscipline
        if (this.oldDisciplineId.length) return
        this.oldDisciplineId = this.discipline.disciplineId
      },
      deep: true,
    },
  },
  methods: {
    saveDiscipline () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        const clonedDiscipline = this.$_.cloneDeep(this.discipline)
        if (this.editMode) {
          this.$emit('update:discipline', clonedDiscipline, this.oldDisciplineId)
        } else {
          this.$emit('add:discipline', clonedDiscipline)
        }
        this.$bvModal.hide('discipline-modal')
      }
    },
    updateField (...payload) {
      const [id, name] = payload
      this.discipline.fieldId = id
      this.discipline.fieldName = name
    },
    updateDiscipline (...payload) {
      const [id, name] = payload
      this.discipline.disciplineId = id
      this.discipline.disciplineName = name
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-icon-calendar {
    font-size: 1.5rem;
  }

  .pcg-datetimepicker-btn {
    margin: .25rem 0 0 1.43rem;
    padding: 1.3rem 1.21rem;
  }
}

</style>
