<template>
  <pcg-modal
      :action="close"
      modal-id="subjectsModal"
      :title="$tc('general.subjects', 2)"
      icon="pcg-icon-document"
      :btn-title="$t('components.prk_syllabus.return.plans_list')"
      size="xl"
      scrollable
  >
    <span class="font-weight-bold pcg-the-darkest-gray-color">
      <span v-if="specialKind">
        Tok {{ +specialKind - 1 }},
      </span>
      {{ studyPlanInfo }}
    </span>

    <square-semester-select
        v-if="!semesterId"
        class="mt-4"
        @change="semesterIds = $event"
    />

    <study-plan-table
        :semester-id="semesterId"
        :semester-ids="semesterIds"
    />
  </pcg-modal>
</template>
<script>

import StudyPlanTable from '../new_study_plan/StudyPlanTable'
import SquareSemesterSelect from '../../matrices/modals/SquareSemesterSelect'

export default {
  name: 'PrkSubjectsModal',
  components: {
    SquareSemesterSelect,
    StudyPlanTable,

  },
  data () {
    return {
      // numberOfSemesters: null,
      semesterIds: [],
    }
  },
  computed: {
    semesterId () {
      return this.$store.state.semesters.semester?.id
    },
    studyPlan () {
      return this.$store.state.studyPlans.plan
    },
    studyPlanInfo () {
      if (!this.studyPlan) return
      return `${this.studyPlan.faculty}, ${this.studyPlan.course}, ` +
          `${this.studyPlan.level}, ${this.studyPlan.form}`
    },
    specialKind () {
      return this.studyPlan?.specialKind
    },
  },
  methods: {
    close () {
      this.$bvModal.hide('subjectsModal')
    },
  },
}
</script>
