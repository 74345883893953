export default function (root, suffix = '') {
  const getDefaultOption = (data = []) => {
    const selectedEffects = data.filter(el => el.selected)
    if (selectedEffects.length === 1) {
      return { text: selectedEffects[0].text }
    } else if (selectedEffects.length > 1) {
      const translation = root.$t(`general.${suffix}`)
      return { text: `${selectedEffects.length} ${translation}` }
    } else {
      return { text: root.$t('general.choose') }
    }
  }

  return {
    getDefaultOption,
  }
}
