<template>
  <g>
    <path d="M23.1748 31.9033L18.5255 26.5306H11.9844V13.4821H29.322V26.5306H23.8847L23.1748 31.9033ZM13.5602 24.9958H19.3134L22.072 28.2192L22.5449 24.9958H27.7462V15.0168H13.5602V24.9958Z" />
  </g>
</template>
<script>

// width=41, height=41
export default {
  name: 'IconCommentWithNumber',
}
</script>

<style scoped>

</style>
