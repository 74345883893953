export default {
  data () {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth
    }
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleWindowResize)
  },
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.handleWindowResize)
    })
  },
  methods: {
    handleWindowResize (event) {
      this.windowHeight = event.currentTarget.innerHeight
      this.windowWidth = event.currentTarget.innerWidth
    }
  }
}
