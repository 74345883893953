<template>
  <b-col cols="12" class="mt-4">
    <prk-select
        color="aqua"
        :default-option="defaultOption('faculties')"
        :error-text="facultyError"
        :label="$tc('general.faculty', 1)"
        :options="faculties"
        :show-error="!!facultyError"
        :value="$attrs.faculty"
        @input="input('faculty', $event)"
    />
  </b-col>
</template>

<script>
import PrkSelect from '../../../PrkSelect'
import useModal from '../../../../composables/useModal'
import { getStudyData } from '../../../../mixins/getStudyData'

export default {
  name: 'NewMatrixFaculty',
  setup () {
    const { defaultOption, input } = useModal()
    return { defaultOption, input }
  },
  components: {
    PrkSelect,
  },
  mixins: [getStudyData],
  props: {
    facultyError: { required: true, type: String },
  },
  created () {
    this.getData(['faculties'])
  },
}
</script>
