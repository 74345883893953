<template>
  <pcg-modal
      :action="close"
      :btn-title="$t('general.ok')"
      modal-id="study-plan-history"
      scrollable
      size="lg"
      :title="$t('general.change_history')"
      @show="page = 1"
  >
    <plan-history :study-plan-u10-id="planU10Id" :property="property"/>
    <semester-history
        :compare-semesters-init="compareInit"
        :property="property"
        :study-plan-id="planId"
    />
    <subject-history
        :compare-subjects-init="compareInit"
        :property="property"
        :study-plan-id="planId"
        @update:loading="loading = $event"
    />
    <prk-observer @intersect="intersect"/>
    <div v-if="loading" class="d-flex justify-content-center">
      <pcg-spinner variant="small" />
    </div>
  </pcg-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PlanHistory from './study_plan_history_modal/PlanHistory'
import SemesterHistory from './study_plan_history_modal/SemesterHistory'
import SubjectHistory from './study_plan_history_modal/SubjectHistory'
import PrkObserver from '../../PrkObserver'
import { ref, provide } from '@vue/composition-api'
const { mapState } = createNamespacedHelpers('studyPlans')

export default {
  name: 'StudyPlanHistoryModal',
  setup () {
    const page = ref(1)
    provide('page', page)
    return { page }
  },
  components: {
    SubjectHistory,
    SemesterHistory,
    PlanHistory,
    PrkObserver,
  },
  data () {
    return {
      loading: true,
    }
  },
  computed: {
    ...mapState(['plan']),
    planId () {
      return this.plan?.id
    },
    planU10Id () {
      return this.plan?.u10Id
    },
  },
  methods: {
    compareInit (resources, groupBy = 'u10Id') {
      const resourcesToCompare = []
      const resourcesGroupedByU10Id = this.$_.groupBy(resources, groupBy)
      for (const key in resourcesGroupedByU10Id) {
        const firstResource = resourcesGroupedByU10Id[key][0]
        const secondResource = resourcesGroupedByU10Id[key][1]
        resourcesToCompare.push([firstResource, secondResource])
      }
      return resourcesToCompare
    },
    close () {
      this.$bvModal.hide('study-plan-history')
    },
    intersect () {
      this.page++
      this.loading = true
    },
    property (key) {
      return this.$tc(`general.${this.$_.snakeCase(key)}`)
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .box {
    border-radius: 5px;
    min-height: 30px;
    padding: 10px 15px;
  }
}
</style>
