<template>
  <div>
    <div v-if="$jsonapi.loading" class="text-center text-danger my-2">
      <pcg-spinner variant="small"/>
    </div>
    <prk-table
        v-else
        class="sub-table"
        :items="semesters"
        :fields-props="{ thClass: 'd-none' }"
        :excluded-fields="EXCLUDED_FIELDS"
        :show-headline="false"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: field.key === 'nameOfTerm' ? '25%' : '15%' }"
        >
      </template>

      <template #cell(id)="{ item: term, index }">
        <pcg-checkbox
            class="pl-5"
            :value="term.selected"
            @input="selectRow(index, $event)"
        />
      </template>
      <template #content="{ header, data }">
        <study-plans-table-terms-header
            :index="data.index"
            :semester="data.item"
            :header-key="header.key"
        />
        <div v-if="header.key === 'actions'" class="grid">
          <pcg-switch
              v-model="data.item.active"
              class="item-a"
              @input="updateSemester(data.item)"
          />
          <study-plans-table-terms-active-term :semester="data.item"/>
          <pcg-switch
              v-model="data.item.activeEffects"
              class="item-c"
              @input="updateSemester(data.item)"
          />
          <study-plans-table-terms-teaching-effects :semester="data.item" />
          <study-plans-table-terms-subjects
              :study-plan="studyPlan"
              :semester="data.item"
              @update:has-subjects="data.item.hasSubjects = false"
          />
        </div>
      </template>
    </prk-table>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkTable from '../../PrkTable'
import StudyPlansTableTermsHeader from './study_plans_table_terms/StudyPlansTableTermsHeader'
import StudyPlansTableTermsActiveTerm from './study_plans_table_terms/StudyPlansTableTermsActiveTerm'
import StudyPlansTableTermsSubjects from './study_plans_table_terms/StudyPlansTableTermsSubjects'
import StudyPlansTableTermsTeachingEffects from './study_plans_table_terms/StudyPlansTableTermsTeachingEffects'
import useSemesters from '../../../composables/useSemesters'
import { studyPlansTableTerms } from '../../../mixins/plans/study_plans_table_terms'

const { mapState, mapActions } = createNamespacedHelpers('semesters')

export default {
  name: 'StudyPlansTableTerms',
  setup (_, { root }) {
    const { updateSemestersHandler, getSemesterParams } = useSemesters(root)
    return { updateSemestersHandler, getSemesterParams }
  },
  components: {
    StudyPlansTableTermsTeachingEffects,
    StudyPlansTableTermsSubjects,
    StudyPlansTableTermsActiveTerm,
    StudyPlansTableTermsHeader,
    PrkTable,
  },
  mixins: [studyPlansTableTerms],
  props: {
    studyPlan: { type: Object, default: () => ({}) },
  },
  data () {
    return {
      unsubscribe: null,
    }
  },
  computed: {
    ...mapState(['semesterProperty']),
    selectedSemesters () {
      return this.semesters.filter(sem => sem.selected)
    },
  },
  watch: {
    'studyPlan.semesters': {
      handler () { this.markAsSelected() },
      deep: true,
    },
    semesters () { this.markAsSelected() },

    semesterProperty () { this.updateSemesters() },
  },
  created () {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      if ((mutation.type === 'semesters/EDIT_SEMESTER' ||
          mutation.type === 'subjects/REMOVE_SUBJECT') &&
          this.$checkRouteName('study_plans_list_path')) {
        this.id = mutation.payload.semester?.id ?? mutation.payload.id
      }
    })
  },
  methods: {
    ...mapActions(['setSemesterProperty']),

    markAsSelected () {
      this.studyPlan.semesters.forEach(sem => {
        const semester = this.semesters.find(semester => semester.id === sem.id)
        semester.selected = sem.selected
      })
    },

    selectRow (index, selected) {
      this.$emit('row-clicked')
      this.$emit('row-selected', index, selected)
    },

    async updateSemester (semester) {
      const params = this.getSemesterParams(semester)
      try {
        await this.$api.put(`semesters/${semester.id}`, params)
      } catch (e) {
        this.$showError(this.$getErrors(e.errors))
      }
    },

    async updateSemesters () {
      await this.updateSemestersHandler(this.selectedSemesters, this.semesterProperty)
      this.setSemesterProperty('')
    },
  },

  destroyed () {
    this.unsubscribe()
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.sub-table {
  ::v-deep {
    .pcg-table-content {
      background: $pcg-the-lightest-gray;
      tbody {
        .pcg-table-narrow-row {
          td {
            border-bottom: 1px solid $pcg-lightest-gray !important;
          }
        }
      }
    }
  }
}

.grid {
  display: grid;
  grid-template-columns: 44px 44px 44px 120px 200px;

  .item-a {
    grid-column-start: 1;
    grid-column-end: 1;
    align-self: center;
  }

  .item-b {
    grid-column-start: 2;
    grid-column-end: 2;
    align-self: center;
  }

  .item-c {
    grid-column-start: 3;
    grid-column-end: 3;
    align-self: center;
  }

  .item-d {
    grid-column-start: 4;
    grid-column-end: 4;
    align-self: center;
  }

  .item-e {
    justify-self: center;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .sub-table {
    ::v-deep {
      .pcg-table-content {
        background: $pcg-the-lightest-gray;
        tbody {
          td {
            border-bottom: 1px solid $pcg-light-gray !important;
          }
        }
      }
    }
  }
}
</style>
