<template>
  <div v-if="automaticDownload"
       class="prk-fz-12 font-italic pcg-dark-gray-color">
    <img :src="require('../../../../assets/images/icons/cloud-inactive.svg')"/>
    {{ $t('components.prk_syllabus.section.automatic_download') }}
  </div>

  <div v-else class="prk-fz-12 font-italic pcg-dark-gray-color">
    <img :src="require('../../../../assets/images/icons/edit-inactive.svg')"
         class="pencil-icon"/>
    {{ $t('components.prk_syllabus.section.fill_user') }}
  </div>
</template>
<script>
export default {
  name: 'PrkTypeCompletion',
  props: {
    automaticDownload: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
