<template>
  <div class="d-flex align-items-center justify-content-end h-100">
    <span class="mr-3">
      {{ selectedLength }}
      <span class="text-lowercase">
        {{ $t('components.prk_syllabus.beam.selected_elements') }}
      </span>
    </span>
    <pcg-btn round variant="danger" class="prk-button" :text="deleteText" :icon="iconRemove"
             @click="$emit('remove:elements')"
    />
  </div>
</template>

<script>
import useIcon from '../../composables/useIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'SelectedElementsBeam',
  setup (_, { root }) {
    const { iconRemove } = useIcon(root)
    return { iconRemove }
  },
  props: {
    selectedLength: { required: true, type: Number },
    type: { required: true, type: String },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    deleteText () {
      if (this.type === 'matrix') {
        return this.selectedLength > 1 ? 'delete_matrices' : 'delete_matrix'
      } else {
        return this.selectedLength > 1 ? 'delete_templates' : 'delete_template'
      }
    },
  },
  watch: {
    contrast () {
      const { iconRemove } = useIcon(this.$root)
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
