<template>
  <pcg-btn
      round
      text="download_doc"
      class="prk-button"
      :disabled="disabled"
      :icon="iconDoc"
      @click="downloadDoc"
  />
</template>

<script>
import useIcon from '../composables/useIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'DownloadDocButton',
  setup (_, { root }) {
    const { iconDoc } = useIcon(root)
    return { iconDoc }
  },
  props: {
    downloadDoc: { required: true, type: Function },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    contrast () {
      const { iconDoc } = useIcon(this.$root)
      this.iconDoc = iconDoc
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
