<template>
  <div :class="customClass">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'PrkSubHeading',
  props: {
    customClass: { type: String, default: 'sub-header' },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

.sub-header {
  padding: 1rem 2.14rem;
  background: $pcg-lightest-aqua-blue;
  color: $pcg-the-darkest-gray;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .sub-header {
    background: $pcg-lightest-aqua-blue;
    color: $pcg-the-darkest-gray;
  }
}
</style>
