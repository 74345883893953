<template>
  <div class="dictionaries-fields-list">
    <div class="d-sm-flex justify-content-between align-items-center">
      <pcg-search
          class="mb-3 mb-lg-0"
          :class="{ 'w-100': $mq !== 'lg' && $mq !== 'xl' }"
          :placeholder="$t('general.search')"
          @input="setQuery"
      />
      <pcg-btn variant="additional" v-b-modal.add-modal size="small"
               class="w-100 w-sm-auto m-0 font-weight-bold"
      >
        + {{ $t('general.new_literature') }}
      </pcg-btn>
    </div>
    <dictionary-table
        :edit-resource="editResource"
        :destroy-resource="destroyResource"
        :items="items"
        resource-name="literature"
        url="literatures"
        :excluded-fields="['id', 'supplementary']"
    />

    <pcg-pagination v-if="pagination.totalPages && !query.length"
                    :page-count="pagination.totalPages"
                    :total-records="pagination.totalRecords"
                    v-model="pagination.currentPage"
                    class="mt-4 prk-pagination"
    />

    <structure-modal
        :action="action(literature, 'literatures')"
        :btn-title="btnTitle"
        :editMode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="literature"
        @hidden="hide"
        @clear-error="clearError"
    >
      <pcg-checkbox v-model="literature.supplementary">
        {{ $t('general.supplementary_literature') }}
      </pcg-checkbox>
    </structure-modal>
  </div>
</template>

<script>
import DictionaryTable from '../../components/dictionaries/shared/DictionaryTable'
import StructureModal from '../../components/dictionaries/shared/StructureModal'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import { required, numeric } from 'vuelidate/lib/validators'
import { dictionary } from '../../mixins/dictionary'
import usePagination from '../../composables/usePagination'

export default {
  name: 'DictionaryLiteratures',
  setup (_, { root }) {
    const { pagination, setPagination } = usePagination(root)
    const suffixes = {
      add: 'add_literature',
      edit: 'edit_literature',
      new: 'new_literature',
    }
    const {
      items, setItems, removeRow, findItemById,
    } = useList(root)
    const {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      title,
      updateResource,
    } = useDictionary(root, suffixes)
    return {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      errors,
      editResource,
      findItemById,
      items,
      pagination,
      removeRow,
      setItems,
      setPagination,
      title,
      updateResource,
    }
  },
  validations: {
    literature: {
      title: { required },
      author: { required },
      year: { numeric },
    },
  },
  components: {
    StructureModal,
    DictionaryTable,
  },
  mixins: [dictionary],
  jsonapi: {
    literatures: {
      config: ({ query, page }) => ({
        method: 'get',
        url: 'literatures',
        params: {
          raw: !query.length,
          pagination: !query.length,
          page: page,
          query: query,
          size: 15,
        },
      }),
      variables () {
        return {
          page: this.pagination.currentPage,
          query: this.query,
        }
      },
      update (literatures, requestInfo) {
        if (!this.query) this.setPagination(requestInfo.raw.data)
        return literatures.map(literature => {
          return this.$_.omit(literature, '__type')
        })
      },
    },
  },
  data () {
    return {
      literature: {
        title: '',
        author: '',
        publishingHouse: '',
        placeOfPublication: '',
        year: '',
        supplementary: false,
        [Symbol.for('_innerName')]: 'literature',
      },
      literatures: [],
      query: '',
    }
  },
  watch: {
    'pagination.currentPage' () {
      this.$jsonapi.queries.literatures.refetch()
    },
    literatures () {
      this.setItems(this.literatures)
    },
  },
  created () {
    this.$watch('literature.year', this.$_.debounce(() => {
      this.$v.literature.$touch()
      if (this.$v.literature.year.$invalid) {
        this.$set(this.errors, 'year', this.$t('general.not_number').toLowerCase())
      } else {
        this.errors.year = ''
      }
    }))
  },
  methods: {
    setQuery: _.debounce(function (query) {
      this.query = query
    }, 200),
    clearError (key) {
      if (key === 'year') return
      this.errors[key] = ''
    },
    hide () {
      this.hideModal('literature', ['supplementary'])
      this.literature.supplementary = false
    },
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$t('general.literatures'),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>
