<template>
  <prk-select
      color="aqua"
      class="w-100"
      :includeBlank="true"
      :label="$tc('general.faculty')"
      :options="faculties"
      :value="$route.query.faculty_id"
      @input="$emit('filter:faculty', $event)"
  />
</template>

<script>
import PrkSelect from '../PrkSelect'

export default {
  name: 'FacultyFilter',
  components: {
    PrkSelect,
  },
  props: {
    faculties: { required: true, type: Array },
  },
}
</script>
