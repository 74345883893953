<template>
  <fragment>
    <p><span class="font-weight-bolder">{{ $t('general.plan_name')}}: </span> {{ plan.name }}</p>
    <p> <span class="font-weight-bolder">{{ $t('general.faculty_name') }}: </span>{{ plan.facultyName }}</p>
    <p> <span class="font-weight-bolder">{{ $t('general.course_name') }}: </span>{{ plan.courseName }}</p>
    <p> <span class="font-weight-bolder">{{ $tc('general.levels') }}: </span>{{ plan.typeName }}</p>
    <p> <span class="font-weight-bolder">{{ $tc('general.kind') }}: </span>{{ plan.kindName }}</p>
  </fragment>
</template>

<script>
export default {
  name: 'PlanInfo',
  props: {
    plan: { required: true, type: Object },
  },
}
</script>
