<template>
  <b-row align-v="center" class="mb-3">
    <b-col cols="auto">
      <pcg-popover variant="additional" triggers="hover" @click="showNewEffects">
        <span class="font-weight-bold">
          {{ $t('general.assign_effects_of_general_characteristics') }}
        </span>
        <template #popoverBody>
          <span class="font-weight-bold">
            {{ $t('components.prk_modals.course_effect.must_select_category') }}
          </span>
        </template>
      </pcg-popover>
    </b-col>
    <b-col>
      <prk-tags
          :value="tags"
          :style="{ marginBottom: '-0.5rem' }"
          class-name="mb-2"
          @input="updateEffects($event)"
      />
    </b-col>
  </b-row>
</template>

<script>
import PrkTags from '../../../PrkTags'

export default {
  name: 'AssignGeneralEffects',
  components: {
    PrkTags,
  },
  props: {
    categoryId: { required: true, type: String },
    effects: { required: true, type: Array },
  },
  computed: {
    tags () {
      return this.effects.map(effect => {
        return {
          value: effect.id,
          text: effect.symbol,
        }
      })
    },
  },
  methods: {
    showNewEffects () {
      if (this.categoryId) this.$bvModal.show('characteristic-effects-modal')
      else this.$emit('update:touchCategories')
    },
    updateEffects (newEffects) {
      const newEffectIds = newEffects.map(effect => effect.value)
      const effects = this.effects.filter(effect => newEffectIds.includes(effect.id))
      this.$emit('update:effects', effects)
    },
  },
}
</script>
