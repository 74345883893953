<template>
  <b-dropdown
      toggle-class="text-decoration-none"
      no-caret
      class="prk prk-dropdown"
      @shown="active = true"
      @hidden="active = false"
      right
      boundary="window"
  >
    <template v-slot:button-content>
      <pcg-btn v-if="!active" round :icon="iconOptions" text="show_options" class="prk-button" v-on="$listeners"/>
      <pcg-btn v-else class="prk prk-button">
        <base-icon width="16" height="5" :icon-color="iconColor">
          <icon-options/>
        </base-icon>
      </pcg-btn>
    </template>
    <b-dropdown-item
        v-for="(option, index) in options"
        @click="option.action"
        :key="index"
    >
      {{ option.text }}
    </b-dropdown-item>
  </b-dropdown>
</template>

<script>
import useIcon from '../composables/useIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'PrkIconOptions',
  setup (_, { root }) {
    const { iconOptions } = useIcon(root)
    return { iconOptions }
  },
  data () {
    return {
      active: false,
    }
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
  },
  watch: {
    contrast () {
      const { iconOptions } = useIcon(this.$root)
      this.iconOptions = iconOptions
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
    iconColor () {
      return this.contrast ? '#008A39' : '#253B80'
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  &.dropdown.prk .btn {
    background: transparent;
    border-color: transparent;
    box-shadow: none;
    padding: 0;
  }
}

.prk-dropdown.show {
  ::v-deep {
    .pcg-button {
      background: $hover-color;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep {
      &.pcg-button {
        background-color: $pcg-dark-green !important;
      }
    }
  }
  .prk-dropdown.show {
    ::v-deep {
      .pcg-button {
        background: $hover-color;
      }
    }
  }
}
</style>
