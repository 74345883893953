<template>
  <div class="index" :style="{ width: indexWidth, height: indexHeight }">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'PrkIndex',
  props: {
    width: {
      type: Object,
      required: true,
    },
    height: {
      type: Object,
      required: true,
    },
  },
  computed: {
    indexWidth () {
      return `${this.width.value}${this.width.unit}`
    },
    indexHeight () {
      return `${this.height.value}${this.height.unit}`
    },
  },
}
</script>

<style scoped lang="scss">
  @import './app/javascript/prk/assets/stylesheets/vars';

  .index {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 2px 15px;
    background: $pcg-lightest-gray;
    border-radius: 5px;
    font-size: 0.86rem;
    color: $pcg-dark-gray;
  }
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .index {
    background: $pcg-lightest-gray;
    color: $pcg-dark-gray;
  }
}
</style>
