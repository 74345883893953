<template>
  <pcg-modal
      modal-id="addLiterature"
      size="md"
      :btn-title="$t('components.prk_syllabus.buttons.add_element')"
      icon="pcg-icon-document"
      :title="$t('components.prk_syllabus.modal.literature.add_literature')"
      :action="saveLiterature"
      @shown="literature.supplementary = supplementary"
      @hidden="clearAll"
  >
    <pcg-text-input
        v-for="(value, name) in literatureFields"
        :key="name"
        class="mb-3"
        :value="value"
        :label="$t(`${inputLang}.${$_.snakeCase(name)}`)"
        :type="name === 'year' ? 'number' : 'text'"
        :required="['author', 'title'].includes(name)"
        :error-text="$t('general.errors.required')"
        :show-error="['author', 'title'].includes(name) ? $v.literature[name].$error : false"
        @input="literature[name] = $event"
    />
    <pcg-checkbox v-if="showCheckbox" v-model="literature.supplementary">
      {{ $t('general.supplementary_literature') }}
    </pcg-checkbox>
  </pcg-modal>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import { required } from 'vuelidate/lib/validators'
const { mapActions } = createNamespacedHelpers('syllabusFill')

export default {
  name: 'add-literature-modal',
  props: {
    supplementary: { type: Boolean, default: false },
    showCheckbox: { type: Boolean, default: false },
  },
  data () {
    return {
      literature: {
        author: '',
        title: '',
        publishingHouse: '',
        placeOfPublication: '',
        year: '',
        supplementary: false,
      },
    }
  },
  validations: {
    literature: {
      author: { required },
      title: { required },
    },
  },
  computed: {
    inputLang () {
      return 'components.prk_syllabus.modal.literature'
    },
    literatureFields () {
      return this.$_.omit(this.literature, 'supplementary')
    },
  },
  methods: {
    ...mapActions(['addLiterature']),
    saveLiterature () {
      this.$v.$touch()
      if (this.$v.$invalid) return
      const literature = {
        ...this.literature,
        customId: this.$uuidv4(),
      }
      this.addLiterature(literature)
      this.$bvModal.hide('addLiterature')
    },
    clearAll () {
      this.$v.$reset()
      for (const key in this.literatureFields) {
        this.literature[key] = ''
      }
      this.literature.supplementary = false
    },
  },
}
</script>
