<template>
  <g>
    <path v-if="contrast"
        d="M10.7648 10.5938C9.86797 10.5938 9.43672 11.125 7.96484 11.125C6.49297 11.125 6.06484 10.5938 5.16484 10.5938C2.84609 10.5938 0.964844 12.5926 0.964844 15.0562V15.9062C0.964844 16.7861 1.63672 17.5 2.46484 17.5H13.4648C14.293 17.5 14.9648 16.7861 14.9648 15.9062V15.0562C14.9648 12.5926 13.0836 10.5938 10.7648 10.5938ZM13.4648 15.9062H2.46484V15.0562C2.46484 13.4758 3.67734 12.1875 5.16484 12.1875C5.62109 12.1875 6.36172 12.7188 7.96484 12.7188C9.58047 12.7188 10.3055 12.1875 10.7648 12.1875C12.2523 12.1875 13.4648 13.4758 13.4648 15.0562V15.9062ZM7.96484 10.0625C10.4492 10.0625 12.4648 7.9209 12.4648 5.28125C12.4648 2.6416 10.4492 0.5 7.96484 0.5C5.48047 0.5 3.46484 2.6416 3.46484 5.28125C3.46484 7.9209 5.48047 10.0625 7.96484 10.0625ZM7.96484 2.09375C9.61797 2.09375 10.9648 3.5248 10.9648 5.28125C10.9648 7.0377 9.61797 8.46875 7.96484 8.46875C6.31172 8.46875 4.96484 7.0377 4.96484 5.28125C4.96484 3.5248 6.31172 2.09375 7.96484 2.09375Z"
        fill="#00FA53"/>
    <path v-else
        d="M10.7648 10.5938C9.86797 10.5938 9.43672 11.125 7.96484 11.125C6.49297 11.125 6.06484 10.5938 5.16484 10.5938C2.84609 10.5938 0.964844 12.5926 0.964844 15.0562V15.9062C0.964844 16.7861 1.63672 17.5 2.46484 17.5H13.4648C14.293 17.5 14.9648 16.7861 14.9648 15.9062V15.0562C14.9648 12.5926 13.0836 10.5938 10.7648 10.5938ZM13.4648 15.9062H2.46484V15.0562C2.46484 13.4758 3.67734 12.1875 5.16484 12.1875C5.62109 12.1875 6.36172 12.7188 7.96484 12.7188C9.58047 12.7188 10.3055 12.1875 10.7648 12.1875C12.2523 12.1875 13.4648 13.4758 13.4648 15.0562V15.9062ZM7.96484 10.0625C10.4492 10.0625 12.4648 7.9209 12.4648 5.28125C12.4648 2.6416 10.4492 0.5 7.96484 0.5C5.48047 0.5 3.46484 2.6416 3.46484 5.28125C3.46484 7.9209 5.48047 10.0625 7.96484 10.0625ZM7.96484 2.09375C9.61797 2.09375 10.9648 3.5248 10.9648 5.28125C10.9648 7.0377 9.61797 8.46875 7.96484 8.46875C6.31172 8.46875 4.96484 7.0377 4.96484 5.28125C4.96484 3.5248 6.31172 2.09375 7.96484 2.09375Z"
        fill="#00B4EB"/>
  </g>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IconUser',
  computed: {
    ...mapGetters('page', ['contrast']),
  },
}
</script>

<style scoped>

</style>
