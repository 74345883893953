<template>
  <div>
    <div class="d-flex align-items-center mb-3">
      <prk-index v-if="showHeadlineIndex" class="mr-3"
        :width="width"
        :height="height"
      >
        {{ index }}
      </prk-index>
      <h3 class="m-0 font-weight-bold pcg-main-color prk-fz-24">
        <slot />
      </h3>
    </div>
    <prk-horizontal-line
      :width="{ value: -2.14, unit: 'rem' }"
    />
  </div>
</template>

<script>
import PrkIndex from '../../PrkIndex'
import PrkHorizontalLine from '../PrkHorizontalLine'

export default {
  name: 'PreviewHeader',
  components: {
    PrkIndex,
    PrkHorizontalLine,
  },
  props: {
    width: { type: Object, default: () => ({ value: 47, unit: 'px' }) },
    height: { type: Object, default: () => ({ value: 26, unit: 'px' }) },
    index: { required: true, type: String },
    showHeadlineIndex: { type: Boolean, default: false },
  },
}
</script>

<style scoped lang="scss">
@import "app/javascript/prk/assets/stylesheets/vars.scss";

</style>
