<template>
  <pcg-modal
      modal-id="generateSyllabusModal"
      size="md"
      scrollable
      :title=" $t('components.prk_matrices.generate_syllabuses')"
      icon="pcg-icon-document"
      :btn-title=" $t('components.prk_matrices.generate_syllabuses')"
      :action="() => action(selectedSubjectNameIds, selectedSemesterIds)">

    <square-semester-select
        v-if="!$jsonapi.loading"
        :countSemesters="countSemesters"
        @change="selectSemester"
    />

    <prk-table
        class="mt-3"
        :fields-props="{ thAttr: thAttr, tdClass: 'py-4' }"
        :items="subjects"
        :headers-props="['id', 'name', 'specialityName', 'specializationName']"
        disable-auto-headers
        :show-headline="false"
        disable-actions
    >
      <template v-slot:head(id)>
        <pcg-checkbox :value="selectedAll" @input="selectAll"/>
      </template>

      <template #cell(id)="{ item: subject }">
        <pcg-checkbox v-model="subject.selected"/>
      </template>
    </prk-table>
  </pcg-modal>
</template>

<script>
import PrkTable from '../../PrkTable'
import SquareSemesterSelect from './SquareSemesterSelect'
import useMatrixSubjectNames from '../../../composables/useMatrixSubjectNames'
import { ref } from '@vue/composition-api'

export default {
  name: 'PrkGenerateSyllabus',
  setup () {
    const matrixSubjectNames = ref([])
    const {
      selectedAll,
      selectedSubjectNameIds,
    } = useMatrixSubjectNames(matrixSubjectNames)
    return {
      selectedAll,
      matrixSubjectNames,
      selectedSubjectNameIds,
    }
  },
  components: {
    SquareSemesterSelect,
    PrkTable,
  },
  props: {
    action: { type: Function, required: true },
    refetch: { required: true, type: Boolean },
  },
  jsonapi: {
    matrixSubjectNames: {
      config: ({ matrixId }) => ({
        method: 'get',
        url: `matrix_subject_names?matrix_id=${matrixId}`,
      }),
      variables () {
        return {
          matrixId: this.$route.params.id,
        }
      },
      update (matrixSubjectNames) {
        return matrixSubjectNames.map(msn => {
          return {
            ...this.$_.omit(msn, '__type'),
            selected: false,
          }
        })
      },
    },
  },
  data () {
    return {
      selectedSemesters: [],
    }
  },
  computed: {
    countSemesters () {
      return this.$_.max(this.semesterNumbers) || 0
    },
    semesterNumbers () {
      return this.matrixSubjectNames.map(msn => msn.semesterData)
        .flat().map(semData => semData.semesterNumber)
    },
    subjects () {
      return this.matrixSubjectNames.filter(subject => {
        return this.selectedSemesters.some(semesterNumber => {
          return subject.semesterData
            .map(semData => semData.semesterNumber)
            .includes(semesterNumber)
        })
      }).sort((a, b) => a.name.localeCompare(b.name))
    },
    selectedSemesterIds () {
      return this.$_.uniq(this.matrixSubjectNames.map(msn => msn.semesterData).flat()
        .filter(semData => this.selectedSemesters.includes(semData.semesterNumber))
        .map(semData => semData.id))
    },
  },
  watch: {
    async refetch () {
      await this.$jsonapi.queries.matrixSubjectNames.refetch()
      this.$emit('clear:refetch')
    },
  },
  methods: {
    thAttr (value, key) {
      return key === 'id' ? { style: 'width:2em' } : {}
    },
    selectAll (selected) {
      selected ? this.$selectAll(this.subjects) : this.$unselectAll(this.subjects)
    },
    selectSemester (selectedSemesters) {
      this.selectedSemesters = selectedSemesters
      this.$_.difference(this.matrixSubjectNames, this.subjects).forEach(subject => {
        subject.selected = false
      })
    },
  },
}
</script>
