var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-sm-flex justify-content-end"},[_c('pcg-btn',{directives:[{name:"b-modal",rawName:"v-b-modal.add-modal",modifiers:{"add-modal":true}}],staticClass:"w-100 w-sm-auto m-0 font-weight-bold",attrs:{"variant":"additional","size":"small"}},[_vm._v("\n      + "+_vm._s(_vm.$t('general.add'))+"\n    ")])],1),_vm._v(" "),(_vm.items.length > 0)?_c('prk-table',{staticClass:"prk-shadow mt-3",attrs:{"excluded-fields":_vm.excludedFields,"items":_vm.items,"show-headline":false,"fields-props":{ thClass: 'pt-4_5'}},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return [_c('prk-col-group',{attrs:{"columns":['id'],"fields":fields,"width":"5%"}})]}},{key:"head(id)",fn:function(){return [_c('pcg-checkbox',{attrs:{"value":_vm.selectedAll},on:{"input":_vm.selectGeneralEffects}})]},proxy:true},{key:"cell(id)",fn:function(ref){
var generalEffect = ref.item;
return [_c('pcg-checkbox',{attrs:{"value":generalEffect.selected},on:{"input":function($event){return _vm.selectGeneralEffect(generalEffect, $event)}}})]}},{key:"head(active)",fn:function(){return undefined},proxy:true},{key:"content",fn:function(ref){
var header = ref.header;
var data = ref.data;
return [(header.key === 'name')?_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.top",value:(_vm.$t(_vm.colorPopoverLang)),expression:"$t(colorPopoverLang)",modifiers:{"hover":true,"top":true}}],staticClass:"prk-color-status mr-3 position-static",style:({backgroundColor: data.item.color})}),_vm._v("\n        "+_vm._s(data.item.name)+"\n      ")]):(header.key === 'actions')?_c('div',[_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('div',{staticClass:"d-flex justify-content-start align-items-end mr-5 w-100"},[_c('active-switch',{attrs:{"active":data.item.active,"update-resource":_vm.updateResource,"url":"general_effects","item":data.item},on:{"update:active":function($event){return _vm.$set(data.item, "active", $event)}}})],1),_vm._v(" "),_vm._l((_vm.buttons),function(button,index){return _c('pcg-btn',{key:index,staticClass:"m-0 mr-3 font-weight-bold",attrs:{"size":"small","variant":"additional"},on:{"click":function($event){return button.action(data.item.id, data.item.name, data.item.studyProfile, data.item.type)}}},[_c('span',{staticClass:"font-weight-bold prk-fz-12"},[_vm._v("\n              "+_vm._s(button.text)+"\n            ")])])}),_vm._v(" "),_c('pcg-btn',{staticClass:"mr-3 prk-button",attrs:{"round":"","text":"edit","icon":_vm.iconEdit},on:{"click":function($event){return _vm.editResource(data.item.id,
                   'generalEffect')}}}),_vm._v(" "),_c('pcg-btn',{staticClass:"mr-3 prk-button",attrs:{"round":"","icon":_vm.iconRemove,"text":"delete","variant":"danger"},on:{"click":function($event){return _vm.destroyResource('general_effects',
                   data.item.id, data.index)}}}),_vm._v(" "),_c('fields-modal',{attrs:{"id":data.item.id,"title":((data.item.name) + " - " + (_vm.$tc('general.field', 2)))}})],2)]):_vm._e()]}}],null,false,2913941550)}):_vm._e(),_vm._v(" "),_c('prk-color-modal',_vm._b({attrs:{"action":_vm.action(_vm.generalEffect, 'general_effects'),"btn-title":_vm.editMode ? _vm.$t('general.update') : _vm.$t('general.add'),"edit-mode":_vm.editMode,"errors":_vm.errors,"modalId":_vm.editMode ? 'edit-modal' : 'add-modal',"title-modal":_vm.editMode ? _vm.$t('general.edit') : _vm.$t('general.add')},on:{"hidden":function($event){return _vm.hideModal('generalEffect')},"clear-error":function($event){_vm.errors[$event] = ''}}},'prk-color-modal',_vm.generalEffect,false,true)),_vm._v(" "),(_vm.selectedGeneralEffects.length > 0)?_c('dictionary-general-effects-beam',{attrs:{"general-effects":_vm.selectedGeneralEffects}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }