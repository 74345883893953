import api from '../../api'

export const CoursesTablePrintouts = {
  computed: {
    options () {
      return [
        {
          text: this.$t('components.prk_courses.settings.download_percentage_share_pdf'),
          action: () => this.print('courses/download_areas',
            { id: this.courseId, type: 'pdf' },
            this.$t('components.prk_courses.settings.download_percentage_share'),
          ),
        },
        {
          text: this.$t('components.prk_courses.settings.download_percentage_share_doc'),
          action: () => this.print('courses/download_areas',
            { id: this.courseId, type: 'doc' },
            this.$t('components.prk_courses.settings.download_percentage_share'),
          ),
        },
        {
          text: this.$t('components.prk_courses.settings.download_relating_course_effects_to_general_effects_pdf'),
          action: () => this.print('course_effects/download_pdf',
            { course_id: this.courseId, type: 'pdf' },
            this.$t('components.prk_courses.settings.download_relating_course_effects_to_general_effects')),
        },
        {
          text: this.$t('components.prk_courses.settings.download_relating_course_effects_to_general_effects_doc'),
          action: () => this.print('course_effects/download_doc',
            { course_id: this.courseId, type: 'doc' },
            this.$t('components.prk_courses.settings.download_relating_course_effects_to_general_effects')),
        },
        {
          text: this.$t('components.prk_courses.settings.download_printout_coverage_general_effects_by_course_effects_pdf'),
          action: () => this.print('characteristic_effects/download_pdf',
            { course_id: this.courseId, type: 'pdf' },
            this.$t('components.prk_courses.settings.download_printout_coverage_general_effects_by_course_effects')),
        },
        {
          text: this.$t('components.prk_courses.settings.download_printout_coverage_general_effects_by_course_effects_doc'),
          action: () => this.print('characteristic_effects/download_doc',
            { course_id: this.courseId, type: 'doc' },
            this.$t('components.prk_courses.settings.download_printout_coverage_general_effects_by_course_effects')),
        },
        // {
        //   text: this.$t('components.prk_courses.settings.download_table_general_effects_for_course_pdf'),
        //   action: () => this.print('general_effects/download_pdf',
        //     { course_id: this.courseId, type: 'pdf' },
        //     this.$t('components.prk_courses.settings.download_table_general_effects_for_course')),
        // },
        // {
        //   text: this.$t('components.prk_courses.settings.download_table_general_effects_for_course_doc'),
        //   action: () => this.print('general_effects/download_doc',
        //     { course_id: this.courseId, type: 'doc' },
        //     this.$t('components.prk_courses.settings.download_table_general_effects_for_course')),
        // },
      ]
    },
  },
  methods: {
    print (url, params, fileName) {
      api.post(url, params,
        { responseType: 'blob' }).then((response) => {
        this.$downloadFile(response, params.type, fileName)
      })
    },
  },
}
