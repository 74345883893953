const getDefaultState = () => {
  return {
    subjectEffects: [],
    teachingEffects: [],
    keks: [],
    literatures: [],
    didacticMethods: [],
    leaders: [],
    comments: [],
    ownWork: 0,
    workWithTeacher: 0,
    planHours: 0,
    ects: 0,
    hasTeachingEffects: false,
    language: '',
  }
}

export const syllabusFill = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    getSubjectEffects (state) {
      return state.subjectEffects
    },
    getOwnWork (state) {
      return state.ownWork
    },
    getWorkWithTeacher (state) {
      return state.workWithTeacher
    },
    getSumOfHours (state) {
      return +state.planHours + +state.ownWork + +state.workWithTeacher
    },
    getLiteratures (state) {
      return state.literatures
    },
    getDidacticMethods (state) {
      return state.didacticMethods
    },
    getLeaders (state) {
      return state.leaders
    },
  },
  mutations: {
    SET_LANGUAGE (state, language) {
      state.language = language
    },
    SET_COMMENT (state, comments) {
      state.comments = comments
    },
    SET_SUBJECT_EFFECTS (state, subjectEffects) {
      state.subjectEffects = subjectEffects
    },
    SET_TEACHING_EFFECTS (state, teachingEffects) {
      state.teachingEffects = teachingEffects
    },
    ADD_SUBJECT_EFFECT (state, subjectEffect) {
      state.subjectEffects.push(subjectEffect)
    },
    SET_SUBJECT_EFFECT (state, subjectEffect) {
      const index = state.subjectEffects.findIndex(eff => eff.id === subjectEffect.id)
      this.$app.$set(state.subjectEffects, index, subjectEffect)
    },
    REMOVE_SUBJECT_EFFECT (state, subjectEffectId) {
      const index = state.subjectEffects.findIndex(effect => effect.id === subjectEffectId)
      if (~index) {
        state.subjectEffects.splice(index, 1)
      }
    },
    SET_FORM_OF_CLASS (state, payload) {
      const index = state.subjectEffects.findIndex(effect => effect.id === payload.effectId)
      if (~index) {
        state.subjectEffects[index].formOfClassId = payload.formOfClassId
      }
    },
    ADD_FORM (state, payload) {
      const index = state.subjectEffects.findIndex(effect => effect.id === payload.effectId)
      if (~index) {
        if (payload.formOfCredit) {
          state.subjectEffects[index].formOfCredits.push(payload.form)
        } else {
          state.subjectEffects[index].formOfClasses.push(payload.form)
        }
      }
    },
    REMOVE_FORM (state, payload) {
      const effectIndex = state.subjectEffects.findIndex(effect => effect.id === payload.effectId)
      if (!~effectIndex) return

      const formOfCredits = state.subjectEffects[effectIndex].formOfCredits
      const creditIndex = formOfCredits.findIndex(credit => credit.value === payload.formId)
      const formOfClasses = state.subjectEffects[effectIndex].formOfClasses
      const formOfClassIndex = formOfClasses.findIndex(formOfClass => formOfClass.value === payload.formId)

      if (~creditIndex) {
        state.subjectEffects[effectIndex].formOfCredits.splice(creditIndex, 1)
      }
      if (~formOfClassIndex) {
        state.subjectEffects[effectIndex].formOfClasses.splice(formOfClassIndex, 1)
      }
    },
    SET_LEADERS (state, leaders) {
      state.leaders = leaders
    },
    ADD_LEADER (state, leaderObjc) {
      const exist = !state.leaders.some(leader => leader.value === leaderObjc.value)
      if (exist) {
        state.leaders.push(leaderObjc)
      }
    },
    REMOVE_LEADER (state, leaderId) {
      const index = state.leaders.findIndex(leader => leader.value === leaderId)
      if (~index) {
        state.leaders.splice(index, 1)
      }
    },
    SET_OWN_WORK (state, value) {
      state.ownWork = value
    },
    SET_WORK_WITH_TEACHER (state, value) {
      state.workWithTeacher = value
    },
    SET_PLAN_HOURS (state, value) {
      state.planHours = value
    },
    SET_ECTS (state, value) {
      state.ects = value
    },
    ADD_LITERATURE (state, literature) {
      let exist = true
      if (Object.prototype.hasOwnProperty.call(literature, 'id')) {
        exist = !state.literatures.some(lit => lit.id === literature.id)
      }
      if (exist) state.literatures.push(literature)
    },
    REMOVE_LITERATURE (state, payload) {
      let index
      if (payload.id) {
        index = state.literatures.findIndex(literature => literature.id === payload.id)
      } else {
        index = state.literatures.findIndex(literature => literature.customId === payload.customId)
      }
      if (~index) {
        state.literatures.splice(index, 1)
      }
    },
    SET_LITERATURES (state, literatures) {
      state.literatures = literatures
    },
    SET_DIDACTIC_METHODS (state, didacticMethods) {
      state.didacticMethods = didacticMethods
    },
    ADD_DIDACTIC_METHOD (state, payload) {
      state.didacticMethods.push(payload)
    },
    UPDATE_DIDACTIC_METHOD_TEXT (state, payload) {
      const didacticMethod = state.didacticMethods
        .find(method => method.formOfClassId === payload.formOfClassId)
      if (didacticMethod) {
        didacticMethod.text = payload.text
      }
    },
    SET_HAS_TEACHING_EFFECTS (state, value) {
      state.hasTeachingEffects = value
    },
    ADD_COMMENT (state, comment) {
      state.comments.push(comment)
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    setSubjectEffects ({ commit }, effects) {
      commit('SET_SUBJECT_EFFECTS', effects)
    },
    setTeachingEffects ({ commit }, effects) {
      commit('SET_TEACHING_EFFECTS', effects)
    },
    addSubjectEffect ({ commit }, effect) {
      commit('ADD_SUBJECT_EFFECT', effect)
    },
    setSubjectEffect ({ commit }, effect) {
      commit('SET_SUBJECT_EFFECT', effect)
    },
    removeSubjectEffect ({ commit }, effectId) {
      commit('REMOVE_SUBJECT_EFFECT', effectId)
    },
    setFormOfClass ({ commit }, payload) {
      commit('SET_FORM_OF_CLASS', payload)
    },
    addForm ({ commit }, payload) {
      commit('ADD_FORM', payload)
    },
    removeForm ({ commit }, payload) {
      commit('REMOVE_FORM', payload)
    },
    setLeaders ({ commit }, leaders) {
      commit('SET_LEADERS', leaders)
    },
    addLeader ({ commit }, leader) {
      commit('ADD_LEADER', leader)
    },
    removeLeader ({ commit }, leaderId) {
      commit('REMOVE_LEADER', leaderId)
    },
    setOwnWork ({ commit }, value) {
      commit('SET_OWN_WORK', value)
    },
    setWorkWithTeacher ({ commit }, value) {
      commit('SET_WORK_WITH_TEACHER', value)
    },
    setPlanHours ({ commit }, value) {
      commit('SET_PLAN_HOURS', value)
    },
    setEcts ({ commit }, value) {
      commit('SET_ECTS', value)
    },
    setLiteratures ({ commit }, value) {
      commit('SET_LITERATURES', value)
    },
    addLiterature ({ commit }, literature) {
      commit('ADD_LITERATURE', literature)
    },
    removeLiterature ({ commit }, literatureId) {
      commit('REMOVE_LITERATURE', literatureId)
    },
    setDidacticMethods ({ commit }, payload) {
      commit('SET_DIDACTIC_METHODS', payload)
    },
    addDidacticMethod ({ commit }, payload) {
      commit('ADD_DIDACTIC_METHOD', payload)
    },
    updateDidacticMethodText ({ commit }, payload) {
      commit('UPDATE_DIDACTIC_METHOD_TEXT', payload)
    },
    setHasTeachingEffects ({ commit }, value) {
      commit('SET_HAS_TEACHING_EFFECTS', value)
    },
    addComment ({ commit }, comment) {
      commit('ADD_COMMENT', comment)
    },
    setComments ({ commit }, comments) {
      commit('SET_COMMENT', comments)
    },
    resetState ({ commit }) {
      commit('RESET_STATE')
    },
    setLanguage ({ commit }, language) {
      commit('SET_LANGUAGE', language)
    },
  },
}
