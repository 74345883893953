<template>
  <b-col cols="12" sm="auto" class="mt-3 mt-sm-0">
    <span class="add-new-element font-weight-bold ml-3 pointer text-nowrap pcg-main-active-color"
          v-b-modal.addLiterature
          @click="$emit('click')"
    >
      + {{ $t('components.prk_syllabus.buttons.add_new_element') }}
    </span>
  </b-col>
</template>

<script>
export default {
  name: 'LiteratureSectionNewElement',
}
</script>
