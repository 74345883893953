<template>
  <div class="d-flex mt-5 ml-5 mb-4">
      <span
          v-for="(item, index) in baseInfo"
          :key="index"
          class="font-weight-bold pcg-dark-gray-color prk-fz-12 mr-4"
      >
        <span v-if="['kind', 'type'].includes(index)">
          {{ $t(`components.prk_syllabus.table.${index}`) }}: {{ item }}
        </span>
<!--        <span v-else-if="index === 'studyProfile'">-->
<!--          {{item}}-->
<!--        </span>-->
<!--        <span v-else-if="index === 'academicYear'">-->
<!--          {{ item }}-->
<!--        </span>-->
        <span v-else>
          {{ item }}
        </span>
      </span>
  </div>
</template>

<script>
export default {
  name: 'matrix-base-info',
  props: {
    matrix: { required: true, type: Object },
  },
  computed: {
    baseInfo () {
      return {
        faculty: this.matrix.faculty,
        course: this.matrix.course,
        kind: this.matrix.kind,
        type: this.matrix.type,
        studyProfile: this.matrix.studyProfile,
        academicYear: this.matrix.academicYear,
      }
    },
  },
}
</script>
