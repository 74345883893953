<template>
  <div>
    <div class="d-sm-flex justify-content-end"
         @click="$bvModal.show('add-modal')">
      <pcg-btn variant="additional" size="small"
               class="w-100 w-sm-auto m-0 font-weight-bold">
        {{ $t('components.prk_dictionaries.new_category') }}
      </pcg-btn>
    </div>

    <dictionary-table
        :items="items"
        :edit-resource="editResource"
        :excluded-fields="['id']"
        :destroy-resource="destroyResource"
        resource-name="category"
        url="categories"
    />

    <category-form-modal
        :action="action(category, 'categories')"
        :btn-title="btnTitle"
        :errors="errors"
        :modal-id="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="category"
        @hidden="hideModal('category')"
        @clear-error="errors[$event] = ''"
    />

  </div>
</template>

<script>
import CategoryFormModal
  from '../../components/dictionaries/modal/CategoryFormModal'
import DictionaryTable
  from '../../components/dictionaries/shared/DictionaryTable'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import { dictionary } from '../../mixins/dictionary'

export default {
  name: 'DictionaryCategories',
  setup (props, { root }) {
    const {
      items, setItems, removeRow, findItemById,
    } = useList(root)
    const {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      updateResource,
    } = useDictionary(root)
    return {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      items,
      removeRow,
      setItems,
      updateResource,
    }
  },
  components: {
    DictionaryTable,
    CategoryFormModal,
  },
  mixins: [dictionary],
  data () {
    return {
      category: {
        name: '',
        shortcut: '',
        description: '',
        shortcutSubjectEffect: '',
        [Symbol.for('_innerName')]: 'category',
      },
    }
  },
  computed: {
    btnTitle () {
      if (this.editMode) {
        return this.$t('general.update')
      } else {
        return this.$t('general.save')
      }
    },
    title () {
      if (this.editMode) {
        return this.$t('general.edit_category')
      } else {
        return this.$t('general.add_category')
      }
    },
  },
  created () {
    this.getCategories()
  },
  methods: {
    async getCategories () {
      let { data: categories } = await this.$api.get('categories')
      categories = categories.map(category => {
        console.log('Category', category.attributes)
        return {
          id: category.id,
          name: category.attributes.name,
          shortcut: category.attributes.shortcut,
          description: category.attributes.description,
          shortcutSubjectEffect: category.attributes.shortcutSubjectEffect,
        }
      })
      this.setItems(categories)
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$t('views.header.dictionary.category'),
      })
    },
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>
