<template>
  <pcg-modal
      :btn-title="$t('general.save')"
      modal-id="statuses-modal"
      :title="$tc('general.status', 2)"
      :action="save"
  >
    <prk-statuses-select :status="status" v-model="status" class-name="light" show-all />
    <template #button-before>
      <pcg-btn variant="additional" @click="$bvModal.hide('statuses-modal')">
        <span class="font-weight-bold prk-fz-14">
          {{ $t('general.cancel') }}
        </span>
      </pcg-btn>
    </template>
  </pcg-modal>
</template>

<script>
import PrkStatusesSelect from '../../PrkStatusesSelect'
import useSyllabuses from '../../../composables/useSyllabuses'

export default {
  name: 'StatusesModal',
  setup (_, { root }) {
    const { setStatus } = useSyllabuses(root)
    return { setStatus }
  },
  components: { PrkStatusesSelect },
  props: {
    syllabuses: { required: true, type: Array },
  },
  data () {
    return {
      status: 'to_fill',
    }
  },
  methods: {
    async save () {
      for (const syllabus of this.syllabuses) {
        await this.setStatus({
          id: syllabus.id,
          matrixId: syllabus.matrixId,
          newStatus: this.status,
        })
      }
      this.$bvModal.hide('statuses-modal')
    },
  },
}
</script>
