import { ref, computed, watch, onBeforeMount } from '@vue/composition-api'
import useList from './useList'

export default function (config, params = {}) {
  const root = config.root
  const refs = config.refs

  const categories = ref([])
  const activeCategoryIndex = ref(null)
  const tmpCategoryId = ref('')
  const {
    setItems,
    items,
    checkedAll,
    getSelected,
    select,
    findItemById,
    removeRow,
    unSelectAll,
  } = useList(root)

  onBeforeMount(async () => {
    if (params.canGetCat === undefined || params.canGetCat) await getCategories()
    if (params.effect_type || params.url) await getAllEffects(params)
  })

  const categoryIds = computed(() => {
    return items.value.map(item => item.categoryId)
  })

  watch(categoryIds, () => {
    const catId = categories.value[activeCategoryIndex.value]?.value
    if (root.$_.uniq(categoryIds.value).length > 1 && activeCategoryIndex.value !== null) {
      setItems(items.value.filter(item => item.categoryId === catId))
    }
  })

  const changeCategory = async (index, event, args) => {
    if (refs) _changeCategoryTemplate(event)
    if (args?.offline) {
      if (activeCategoryIndex.value === index) {
        activeCategoryIndex.value = null
      } else {
        activeCategoryIndex.value = index
      }
      return
    }

    let data = null
    if (activeCategoryIndex.value === index) {
      const params = {
        effect_type: args?.effectType,
        url: args?.url,
        general_effect_id: args?.generalEffectId,
        course_id: args?.courseId,
      }
      activeCategoryIndex.value = null
      await getAllEffects(root.$_.omitBy(params, root.$_.isUndefined))
    } else {
      activeCategoryIndex.value = index
      const catId = categories.value[index].value
      const params = {
        effect_type: args?.effectType,
        category_id: catId,
        url: args?.url,
        general_effect_id: args?.generalEffectId,
        course_id: args?.courseId,
      }
      data = await _getEffects(root.$_.omitBy(params, root.$_.isUndefined))
      setItems(data)
    }
  }

  const checkIfCanAdd = () => {
    const catIndex = categories.value.findIndex(cat => {
      return cat.value === tmpCategoryId.value
    })
    if (activeCategoryIndex.value === null) {
      return true
    } else {
      return catIndex === activeCategoryIndex.value
    }
  }

  const getAllEffects = async (params = {}) => {
    const effects = []
    const data = await _getEffects(params)
    effects.push(...data)
    setItems(effects)
  }

  const getCategories = async () => {
    const { data: response } = await root.$api.get('categories')
    categories.value = response.map(category => {
      return {
        value: category.id,
        text: category.attributes.name,
        shortcut: category.attributes.shortcut,
        description: category.attributes.description,
        shortcutSubjectEffect: category.attributes.shortcutSubjectEffect,
      }
    })
  }

  const _changeCategoryTemplate = (event) => {
    const buttons = refs.categoriesRef || refs.allCategories.$refs.categoriesRef
    const category = buttons.find(category => {
      return category.classList.contains('active')
    })
    if (event) {
      event.target.classList.toggle('active')
    }
    if (category) {
      category.classList.remove('active')
    }
  }

  async function _getEffects (params = {}) {
    const url = params.url || 'effects'
    let { data: effects } = await root.$api.get(url, params)

    effects = effects.map(effect => {
      return {
        id: effect.id,
        symbol: effect.attributes.symbol,
        description: effect.attributes.description,
        enDescription: effect.attributes.enDescription,
        category: effect.attributes.category,
        categoryId: effect.attributes.categoryId,
        generalEffectId: effect.attributes.generalEffectId,
        range: effect.attributes.range,
        details: '',
        selected: false,
        toggleDetails: false,
        active: effect.attributes.active,
        effects: effect.attributes.effects,
        [Symbol.for('_innerName')]: 'effect',
      }
    })
    effects.forEach((effect, index) => {
      effects[index] = root.$_.omitBy(effect, root.$_.isUndefined)
    })
    return effects
  }

  return {
    activeCategoryIndex,
    categories,
    changeCategory,
    checkedAll,
    checkIfCanAdd,
    getAllEffects,
    findItemById,
    getSelected,
    unSelectAll,
    items,
    removeRow,
    select,
    setItems,
    tmpCategoryId,
  }
}
