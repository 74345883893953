<template>
  <hr :style="{ margin: margin }" :class="customClass">
</template>

<script>
export default {
  name: 'PrkHorizontalLine',
  props: {
    width: { type: Object, default: () => ({ value: 0, unit: 'rem' }) },
    customClass: { type: String, default: 'my-0' },
  },
  computed: {
    margin () {
      return `${this.width.value}${this.width.unit}`
    },
  },
}
</script>
