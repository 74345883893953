<template>
  <svg width="20" height="19" viewBox="0 0 26 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path v-if="contrast" d="M17.367 0.330688H0.101685V31.8912H25.1062V8.06988L17.367 0.330688ZM17.9616 4.0219L21.415 7.47526H17.9616V4.0219ZM22.8405 29.7414H2.36428V2.59329H15.8194V9.73786H22.964V29.7414H22.8405Z" fill="#00FA53"/>
    <path v-else d="M17.367 0.330688H0.101685V31.8912H25.1062V8.06988L17.367 0.330688ZM17.9616 4.0219L21.415 7.47526H17.9616V4.0219ZM22.8405 29.7414H2.36428V2.59329H15.8194V9.73786H22.964V29.7414H22.8405Z" fill="#00B4EB"/>
  </svg>

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'IconSubjectEffect',
  computed: {
    ...mapGetters('page', ['contrast']),
  },
}
</script>
