<template>
  <div class="d-flex align-items-center justify-content-between">
    <syllabus-acceptance-time-admin-actions
        v-if="syllabus.status === 'to_verification'"
        :date-time="syllabus.acceptanceDateTime"
    />
<!--    <syllabus-effects-button-admin-actions-->
<!--        :effects="syllabus.effects"-->
<!--        :syllabus-id="syllabusId"-->
<!--    />-->
    <syllabus-educators-admin-actions v-if="currentRole !== 'educator'" :syllabus-id="syllabusId"/>

    <comment-modal-button
        modal-id="allCommentsModal"
        :comment-count="commentsCount"
        @input="setSyllabusId(syllabus.id)"/>

    <template v-for="(button, index) in buttons">
      <base-button
          :key="index"
          class="mr-2 prk-button"
          :text="button.text"
          @click="button.action"
      >
        <base-icon :width="button.width" :height="button.height">
          <component :is="`icon-${button.type}`"/>
        </base-icon>
      </base-button>
    </template>
    <prk-icon-options :options="options" class="mr-3"/>
    <send-email-modal :syllabus="syllabus" :modal-id="`send-email-modal-${syllabus.id}`"/>
  </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import DidacticInitial from '../DidacticInitial'
import PrkIconOptions from '../../../PrkIconOptions'
import SendEmailModal from '../../modals/PrkSendEmailModal'
import CommentModalButton from '../../../PrkCommentModalButton'
import SyllabusEffectsButtonAdminActions from './admin_actions/SyllabusEffectsButtonAdminActions'
import SyllabusEducatorsAdminActions from './admin_actions/SyllabusEducatorsAdminActions'
import SyllabusAcceptanceTimeAdminActions from './admin_actions/SyllabusAcceptanceTimeAdminActions'
import useIcon from '../../../../composables/useIcon'
import { pdf } from '../../../../mixins/syllabus/pdf'
import { doc } from '../../../../mixins/syllabus/doc'
import api from '../../../../api'

const { mapActions } = createNamespacedHelpers('syllabus')

export default {
  name: 'AdminActions',
  setup (_, { root }) {
    const {
      iconEdit,
      iconBook,
      iconPrintSyllabus,
      iconSendEmail,
      iconSubjectEffect,
    } = useIcon(root)
    return {
      iconEdit,
      iconBook,
      iconPrintSyllabus,
      iconSendEmail,
      iconSubjectEffect,
    }
  },
  components: {
    SyllabusAcceptanceTimeAdminActions,
    SyllabusEducatorsAdminActions,
    SyllabusEffectsButtonAdminActions,
    CommentModalButton,
    PrkIconOptions,
    DidacticInitial,
    SendEmailModal,
  },
  mixins: [pdf, doc],
  props: {
    syllabus: { required: true, type: Object },
    effects: Array,
    commentsCount: Number,
  },
  data () {
    return {
      active: false,
      effectsListModalId: `effectsListModal_${this.$uuidv4()}`,
      objectiveEffectsModalId: `objectiveEffectsModal_${this.$uuidv4()}`,
    }
  },
  computed: {
    ...mapGetters('auth', ['currentRole']),
    ...mapGetters('page', ['contrast']),
    syllabusId () {
      return this.syllabus.id
    },
    buttons () {
      return [
        {
          text: 'edit',
          type: 'edit',
          width: '16',
          height: '18',
          action: () => {
            this.$router.push({
              name: 'syllabuses_edit_path',
              params: { id: this.syllabusId },
            })
          },
        },
      ]
    },
    options () {
      const opts = [
        {
          text: this.$tc('general.course_effect', 2),
          action: () => {
            this.$bvModal.show('course-effects-modal')
            this.setSyllabusId(this.syllabus.id)
          },
        },
        {
          text: this.$t('general.download_pdf'),
          action: this.downloadPdf,
        },
        {
          text: this.$t('general.download_doc'),
          action: this.downloadDoc,
        },
        {
          text: this.$t('components.prk_syllabus.buttons.send_email'),
          action: () => {
            this.$bvModal.show(`send-email-modal-${this.syllabusId}`)
          },
        },
      ]
      if (this.currentRole === 'admin' || this.currentRole === 'employer') {
        opts.push(
          {
            text: `${this.syllabus.matrixName}`,
            action: this.goToMatrix,
          })
      }
      if (this.syllabus.effects && this.syllabus.effects.length > 0) {
        opts.push(
          {
            text: this.$tc('general.subject_effects', 2),
            action: () => {
              this.setSyllabusId(this.syllabus.id)
              this.$bvModal.show('subjectEffectModal')
            },
          })
      }
      return opts
    },
  },
  methods: {
    ...mapActions(['setSyllabusId']),
    downloadSubjectEffects () {
      api.post('subject_effects/download_pdf', { syllabus_id: this.syllabus.id },
        { responseType: 'blob' }).then((response) => {
        this.$downloadFile(response, 'pdf')
      })
    },
    goToMatrix () {
      this.$router.push({ name: 'matrix_edit_path', params: { id: this.syllabus.matrixId }, query: { from_syllabus: true } })
    },
  },
  watch: {
    contrast () {
      const { iconEdit, iconBook, iconPrintSyllabus, iconSendEmail, iconSubjectEffect } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconBook = iconBook
      this.iconPrintSyllabus = iconPrintSyllabus
      this.iconSendEmail = iconSendEmail
      this.iconSubjectEffect = iconSubjectEffect
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
