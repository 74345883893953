import { computed } from '@vue/composition-api'

export default function (root, studyPlan) {
  const optionsArray = [
    {
      text: root.$t('general.show_all_subjects'),
      action: () => root.$bvModal.show('subjectsModal'),
    },
    {
      text: root.$t('general.show_changes'),
      action: () => root.$bvModal.show('study-plan-history'),
    },
    {
      text: root.$t('general.update_plan'),
      action: () => updatePlan(),
    },
  ]

  const options = computed(() => {
    const updatedPlanIds = root.$store.state.studyPlans.updatedPlans.map(plan => plan.u10Id)
    if (updatedPlanIds.includes(studyPlan.u10Id)) {
      return optionsArray.slice(0, optionsArray.length)
    } else {
      return optionsArray.slice(0, optionsArray.length - 2)
    }
  })

  const updatePlan = async () => {
    await root.$api.put(`study_plans/${studyPlan.id}`, {
      form: '0',
    })
    root.$emit('refetch')
    root.$toastr.s(root.$t('views.study_plans.update_plan_beam.updated_plans'))
  }

  return {
    options,
  }
}
