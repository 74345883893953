<template>
  <base-beam>
    <prk-beam desktop-class="justify-content-between"
              mobile-class="justify-content-between"
    >
      <template>
        <span class="pcg-gray-color ml-5">
          <span>{{ $t('components.prk_beam.preview_template') }}</span>
        </span>
        <div>
          <pcg-btn
              class="font-weight-bold"
              size="small"
              variant="additional"
              @click="$router.push(returnUrl)"
          >
            {{ $t('components.prk_beam.back_to_edit_template') }}
          </pcg-btn>
        </div>
      </template>

      <template #mobile_beam>
        <span class="pcg-gray-color ml-5">
          <span>{{ $t('components.prk_beam.preview_template') }}</span>
        </span>
      </template>

      <template #options>
        <div>
          <pcg-btn
              class="font-weight-bold"
              size="small"
              variant="additional"
              @click="$router.push(returnUrl)"
          >
            {{ $t('components.prk_beam.back_to_edit') }}
          </pcg-btn>
        </div>
      </template>
    </prk-beam>
  </base-beam>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkBeam from './PrkBeam'
const { mapGetters } = createNamespacedHelpers('syllabusTemplate')

export default {
  name: 'PrkBeamSyllabusesAdmin',
  components: { PrkBeam },
  computed: {
    ...mapGetters({
      returnUrl: 'getReturnUrl',
    }),
  },
}
</script>
