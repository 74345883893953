<template>
  <div class="d-flex align-items-baseline">
      <span class="pr-2 prk-fz-12">
        {{ $tc('general.term', 2) }}
      </span>
    <div v-if="!studyPlanId.length" class="d-flex">
      <div v-for="(term, index) in computedSemesters"
           :key="index"
           class="term"
           :class="{ active: selectedSemesters.includes(index + 1) }"
           @click="addSemester(index + 1, true)"
      >
        {{ term }}
      </div>
    </div>
    <div v-else class="d-flex flex-wrap">
      <div v-for="(term, index) in semesters"
           :key="index"
           class="term mb-3"
           :class="{ active: selectedSemesters.includes(term) }"
           @click="addSemester(term, true)"
      >
        {{ getSemesterNumber(term.semesterNumber - 1) }} {{ specialityName(term.specialityName) }}
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const { mapActions } = createNamespacedHelpers('semesters')
export default {
  name: 'square-semester-select',
  props: {
    countSemesters: {
      type: Number,
      required: false,
    },
  },
  data () {
    return {
      semesters: [],
      selectedSemesters: [],
    }
  },
  jsonapi: {
    semesters: {
      config: ({ studyPlanId }) => ({
        method: 'get',
        url: 'semesters',
        params: {
          study_plan_id: studyPlanId,
          serializer: 'subject_list',
        },
      }),
      fetchPolicy: 'no-cache',
      variables () {
        return {
          studyPlanId: this.studyPlanId,
        }
      },
      skip () {
        return this.studyPlanId.length === 0
      },
      update (semesters) {
        return semesters.sort((a, b) => b.specialityName?.localeCompare(a.specialityName))
          .sort((a, b) => a.semesterNumber - b.semesterNumber)
      },
    },
  },
  watch: {
    countSemesters: {
      immediate: true,
      handler () {
        if (this.studyPlanId.length) return
        this.computedSemesters.forEach((number, index) => this.addSemester(index + 1))
        this.$emit('change', this.selectedSemesters)
      },
    },
    semesters () {
      if (this.studyPlanId) this.semesters.forEach(sem => this.addSemester(sem))
      this.$emit('change', this.selectedSemesters.map(sem => sem.id))
    },
  },
  computed: {
    computedSemesters () {
      return ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII'].slice(0, this.countSemesters)
    },
    studyPlanId () {
      return this.$store.getters['studyPlans/planId']
    },
  },
  methods: {
    ...mapActions(['setSemesters']),
    addSemester (semester, emitEvent = false) {
      if (this.studyPlanId) {
        if (!this.selectedSemesters.includes(semester)) {
          this.selectedSemesters.push(semester)
        } else {
          this.selectedSemesters.splice(this.selectedSemesters.indexOf(semester), 1)
        }
        if (emitEvent) this.$emit('change', this.selectedSemesters.map(sem => sem.id))
      } else {
        if (!this.selectedSemesters.includes(semester)) {
          this.selectedSemesters.push(semester)
        } else {
          this.selectedSemesters.splice(this.selectedSemesters.indexOf(semester), 1)
        }
        if (emitEvent) this.$emit('change', this.selectedSemesters)
      }
    },
    getSemesterNumber (index) {
      const semesterNumbers = ['I', 'II', 'III', 'IV', 'V', 'VI', 'VII', 'VIII', 'IX', 'X', 'XI', 'XII']
      return semesterNumbers[index]
    },
    specialityName (speciality) {
      if (!speciality) return ''
      else return `- ${speciality}`
    },
  },
  destroyed () {
    this.$emit('clear:number-of-semesters')
  },
}
</script>
<style lang="scss" scoped>
@import 'app/javascript/prk/assets/stylesheets/vars';

.term {
  text-align: center;
  border: 1px solid $pcg-aqua-blue;
  line-height: 30px;
  border-radius: 12px;
  cursor: pointer;
  background-color: $pcg-white;
  color: $pcg-the-darkest-gray;
  font-weight: normal;
  margin-left: 10px;
  padding: 0 1.2rem;

  &.active {
    background-color: $pcg-aqua-blue;
    color: $pcg-white;
    font-weight: bold;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .term {
    text-align: center;
    border: 1px solid $pcg-aqua-blue;
    background-color: $pcg-white;
    color: $pcg-the-darkest-gray;

    &.active {
      background-color: $pcg-aqua-blue;
      color: $pcg-white;
    }
  }
}
</style>
