<template>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.309204 6.48387L5.57243 11.7416L13.6954 1.93777L11.8071 0.28418L5.43897 8.02112L1.99518 4.62818L0.309204 6.48387ZM2.01517 5.34978L2.01515 5.34976L0.999944 6.46714L5.53755 11L5.53757 10.9999L1 6.46714L2.01517 5.34978ZM11.8656 1L11.8655 0.999972L5.47757 8.761L5.4776 8.76103L11.8656 1Z"/>
</template>

<script>
export default {
  name: 'IconCheckmark',
}
</script>

<style scoped>

</style>
