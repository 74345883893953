<template>
  <pcg-modal
      btnClass="d-none"
      icon="fa fa-users"
      modal-id="lecturers-modal"
      size="md"
      :title="$t('general.leading')"
  >
    <prk-table
        v-if="lecturers.length > 0"
        disable-actions
        :items="filteredLecturers"
        :fields-props="{ tdClass: 'py-4' }"
        :show-headline="false"
    >
    </prk-table>
  </pcg-modal>
</template>

<script>
import PrkTable from '../PrkTable'

export default {
  name: 'LecturersModal',
  components: {
    PrkTable,
  },
  props: {
    lecturers: { type: Array, default: () => ([]) },
  },
  computed: {
    filteredLecturers () {
      return this.lecturers.map(lec => {
        return {
          firstName: lec.firstName,
          lastName: lec.lastName,
        }
      })
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .tags-container {
    padding-top: 0.8rem;
  }
}

</style>
