<template>
  <div class="prk-shadow br-15">
    <prk-list-filter filters-type="standard" @update:filters="updateFilters"
    />
    <dictionaries-standard-table :filters="filters"/>
  </div>
</template>

<script>
import PrkListFilter from '../../../components/PrkListFilter'
import DictionariesStandardTable from '../../../components/dictionaries/DictionariesStandardTable'
import { app } from '../../../index'
import useFilters from '../../../composables/useFilters'

export default {
  name: 'DictionaryStandards',
  setup (_, { root }) {
    const { filters, updateFilters } = useFilters(root)
    return { filters, updateFilters }
  },
  components: {
    DictionariesStandardTable,
    PrkListFilter,
  },
  watch: {
    filters: {
      handler () {
        this.link = document.querySelector('[href="/dictionaries/standards"]')
        this.link.classList.add('active', 'link-active')
      },
      deep: true,
    },
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$tc('views.header.dictionary.standard', 2),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    const role = localStorage.getItem('prkCR')
    if (['admin', 'employer'].includes(role)) {
      next(vm => {
        vm.setHeader()
      })
    } else {
      app.$toastr.e(app.$t('general.forbidden'))
    }
  },
}
</script>
