<template>
  <pcg-btn
      round
      text="download_pdf"
      class="prk-button"
      :disabled="disabled"
      :icon="iconPdf"
      @click="downloadPdf"
  />
</template>

<script>
import useIcon from '../composables/useIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'DownloadPdfButton',
  setup (_, { root }) {
    const { iconPdf } = useIcon(root)
    return { iconPdf }
  },
  props: {
    downloadPdf: { required: true, type: Function },
    disabled: { type: Boolean, default: false },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    contrast () {
      const { iconPdf } = useIcon(this.$root)
      this.iconPdf = iconPdf
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
