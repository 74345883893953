<template>
  <b-col sm="12" md="6" class="mt-3">
    <prk-select
        color="aqua"
        :default-option="defaultOption('studyProfiles')"
        :error-text="studyProfileError"
        :label="$t('general.profile')"
        :options="studyProfiles"
        :show-error="!!studyProfileError"
        :value="$attrs.studyProfile"
        @input="input('studyProfile', $event)"
    />
  </b-col>
</template>

<script>
import PrkSelect from '../../../PrkSelect'
import useModal from '../../../../composables/useModal'
import { getStudyData } from '../../../../mixins/getStudyData'
export default {
  name: 'NewMatrixStudyProfile',
  setup () {
    const { defaultOption, input } = useModal()
    return { defaultOption, input }
  },
  components: {
    PrkSelect,
  },
  mixins: [getStudyData],
  props: {
    studyProfileError: { required: true, type: String },
  },
  async created () {
    await this.getData(['study_profiles'])
  },
}
</script>
