<template>
  <b-col class="col-12 col-lg-3 my-3 my-lg-0">
    <div class="d-flex justify-content-between align-items-center">
      <prk-select
          color="aqua"
          :label="$tc('general.form_of_credit', 1)"
          :default-option="{ text: $t('general.choose') }"
          :error-text="$t('views.study_plans.errors.formOfCredit')"
          :include-blank="true"
          :options="formOfCredits"
          :show-error="showError"
          :value="value"
          @input="update($event)"
      />
      <portal-target :name="form.id" slim/>
    </div>
  </b-col>
</template>

<script>
import PrkSelect from '../../../../PrkSelect'

export default {
  name: 'FormOfCredit',
  components: {
    PrkSelect,
  },
  props: {
    index: { required: true, type: Number },
    form: { required: true, type: Object },
    formOfCredit: { type: String, default: '' },
  },
  inject: ['$v'],
  jsonapi: {
    formOfCreditsData: {
      config: { method: 'get', url: 'form_of_credits' },
      fetchPolicy: 'no-cache',
    },
  },
  data () {
    return {
      formOfCreditsData: [],
    }
  },
  computed: {
    value () {
      return this.formOfCredits.find(form => form.text === this.formOfCredit)?.value ?? null
    },
    showError () {
      return Object.values(this.$v.subject.subjectForms.$each.$iter).filter(obj => {
        return !obj.$model.hidden
      })[this.index].formOfCreditName.$error
    },
    formOfCredits () {
      return this.formOfCreditsData.map(formOfClass => {
        return { text: formOfClass.name, value: formOfClass.id }
      })
    },
  },
  methods: {
    update (value) {
      const formOfCredit = this.formOfCredits.find(formOfCredit => formOfCredit.value === value)
      if (value === null) {
        this.$emit('update:formOfCredit', '')
        this.$emit('update:formOfCreditId', '')
        this.$emit('update')
      } else {
        this.$emit('update:formOfCredit', formOfCredit.text)
        this.$emit('update:formOfCreditId', formOfCredit.value)
        this.$emit('update')
      }
    },
  },
}
</script>
