export const views = {
  courses: {
    created_standard_based_on_course: 'Utworzono standard na podstawie kierunku',
    course_exist: 'Taki kierunek już istnieje',
    standard_effects: {
      info: 'Nie wybrałeś, żadnego standardu. Jeśli żaden standard nie został dopasowany to zamknij modal',
    },
  },
  dev_sessions: {
    dev_sessions_index: {
      title: 'Dev login',
    },
  },
  error: {
    general_error: 'Niektóre pola nie zostały poprawnie wypełnione. Sprawdź i popraw błedy.',
  },
  header: {
    courses: {
      new: 'Nowy kierunek',
      edit: 'Edytuj kierunek',
      title: 'Kierunki',
    },
    dictionary: {
      basic_characteristics: 'Charakterystki podstawowe',
      category: 'Kategorie',
      course_effects: 'Efekty kierunkowe',
      edit_standard: 'Edytuj standard',
      faculties_and_courses: 'Wydziały i kierunki',
      fields: 'Dziedziny i dyscypliny',
      form_of_classes: 'Formy zajęć',
      form_of_credits: 'Formy zaliczenia',
      general_effects: 'Charakterystyki ogólne',
      kind: 'Formy studiów',
      literatures: 'Literatura',
      new_standard: 'Nowy standard',
      profiles: 'Profile',
      specialities: 'Specjalności',
      teaching_effects: 'Efekty nauczycielskie',
      title: 'Słowniki',
      types: 'Poziomy',
      standard: 'Standard | Standardy',
      standard_effects: 'Efekty standard',
      subject_form_names: 'Nazwy form przedmiotów',
      subject_names: 'Nazwy przedmiotów',
      subjects_types: 'Rodzaje przedmitów',
      unit_names: 'Jednostki',
    },
    matrices: 'Matryce',
    permission_list: 'Uprawnienia',
    personnel_list: 'Kadra (użytkownicy)',
    study_plan: {
      new: 'Nowy plan studiów',
      edit: 'Edytuj plan studiów',
      title: 'Plan studiów',
    },
    syllabus: {
      accepted: 'Zatwierdzony',
      copy_template: 'Kopiuj szablon',
      edit_template: 'Edytuj Szablon',
      list: 'Sylabusy',
      new_template: 'Nowy szablon',
      syllabuses_templates: 'Szablony sylabusów',
      to_fill: 'Do wypelnienia',
      to_improve: 'Do poprawy',
      to_verification: 'Do sprawdzenia',
    },
    syllabus_templates: {
      list: 'Szablony sylabusa',
    },
  },
  login: {
    companies_title: 'firm | firma | firmy | firm',
    companies_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor scelerisque lectus, eget ' +
      'maximus dolor.',
    copyrights: 'Copyrighting.',
    header: 'Sylabus',
    login_btn: 'Zaloguj się',
    main_text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed auctor scelerisque lectus, eget maximus ' +
      'dolor.',
    offers_title: 'ofert pracy | oferta pracy | oferty pracy | ofert pracy',
    students_and_graduates_title: 'studentów i absolwentów | student lub absolwent | studentów i absolwentów | ' +
      'studentów i absolwentów',
  },
  matrix: {
    have_to_choose_matrix: 'Musisz wybrać matrycę',
  },
  permissions: {
    success: {
      save: 'Pomyślnie zapisano uprawnienia',
    },
    errors: {
      complete_path: 'Proszę uzupełnić ścieżkę',
    },
  },
  study_plans: {
    assign_course_to_study_plan: 'Przypisano przedmioty do planu studiów',
    errors: {
      academic_year: 'Podaj rok akademicki, dla którego ma być przypisany plan',
      code: 'Podaj kod przedmiotu',
      course: 'Podaj nazwę kierunku, dla którego ma być przypisany plan',
      course_name: 'Podaj nazwę kierunku, dla którego ma być przypisany plan',
      course_code: 'Podaj kod przedmiotu',
      ects: {
        required: 'Podaj ilość punktów ECTS dla tego przedmiotu',
        numeric: 'Liczba punktów musi być całkowita i większa lub równa od 0',
      },
      faculty: 'Podaj nazwę wydziału dla, którego ma być przypisany plan',
      formOfClass: 'Musisz wybrać formę przedmiotu',
      formOfCredit: 'Musisz wybrać formę zaliczenia',
      import: {
        academic_year: 'Rok akademicki jest wymagany',
        course: 'Kierunek jest wymagany',
        faculty: 'Wydział jest wymagany',
        failed: 'Nie udało się pobrać planów',
      },
      import_unexpected_error: 'Wystąpił nieoczekiwany błąd podczas importu. Spróbuj ponownie później',
      kind: 'Podaj nazwę formy, dla której ma być przypisany plan',
      lecturer: 'Musi istnieć co najmniej jeden prowadzący',
      name_of_study_plan: 'Podaj nazwę planu',
      name_of_subject: 'Podaj nazwę przedmiotu',
      not_found: 'Nie znaleziono planu studiów',
      number_of_hours: {
        required: 'Podaj liczbę godzin',
        numeric: 'Liczba godzin musi być całkowita i większa lub równa 0',
      },
      semester: {
        same_subject: 'Na danym semestrze został dodany już przedmiot o tej samej nazwie',
      },
      study_plan_type: 'Podaj jaki typ ma być przypisany do planu',
      subject_already_added: 'Przedmiot o podanej nazwie został już dodany',
      subject_form_must_exist: 'Musi istnieć co najmniej jedna forma zajęć',
      type: 'Podaj nazwę poziomu dla, którego ma być przypisany plan',
    },
    import: {
      plans_updated: 'Pojawiły się zmiany w planach',
    },
    subjects: {
      more_than_one_form: 'Istnieje więcej niż jedna forma przedmiotu. Usunięty zostanie przedmiot ze wszystkimi formami.',
    },
    success: {
      added: 'Plan studiów został stworzony i dodany',
      import: 'Import planów się powiódł',
      updated: 'Plan studiów został zaktualizowany',
    },
    update_plan_beam: {
      discard_changes: 'Zmiany zostały odrzucone',
      changes: 'Pojawiły się zmiany w {count} planach',
      updated_plans: 'Zaktualizowano plany',
    },
    warning: {
      import: 'Nie pobrano żadnych planów',
    },
  },
  syllabus: {
    copy: {
      method: 'Wybierz metodę kopiowania',
      methods: {
        content_and_subject_effects: 'Treść i efekty przedmiotowe',
        just_content: 'Tylko treść',
      },
      need_to_choose_copy_method: 'Musisz wybrać metodę kopiowania',
      no_syllabus_selected: 'Nie wybrano sylabusa',
    },
    header: {
      all_course_effects_assigned: 'Wszystkie efekty kierunkowe zostały przypisane',
      copy_subject_effects: 'Pomyślnie skopiowano efekty przedmiotowe',
      course_effects_not_assigned: 'Efekty kierunkowe nie zostały przypisane',
      syllabus_not_found: 'Nie znaleziono sylabusa',
    },
    student_workload: {
      hours_not_match_to_ects: 'Suma godzin nie zgadza się z liczbą ECTS',
    },
    syllabus_list: {
      status: {
        not_to_fill: 'Nie można usunąć. Niektóre z sylabusów mają status inny niż do wypełnienia',
      },
    },
  },
  syllabus_list: {
    email: {
      success: 'Emaile zostały wysłane pomyślnie',
    },
  },
  syllabus_show: {
    return_to_editing_template: 'Wróć do edycji szablonu',
  },
  syllabus_edit: {
    return: 'Wróć do listy sylabusów',
  },
}
