<template>
  <pcg-modal
      scrollable
      modal-id="generalEffectModal"
      size="lg"
      :title="$t('general.add')"
      icon="fas fa-plus"
      :action="addGeneralEffects"
      :btn-title="$t('general.save')"
  >

    <prk-table
        v-if="items.length > 0"
        disable-actions
        :items="items"
        :fields-props="{ tdClass: 'py-4' }"
        :show-headline="false"
        :excluded-fields="excludedFields"
        :busy="$jsonapi.loading"
    >
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <pcg-spinner variant="small"/>
        </div>
      </template>

      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: field.key === 'id' ? '5%' : '' }"
        >
      </template>
      <template v-slot:head(id)>
        <pcg-checkbox @input="select" :value="checkedAll"/>
      </template>
      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'id'" class="position-relative">
          <div class="prk-color-status" :style="{'background-color': data.item.color}"
               v-b-popover.hover.top="data.item.name"
               :class="data.item.class"
          />
          <pcg-checkbox v-model="data.item.selected"/>
        </div>
      </template>
    </prk-table>
  </pcg-modal>
</template>

<script>
import useList from '../../../composables/useList'
import useGeneralEffects from '../../../composables/useGeneralEffects'
import { inject } from '@vue/composition-api'
import PrkTable from '../../PrkTable'

export default {
  name: 'GeneralEffectModal',
  components: { PrkTable },
  setup (_, { root }) {
    const {
      checkedAll,
      getSelected,
      items,
      setItems,
      select,
      unSelectAll,
    } = useList(root)
    const { excludedFields, mapGeneralEffects } = useGeneralEffects()
    const studyProfileId = inject('studyProfileId', '')
    const typeId = inject('typeId', '')
    return {
      checkedAll,
      excludedFields,
      getSelected,
      items,
      mapGeneralEffects,
      setItems,
      select,
      studyProfileId,
      typeId,
      unSelectAll,
    }
  },
  jsonapi: {
    generalEffects: {
      config: ({ studyProfileId, typeId }) => ({
        method: 'get',
        url: `general_effects?active=true&study_profile_id=${studyProfileId}&type_id=${typeId}`,
        params: {
          serializer: 'course_form',
        },
      }),
      variables () {
        return {
          studyProfileId: this.studyProfileId,
          typeId: this.typeId,
        }
      },
      skip () {
        return !this.studyProfileId.length || !this.typeId.length
      },
      update (generalEffects) {
        return generalEffects.map(effect => {
          return {
            ...this.$_.omit(effect, '__type', 'active'),
            _showDetails: false,
            selected: false,
          }
        })
      },
    },
  },
  data () {
    return {
      generalEffects: [],
    }
  },
  props: {
    excludedGeneralEffectIds: {
      type: Array,
    },
  },
  computed: {
    availableGeneralEffects () {
      return this.generalEffects.filter(effect => !this.excludedGeneralEffectIds.includes(effect.id))
    },
  },
  watch: {
    availableGeneralEffects (newGeneralEffects) {
      this.setItems(newGeneralEffects)
    },
  },
  methods: {
    addGeneralEffects () {
      this.$emit('add:general-effect', this.getSelected(this.items))
      this.unSelectAll(this.items)
    },
  },
}
</script>
