<template>
  <b-col class="col-12 col-lg-2 my-3 my-lg-0">
    <pcg-text-input
        class="my-3"
        :error-text="$t(`views.study_plans.errors.ects.${ectsErrorKey}`)"
        :label=" $t('general.ects')"
        :show-error="showError"
        :value="ects"
        @input="update"
    />
  </b-col>
</template>

<script>
export default {
  name: 'EctsPoints',
  props: {
    ects: { required: true, type: [Number, String] },
    index: { required: true, type: Number },
  },
  inject: ['$v'],
  computed: {
    ectsErrorKey () {
      if (!this.$v.subject.subjectForms.$each[this.index].ects.numeric) {
        return 'numeric'
      } else {
        return 'required'
      }
    },
    showError () {
      return Object.values(this.$v.subject.subjectForms.$each.$iter).filter(obj => {
        return !obj.$model.hidden
      })[this.index].ects.$error
    },
  },
  methods: {
    update (ects) {
      this.$emit('update:ects', +ects)
      this.$emit('update')
    },
  },
}
</script>
