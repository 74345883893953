<template>
  <b-row class="justify-content-end align-items-baseline">
    <b-col md="12" lg="auto">
      <pcg-btn variant="additional" size="small" @click="expandOrCollapse">
        <span v-if="!expandedAll" class="font-weight-bold">
          {{ $t('general.expand_all') }}
        </span>
        <span v-else class="font-weight-bold">
          {{ $t('general.collapse_all') }}
        </span>
      </pcg-btn>
    </b-col>
    <b-col md="12" lg="auto">
      <pcg-btn variant="additional" size="small"
               class="font-weight-bold m-0 mb-3"
               :class="{'w-100': $mq === 'sm' || $mq === 'xs'}"
               v-b-modal.generalEffectModal>
        + {{ $t('general.add_general_characteristics') }}
      </pcg-btn>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'GeneralEffectsButtons',
  data () {
    return {
      expandedAll: false,
    }
  },
  props: {
    collapseAll: { required: true, type: Function },
    expandAll: { required: true, type: Function },
  },
  computed: {
    action () {
      return this.expandedAll ? this.collapseAll : this.expandAll
    },
  },
  methods: {
    expandOrCollapse () {
      this.action()
      this.expandedAll = !this.expandedAll
    },
  },
}
</script>
