<template>
  <div>
    <div class="form-group">
      <label class="pcg-input-label">
        {{ label }}
      </label>
      <div class="picker-wrapper" @click="show">
        <div class="picker" :style="{'background-color': temporaryColor}"></div>
      </div>
    </div>

    <b-modal id="pickerModal" size="sm"
             centered :hide-header="true" @hide="restoreColor">
      <div class="arrow-up"></div>
      <div class="pcg-input-label">{{$t('components.prk_modals.colors.name')}}
      </div>

      <div class="grid-picker">
        <div v-for="(color, index) in availableColor" :key="index">
          <div class="picker-wrapper select"
               :class="{'transparent-border': color !== temporaryColor}">
            <div class="picker" :style="{'background-color': color}"
                 @click="temporaryColor = color"></div>
          </div>
        </div>
      </div>

      <template v-slot:modal-footer>
        <div class="w-100 d-flex justify-content-center">
          <pcg-btn size="small" @click="selectColor">
            {{$t('components.prk_modals.colors.select_color')}}
          </pcg-btn>
        </div>
      </template>
    </b-modal>
    <div v-if="showError" class="pcg-error-messages">{{ errorText }}</div>
  </div>
</template>

<script>
export default {
  name: 'prk-color-picker',
  data: function () {
    return {
      selectWasEmitted: false,
      temporaryColor: '#E8B42E',
      availableColor: [
        '#E8B42E',
        '#E8712E',
        '#FC4747',
        '#55C64C',
        '#5090CD',
        '#5725C0',
        '#253B80',
      ],
    }
  },
  props: {
    selectedColor: { type: String, default: '#E8B42E' },
    showError: { type: Boolean, default: false },
    errorText: { type: String, default: '' },

    label: { type: String, default: '' },
  },
  methods: {
    show () {
      this.$bvModal.show('pickerModal')
    },
    selectColor () {
      this.selectWasEmitted = true
      this.$emit('select', this.temporaryColor)
      this.$bvModal.hide('pickerModal')
      this.selectWasEmitted = false
    },
    restoreColor () {
      if (!this.selectWasEmitted) {
        this.temporaryColor = this.selectedColor
      }
    },
  },
  mounted () {
    this.temporaryColor = this.selectedColor
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

/deep/ .modal-content {
  padding: 5px;
  box-shadow: 0px 6px 30px rgba(0, 0, 26, 0.25);
  transform: translate(-62px, 7px);
  width: 400px;
}

/deep/ .modal-footer {
  padding: 0;
  border-top: none;
}

.grid-picker {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 10px;
}

@media only screen and (max-width: 575px) {
  .grid-picker {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 10px;
  }

  /deep/ .modal-content {
    width: 300px;
    transform: translate(38px, 47px);
  }
}

.picker-wrapper {
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid $pcg-light-gray;
  width: 30px;
  height: 30px;

  .selected {
    border: 2px solid $pcg-the-darkest-gray;
  }

  &.select {
    margin-left: 12px;
  }

  &.transparent-border {
    border-color: transparent;
  }
}

.picker {
  cursor: pointer;
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: 3px solid $pcg-white;
  box-shadow: 0px 3px 10px rgba(32, 19, 19, 0.2);
}

.arrow-up {
  position: absolute;
  top: -32px;
  left: -5px;
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 30px solid $pcg-white;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .picker-wrapper {
    border: 2px solid $pcg-light-gray;
    .selected {
      border: 2px solid $pcg-the-darkest-gray;
    }
  }

  .picker {
    border: 3px solid $pcg-white;
  }

  .arrow-up {
    border-bottom: 30px solid $pcg-white;
  }
}
</style>
