export const semesters = {
  namespaced: true,
  state: {
    semester: null,
    allSemesters: [],
    selectedSemester: 0,
    semesterProperty: '', // allowed: active, activeEffects
  },
  getters: {
    getSemesterType: (state) => (id) => {
      return state.allSemesters.find(sem => sem.id === id)?.semesterType || 'winter'
    },
    getSemesterById: (state) => (id) => {
      return state.allSemesters.find(sem => sem.id === id)
    },
    getSemesterIndex: (state) => (id) => {
      return state.allSemesters.findIndex(sem => sem.id === id)
    },
    getNumberOfSemesterWithSameNumber: (state) => (id) => {
      const semesterNumber = state.allSemesters.find(sem => sem.id === id).semesterNumber
      return state.allSemesters.filter(sem => sem.semesterNumber === semesterNumber).length
    },
  },
  mutations: {
    SET_SEMESTER (state, semester) {
      state.semester = this.$app.$_.cloneDeep(semester)
    },
    ADD_SEMESTER (state, semester) {
      state.allSemesters.push(semester)
    },
    EDIT_SEMESTER (state, editedSemester) {
      const semesterIndex = state.allSemesters.findIndex(semester => {
        return semester.id === editedSemester.id
      })
      this.$app.$set(state.allSemesters, semesterIndex, editedSemester)
    },
    INSERT_SEMESTER (state, payload) {
      state.allSemesters.splice(payload.index + 1, 0, payload.semester)
    },
    REMOVE_SEMESTER (state, index) {
      state.allSemesters.splice(index, 1)
    },
    SET_SELECTED_SEMESTER (state, selectedSemester) {
      state.selectedSemester = selectedSemester
    },
    SET_SEMESTERS (state, semesters) {
      state.allSemesters = semesters
    },
    SET_SEMESTER_PROPERTY (state, semesterProperty) {
      state.semesterProperty = semesterProperty
    },
    SET_HAS_SUBJECTS (state, hasSubjects) {
      state.semester.hasSubjects = hasSubjects
    },
  },
  actions: {
    setSemester ({ commit }, semester) {
      commit('SET_SEMESTER', semester)
    },
    addSemester ({ commit }, semester) {
      commit('ADD_SEMESTER', semester)
    },
    editSemester ({ commit }, editedSemester) {
      commit('EDIT_SEMESTER', editedSemester)
    },
    insertSemester ({ commit }, payload) {
      commit('INSERT_SEMESTER', payload)
    },
    removeSemester ({ commit }, id) {
      commit('REMOVE_SEMESTER', id)
    },
    setSelectedSemester ({ commit }, selectedSemester) {
      commit('SET_SELECTED_SEMESTER', selectedSemester)
    },
    setSemesters ({ commit }, semesters) {
      commit('SET_SEMESTERS', semesters)
    },
    setSemesterProperty ({ commit }, semesterProperty) {
      commit('SET_SEMESTER_PROPERTY', semesterProperty)
    },
    setHasSubjects ({ commit }, hasSubjects) {
      commit('SET_HAS_SUBJECTS', hasSubjects)
    },
  },
}
