<!--TODO ujednolicić wraz coordinators modal z syllabusów-->
<template>
  <div>
    <pcg-search-select
        always-active
        :class="{ 'mt-4': !showLabel }"
        :error-text="$t('views.study_plans.errors.lecturer')"
        :label="$t('general.leading')"
        :options="allLecturers"
        :show-label="showLabel"
        :show-error="showError"
        @input="add"
    >
      <template v-slot="{ option }">
        <div class="dropdown-item d-flex align-items-center">
          <prk-avatar :avatar="option.avatar" :abbr="option.abbr"/>
          <span class="d-inline-block ml-3">
          {{ option.text }}
        </span>
        </div>
      </template>
    </pcg-search-select>
    <prk-tags
        class="pt-0 mt-4"
        :value="tagLecturers"
        @removed="$emit('remove', $event)"
    />
  </div>
</template>

<script>
import PrkTags from '../../../PrkTags'
import PrkAvatar from '../../../PrkAvatar'

export default {
  name: 'SearchCoordinators',
  components: {
    PrkTags,
    PrkAvatar,
  },
  props: {
    index: { required: true, type: Number },
    lecturers: { required: true, type: Array },
  },
  jsonapi: {
    lecturersData: {
      config: { method: 'get', url: 'lecturers' },
      fetchPolicy: 'no-cache',
    },
  },
  data () {
    return {
      lecturersData: [],
    }
  },
  inject: {
    showLabel: { default: true },
    $v: {},
  },
  computed: {
    tagLecturers () {
      return this.lecturers.map(lec => ({ text: lec.fullName, value: lec.id }))
    },
    showError () {
      return Object.values(this.$v.subject.subjectForms.$each.$iter).filter(obj => {
        return !obj.$model.hidden
      })[this.index].lecturers.$error
    },
    allLecturers () {
      return this.lecturersData.map(lec => {
        return { text: lec.fullName, value: lec.id, avatar: lec.avatar, abbr: lec.abbr }
      })
    },
  },
  methods: {
    add (lecturerId) {
      const lecturer = this.lecturersData.find(lec => lec.id === lecturerId)
      this.$emit('add', lecturer)
    },
  },
}
</script>
