<template>
  <fragment>
    <div v-b-popover.hover.top="kek.description"
         v-for="(kek, index) in keks"
         :key="index"
         class="pointer"
         style="width: max-content; line-height: 1.3rem"
    >
      {{ kek.symbol }}
    </div>
  </fragment>
</template>

<script>
export default {
  name: 'ObjectiveEffectsKeks',
  props: {
    keks: { required: true, type: Array },
  },
}
</script>
