<template>
  <div class="disciplines-list">
    <prk-table
        v-if="items.length > 0"
        :items="items"
        :fields-props="{ thAttr: thAttr }"
        :show-headline="false"
        :excluded-fields="['disciplineId', 'fieldId', 'id', 'selected']"
    >

      <template v-slot:head(fieldName)>
       {{ $t('components.prk_syllabus.table.field') }}
      </template>

      <template v-slot:head(disciplineName)>
        {{ $t('components.prk_syllabus.table.discipline') }}
      </template>

      <template v-slot:content="{ header, data }">
        <div v-if="header.key === 'leadingDiscipline'"
             class="d-flex justify-content-center">
          <pcg-radio
              name="name"
              style="transform: translateX(21px)"
              :show-label="false"
              :options="[{ value: data.item.disciplineId }]"
              :class="{ 'aqua-blue': data.item.leadingDiscipline }"
              :value="data.item.leadingDiscipline ? data.item.disciplineId : ''"
              @input="$emit('set:leading-discipline', $event)"
          />
        </div>

        <template v-else-if="header.key === 'actions'">
          <div class="d-flex justify-content-end">
            <pcg-btn
                round text="edit"
                class="mr-3 prk-button"
                :icon="iconEdit"
                v-b-modal.discipline-modal
                @click="editDiscipline(data.item)"
            />
            <pcg-btn
                round :icon="iconRemove" text="delete"
                class="mr-4 prk-button" variant="danger"
                @click="removeDiscipline(data.item.disciplineId)"
            />
          </div>
        </template>
      </template>
    </prk-table>
    <hr class="m-0">
    <div class="w-100 d-flex justify-content-center
                pb-3 pt-2 mb-4 pcg-white-background-color
                new-discipline"
    >
      <pcg-btn
          class="font-weight-bold" size="small"
          variant="additional"
          v-b-modal.discipline-modal
          @click="editMode = false"
      >
        {{ $t('components.prk_fields.add_discipline') }}
      </pcg-btn>
    </div>
    <prk-add-discipline-modal
        :editMode="editMode"
        :edited-discipline="editedDiscipline"
        :key="rerenderDisciplineModal"
        @add:discipline="$emit('add:discipline', $event)"
        @update:discipline="updateDiscipline"
        @hidden="rerenderDisciplineModal += 1"
    />
  </div>
</template>

<script>
import PrkTable from '../PrkTable'
import PrkAddDisciplineModal from '../courses/modal/PrkAddDisciplineModal'
import useList from '../../composables/useList'
import useIcon from '../../composables/useIcon'
import useDictionary from '../../composables/useDictionary'
import { provide, computed } from '@vue/composition-api'
import { mapGetters } from 'vuex'

export default {
  name: 'Disciplines',
  setup (props, { root }) {
    const { removeRow, setItems, items } = useList(root)
    const { iconEdit, iconRemove } = useIcon(root)
    const excludedDisciplineIds = computed(() => {
      return root.$_.map(props.disciplines, 'disciplineId')
    })
    const { editMode } = useDictionary(root)
    provide('editMode', editMode)
    provide('excludedDisciplineIds', excludedDisciplineIds)
    return {
      editMode,
      iconRemove,
      iconEdit,
      items,
      removeRow,
      setItems,
    }
  },
  components: {
    PrkAddDisciplineModal,
    PrkTable,
  },
  data () {
    return {
      editedDiscipline: {},
      rerenderDisciplineModal: 0,
      errors: '',
    }
  },
  props: {
    disciplines: { type: Array, required: true },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    disciplines: {
      handler (newVal) {
        this.setItems(newVal)
      },
      immediate: true,
      deep: true,
    },
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
  methods: {
    async removeDiscipline (id) {
      const response = await this.$removeSwal()
      if (response.value) {
        this.$emit('remove:discipline', id)
      }
    },
    editDiscipline (discipline) {
      this.editMode = true
      this.editedDiscipline = this.$_.cloneDeep(discipline)
    },
    thAttr (value, key) {
      if (key === 'leading') {
        return {
          style: 'text-align: center',
        }
      } else {
        return {}
      }
    },
    updateDiscipline (...payload) {
      this.$emit('update:discipline', payload)
    },
  },
}
</script>

<style scoped lang="scss">
@import "app/javascript/prk/assets/stylesheets/vars";

hr {
  border-color: $pcg-lightest-gray;
}

.aqua-blue ::v-deep {
  .pcg-radio-container {
    font-weight: bold;
    color: $pcg-aqua-blue;
  }
}

::v-deep .pcg-radio-container {
  font-weight: bold;
  color: $pcg-gray;

  .pcg-radio {
    top: -12px;
  }
}

.pcg-table-container {
  ::v-deep {
    .pcg-table-content {
      border-radius: 5px;
    }
  }
}

.new-discipline {
  border-radius: 0 0 15px 15px;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  hr {
    border-color: $pcg-lightest-gray;
  }

  .aqua-blue ::v-deep {
    .pcg-radio-container {
      color: $pcg-aqua-blue;
    }
  }

  ::v-deep .pcg-radio-container {
    color: $pcg-gray;
  }

  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
