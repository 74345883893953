<template>
  <div class="d-flex align-items-center justify-content-end">
    <pcg-btn class="mr-3 prk-button"
             round
             :icon="iconBook"
             :disabled="studyPlan._syllabusCount === 0"
             text="generated_syllabuses"
             @click="showSyllabuses"
    />
    <pcg-btn round
             text="edit_plan"
             class="mr-3 prk-button"
             :icon="iconEdit"
             @click="editPlan"
    />
    <pcg-btn round
             :icon="iconRemove"
             text="delete_plan"
             class="mr-3 prk-button"
             variant="danger"
             @click="$emit('remove-plan')"
    />
    <prk-icon-options
        :options="options"
        @click="setState"
    />
  </div>
</template>

<script>
import PrkIconOptions from '../../PrkIconOptions'
import useIcon from '../../../composables/useIcon'
import useStudyPlanActions from '../../../composables/useStudyPlanActions'
import { createNamespacedHelpers, mapGetters } from 'vuex'

const { mapActions } = createNamespacedHelpers('studyPlans')

export default {
  name: 'StudyPlansTableActions',
  setup (props, { root }) {
    const { iconBook, iconEdit, iconRemove } = useIcon(root)
    const { options } = useStudyPlanActions(root, props.studyPlan)
    return {
      iconBook,
      iconEdit,
      iconRemove,
      options,
    }
  },
  components: { PrkIconOptions },
  props: {
    studyPlan: { required: true, type: Object },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  methods: {
    ...mapActions(['setPlan']),
    editPlan () {
      const params = {
        name: 'study_plans_edit_path',
        query: {
          study_plan_id: this.studyPlan.id,
          ...this.$route.query,
        },
      }
      this.$router.push(params)
      this.setPlan(this.studyPlan)
    },
    setState () {
      this.setPlan(this.studyPlan)
      this.$store.dispatch('semesters/setSemester', null)
    },
    showSyllabuses () {
      if (this.studyPlan._syllabusCount === 0) return
      this.$bvModal.show('syllabuses-list')
      this.setPlan(this.studyPlan)
    },
  },
  watch: {
    contrast () {
      const { iconBook, iconEdit, iconRemove } = useIcon(this.$root)
      this.iconBook = iconBook
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
