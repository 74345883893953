<template>
  <pcg-modal
      modal-id="characteristic-effects-modal"
      size="xl"
      :title="$t('general.add_effect_of_general_characteristics')"
      icon="fas fa-plus"
      :btn-title="$t('general.save')"
      :action="addEffects"
      @show="show"
      @hidden="query = ''"
  >
    <b-row align-v="center">
      <b-col cols="12" sm="6" lg="4" class="mb-2 mb-md-0">
        <prk-search :query.sync="query"/>
      </b-col>
    </b-row>

    <prk-table
        :show-headline="false"
        :items="characteristicEffectsByQuery"
        :fields-props="{ tdClass: tdClass }"
        :excluded-fields="['__type', 'selected']"
        class="mt-3"
        disable-actions
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: field.key === 'description' ? '70%' : '' }"
        >
      </template>

      <template v-slot:head(id)>
        <pcg-checkbox :value="selectedAll" @input="selectCharacteristicEffects"/>
      </template>
      <template v-slot:head(toggleDetails)/>

      <template v-slot:content="{ header, data }">
        <pcg-checkbox
            v-if="header.key === 'id'"
            v-model="data.item.selected"
        >
        </pcg-checkbox>
        <template v-else-if="header.key === 'description'">
          <prk-animated-description :description="data.item.description"/>
        </template>
        <base-arrow
            v-else-if="header.key === 'toggleDetails'"
            :show="data.item.toggleDetails"
            @click="showText(data, $event)"
        />
      </template>
    </prk-table>
  </pcg-modal>
</template>

<script>
import PrkTable from '../../PrkTable'
import PrkAnimatedDescription from '../../PrkAnimatedDescription'
import PrkSearch from '../../PrkSearch'
import useExpandText from '../../../composables/useExpandText'
import { inject } from '@vue/composition-api'

export default {
  name: 'EffectForGeneralEffect',
  setup () {
    const { tdClass, showText } = useExpandText('effect-for-general-effect')
    const generalEffectIds = inject('generalEffectIds', [])
    return { tdClass, showText, generalEffectIds }
  },
  components: {
    PrkAnimatedDescription,
    PrkTable,
    PrkSearch,
  },
  props: {
    categoryId: { required: true, type: String },
    characteristicEffectIds: { required: true, type: Array },
  },
  jsonapi: {
    characteristicEffects: {
      config: ({ url }) => ({
        method: 'get',
        url: url,
      }),
      variables () {
        return {
          url: this.url,
        }
      },
      skip () {
        return !this.url.length
      },
      update (characteristicEffects) {
        return characteristicEffects.map(effect => {
          return {
            ...effect,
            toggleDetails: false,
            selected: false
          }
        })
      },
    },
  },
  data () {
    return {
      characteristicEffects: [],
      url: '',
      query: '',
    }
  },
  computed: {
    selectedAll () {
      return this.selectedCharacteristicEffects.length === this.characteristicEffects.length
    },
    characteristicEffectsByQuery () {
      return this.characteristicEffects.filter(effect => {
        return effect.symbol.toLowerCase().includes(this.query.toLowerCase())
      })
    },
    selectedCharacteristicEffects () {
      return this.characteristicEffects.filter(effect => effect.selected)
    },
  },
  watch: {
    categoryId () {
      this.url = `characteristic_effects?${this.$qs.stringify({
        category_id: this.categoryId,
        general_effect_ids: this.generalEffectIds,
      })}`
    },
  },
  methods: {
    addEffects () {
      this.$emit('add:effects', this.selectedCharacteristicEffects)
      this.$bvModal.hide('characteristic-effects-modal')
    },
    selectCharacteristicEffects (selected) {
      selected ? this.$selectAll(this.characteristicEffects)
        : this.$unselectAll(this.characteristicEffects)
    },
    show () {
      this.characteristicEffects.filter(effect => {
        return this.characteristicEffectIds.includes(effect.id)
      }).forEach(effect => (effect.selected = true))
    },
  },
}
</script>
