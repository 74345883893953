<template>
  <pcg-btn
      round
      :disabled="!effects.length"
      variant="additional"
      size="small"
      v-b-modal.subjectEffectModal
      class="m-0 mr-2 prk-button"
      :icon="iconSubjectEffect"
      :text="$tc('general.subject_effects', 2)"
      @click="setSyllabusId(syllabusId)"
  />
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import useIcon from '../../../../../composables/useIcon'

const { mapActions } = createNamespacedHelpers('syllabus')

export default {
  name: 'SyllabusEffectsButtonAdminActions',
  setup (props, { root }) {
    const { iconSubjectEffect } = useIcon(root)
    return { iconSubjectEffect }
  },
  props: {
    effects: { required: true, type: Array },
    syllabusId: { required: true, type: String },
  },
  methods: {
    ...mapActions(['setSyllabusId']),
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  mounted () {
    const { iconSubjectEffect } = useIcon(this.$root)
    this.iconSubjectEffect = iconSubjectEffect
  },
  watch: {
    contrast () {
      const { iconSubjectEffect } = useIcon(this.$root)
      this.iconSubjectEffect = iconSubjectEffect
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
