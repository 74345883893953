var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.items.length > 0)?_c('prk-table',{staticClass:"prk-shadow",attrs:{"items":_vm.items,"busy":_vm.loading,"show-headline":false,"excluded-fields":['id', 'active', 'effect_type']},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({ width: _vm.colgroupDefinition(field.key) })})})}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('pcg-spinner',{attrs:{"variant":"small"}})],1)]},proxy:true},{key:"cell(description)",fn:function(ref){
var effect = ref.item;
return [_c('span',{staticClass:"d-inline-block pr-5"},[_vm._v("\n        "+_vm._s(effect.description)+"\n      ")])]}},{key:"cell(actions)",fn:function(ref){
var effect = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',{staticClass:"d-flex justify-content-end mr-5"},[_c('active-switch',{attrs:{"active":effect.active,"update-resource":_vm.updateResource,"url":_vm.url,"item":effect},on:{"update:active":function($event){return _vm.$set(effect, "active", $event)}}})],1),_vm._v(" "),_c('pcg-btn',{staticClass:"mr-3 prk-button",attrs:{"round":"","text":"edit","icon":_vm.iconEdit},on:{"click":function($event){return _vm.editResource(effect.id, 'effect')}}}),_vm._v(" "),_c('pcg-btn',{staticClass:"mr-3 prk-button",attrs:{"round":"","icon":_vm.iconRemove,"text":"delete","variant":"danger"},on:{"click":function($event){return _vm.destroyResource(_vm.url, effect.id, index)}}})],1)]}}],null,false,2471957088)}):_vm._e(),_vm._v(" "),_c('effect-form-modal',_vm._b({attrs:{"action":_vm.action(_vm.effect, _vm.url),"btn-title":_vm.editMode ? _vm.$t('general.edit') : _vm.$t('general.add'),"edit-mode":_vm.editMode,"errors":_vm.errors,"modalId":_vm.editMode ? 'edit-modal' : 'add-modal',"title-modal":_vm.editMode ? _vm.$t('general.edit') : _vm.$t('general.add')},on:{"hidden":function($event){return _vm.hideModal('effect', ['effect_type'])},"clear-error":function($event){_vm.errors[$event] = ''}}},'effect-form-modal',_vm.effect,false,true))],1)}
var staticRenderFns = []

export { render, staticRenderFns }