<template>
  <pcg-modal
      modal-id="confirm-deletion-syllabuses"
      size="sm"
      :btn-title="$t('general.yes')"
      btn-class="w-50"
      scrollable
      :action="save"
  >
    <div class="prk-fz-16 font-weight-bold pcg-the-darkest-gray-color mb-3">
      {{ $t('components.prk_modals.confirm_deletion_syllabuses.text') }}
     <prk-table :items="syllabusNames" disable-actions :show-headline="false" />
    </div>
    <template #button-before>
      <pcg-btn
          class="w-50" variant="additional"
          @click="hide"
      >
        {{$t ('general.no') }}
      </pcg-btn>
    </template>
  </pcg-modal>
</template>

<script>
import { mapActions } from 'vuex'
import PrkTable from '../../PrkTable'

export default {
  name: 'ConfirmDeletionSyllabuses',
  components: { PrkTable },
  props: {
    filledSyllabuses: { required: true, type: Array },
  },
  computed: {
    syllabusNames () {
      return this.filledSyllabuses.map(syllabus => ({ name: syllabus.name }))
    },
  },
  methods: {
    ...mapActions('syllabus', ['setShowWarning']),
    save () {
      this.setShowWarning(false)
      this.$emit('save')
      this.$bvModal.hide('confirm-deletion-syllabuses')
    },
    hide () {
      this.$bvModal.hide('confirm-deletion-syllabuses')
    },
  },
}
</script>
