
export default function (root, props = {}) {
  const setComments = (commentable, type) => {
    root.$store.dispatch('comments/setComments', commentable.comments)
    root.$store.dispatch('comments/setCommentableId', commentable.id)
    root.$store.dispatch('comments/setCommentableType', type)
  }

  return {
    setComments,
  }
}
