<template>
  <prk-table
      :items="items"
      :show-headline="false"
      :style="{ 'display': items.length > 0 ? 'block' : 'none' }"
      class="prk-shadow mt-4"
      :disable-actions="disableActions"
      :excluded-fields="excludedFields"
      :busy="busy"
  >
    <template v-if="!!name" v-slot:head(name)>
      {{ $tc(`general.${name}`, 2) }}
    </template>

    <template #table-busy>
      <div class="text-center text-danger my-2">
        <pcg-spinner variant="small" />
      </div>
    </template>

    <template #cell(actions)="{ item, index }">
      <div class="d-flex justify-content-end align-items-center">
        <fields-effective-date
            v-if="activeDate"
            :dateTo.sync="item.dateTo"
            :dateFrom.sync="item.dateFrom"
            :item="item"
            :update-resource="updateResource"
            :url="url"
        />
        <pcg-btn round text="edit" class="mr-3 prk-button" :icon="iconEdit"
                 @click="editResource(item.id, resourceName)"
        />
        <pcg-btn round :icon="iconRemove" text="delete"
                 class="mr-4 prk-button" variant="danger"
                 @click="destroyResource(url, item.id, index)"
        />
        <slot :item="item" />
      </div>
    </template>
  </prk-table>
</template>

<script>
import PrkTable from '../../PrkTable'
import FieldsEffectiveDate from '../FieldsEffectiveDate'
import useIcon from '../../../composables/useIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'DictionaryTable',
  setup (_, { root }) {
    const { iconEdit, iconRemove } = useIcon(root)
    return {
      iconEdit,
      iconRemove,
    }
  },
  components: {
    FieldsEffectiveDate,
    PrkTable,
  },
  props: {
    activeDate: {
      type: Boolean,
      default: false,
    },
    destroyResource: {
      required: true,
      type: Function,
    },
    editResource: {
      required: true,
      type: Function,
    },
    excludedFields: {
      type: Array,
      default: () => ['id', 'active', 'dateFrom', 'dateTo'],
    },
    items: {
      required: true,
      type: Array,
    },
    name: {
      type: String,
      required: false,
      default: '',
    },
    resourceName: {
      required: true,
      type: String,
    },
    updateResource: {
      type: Function,
      default: () => ({}),
    },
    url: {
      required: true,
      type: String,
    },
    disableActions: {
      type: Boolean,
      default: false,
    },
    busy: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
}
</script>

<style scoped lang="scss">
.pcg-table-container {
  border-radius: 15px 15px 5px 5px;

  ::v-deep {
    .pcg-table-content {
      border-radius: 15px 15px 5px 5px;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
