import Vue from 'vue'

export default function (root) {
  let response = null

  async function getSubjects (url, params) {
    response = await this.$api.get(url, params)
    return response.data.map(subject => {
      return {
        id: subject.id,
        // selected: false,
        ...subject.attributes,
        relationships: subject.relationships,
      }
    })
  }

  function getSubjectForms () {
    return response.included.filter(object => object.type === 'subject_form')
      .map(subjectForm => {
        return {
          id: subjectForm.id,
          formOfClass: subjectForm.attributes.formOfClassName,
          formOfCredit: subjectForm.attributes.formOfCreditName,
          ...root.$_.omit(subjectForm.attributes, 'formOfClassName', 'formOfCreditName'),
          relationships: subjectForm.relationships,
        }
      })
  }

  const updateOrCreate = (objects, object) => {
    const subjectIndex = objects.findIndex(el => el.id === object.id)
    if (!objects.length || !~subjectIndex) {
      objects.push(object)
    } else {
      Vue.set(objects, subjectIndex, object)
    }
  }

  const updateSubject = async (subject, url) => {
    await root.$api.put(url, {
      subject: {
        ...root.$_.pick(subject, 'name'),
        subject_forms: _mapIncluded(subject),
      },
    })
  }

  const removeSubject = (subjects, subject) => {
    const subjectIndex = subjects.findIndex((el) => el.id === subject.id)
    const removedSubjects = subjects.splice(subjectIndex, 1)
    removedSubjects[0]._destroy = '1'
    return removedSubjects[0]
  }

  const filterSubjectForms = (subjectForms, subject) => {
    return subjectForms.filter(form => {
      return form.relationships.subject.data.id !== subject.id
    })
  }

  const _mapIncluded = (subject) => {
    const subjectForms = root.$store.state.subjects.allSubjectForms
    const lecturers = root.$store.state.subjects.lecturers
    return subjectForms.filter(form => form.relationships.subject.data.id === subject.id).map(form => {
      return {
        id: form.id,
        form_of_class_id: form.formOfClassId,
        form_of_credit_id: form.formOfCreditId,
        number_of_hours: form.numberOfHours,
        ects: form.ects,
        lecturers: lecturers.filter(lecturer => lecturer.relationships.subjectForms.data
          .map(el => el.id).includes(form.id)).map(lecturer => {
          return { id: lecturer.id }
        }),
      }
    })
  }

  return {
    getSubjects,
    getSubjectForms,
    filterSubjectForms,
    removeSubject,
    updateOrCreate,
    updateSubject,
  }
}
