<template>
  <div class="d-flex align-items-center justify-content-end w-100 h-100">
    <download-pdf-button
        v-show="resources.length"
        :disabled="courseModified"
        :download-pdf="downloadPdf"
    />
    <download-doc-button
        v-show="resources.length"
        :disabled="courseModified"
        :download-doc="downloadDoc"
    />
    <pcg-btn variant="additional" size="small" v-b-modal.file-type>
      {{ $t('general.download_course_effects') }}
    </pcg-btn>
    <pcg-btn size="small" @click="save">
      <span class="font-weight-bold prk-fz-12" v-if="customText">
        {{ customText }}
      </span>
      <span class="font-weight-bold prk-fz-12" v-else>
        {{ editMode ? $t('general.update') : $t('general.save_course') }}
      </span>
    </pcg-btn>
    <pcg-btn v-if="kek && selectedCourseEffects" size="small" variant="additional"
             @click="$emit('remove:course-effects')">
      {{ $t('general.remove_selected') }}
    </pcg-btn>
    <file-type :download="downloadCourseEffects"/>
  </div>
</template>

<script>
import api from '../../api'
import DownloadPdfButton from '../DownloadPdfButton'
import DownloadDocButton from '../DownloadDocButton'
import FileType from '../modals/FileType'

export default {
  name: 'CoursesBeam',
  components: { FileType, DownloadDocButton, DownloadPdfButton },
  props: {
    action: { type: Function, default: () => {} },
    selectedCourseEffects: { type: Number, default: 0 },
    editMode: { type: Boolean, default: false },
    customText: { type: String },
    kek: { type: Boolean, default: false },
    resources: { type: Array, default: () => ([]) },
  },
  computed: {
    courseModified () {
      return this.$store.state.courses.modified
    },
  },
  methods: {
    downloadPdf () {
      for (const resource of this.resources) {
        api.post(`${this.$pluralize(resource.__type)}/download_pdf`, {
          id: resource.id,
          course_id: this.$route.params.id,
        },
        { responseType: 'blob' }).then((response) => {
          this.$downloadFile(response, 'pdf', resource.name)
        })
      }
    },
    downloadDoc () {
      for (const resource of this.resources) {
        api.post(`${this.$pluralize(resource.__type)}/download_doc`, {
          id: resource.id,
          course_id: this.$route.params.id,
        },
        { responseType: 'blob' }).then((response) => {
          this.$downloadFile(response, 'doc', resource.name)
        })
      }
    },
    downloadCourseEffects (fileType) {
      const data = { course_id: this.$route.params.id, file_type: fileType, template_name: 'course_effects' }
      const url = `/course_effects/${fileType === 'pdf' ? 'download_pdf' : 'download_doc'}`
      api.post(url, data, { responseType: 'blob' }).then((response) => {
        this.$downloadFile(response, fileType, 'Efekty kierunkowe')
      })
    },
    save () {
      this.$store.dispatch('courses/setModified', false)
      this.action()
    },
  },
}
</script>
