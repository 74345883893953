<template>
  <div id="subject-history" class="mt-3">
    <div v-for="(difference, index) in differences" :key="index">
      <h1 class="prk-fz-20">
        {{ $tc('general.subjects', 1) }} - {{ subjects[index * 2].name }}
      </h1>
      <h3><span class="font-weight-bolder">
        {{ $t('general.semester_name') }}:</span> {{ subjects[index * 2].semesterName }}
      </h3>
      <h3 v-if="subjects[index * 2].speciality">
        <span class="font-weight-bolder">{{ $t('general.speciality') }}:</span>
        {{ subjects[index * 2].speciality }}
      </h3>
      <div v-for="(value, key, index) in difference" :key="`${index}_${$uuidv4()}`">
        <div class="ml-3 my-3">
          <h5> {{ property(key) }}</h5>
          <div class="d-flex justify-content-between align-items-center prk-color-error prk-background-error box mb-2">
            <span class="prk-fz-12" v-html="value[0]"/>
            <span class="font-weight-bold">
              {{ $t('general.previous_version') }}
            </span>
          </div>
          <div
              class="d-flex justify-content-between align-items-center prk-color-success prk-background-success box mb-2">
            <span class="prk-fz-12" v-html="value[1]"/>
            <span class="font-weight-bold">
              {{ $t('general.current_version') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from '@vue/composition-api'

export default {
  name: 'SubjectHistory',
  setup () {
    const page = inject('page', 1)
    return { page }
  },
  props: {
    compareSubjectsInit: { required: true, type: Function },
    property: { required: true, type: Function },
    studyPlanId: { required: true, type: String },
  },
  jsonapi: {
    subjects: {
      config: ({ studyPlanId, page }) => ({
        method: 'get',
        url: 'subjects',
        params: {
          study_plan_id: studyPlanId,
          updated: '1',
          serializer: 'subject_history',
          page: page,
        },
      }),
      variables () {
        return {
          studyPlanId: this.studyPlanId,
          page: this.page,
        }
      },
      update (subjects, rawResponse) {
        this.pages = rawResponse.raw.data.meta.pagination.pages
        return subjects.map(subject => this.$_.omit(subject, 'id', '__type'))
      },
      skip () {
        return this.page > 1
      },
    },
  },
  data () {
    return {
      pages: null,
      init: false,
      subjects: [],
      subjectHistory: [],
    }
  },
  computed: {
    differences () {
      return this.subjectHistory.map(el => this.$_.omit(el, 'info'))
    },
    loading () {
      return this.$jsonapi.loading
    },
  },
  watch: {
    async page () {
      if (this.page >= this.pages) {
        this.$emit('update:loading', false)
        return
      }
      await this.$jsonapi.queries.subjects.fetchMore()
      this.$emit('update:loading', this.loading)
    },
    subjects () {
      this.subjectHistory = []
      this.compareSubjects()
    },
  },
  methods: {
    addDifference (field, index) {
      const u10Id = this.subjects[index * 2].u10Id
      const semesterId = this.subjects[index * 2].semesterId
      const subjectIndex = this.subjectHistory.findIndex(sem => sem.info.u10Id === u10Id && sem.info.semesterId === semesterId)
      const arrayOfDifference = [this.subjects[index * 2][field], this.subjects[index * 2 + 1][field]]
      if (!~subjectIndex) {
        this.subjectHistory.push(
          {
            info: { u10Id, semesterId },
            [field]: arrayOfDifference,
          },
        )
      } else {
        this.subjectHistory[subjectIndex][field] = arrayOfDifference
      }
    },
    compareSubjects () {
      this.compareSubjectsInit(this.subjects, 'semesterId').forEach((subPair, index) => {
        const [firstSubject, secondSubject] = subPair
        const difference = this.$_.reduce(firstSubject, function (result, value, key) {
          return _.isEqual(value, secondSubject[key])
            ? result : result.concat(key)
        }, [])
        difference.forEach(field => {
          this.addDifference(field, index)
        })
      })
    },
  },
  updated () {
    if (this.init) return
    this.init = true
    this.$emit('update:loading', false)
  },
}
</script>
