const getDefaultState = () => {
  return {
    commentableId: '',
    commentableType: '',
    existingComments: [],
    difference: [],
    commentCount: 0,
    allCommentCount: 0,
    url: '',
  }
}

export const comments = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    comments (state) {
      return state.existingComments.concat(state.difference).filter(comment => {
        return comment.commentableId === state.commentableId
      })
    },
    workloadComments (state, getters) {
      return getters.comments.filter(comment => {
        return comment.syllabusFieldName
      })
    },
  },
  mutations: {
    SET_COMMENTABLE_ID (state, id) {
      state.commentableId = id
    },
    SET_COMMENTABLE_TYPE (state, type) {
      state.commentableType = type
    },
    SET_COMMENTS (state, comments) {
      state.existingComments = comments
    },
    ADD_COMMENT (state, comment) {
      state.difference.push(
        {
          ...comment,
          userId: localStorage.getItem('prkUID'),
          commentableId: state.commentableId,
          commentableType: state.commentableType,
        },
      )
    },
    SET_URL (state, url) {
      state.url = url
    },
    SET_COMMENT_COUNT (state, commentCount) {
      state.commentCount = commentCount
    },
    EDIT_COMMENT (state, payload) {
      this.$app.$set(state[payload.stateName], payload.index, payload.editedComment)
      if (payload.stateName !== 'existingComments') return

      state[payload.stateName].splice(payload.index, 1)
      state.difference.push(payload.editedComment)
    },
    SET_ALL_COMMENT_COUNT (state, count) {
      state.allCommentCount = count
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    },
  },
  actions: {
    addComment ({ commit }, comment) {
      comment.id = this.$app.$uuidv4() + '__new'
      commit('ADD_COMMENT', comment)
    },
    setComments ({ commit }, comments) {
      commit('SET_COMMENTS', comments)
    },
    setCommentableId ({ commit }, id) {
      commit('SET_COMMENTABLE_ID', id)
    },
    setCommentableType ({ commit }, type) {
      commit('SET_COMMENTABLE_TYPE', type)
    },
    setUrl ({ commit }, url) {
      commit('SET_URL', url)
    },
    setCommentCount ({ commit }, commentCount) {
      commit('SET_COMMENT_COUNT', commentCount)
    },
    editComment ({
      commit,
      state,
    }, editedComment) {
      let stateName = 'existingComments'
      let index = state.existingComments.findIndex(comment => comment.id === editedComment.id)
      if (!~index) {
        index = state.difference.findIndex(comment => comment.id === editedComment.id)
        stateName = 'difference'
      }
      if (!~index) index = 0

      editedComment.commentableType = state.commentableType
      editedComment.userId = localStorage.getItem('prkUID')
      commit('EDIT_COMMENT', {
        index,
        editedComment,
        stateName,
      })
    },
    setAllCommentCount ({ commit }, count) {
      commit('SET_ALL_COMMENT_COUNT', count)
    },
    resetState ({ commit }) {
      commit('RESET_STATE')
    },
  },
}
