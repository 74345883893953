<template>
  <prk-table
      :items="semesters"
      :excluded-fields="excludedFields"
      :show-headline="false"
      disable-actions
      class="subtable"
  >
    <template v-slot:table-colgroup="{ fields }">
      <col v-for="field in fields" :key="field.key"
           :style="{ width: colgroup(field.key) }"
      >
    </template>
    <template v-slot:head(id)>
      <pcg-checkbox class="ml-2 pl-1" style="transform: translateX(2px)" :value="selectedAll"
                    @input="selectAllSemesters"/>
    </template>

    <template #cell(id)="{ item: semester }">
      <pcg-checkbox
          v-model="semester.selected" class="ml-5"
          @input="selectSemester($event, semester.id)"
      />
    </template>

    <template #cell(semesterNumber)="{ item: semester }">
      {{ mapNumber(semester.semesterNumber) }}
    </template>
  </prk-table>
</template>
<script>
import PrkTable from '../PrkTable'
import useMatrices from '../../composables/useMatrices'

export default {
  name: 'matrices-study-plans-details',
  setup () {
    const { mapNumber } = useMatrices()
    return { mapNumber }
  },
  components: { PrkTable },
  props: {
    semesters: { type: Array, required: true },
    studyPlanId: { type: String, required: true },
  },
  data () {
    return {
      excludedFields: ['subjectNames', 'selected', 'active'],
    }
  },
  computed: {
    selectedSemesters () {
      return this.semesters.filter(semester => semester.selected)
    },
    selectedAll () {
      return this.selectedSemesters.length === this.semesters.length
    },
  },
  methods: {
    selectAllSemesters (selected) {
      if (selected) {
        this.$emit('add:all-semesters', this.studyPlanId, this.semesters.map(semester => semester.id))
      } else {
        this.$emit('remove:all-semesters', this.studyPlanId, this.semesters.map(semester => semester.id))
      }
    },
    selectSemester (selected, semesterId) {
      selected ? this.$emit('add:semester', this.studyPlanId, semesterId)
        : this.$emit('remove:semester', this.studyPlanId, semesterId)
    },
    colgroup (header) {
      switch (header) {
        case 'id':
          return '5%'
        case 'name':
          return '20%'
        case 'semesterNumber':
          return '15%'
        default:
          return ''
      }
    },
  },
}
</script>
<style scoped lang="scss">
::v-deep {
  .pcg-table-narrow-row {
    td {
      border-bottom: 1px !important;
    }
  }
}
</style>
