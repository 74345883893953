var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('pcg-modal',{attrs:{"modal-id":"newEffect","size":"xl","title":_vm.$t('components.prk_dictionaries.assign_learning_outcomes'),"icon":"fas fa-plus","btn-title":_vm.$t('general.save'),"action":_vm.addSubEffects},on:{"hidden":function($event){return _vm.$emit('hidden')}}},[_c('div',{staticClass:"mb-2 subheader"},[_vm._v("\n    "+_vm._s(_vm.$t('components.prk_modals.course_effects.select_all'))+"\n  ")]),_vm._v(" "),_c('syllabus-sub-effects-modal-search',{on:{"change:query":function($event){_vm.query = $event}}}),_vm._v(" "),_c('prk-table',{staticClass:"mt-3",attrs:{"show-headline":false,"items":_vm.onlyNotIncludedEffects,"excluded-fields":_vm.EXCLUDED_FIELDS,"disable-actions":"","fields-props":{ tdClass: _vm.tdClass }},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return [_c('col-group',{attrs:{"columns":['description', 'enDescription'],"fields":fields,"width":"70%"}})]}},{key:"head(id)",fn:function(){return [_c('pcg-checkbox',{attrs:{"value":_vm.selectedAll},on:{"input":_vm.select}})]},proxy:true},{key:"head(details)",fn:function(){return undefined},proxy:true},{key:"cell(id)",fn:function(ref){
var effect = ref.item;
return [_c('pcg-checkbox',{model:{value:(effect.selected),callback:function ($$v) {_vm.$set(effect, "selected", $$v)},expression:"effect.selected"}})]}},{key:"cell(description)",fn:function(ref){
var effect = ref.item;
return [_c('prk-animated-description',{attrs:{"description":effect.description}})]}},{key:"cell(enDescription)",fn:function(ref){
var effect = ref.item;
return [_c('prk-animated-description',{attrs:{"description":effect.enDescription}})]}},{key:"cell(details)",fn:function(data){return [_c('base-arrow',{attrs:{"show":data.item.toggleDetails},on:{"click":function($event){return _vm.showText(data, $event)}}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }