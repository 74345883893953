<template>
  <div class="dictionaries-fields-list">
    <div class="d-sm-flex justify-content-between align-items-center">
      <pcg-search
          class="mb-3 mb-lg-0"
          :class="{ 'w-100': $mq !== 'lg' && $mq !== 'xl' }"
          :placeholder="$t('general.search')"
          @input="setQuery"
      />
      <pcg-btn variant="additional" v-b-modal.add-modal size="small"
               class="w-100 w-sm-auto m-0 font-weight-bold"
      >
        + {{ $t('general.new_unit_name') }}
      </pcg-btn>
    </div>

    <prk-table
        v-if="items.length > 0"
        class="prk-shadow mt-4"
        :items="items"
        :excludedFields="['id']"
        :show-headline="false"
        :busy="$jsonapi.loading"
    >

      <template #table-busy>
        <div class="text-center text-danger my-2">
          <pcg-spinner variant="small" />
        </div>
      </template>

      <template #cell(actions)="{ item: unitName, index }">
        <div class="d-flex justify-content-end align-items-center">
          <pcg-btn round text="edit" class="mr-3 prk-button" :icon="iconEdit"
                   @click="editResource(unitName.id, 'unitName')"/>
          <pcg-btn round :icon="iconRemove" text="delete"
                   class="mr-3 prk-button" variant="danger"
                   @click="destroyResource('unit_names', unitName.id, index)"/>
        </div>
      </template>
    </prk-table>

    <pcg-pagination v-if="pagination.totalPages && !query.length"
                    :page-count="pagination.totalPages"
                    :total-records="pagination.totalRecords"
                    v-model="pagination.currentPage"
                    class="mt-4 prk-pagination"
    />

    <structure-modal
        :action="action(unitName, 'unit_names')"
        :btn-title="btnTitle"
        :editMode="editMode"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="unitName"
        @hidden="hideModal('unitName')"
        @clear-error="errors[$event] = ''"
    />
  </div>
</template>

<script>
import PrkTable from '../../components/PrkTable'
import StructureModal from '../../components/dictionaries/shared/StructureModal'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import useIcon from '../../composables/useIcon'
import usePagination from '../../composables/usePagination'
import { dictionary } from '../../mixins/dictionary'
import { mapGetters } from 'vuex'

export default {
  name: 'DictionaryUnitNames',
  setup (_, { root }) {
    const { pagination, setPagination } = usePagination(root)
    const { items, setItems, removeRow, findItemById } = useList(root)
    const { iconEdit, iconRemove } = useIcon(root)
    const suffixes = { add: 'add_unit_name', edit: 'edit_unit_name', new: 'new_unit_name' }
    const {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      title,
      updateResource,
    } = useDictionary(root, suffixes)
    return {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      iconEdit,
      iconRemove,
      items,
      pagination,
      removeRow,
      setItems,
      setPagination,
      title,
      updateResource,
    }
  },
  components: {
    StructureModal,
    PrkTable,
  },
  mixins: [dictionary],
  jsonapi: {
    unitNames: {
      config: ({ query, page }) => ({
        method: 'get',
        url: 'unit_names',
        params: {
          raw: !query.length,
          pagination: !query.length,
          page: page,
          query: query,
          size: 15,
        },
      }),
      variables () {
        return {
          page: this.pagination.currentPage,
          query: this.query,
        }
      },
      update (unitNames, requestInfo) {
        if (!this.query) this.setPagination(requestInfo.raw.data)
        return unitNames.map(teachingEffect => {
          return {
            ...this.$_.omit(teachingEffect, '__type'),
            [Symbol.for('_innerName')]: 'unitName',
          }
        })
      },
    },
  },
  data () {
    return {
      unitName: {
        name: '',
        [Symbol.for('_innerName')]: 'unitName',
      },
      unitNames: [],
      query: '',
    }
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  watch: {
    'pagination.currentPage' () {
      this.$jsonapi.queries.unitNames.refetch()
    },
    unitNames () {
      this.setItems(this.unitNames)
    },
    contrast () {
      const { iconEdit, iconRemove } = useIcon(this.$root)
      this.iconEdit = iconEdit
      this.iconRemove = iconRemove
    },
  },
  methods: {
    setQuery: _.debounce(function (query) {
      this.query = query
    }, 200),
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.dictionary.title'),
        subtitle: this.$tc('general.unit_name', 2),
      })
    },
  },

  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  .pcg-table-container {
    border-radius: 15px 15px 5px 5px;

    .pcg-table-content {
      border-radius: 15px 15px 5px 5px;
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-pagination {
    /deep/ .page-item {
      &, &.disabled {
        .page-link {
          color: $pcg-the-darkest-gray !important;
          &:hover {
            color: $hover-color !important;
          }
        }
      }
      &.active {
        .page-link {
          background-color: $main-active-color !important;
          color: $pcg-white !important;
        }
      }
      &:first-child, &:last-child {
        .page-link {
          color: $main-active-color !important;
        }
      }
    }
  }
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
