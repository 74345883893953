<template>
  <div class="personnel">
    <transition name="slide" mode="out-in">
      <router-view />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'Personnel',
}
</script>

<style scoped>

</style>
