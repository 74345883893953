<template>
  <div class="prk-shadow br-15">
    <syllabus-box>
      <template #header>
        <div class="d-flex justify-content-end">
          <prk-main-select
              :label="$t('general.role')"
              :options="roles"
              :default-option="roles[0]"
              color="aqua"
              class="w-100 w-sm-auto"
          />
        </div>
      </template>
    </syllabus-box>
    <personnel-table/>
  </div>
</template>

<script>
import SyllabusBox from '../../components/syllabus/PrkSyllabusBox'
import PrkMainSelect from '../../components/PrkSelect'
import PersonnelTable from '../../components/personnel/PersonnelTable'

export default {
  name: 'PersonnelList',
  components: {
    PersonnelTable,
    PrkMainSelect,
    SyllabusBox,
  },
  computed: {
    roles () {
      return [
        {
          text: this.$i18n.t('general.all'),
          value: 0,
        },
        {
          text: this.$i18n.t('general.coordinating_person'),
          value: 1,
        },
        {
          text: this.$i18n.t('general.supervisor'),
          value: 2,
        },
        {
          text: this.$i18n.t('general.responsible_person'),
          value: 3,
        },
      ]
    },
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.personnel_list'),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>

<style scoped lang="scss">
  ::v-deep {
    .box {
      border-radius: 15px 15px 0 0 !important;
    }

    .pcg-table-container {
      border-radius: 0 0 15px 15px;

      .pcg-table-content {
        border-radius: 0 0 15px 15px;
      }
    }
  }
</style>
