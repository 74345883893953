import { render, staticRenderFns } from "./PrkPcgWorkloadElement.vue?vue&type=template&id=8db3bd66&scoped=true&"
import script from "./PrkPcgWorkloadElement.vue?vue&type=script&lang=js&"
export * from "./PrkPcgWorkloadElement.vue?vue&type=script&lang=js&"
import style0 from "./PrkPcgWorkloadElement.vue?vue&type=style&index=0&id=8db3bd66&scoped=true&lang=scss&"
import style1 from "./PrkPcgWorkloadElement.vue?vue&type=style&index=1&id=8db3bd66&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8db3bd66",
  null
  
)

export default component.exports