// możliwe użycie this ze względu na to że funkcje są wywoływane wewnątrz komponentu vue
// TODO powinno być użyte root, mniej podatne na błędy, bardziej czytelne
import { ref, computed } from '@vue/composition-api'
import useModalValidation from './useModalValidation'

export default function (root, suffixes) {
  const { setErrors } = useModalValidation()
  const editMode = ref(false)
  const errors = ref({})
  const innerName = Symbol.for('_innerName')
  const canAdd = ref(true)

  async function addResource (resource, url, params = {}) {
    params = getParams(params, resource)
    try {
      const response = await this.$api.post(url, params)
      canAdd.value && this.items.push({
        ...this.$_.omit(response, 'message'),
        [Symbol.for('_innerName')]: resource[innerName],
        ...this.$_.clone(resource),
      })
      this.$bvModal.hide('add-modal')
      this.$toastr.s(this.$t('general.record_saved'))
    } catch (e) {
      errors.value = setErrors(e.errors)
    }
  }

  async function destroyResource (url, id, index) {
    const removeHandler = async () => {
      await this.$api.destroy(`${url}/${id}`)
    }
    await this.removeRow(index, null, removeHandler)
  }

  function clearModel (resource, omit = []) {
    const fieldsToBeOmitted = [...omit, 'selected', 'toggleDetails', 'active']
    for (const prop in this.$_.omit(resource, fieldsToBeOmitted)) {
      resource[prop] = ''
    }
  }

  function editResource (id, resourceName) {
    const innerName = Symbol.for('_innerName')
    editMode.value = true
    this[resourceName] = this.$_.clone(this.findItemById(id))
    if (resourceName !== 'effect') this[resourceName][innerName] = resourceName

    this.$nextTick(() => {
      this.$bvModal.show('edit-modal')
    })
  }

  function getParams (params, resource) {
    if (!Object.keys(params).length) {
      return { [resource[innerName]]: resource }
    } else {
      return params
    }
  }

  async function updateResource (resource, url) {
    const id = resource.id
    const params = { [resource[innerName]]: resource }
    try {
      await this.$api.update(`${url}/${id}`, params)
      const itemIndex = this.items.findIndex(item => item.id === id)
      const clonedResource = this.$_.clone(resource)
      this.$set(this.items, itemIndex, clonedResource)
      this.$bvModal.hide('edit-modal')
      this.$toastr.s(this.$t('general.record_saved'))
    } catch (e) {
      errors.value = setErrors(e.errors)
    }
  }

  const btnTitle = computed(() => {
    if (editMode.value) {
      return root.$t('general.update')
    } else {
      return root.$t(`general.${suffixes.add}`)
    }
  })

  const title = computed(() => {
    if (editMode.value) {
      return root.$t(`general.${suffixes.edit}`)
    } else {
      return root.$t(`general.${suffixes.new}`)
    }
  })

  return {
    addResource,
    btnTitle,
    canAdd,
    clearModel,
    destroyResource,
    editResource,
    editMode,
    errors,
    title,
    updateResource,
  }
}
