<template>
  <prk-table
      v-if="env && env.classCard"
      :items="rows"
      :syllabus-header="true"
      :show-headline-index="false"
      headline-index="1"
      thead-class="d-none"
      :excluded-fields="EXCLUDED_FIELDS"
      :fields-props="{ tdClass: ['py-4_5'] }"
      disable-actions
      :responsive="$mq === 'xs'"
      :class-card="env.classCard == 1"
  >
    <template #headline-text>
      <div class="d-flex align-items-center">
        <span>
          {{ section.header }}
        </span>
        <comment-modal-button
            modal-id="commentModal"
            :comment-count="section.commentCount"
            @input="setComments(section, 'SyllabusSection')"
        />
      </div>
    </template>
    <template v-slot:table-colgroup="{ fields }">
      <col v-for="field in fields" :key="field.key"
           :style="{ width: field.key === 'information' ? '40%' : '60%' }"
      >
    </template>
    <template v-slot:content="{ header, data }">
      <component v-if="header.key !== 'actions'"
                 :is="component"
                 :headerKey="header.key"
                 v-bind.sync="data.item"
                 :data="data"
                 :header="header"
      />
    </template>
  </prk-table>
</template>

<script>
import PrkTable from '../../../PrkTable'
import ToFillContent from './basic_information_about_subject/ToFillContentBasicInformation'
import ToVerificationContent from './basic_information_about_subject/ToVerificationContentBasicInformation'
import CommentModalButton from '../../../PrkCommentModalButton'
import useIcon from '../../../../composables/useIcon'
import useComments from '../../../../composables/useComments'
import { syllabusHeaderStatus } from '../../../../mixins/sylabus_header_status'
import { mapState, mapGetters } from 'vuex'
import { inject } from '@vue/composition-api'
import { showStatus } from '../../../../mixins/syllabus/status'

export default {
  name: 'BasicInformationAboutSubject',
  setup (_, { root }) {
    const editMode = inject('editMode', false)
    const { iconEdit } = useIcon(root)
    const { setComments } = useComments(root)
    return {
      iconEdit,
      setComments,
      editMode,
    }
  },
  components: {
    CommentModalButton,
    ToFillContent,
    ToVerificationContent,
    PrkTable,
  },
  mixins: [syllabusHeaderStatus, showStatus],
  props: {
    section: {
      required: true,
      type: Object,
    },
  },
  provide: {
    width: {
      value: 37,
      unit: 'px',
    },
  },
  inject: ['userId'],
  computed: {
    ...mapState('comments', ['commentableId']),
    ...mapGetters('page', ['contrast']),
    ...mapGetters('environment', ['env']),
    rows () {
      return this.section.rows.filter(el => el.visible)
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['isEditable', 'comments', 'visible', 'position',
      'elementType', 'whence', 'fill', 'questionMark', 'selected', 'id',
      'commentCount']
  },
  watch: {
    contrast () {
      const { iconEdit } = useIcon(this.$root)
      this.iconEdit = iconEdit
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  .question {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 30px;
    width: 30px;
    height: 30px;
    border: 2px solid $pcg-aqua-blue;
    border-radius: 50%;
    font-size: 18px;
    color: $pcg-aqua-blue;
  }

  textarea {
    height: 8rem !important;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {
    .question {
      border: 2px solid $pcg-aqua-blue;
      color: $pcg-aqua-blue;
    }
  }
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
