<template>
  <b-row align-h="end">
    <b-col cols="auto">
      <pcg-btn variant="additional" size="small" class="mx-0"
               @click="expandAll(characteristicEffects)">
      <span class="font-weight-bold">
        {{ $t('general.expand_all') }}
      </span>
      </pcg-btn>
    </b-col>
    <b-col cols="auto">
      <pcg-btn variant="additional" size="small" @click="addEffect">
          <span class="font-weight-bold">
            {{ $t('general.new_effect') }}
          </span>
      </pcg-btn>
    </b-col>
  </b-row>
</template>

<script>

import useExpandText from '../../../composables/useExpandText'
export default {
  name: 'CharacteristicEffectButtons',
  setup () {
    const { expandAll } = useExpandText('characteristic-effects')
    return { expandAll }
  },
  props: {
    addEffect: { required: true, type: Function },
    characteristicEffects: { required: true, type: Array },
  },
}
</script>
