<template>
  <pcg-modal
      :action="submit"
      :btn-title="btnTitle"
      icon="pcg-icon-document"
      :modal-id="modalId"
      size="lg"
      scrollable
      :title="title"
      @show="subject.semester.id = semesterId"
      @hidden="$emit('hidden')"
  >
    <subject-names
        v-bind.sync="subject.subjectName"
        :disabled="subject.hasMatrices"
        :show-error="$v.subject.subjectName.$error"
        :subjectName="subject.subjectName"
    />

    <b-row class="d-flex ml-2 mt-2">
      <span class="prk-fz-12 d-inline-block ">
        {{ $t('components.prk_modals.add_subject.forms_of_classes') }}
      </span>
    </b-row>

    <b-row class="mx-3 mt-4">
      <new-form-of-classes
          :modal-id="modalId"
          :edited-subject-forms="subject.subjectForms"
          @add:subject-form="addSubjectForm"
          @update:subject-form="updateSubjectForm"
          @remove:subject-form="removeSubjectForm"
      />
    </b-row>
  </pcg-modal>
</template>

<script>
import NewFormOfClasses from './subject_form_modal/NewFormOfClasses'
import SubjectNames from './subject_form_modal/SubjectNames'
import useSubjects from '../../../composables/useSubjects'
import useDictionary from '../../../composables/useDictionary'
import { provide, ref } from '@vue/composition-api'
import { numeric, required, requiredUnless } from 'vuelidate/lib/validators'

export default {
  name: 'PrkSubjectFormModal',
  setup (_, { root }) {
    const { updateSubject } = useSubjects(root)
    const suffixes = { add: 'add', edit: 'edit_subject', new: 'add_new_subject' }
    const { btnTitle, title, editMode } = useDictionary(root, suffixes)

    const shownModal = ref(false)
    provide('shownModal', shownModal)
    return {
      btnTitle,
      editMode,
      title,
      shownModal,
      updateSubject,
    }
  },
  validations: {
    subject: {
      subjectName: {
        name: { required },
      },
      subjectForms: {
        $each: {
          ects: {
            required: requiredUnless(function (form) {
              return form._destroy === 1
            }),
            numeric,
          },
          formOfClassName: {
            required: requiredUnless(function (form) {
              return form._destroy === 1
            }),
          },
          formOfCreditName: {
            required: requiredUnless(function (form) {
              return form._destroy === 1
            }),
          },
          numberOfHours: {
            required: requiredUnless(function (form) {
              return form._destroy === 1
            }),
            numeric,
          },
          lecturers: {
            required: requiredUnless(function (form) {
              return form._destroy === 1 || form.hidden
            }),
          },
        },
      },
    },
  },
  components: { SubjectNames, NewFormOfClasses },
  props: {
    modalId: { required: true, type: String },
    editedSubject: { type: Object, default: () => null },
    semesterId: { required: true, type: String },
  },
  provide () {
    return { $v: this.$v }
  },
  data () {
    return {
      addSubjectButtonWasClicked: false,
      subject: {
        id: `${this.$uuidv4()}__new`,
        parentId: null,
        semester: {
          id: this.semesterId,
        },
        subjectForms: [
          {
            formOfClass: '',
            formOfClassId: '',
            formOfCredit: '',
            formOfCreditId: '',
            numberOfHours: '',
            ects: '',
            hidden: false,
            id: `${this.$uuidv4()}__new`,
            lecturers: [],
          },
        ],
        subjectName: {
          name: '',
          id: '',
        },
      },
    }
  },
  computed: {
    semester () {
      return this.$store.getters['semesters/getSemesterById'](this.semesterId) ||
          this.$store.state.semesters.semester
    },
    subjectForApi () {
      return {
        id: this.subject.id,
        name: this.subject.subjectName.name,
        _edited: this.subject._edited,
        _destroy: this.subject._destroy,
        subject_forms: this.subject.subjectForms,
      }
    },
  },
  watch: {
    editedSubject: {
      handler () {
        if (!this.editedSubject) return
        this.subject = this.$_.cloneDeep(this.editedSubject)
        this.editMode = !!this.editedSubject
      },
      deep: true,
    },
  },
  created () {
    if (!this.editedSubject) return

    this.subject = this.$_.cloneDeep(this.editedSubject)
    this.editMode = !!this.editedSubject
  },
  methods: {
    submit () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      if (this.editedSubject) {
        this.compareSubject()
        if (this.$checkRouteName('study_plans_list_path')) {
          this.$api.put(`subjects/${this.subject.id}`, { subject: this.subjectForApi })
          this.editSemester()
        } else {
          this.editSemester()
        }
        this.$store.dispatch('subjects/setEditedSubject', this.subject)
      } else {
        if (this.$checkRouteName('study_plans_list_path')) {
          this.$api.put(`subjects/${this.subject.id}`, { subject: this.subjectForApi })
          this.editSemester()
        } else {
          this.editSemester()
        }
        this.$store.dispatch('subjects/setNewSubject', this.subject)
      }
      this.$bvModal.hide(this.modalId)
    },
    addSubjectForm () {
      const subjectForm = this.$_.cloneDeep(this.subject.subjectForms[0])
      for (const key in subjectForm) subjectForm[key] = ''
      subjectForm.lecturers = []
      subjectForm._destroy = 0
      subjectForm.id = `${this.$uuidv4()}__new`
      this.subject.subjectForms.push(subjectForm)
    },
    updateSubjectForm (editedForm) {
      const form = this.editedSubject?.subjectForms.find(form => form.id === editedForm.id)
      const editedFormIndex = this.subject.subjectForms.findIndex(form => form.id === editedForm.id)

      if (!editedForm.id.match('__new') && form) this.compareSubjectForm(form, editedForm)
      this.$set(this.subject.subjectForms, editedFormIndex, editedForm)
    },
    removeSubjectForm (id) {
      const form = this.subject.subjectForms.find(form => form.id === id)
      this.$set(form, '_destroy', 1)
    },
    compareSubject () {
      const result = this.$compare(this.subject, this.editedSubject)
      result ? this.subject._edited = 0 : this.subject._edited = 1
    },
    compareSubjectForm (form, editedForm) {
      delete editedForm._edited
      const result = this.$compare(form, editedForm)
      result ? editedForm._edited = 0 : editedForm._edited = 1
    },
    editSemester () {
      const semester = this.$_.cloneDeep(this.semester)
      semester._edited = 1
      this.$store.dispatch('semesters/editSemester', semester)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  .pcg-icon-cross {
    color: $pcg-gray;
    font-size: 0.71rem;
    cursor: pointer;
  }
}
</style>

<style lang="scss">
#subject-form, #add-subject {
    .modal-body {
      overflow-x: hidden;
    }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  ::v-deep {
    .pcg-icon-cross {
      color: $pcg-gray;
    }
  }
}
</style>
