<template>
  <div class="w-100">
    <div class="d-flex mr-3 w-100 align-self-end justify-content-center">
      <pcg-btn variant="additional" class="mb-sm-3" size="small"
               @click="$emit('add:subject-form')"
      >
        + {{ $t('components.prk_modals.add_subject.new_form_of_classes') }}
      </pcg-btn>
    </div>

    <transition-group name="fade">
      <template v-for="(form, index) in subjectForms">
        <b-row align-v="center" class="mb-3 mt-3" :key="form.id">
          <b-col cols="2" sm="auto" lg="2" class="my-3 my-lg-0 pt-4_5">
            <prk-index :width="{ value: 37, unit: 'px' }" :height="{ value: 26, unit: 'px' }">
              {{ index + 1 }}
            </prk-index>
          </b-col>
          <form-of-classes
              :index="index" :formOfClass.sync="form.formOfClassName"
              :form-of-class-id.sync="form.formOfClassId"
              @update="$emit('update:subject-form', form)"
          />
          <number-of-hours
              :index="index"
              :numberOfHours.sync="form.numberOfHours"
              @update="$emit('update:subject-form', form)"
          />
          <ects-points
              :index="index"
              :ects.sync="form.ects"
              @update="$emit('update:subject-form', form)"
          />
          <form-of-credit
              :index="index"
              :form="form"
              :form-of-credit.sync="form.formOfCreditName"
              :form-of-credit-id.sync="form.formOfCreditId"
              @update="$emit('update:subject-form', form)"
          />
          <portal :to='form.id'>
            <remove-subject-form
                :edited-subject-forms="editedSubjectForms"
                @remove="$emit('remove:subject-form', form.id)"
            />
          </portal>
        </b-row>

        <b-row align-v="center" class="mb-3" :key="`${form.id}_lecturers`">
          <b-col cols="12">
            <search-coordinators
                class="w-100"
                :index="index"
                :lecturers="form.lecturers"
                @add="addLecturer($event, index)"
                @remove="removeLecturer($event, index)"
            />
          </b-col>
        </b-row>

        <prk-horizontal-line v-if="index === subjectForms.length - 2" :key="`${index}-hr`"/>
      </template>
    </transition-group>
  </div>
</template>

<script>
import PrkHorizontalLine from '../../../syllabus/PrkHorizontalLine'
import PrkIndex from '../../../PrkIndex'
import SearchCoordinators from './SearchCoordinators'
import FormOfClasses from './new_form_of_classes/FormOfClasses'
import NumberOfHours from './new_form_of_classes/NumberOfHours'
import FormOfCredit from './new_form_of_classes/FormOfCredit'
import EctsPoints from './new_form_of_classes/EctsPoints'
import RemoveSubjectForm from './new_form_of_classes/RemoveSubjectForm'
import { inject } from '@vue/composition-api'

export default {
  name: 'NewFormOfClasses',
  setup () {
    const subjectFormId = inject('subjectFormId', '')
    return { subjectFormId }
  },
  components: {
    RemoveSubjectForm,
    EctsPoints,
    FormOfCredit,
    NumberOfHours,
    FormOfClasses,
    SearchCoordinators,
    PrkHorizontalLine,
    PrkIndex,
  },
  props: {
    modalId: { required: true, type: String },
    editedSubjectForms: { required: true, type: Array },
  },
  data () {
    return {
      init: false,
      subjectForms: [],
    }
  },
  watch: {
    editedSubjectForms: {
      handler () {
        this.subjectForms = this.$_.cloneDeep(this.editedSubjectForms).filter(form => {
          return (form._destroy !== 1 && !form.hidden) &&
              (!!form.id.match('__new') || form.id === this.subjectFormId)
        })
        if (this.modalId === 'add-subject' && !this.subjectForms.length) {
          this.subjectForms = this.$_.cloneDeep(this.editedSubjectForms)
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    addLecturer (lecturer, index) {
      const form = this.subjectForms[index]
      form.lecturers.push(lecturer)
      form.lecturers = this.$_.unionBy(form.lecturers, 'id')
      this.$emit('update:subject-form', form, index)
    },
    removeLecturer (lecturerId, index) {
      const form = this.subjectForms[index]
      const lecturerIndex = form.lecturers.findIndex(lec => lec.id === lecturerId)
      form.lecturers.splice(lecturerIndex, 1)
      this.$emit('update:subject-form', form, index)
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

::v-deep {
  .pcg-input {
    padding: 0;
    text-align: center;
  }
}
</style>
