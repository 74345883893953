import axios from 'axios'
import objectToFormData from 'object-to-formdata'
import { decamelize } from '@ridi/object-case-converter'
import humps from 'humps'
import { store } from '../store/index'
import router from '../router/index'
import { app } from '../index'

const decamelizeThatDontBreaksFile = (object) => {
  if (object && !(object instanceof File)) {
    if (object instanceof Array) {
      return object.map(item => decamelizeThatDontBreaksFile(item))
    }
    if (typeof object === 'object') {
      return Object.keys(object).reduce((acc, next) => ({
        ...acc,
        [decamelize(next)]: decamelizeThatDontBreaksFile(object[next]),
      }), {})
    }
  }
  return object
}
const baseURL = '/api/v1'
const headers = {
  'Content-Type': 'application/json',
}
const transformResponse = [
  ...axios.defaults.transformResponse,
  (data, headers) => {
    // if (data && headers['Content-Type'].includes('application/json')) {
    return humps.camelizeKeys(data)
    // }
  },
]
const transformRequest = [
  (data, headers) => {
    if (data && headers['Content-Type'].includes('application/json')) {
      return decamelize(data, { recursive: true })
    } else if (data && headers['Content-Type'].includes('multipart/form-data')) {
      return objectToFormData.serialize(decamelize(data, { recursive: true }))
    } else {
      return data
    }
  },
  ...axios.defaults.transformRequest,
]

const api = axios.create({
  baseURL,
  headers,
  transformResponse,
  transformRequest,
})

api.interceptors.request.use(config => {
  if (store.getters['auth/signedIn']) {
    config.headers.common.Authorization = store.getters['auth/token']
  }
  if (app) { app.$Progress.start() }
  return config
}, function (error) {
  return Promise.reject(error)
})

let expiredToken

api.interceptors.response.use(function (response) {
  app.$Progress.finish()
  return response
}, async function (error) {
  app.$Progress.fail()
  if (error.response && error.response.status && error.response.status == 401) {
    if (error.response.data.message === 'Signature has expired') {
      expiredToken = error.response.config.headers.Authorization
    }
    await store.dispatch('auth/signOut')
    await router.push({ name: 'login_path' }).catch(() => {})
    return new Promise(() => {})
  } if (error.response && error.response.status && error.response.status == 403) {
    if (error.response.config.headers.Authorization !== expiredToken) {
      app.$toastr.e(app.$t('general.forbidden'))
    }
    return new Promise(() => {})
  } else if (error.response && error.response.status && error.response.status == 422 &&
    error.response.data && error.response.data.error &&
    error.response.data.error === 'Missing role') {
    router.push({ name: 'role_choose_path' })
    return false
  }
  return Promise.reject(error)
})

export default api
