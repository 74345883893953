<template>
  <div class="d-flex">
    <div class="prk-info-box-text" :class="`${status}-color`">
      <i class="fas fa-circle"/>
      {{ $t(`general.events.statuses.${status}`) }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'prk-info-type-box',
  props: {
    status: {
      type: String,
      required: true,
    },
  },
}
</script>
<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';

.prk-info-box-text {
  background-color: $pcg-white;
  border-radius: 100px;
  font-size: 1rem;
  line-height: 2.5rem;
  padding: 0 1rem 0 1rem;
  font-weight: bold;
  margin-left: .8rem;

  i {
    font-size: 0.8rem;
    margin-right: 0.5rem;
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-info-box-text {
    background-color: $pcg-white;
  }
}
</style>
