<template>
  <b-row no-gutters>
    <pcg-box class="syllabus-box">
      <slot name="header"/>
      <div class="contents mt-3">
        <slot name="content">
          <div v-for="(item, index) in items" :key="index">
            <template v-if="item.type === 'checkbox' && item.visible">
              <h4
                  v-if="item.descriptionAbove.length"
                  class="font-weight-bold my-3"
                  :class="{ 'my-4': index === 0 }"
              >
                {{ item.descriptionAbove }}
              </h4>
              <pcg-checkbox
                  class="font-weight-bold prk-fz-12"
                  :class="{
                  'aqua-blue': item.selected,
                  'mb-4': isLast(item, index)
                }"
                  @input="select(index, $event)"
              >
                {{ item.label }}
              </pcg-checkbox>
              <h4
                  v-if="item.descriptionUnder.length"
                  class="font-weight-bold"
              >
                {{ item.descriptionUnder }}
              </h4>
            </template>
            <template v-if="(item.type === 'textarea' ||
                             item.type === 'input')  &&
                             item.visible"
            >
              <h4
                  v-if="item.descriptionAbove.length"
                  class="font-weight-bold my-4"
              >
                {{ item.descriptionAbove }}
              </h4>
              <div v-if="item.type === 'input'" class="mb-4 prk-control">
                <pcg-text-input
                    :ref="setRef(item.type, index)"
                    :label="item.label"
                    :type="item.type"
                    v-model="item.value"
                />
              </div>
              <div v-else class="mb-4 prk-control">
                <label class="pcg-input-label">{{ item.label }}</label>
                <textarea-autosize
                    :ref="setRef(item.type, index)"
                    v-model="item.value"
                    type="textarea"
                    :min-height="30"
                    class="text-area-autosize form-control pcg-field"
                />
              </div>
              <h4
                  v-if="item.descriptionUnder.length"
                  class="font-weight-bold mt-3"
              >
                {{ item.descriptionUnder }}
              </h4>
            </template>
            <template v-if="item.type === 'table' && item.visible">
              <h4
                  v-if="item.descriptionAbove.length"
                  class="font-weight-bold my-3"
              >
                {{ item.descriptionAbove }}
              </h4>
              <prk-table
                  :show-headline="false"
                  :items="item.data"
              >
                <template v-slot:table-colgroup="{ fields }">
                  <col v-for="field in fields" :key="field.key"
                       :style="{ width: '30%' }"
                  >
                </template>
                <template v-slot:content="{ header, data }">
                  <pcg-text-input
                      v-if="header.key !== 'actions'"
                      :show-label="false"
                      v-model="data.item[header.key].value"
                      class="min-width"
                  />
                  <base-button-remove
                      v-else
                      text="delete_row"
                      @click="removeRow(data.index, item.data)"
                  />
                </template>
                <template v-slot:custom-foot>
                  <add-row :items="item.data" @addRow="item.data.push($event)"/>
                </template>
              </prk-table>
              <h4
                  v-if="item.descriptionUnder.length"
                  class="font-weight-bold mt-3"
              >
                {{ item.descriptionUnder }}
              </h4>
            </template>
          </div>
        </slot>
      </div>
    </pcg-box>
  </b-row>
</template>

<script>
import AddRow from '../TableHelpers/PrkAddRow'
import PrkTable from '../PrkTable'
import useList from '../../composables/useList'

export default {
  name: 'SyllabusBox',
  setup (props, { root }) {
    const { removeRow } = useList(root)
    return { removeRow }
  },
  components: {
    PrkTable,
    AddRow,
  },
  props: {
    items: {
      required: false,
      type: Array,
      default: () => ([]),
    },
    rows: {
      type: String,
      default: '1',
    },
    index: {
      type: String,
      required: false,
      default: '',
    },
    showHeader: {
      type: Boolean,
      default: true,
    },
  },
  mounted () {
    if (Object.keys(this.$refs).length) {
      for (const textarea in this.$refs) {
        this.$refs[textarea][0].$el.querySelector('textarea')
          .setAttribute('rows', this.rows)
      }
    }
  },
  computed: {
    margin () {
      return {
        'my-4': this.showHeader,
        'my-3': !this.showHeader,
      }
    },
  },
  methods: {
    select (index, val) {
      this.items[index].selected = val
    },
    isLast (item, index) {
      const filteredItems = this.items.filter(item => item.visible)
      return !item.descriptionUnder.length && index !== filteredItems.length - 1
    },
    setRef (type, index) {
      return type === 'textarea' ? `${type}-${index}` : null
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars';
.syllabus-box.box-container {
  margin-bottom: 0;
  ::v-deep {
    .box {
      box-shadow: none;
      border-radius: 0;
    }
  }
}
.contents {
  color: $pcg-the-darkest-gray;
  ::v-deep {
    .aqua-blue {
      label {
        color: $pcg-aqua-blue;
      }
    }
  }
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .contents {
    color: $pcg-the-darkest-gray;
    ::v-deep {
      .aqua-blue {
        label {
          color: $pcg-aqua-blue;
        }
      }
    }
  }
}
</style>
