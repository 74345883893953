var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.$jsonapi.loading)?_c('div',{staticClass:"text-center text-danger my-2"},[_c('pcg-spinner',{attrs:{"variant":"small"}})],1):_c('prk-table',{staticClass:"sub-table",attrs:{"items":_vm.semesters,"fields-props":{ thClass: 'd-none' },"excluded-fields":_vm.EXCLUDED_FIELDS,"show-headline":false},scopedSlots:_vm._u([{key:"table-colgroup",fn:function(ref){
var fields = ref.fields;
return _vm._l((fields),function(field){return _c('col',{key:field.key,style:({ width: field.key === 'nameOfTerm' ? '25%' : '15%' })})})}},{key:"cell(id)",fn:function(ref){
var term = ref.item;
var index = ref.index;
return [_c('pcg-checkbox',{staticClass:"pl-5",attrs:{"value":term.selected},on:{"input":function($event){return _vm.selectRow(index, $event)}}})]}},{key:"content",fn:function(ref){
var header = ref.header;
var data = ref.data;
return [_c('study-plans-table-terms-header',{attrs:{"index":data.index,"semester":data.item,"header-key":header.key}}),_vm._v(" "),(header.key === 'actions')?_c('div',{staticClass:"grid"},[_c('pcg-switch',{staticClass:"item-a",on:{"input":function($event){return _vm.updateSemester(data.item)}},model:{value:(data.item.active),callback:function ($$v) {_vm.$set(data.item, "active", $$v)},expression:"data.item.active"}}),_vm._v(" "),_c('study-plans-table-terms-active-term',{attrs:{"semester":data.item}}),_vm._v(" "),_c('pcg-switch',{staticClass:"item-c",on:{"input":function($event){return _vm.updateSemester(data.item)}},model:{value:(data.item.activeEffects),callback:function ($$v) {_vm.$set(data.item, "activeEffects", $$v)},expression:"data.item.activeEffects"}}),_vm._v(" "),_c('study-plans-table-terms-teaching-effects',{attrs:{"semester":data.item}}),_vm._v(" "),_c('study-plans-table-terms-subjects',{attrs:{"study-plan":_vm.studyPlan,"semester":data.item},on:{"update:has-subjects":function($event){data.item.hasSubjects = false}}})],1):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }