<template>
  <div>
    <return v-if="listMode" path-name="dictionaries_courses_path"
            :params="routeParams" :query="routeQuery">
      {{ $t('components.prk_dictionaries.back_to_courses') }}
    </return>

    <add-speciality v-if="!listMode" v-b-modal.add-modal button-name="new_speciality"/>
    <specialities-table
        :disable-actions="listMode"
        :edit-resource="editResource"
        :destroy-resource="destroyResource"
        :items="items"
        :busy="$jsonapi.loading"
        resource-name="speciality"
        url="specialities"
    />

    <structure-modal
        :action="action(speciality, 'specialities')"
        :btn-title="btnTitle"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="speciality"
        @hidden="hideModal('speciality')"
        @clear-error="errors[$event]= ''"
    />
  </div>
</template>

<script>
import Return from '../../components/base_components/Return'
import AddSpeciality from '../../components/dictionaries/DictionariesAddResourceButton'
import SpecialitiesTable from '../../components/dictionaries/shared/DictionaryTable'
import StructureModal from '../../components/dictionaries/shared/StructureModal'
import useDictionary from '../../composables/useDictionary'
import useList from '../../composables/useList'
import { dictionary } from '../../mixins/dictionary'

export default {
  name: 'DictionarySpecialities',
  setup (_, { root }) {
    const { items, setItems, removeRow, findItemById } = useList(root)
    const suffixes = { add: 'add_speciality', edit: 'edit_speciality', new: 'new_speciality' }
    const {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      title,
      updateResource,
    } = useDictionary(root, suffixes)
    return {
      addResource,
      btnTitle,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      items,
      removeRow,
      setItems,
      title,
      updateResource,
    }
  },
  components: {
    SpecialitiesTable,
    StructureModal,
    AddSpeciality,
    Return,
  },
  mixins: [dictionary],
  jsonapi: {
    specialities: {
      config: ({ url }) => ({
        method: 'get',
        url: url,
      }),
      variables () {
        return {
          url: this.url,
        }
      },
      update (specialities) {
        return specialities.map(speciality => {
          return this.$_.omit(speciality, '__type')
        })
      },
      skip () {
        return !this.url.length
      },
    },
  },
  data () {
    return {
      url: '',
      routeParams: {},
      routeQuery: {},
      specialities: [],
      listMode: false,
      speciality: {
        name: '',
        shortcut: '',
        [Symbol.for('_innerName')]: 'speciality',
      },
    }
  },
  watch: {
    specialities () {
      this.setItems(this.specialities)
    },
  },
  created () {
    if (this.$route.params.courseNameId) {
      this.url = `specialities?course_name_id=${this.$route.params.courseNameId}`
      this.routeParams = { facultyId: localStorage.getItem('facultyId'), ...this.$route.params }
      this.routeQuery = { faculty: localStorage.getItem('faculty') }
      this.listMode = true
    } else {
      this.url = 'specialities'
    }
  },
  mounted () {
    if (!this.$route.params.courseNameId) return
    this.link = document.querySelector('[href="/dictionaries/faculties"]')
    this.link.classList.add('active', 'link-active')
  },
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$route.query.course,
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
  beforeRouteLeave (to, from, next) {
    if (this.$route.params.courseNameId) {
      this.link.classList.remove('active', 'link-active')
    }
    next()
  },
}
</script>
