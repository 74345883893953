<template>
  <b-row v-if="syllabuses.length">
    <b-col cols="6">
      <prk-table
          :items="syllabuses"
          :excluded-fields="EXCLUDED_FIELDS"
          :busy="$jsonapi.loading"
          hover
          :show-headline="false"
          @row-clicked="rowClicked"
      >
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
      </prk-table>
    </b-col>
    <b-col cols="6">
      <prk-table
          :items="subjectsInfo"
          v-if="subjectsInfo.length > 0"
          :excluded-fields="EXCLUDED_FIELDS"
          :busy="$jsonapi.loading"
          :show-headline="false">
        <template v-slot:table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle"></b-spinner>
          </div>
        </template>
        <template v-slot:content="{ header, data }">
          <div style="white-space: pre">{{ data.item[header.key] }}</div>
        </template>
      </prk-table>
    </b-col>
  </b-row>
</template>
<script>

import PrkTable from '../../components/PrkTable'
import useSyllabusCard from '../../composables/useSyllabusCard'

export default {
  name: 'matrix-syllabuses',
  setup (_, { root }) {
    const { makeSyllabusCardUniq } = useSyllabusCard(root)
    return { makeSyllabusCardUniq }
  },
  components: { PrkTable },
  props: { matrixId: String },
  jsonapi: {
    syllabuses: {
      config: ({ url }) => ({
        method: 'get',
        url: url,
      }),
      variables () {
        return {
          url: this.url,
        }
      },
      update (syllabuses) {
        const syllabusesWithUpdatedName = this.makeSyllabusCardUniq(syllabuses)
        return syllabusesWithUpdatedName.map(syllabus => {
          return {
            id: syllabus.id,
            syllabusCard: syllabus.syllabusCard,
            speciality: syllabus.specialityName || '-',
            specialization: syllabus.specializationName || '-',
            coordinatingPerson: syllabus.coordinators.length
              ? syllabus.coordinators.join('\n') : '-',
            status: this.$t(`general.statuses.${syllabus.status}`),
            subjectsInfo: syllabus.semesters.map(semester => {
              return {
                studyPlanName: syllabus.studyPlanName,
                semester: semester.semesterNumber,
                subjectForm: syllabus.subjectForms.filter(form => {
                  return form.semesterId === semester.id
                }).map(form => form.name).join('\n'),
                kind: syllabus.kind,
              }
            }),
          }
        })
      },
      skip () {
        return !this.matrixId.length
      },
    },
  },
  data () {
    return {
      syllabuses: [],
      subjectsInfo: [],
      url: '',
    }
  },
  watch: {
    matrixId: {
      handler () {
        this.url = `matrices/${this.matrixId}/matrix_syllabuses`
        this.EXCLUDED_FIELDS = ['id', 'subjectsInfo']
      },
      immediate: true,
    },
  },
  methods: {
    rowClicked (syllabus) {
      this.subjectsInfo = syllabus.subjectsInfo
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .pcg-table-narrow-row {
    cursor: pointer !important;
  }

  tr:focus {
    outline: none !important;
  }
}
</style>
