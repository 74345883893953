<template>
  <pcg-checkbox
      style="line-height: 3rem"
      :value="checkSubItems()"
      @input="selectCheckbox"
  >
  </pcg-checkbox>
</template>

<script>
import { tableCheckbox } from '../../../mixins/table_checkbox'

export default {
  name: 'SyllabusTableCheckbox',
  mixins: [tableCheckbox],
  computed: {
    // TODO refactor
    args () {
      return {
        index: this.data.index,
        prop: 'syllabuses',
        value: this.state,
      }
    },
  },
  methods: {
    // TODO refactor
    checkSubItems () {
      let isEverySelected = false
      const subItems = this.items[this.data.index].syllabuses
      if (this.items[this.data.index].syllabuses.length) {
        isEverySelected = subItems.every(el => el.selected)
      }
      this.$emit('input', isEverySelected)
      return isEverySelected
    },
  },
}
</script>
