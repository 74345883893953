<template>
  <div>
    <template v-if="type === 'textarea'">
      <prk-editor :value="value"
                  @input="$emit('input', $event)"/>
    </template>
    <pcg-text-input
        v-else
        class="w-100 pl-3 pl-lg-0"
        :class="className"
        :show-label="false"
        :type="type"
        :value="value"
        @input="$emit('input', $event)"
    />
  </div>
</template>

<script>
import PrkEditor from '../../../../../PrkEditor'
export default {
  name: 'BasicInformationAboutSubjectInput',
  components: { PrkEditor },
  props: {
    type: {
      required: true,
      type: String,
    },
    value: {
      type: String,
      default: '',
    },
  },
  computed: {
    className () {
      return {
        'w-100': this.type === 'textarea',
        'w-lg-50': this.type !== 'textarea',
      }
    },
  },
}
</script>
