<template>
  <div v-if="header.key === 'value'">
    <span v-if="isLanguageOfLectureRow">
      {{ languageOfLecture }}
    </span>
    <span v-else-if="isLeadersRow">
      <prk-tags :value="leaders" without-remove />
    </span>
    <span v-else-if="isUnitNameRow">{{ realUnitName() }}</span>
    <span v-else>
      {{ value }}
    </span>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkTags from '../../../../PrkTags'
const { mapState } = createNamespacedHelpers('syllabusFill')

export default {
  name: 'basic-information-about-subject-value',
  components: {
    PrkTags,
  },
  data () {
    return {
      units: [],
    }
  },
  props: {
    header: { required: true, type: Object },
    information: { required: true, type: String },
    value: { type: [String, Number, Array], default: '' },
  },
  computed: {
    ...mapState(['leaders']),
    languageOfLecture () {
      return this.value === '0' ? 'Polski' : 'English'
    },
    isLanguageOfLectureRow () {
      return this.information === 'language'
    },
    isLeadersRow () {
      return this.information === 'leading_persons'
    },
    isUnitNameRow () {
      return this.information === 'row_3'
    },
  },
  created () {
    if (this.information === 'row_3') {
      this.getUnits()
    }
  },
  methods: {
    async getUnits () {
      const { data: units } = await this.$api.get('unit_names')
      this.units = units.map(unit => {
        return {
          text: unit.attributes.name,
          value: unit.id,
        }
      })
    },
    realUnitName () {
      if (this.value) {
        const unit = this.units.find(unit => unit.value === this.value)
        if (unit) {
          return unit.text
        }
      }
    },
  },
  watch: {
    information: {
      deep: true,
      handler () {
        if (this.information === 'row_3') {
          this.getUnits()
        }
      },
    },
  },
}
</script>
