<template>
  <base-beam>
    <prk-beam>
      {{ count }}
      <span class="text-lowercase d-inline-block mr-3">
          &nbsp; {{ $t('components.prk_syllabus.beam.selected_elements') }}
        </span>
      <prk-group-prints
          :ids="generalEffectIds"
          resource="general_effects"
          :resources="generalEffects"
      />
    </prk-beam>
  </base-beam>
</template>

<script>
import PrkBeam from './PrkBeam'
import PrkGroupPrints from '../PrkGroupPrints'

export default {
  name: 'DictionaryGeneralEffectsBeam',
  components: { PrkBeam, PrkGroupPrints },
  props: {
    generalEffects: { required: true, type: Array },
  },
  computed: {
    generalEffectIds () {
      return this.generalEffects.map(effect => effect.id)
    },
    count () {
      return this.generalEffects.length
    },
  },
}
</script>
