<template>
  <div class="d-flex mt-3 ml-5 mb-4">
      <span
          v-for="(item, index) in baseInfo"
          :key="index"
          class="font-weight-bold pcg-dark-gray-color prk-fz-12 mr-4"
      >
        <span v-if="index === 'type'">
          {{ $t('components.prk_syllabus.table.type') }}: {{ item }}
        </span>
        <span v-else>
          {{ item }}
        </span>
      </span>
  </div>
</template>

<script>
export default {
  name: 'CourseBaseInfo',
  props: {
    course: { required: true, type: Object },
  },
  computed: {
    baseInfo () {
      return {
        faculty: this.course.faculty,
        courseName: this.course.name,
        type: this.course.type,
        academicYear: this.course.academicYear,
        studyProfile: this.course.studyProfile,
        speciality: this.course.speciality,
      }
    },
  },
}
</script>
