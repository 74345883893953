<template>
  <pcg-btn
      round :icon="iconRemove" text="delete"
      class="mr-4 prk-button" variant="danger"
      @click="removePath"
  />
</template>

<script>
import useIcon from '../../composables/useIcon'
import { mapGetters } from 'vuex'

export default {
  name: 'RemovePath',
  setup (_, { root }) {
    const { iconRemove } = useIcon(root)
    return { iconRemove }
  },
  methods: {
    async removePath () {
      const res = await this.$removeSwal()
      if (!res.value) return
      this.$emit('click')
    },
  },
  watch: {
    contrast () {
      const { iconRemove } = useIcon(this.$root)
      this.iconRemove = iconRemove
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .prk-button {
    ::v-deep .pcg-button {
      background-color: $pcg-dark-green !important;
    }
  }
}
</style>
