import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import { store } from './store'
import router from './router'
import { i18n } from './translations'
import BootstrapVue from 'bootstrap-vue'
import Vuex from 'vuex'
import App from './layout/app.vue'
import VueToastr from 'vue-toastr'
import VueProgressBar from 'vue-progressbar'
import Paginate from 'vuejs-paginate'
import VueSweetalert2 from 'vue-sweetalert2'
import VueDisqus from 'vue-disqus'
import PcgCoreVue from 'pcg-core-vue'
import PortalVue from 'portal-vue'
import VueMq from 'vue-mq'
import Vuelidate from 'vuelidate'
import lodash from './plugins/lodash'
import humps from './plugins/humps'
import Jsonapi from 'vue-jsonapi'
import api from './plugins/api'
import axiosConfig from './api/index'
import pluralize from './plugins/pluralize'
import momentjs from './plugins/moment'
import routeHelpers from './plugins/route-helpers'
import helpers from './plugins/helpers'
import qs from './plugins/qs'
import list from './plugins/list'
import errors from './plugins/errors'
import cookies from './plugins/js-cookie'
import Fragment from 'vue-fragment'
import VueObserveVisibility from 'vue-observe-visibility'
import ActionCableVue from 'actioncable-vue'
import TextareaAutosize from 'vue-textarea-autosize'

import './components/_globals'
import './assets/stylesheets/application.scss'
import 'sweetalert2/dist/sweetalert2.min.css'

Vue.use(VueCompositionApi)
Vue.use(momentjs)
Vue.use(lodash)
Vue.use(pluralize)
Vue.use(humps)
Vue.use(api)
Vue.use(helpers)
Vue.use(routeHelpers)
Vue.use(qs)
Vue.use(Vuelidate)
Vue.use(list)
Vue.use(cookies)
Vue.use(Vuex)
Vue.use(TextareaAutosize)
Vue.use(VueMq, {
  breakpoints: {
    xs: 576,
    sm: 768,
    md: 992,
    lg: 1200,
    xl: Infinity,
  },
})

Vue.use(Jsonapi, {
  client: axiosConfig,
})
Vue.use(Fragment.Plugin)
Vue.use(VueObserveVisibility)

const sweetAlertOptions = {
  cancelButtonText: i18n.messages[i18n.locale].general.cancel,
  buttonsStyling: false,
  customClass: {
    title: 'pcg-sweetalert-title',
    content: 'pcg-sweetalert-content',
    confirmButton: 'pcg-button pcg-main-btn pcg-small-btn',
    cancelButton: 'pcg-button pcg-additional-btn pcg-small-btn',
  },
}

Vue.use(BootstrapVue)
Vue.use(VueSweetalert2, sweetAlertOptions)
Vue.use(VueDisqus)
Vue.component('paginate', Paginate)
Vue.use(PcgCoreVue)
Vue.use(PortalVue)
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectImmediately: true,
})

moment.locale(i18n.locale)

let app
document.addEventListener('DOMContentLoaded', () => {
  Vue.use(VueToastr)
  Vue.use(VueProgressBar, { color: '#00B4EB', failedColor: '#FC4747', thickness: '4px' })
  Vue.use(errors)

  app = new Vue({
    store,
    router,
    i18n,
    render: h => h(App),
  })

  store.$app = app
  app.$mount('#app-root')
})

export { app }
