<template>
  <b-dd-form>
    <div v-for="(role, index) in roles"
         class="d-flex align-items-center py-3 pointer"
         :key="index"
    >
      <pcg-checkbox v-model="role.selected" @input="selectRole"/>
      <span class="prk-fz-12 font-weight-bold pcg-gray-color text-nowrap">
        {{ role.text }}
      </span>
    </div>
  </b-dd-form>
</template>

<script>
import useEducators from '../../../../../composables/useEducators'
export default {
  name: 'EducatorListRoles',
  setup (_, { root }) {
    const { getDefaultOption } = useEducators(root)
    return { getDefaultOption }
  },
  props: {
    educator: { required: true, type: Object },
    roles: { required: true, type: Array },
  },
  methods: {
    selectRole () {
      const defaultOption = this.getDefaultOption(this.educator.roles)
      this.$emit('update:educator', defaultOption)
    },
  },
}
</script>
