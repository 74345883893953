export default {
  install: function (Vue) {
    Vue.prototype.$uuidv4 = function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,
        function (c) {
          const r = Math.random() * 16 | 0
          const v = c === 'x' ? r : (r & 0x3 | 0x8)
          return v.toString(16)
        })
    }

    Vue.prototype.$isEven = function (number) {
      if (!Number.isInteger(number)) throw new Error('invalid number')

      return number % 2 === 0
    }

    Vue.prototype.$compare = function (object1, object2) {
      return JSON.stringify(object1) === JSON.stringify(object2)
    }

    Vue.prototype.$removeSwal = function removeSwal (plural = 1, text = '') {
      return this.$swal({
        title: this.$t('general.are_you_sure'),
        text: text,
        showCancelButton: true,
        confirmButtonText: this.$tc('components.prk_syllabus.buttons.delete_elements', plural),
        cancelButtonText: this.$t('general.cancel'),
      })
    }

    Vue.prototype.$downloadFile = function (response, type = '', fileName) {
      const blob = new Blob([response.request.response], { type: response.headers['content-type'] })
      const url = window.URL.createObjectURL(blob)
      const link = document.createElement('a')
      const resource = response.config.url.split('/')[0]

      link.href = url
      fileName = fileName ?? `${this.$tc(`general.${resource}`, 2)}.${type}`
      if (!fileName.match(`.${type}`)) fileName = `${fileName}.${type}`
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()
    }
  },
}
