<template>
  <prk-select
      :options="options"
      :default-option="defaultOption"
      :class="className"
      class="prk-fz-14"
      @input="$emit('input', $event)"
  >
    <template v-slot:button-text="{ text, className }">
      <status :status="text" :class-name="className"/>
    </template>
    <template v-slot:item-text="{ option }">
      <status :status="option.text" :class-name="option.class"/>
    </template>
  </prk-select>
</template>

<script>
import PrkSelect from './PrkSelect'
import Status from './syllabus/syllabuses_list/syllabuses_list_details/Status'
import useStateMachine from '../composables/useStateMachine'

export default {
  name: 'prk-statuses-select',
  setup (_, { root }) {
    const {
      availableStatuses,
      getStates,
      loading,
      statuses,
      states,
    } = useStateMachine(root)
    return {
      availableStatuses,
      getStates,
      loading,
      states,
      statuses,
    }
  },
  components: {
    PrkSelect,
    Status,
  },
  props: {
    optionsData: { type: Array, default: () => ([]) },
    status: {
      type: String,
      default: 'to_fill',
      validator: function (value) {
        return [
          'during_fill',
          'to_fill',
          'to_verification',
          'to_improve',
          'accepted',
        ].indexOf(value) !== -1
      },
    },
    className: { type: String, default: 'statuses' },
  },
  computed: {
    defaultOption () {
      return this.statuses.find(s => s.value === this.status) || this.statuses[0]
    },
    options () {
      return this.optionsData.length ? this.optionsData : this.availableStatuses(this.status)
    },
  },
  watch: {
    states: {
      async handler () {
        if (this.loading || Object.keys(this.states).length || this.optionsData.length) return
        await this.getStates()
      },
      immediate: true,
    },
  },
  destroyed () {
    this.states = {}
  },
}
</script>

<style scoped lang="scss">
.statuses {
  ::v-deep {
    .bootstrap-select .dropdown-toggle {
      height: 30px;
      padding-top: 0;
      padding-bottom: 0;
      line-height: 2rem;

      .filter-option-inner {
        align-self: center;
      }

      .fas {
        line-height: 2.5rem;
      }
    }
  }
}
</style>
