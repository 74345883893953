<template>
  <pcg-radio
      v-model="method"
      name="copy-method"
      class="d-flex align-items-center"
      style="transform: translateY(-13px)"
      :options="copyMethods"
  />
</template>

<script>
export default {
  name: 'ConfirmCopyingSyllabusCopyMethods',
  data () {
    return {
      method: '',
      copyMethods: [],
    }
  },
  watch: {
    method () {
      this.$emit('update:copy-method', this.method)
    },
  },
  created () {
    this.copyMethods = [
      { text: this.$t('views.syllabus.copy.methods.just_content'), value: 0 },
      { text: this.$t('views.syllabus.copy.methods.content_and_subject_effects'), value: 1 },
    ]
  },
}
</script>
