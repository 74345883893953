<template>
  <b-col cols="12" lg="auto" class="mb-3">
    <div class="d-flex align-items-center">
      <span class="d-inline-block mr-3">
        {{ $t('general.speciality') }}
      </span>
      <prk-select
          class="prk-fz-14 w-100"
          color="aqua"
          :include-blank="true"
          :options="specialities"
          reset
          :value="specialityId"
          @input="setSpeciality"
      />
    </div>
  </b-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkSelect from '../../PrkSelect'

const { mapActions } = createNamespacedHelpers('semesters')

export default {
  name: 'NewSubjectSpecialties',
  components: {
    PrkSelect,
  },
  props: {
    semester: { type: Object, default: () => ({}) },
  },
  jsonapi: {
    specialities: {
      config: () => ({
        method: 'get',
        url: 'specialities',
      }),
      update (specialities) {
        return this.$_.unionBy(specialities, 'name').map(spec => {
          return { text: spec.name, value: spec.id }
        })
      },
    },
  },
  data () {
    return {
      specialities: [],
    }
  },
  computed: {
    specialityId () {
      const speciality = this.specialities.find(speciality => {
        return speciality.text === this.semester.speciality?.name
      })
      return speciality?.value
    },
  },
  methods: {
    ...mapActions(['editSemester']),
    setSpeciality (id) {
      const semester = this.$_.cloneDeep(this.semester)
      semester.speciality = this.specialities.filter(speciality => {
        return speciality.value === id
      }).map(speciality => ({ id: speciality.value, name: speciality.text }))[0]
      semester._edited = 1
      this.editSemester(semester)
    },
  },
}
</script>
