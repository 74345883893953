<template>
  <div class="permissions">
    <transition name="slide" mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>

<script>
import { app } from '../index'

export default {
  name: 'Permissions',
  methods: {
    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$t('views.header.permission_list'),
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    const role = localStorage.getItem('prkCR')
    if (['admin', 'employer'].includes(role)) {
      next(vm => {
        vm.setHeader()
      })
    } else {
      app.$toastr.e(app.$t('general.forbidden'))
    }
  },
}
</script>
