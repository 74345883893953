<template>
  <pcg-modal modal-id="teachingEffectModal"
             size="xl"
             :title="$t('general.add_teaching_effects')"
             icon="fas fa-plus"
             :btn-title="$t('general.save')"
             :action="addTeachingEffects"
  >
    <prk-table :items="filteredItems"
               :show-headline="false"
               disable-actions
               :excluded-fields="EXCLUDED_FIELDS"
               :fields-props="{ tdClass: tdClass }"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ width: field.key === 'description' ? '70%' : '' }"
        >
      </template>
      <template v-slot:head(id)>
        <pcg-checkbox @input="select" :value="checkedAll"/>
      </template>
      <template v-slot:head(details)/>

      <template v-slot:content="{ header, data }">
        <pcg-checkbox v-if="header.key === 'id'"
                      v-model="data.item.selected"
        >
        </pcg-checkbox>
        <template v-else-if="header.key === 'description'">
          <prk-animated-description :description="data.item.description"/>
        </template>
        <base-arrow v-else-if="header.key === 'details'"
                    :show="data.item.toggleDetails"
                    @click="showText(data, $event)"
        />
      </template>
    </prk-table>

  </pcg-modal>
</template>

<script>
import PrkTable from '../../PrkTable'
import { mapState } from 'vuex'
import useList from '../../../composables/useList'
import useExpandText from '../../../composables/useExpandText'
import PrkAnimatedDescription from '../../PrkAnimatedDescription'

export default {
  name: 'SyllabusTeachingEffectsModal',
  setup ({ root }) {
    const {
      items,
      setItems,
      checkedAll,
      unSelectAll,
      select,
      getSelected,
    } = useList(root)
    const {
      tdClass,
      showText,
    } = useExpandText('prk-course-effects-modal')
    return {
      items,
      setItems,
      checkedAll,
      unSelectAll,
      select,
      getSelected,
      tdClass,
      showText,
    }
  },
  components: {
    PrkAnimatedDescription,
    PrkTable,
  },
  watch: {
    teachingEffects (effects) {
      this.setItems(effects)
    },
  },
  computed: {
    ...mapState('syllabusFill', ['teachingEffects']),
    filteredItems () {
      const kens = this.$store.state.courseLearningOutcome.keu.kens
      return this.items.filter(item => !kens.map(ken => ken.value).includes(item.id))
    },
  },
  methods: {
    addTeachingEffects () {
      const kens = this.getSelected(this.items)
      this.$store.dispatch('courseLearningOutcome/addKens', kens)
      this.unSelectAll(this.items)
      this.$bvModal.hide('teachingEffectModal')
    },
  },
  created () {
    this.EXCLUDED_FIELDS = ['selected', 'toggleDetails']
  },
}
</script>
