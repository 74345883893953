import { provide } from '@vue/composition-api'

export const languages = [{
  text: 'Polski',
  value: 0,
}, {
  text: 'English',
  value: 1,
}]

export const langProvider = () => provide('languages', languages)

export default function () {
  const x = '1234'
  return {
    x,
  }
}
