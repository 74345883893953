import { ref, watch, set } from '@vue/composition-api'
import useModalValidation from './useModalValidation'

const { setErrors } = useModalValidation()

export default function (root) {
  const checkedAll = ref(false)
  const items = ref([])
  let selectedItems = []
  const maxItems = ref(0)

  const getSelected = (allItems) => {
    if (!allItems?.length) return []
    getSelected.counter++
    if (getSelected.counter === 1) {
      selectedItems = []
    }
    allItems.forEach((item) => {
      if ('selected' in item) {
        item.selected && selectedItems.push(item)
      }
      if ('subItems' in item) {
        getSelected(item.subItems)
      }
    })
    getSelected.counter--
    return selectedItems
  }
  getSelected.counter = 0

  watch(items, () => {
    let selectedLength = 0
    if (maxItems.value !== 0) {
      selectedLength = getSelected(items.value).length
      checkedAll.value = selectedLength >= maxItems.value
    } else {
      selectedLength = getSelected(items.value).length
      checkedAll.value = selectedLength >= items.value.length
      if (items.value.length === 0) checkedAll.value = false
    }
  }, {
    deep: true,
  })

  function setItems (newItems) {
    items.value = []
    newItems.forEach(item => items.value.push(item))
    getMaxItems(newItems)
  }

  function select (event, args = null) {
    if (args) {
      if (args.deep) {
        checkedAll.value = !checkedAll.value
        selectAll(items.value)
      } else if (args) {
        items.value[args.index][args.prop].forEach(item => (item.selected = args.value))
      }
    } else {
      checkedAll.value = !checkedAll.value
      if (event === 'unSelect') checkedAll.value = false
      items.value.forEach(item => (item.selected = checkedAll.value))
    }
  }

  function selectAll (allItems) {
    allItems.forEach((item) => {
      if ('selected' in item) {
        item.selected = checkedAll.value
      }
      if ('subItems' in item) {
        selectAll(item.subItems)
      }
      if ('syllabuses' in item) {
        selectAll(item.syllabuses)
      }
    })
  }

  function unSelectAll (allItems) {
    allItems.forEach(item => {
      if ('selected' in item) {
        item.selected = false
      }
      if ('subItems' in item) {
        unSelectAll(item.subItems)
      }
    })
  }

  function getMaxItems (items) {
    getMaxItems.counter++
    if (getMaxItems.counter === 1) maxItems.value = 0
    items.forEach((item) => {
      if ('selected' in item) {
        maxItems.value++
      }
      if ('subItems' in item) {
        getMaxItems(item.subItems)
      }
    })
    getMaxItems.counter--
  }

  getMaxItems.counter = 0

  // TODO this function is deprecated, should be delete in the future
  function addToList (index, data = null) {
    if (typeof (+index) === 'number' && !isNaN(+index)) {
      const text = items.value[+index - 1].text
      // temporary identification after the text
      const itemFound = findItem(text, data)
      if (!itemFound) {
        data.push(items.value[+index - 1])
      }
    }
  }

  const addToListv2 = (id, data = [], searchField = 'value') => {
    const item = items.value.find(item => item[searchField] === id)
    const itemIndex = data.findIndex(item => item[searchField] === id)
    if (~itemIndex) return
    data.push(item)
  }

  function findItem (text, data = null) {
    if (!data) return []
    return data.find(item => {
      return item.text === text
    })
  }

  function findItemById (id) {
    return items.value.find(item => {
      return item.id === id
    })
  }

  async function removeRow (index, data = null, removeHandler = null) {
    try {
      const response = await removeSwal()
      if (response.value && typeof (index) === 'number') {
        removeHandler && await removeHandler()
        data ? data.splice(index, 1) : items.value.splice(index, 1)
        maxItems.value = 0
        getMaxItems(items.value)
      }
    } catch (e) {
      const errors = setErrors(e.errors)
      if (Object.values(errors).length) {
        root.$toastr.e((Object.values(errors)[0]))
      } else {
        root.$toastr.e(root.$t('general.error_try_later'))
      }
    }
  }

  async function removeRows (removeHandler = null) {
    try {
      const response = await removeSwal(2)
      if (response.value) {
        removeHandler && await removeHandler()
        updateList()
      }
    } catch (e) {
      root.$toastr.e(root.$t('general.error_try_later'))
    }
  }

  function updateList () {
    if (!items.value?.length) return
    getSelected(items.value).forEach(item => {
      _deleteItem(item, items.value)
      maxItems.value = 0
      getMaxItems(items.value)
    })
  }

  const updateItem = (newItem) => {
    const itemIndex = items.value.findIndex(item => item.id === newItem.id)
    set(items.value, itemIndex, newItem)
  }

  async function removeSwal (plural = 1) {
    return root.$swal({
      title: root.$t('general.are_you_sure'),
      showCancelButton: true,
      confirmButtonText: root.$tc('components.prk_syllabus.buttons.delete_elements', plural),
      cancelButtonText: root.$t('general.cancel'),
    })
  }

  function _deleteItem (item, allItems) {
    const index = allItems.findIndex(el => item.id === el.id)
    if (!~index) {
      allItems.forEach(el => {
        if ('subItems' in el) {
          _deleteItem(item, el.subItems)
        }
      })
    } else {
      allItems.splice(index, 1)
    }
  }

  return {
    items,
    checkedAll,
    getSelected,
    setItems,
    select,
    addToList,
    addToListv2,
    findItemById,
    findItem,
    removeRow,
    removeRows,
    removeSwal,
    unSelectAll,
    updateItem,
    updateList,
  }
}
