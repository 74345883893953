<template>
  <b-col class="col-12 col-lg-2 my-3 my-lg-0">
    <div class="d-flex justify-content-end align-items-center">
      <pcg-text-input
          class="w-100"
          :error-text="numberOfHoursError"
          :label="$t('general.number_of_hours')"
          :show-error="showError"
          :value="numberOfHours"
          @input="update"
      />
    </div>
  </b-col>
</template>

<script>
export default {
  name: 'NumberOfHours',
  props: {
    index: { required: true, type: Number },
    numberOfHours: { required: true, type: [Number, String] },
  },
  inject: ['$v'],
  computed: {
    showError () {
      return Object.values(this.$v.subject.subjectForms.$each.$iter).filter(obj => {
        return !obj.$model.hidden
      })[this.index].numberOfHours.$error
    },
    numberOfHoursError () {
      const key = 'views.study_plans.errors.number_of_hours'
      if (!this.$v.subject.subjectForms.$each[this.index].numberOfHours.required) {
        return this.$t(`${key}.required`)
      } else {
        return this.$t(`${key}.numeric`)
      }
    },
  },
  methods: {
    update (event) {
      this.$emit('update:numberOfHours', event)
      this.$emit('update')
    },
  },
}
</script>
