<template>
  <div>
    <return @click="$router.go(-1)" class="d-inline-block mb-3">
      {{ $t('views.syllabus_edit.return') }}
    </return>
    <syllabus-fill
        :header="syllabus.header"
        :sections.sync="syllabus.sections"
        :get-syllabus="getSyllabus"
        @set-status="changeStatus"
    />
    <prk-beam-syllabuses-educator
        v-if="['admin', 'employer', 'educator'].includes(currentRole)"
        :syllabus="syllabus"
        :save-action="save"
        @clear="clearSyllabus"
    />

    <prk-beam-syllabuses-student v-else/>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import Return from '../../components/base_components/Return'
import SyllabusFill from '../../components/syllabus/syllabus_fill/SyllabusFill'
import PrkBeamSyllabusesStudent from '../../components/beams/PrkBeamSyllabusesStudent'
import PrkBeamSyllabusesEducator from '../../components/beams/PrkBeamSyllabusesEducator'
import useSyllabusEdit from '../../composables/useSyllabusEdit'
import { inject, provide } from '@vue/composition-api'

export default {
  name: 'SyllabusEdit',
  setup (_, { root }) {
    const {
      getSyllabus,
      syllabus,
      prepareSyllabus,
      clearSyllabus,
    } = useSyllabusEdit(root)
    provide('syllabus', syllabus)
    const editMode = inject('editMode')
    return {
      editMode,
      getSyllabus,
      syllabus,
      prepareSyllabus,
      clearSyllabus,
    }
  },
  components: {
    PrkBeamSyllabusesStudent,
    PrkBeamSyllabusesEducator,
    SyllabusFill,
    Return,
  },
  jsonapi: {
    didacticMethods: {
      config: ({ syllabusId }) => ({
        method: 'get',
        url: `didactic_methods?syllabus_id=${syllabusId}`,
      }),
      variables () {
        return {
          syllabusId: this.syllabus.id,
        }
      },
      skip () {
        return !this.afterSave
      },
      update (didacticMethods) {
        return didacticMethods.map(method => {
          return {
            formOfClassId: method.formOfClassId,
            id: method.id,
            formOfClass: method.formOfClassName,
            text: method.text,
          }
        })
      },
    },
  },
  data () {
    return {
      headerKey: 0,
      didacticMethods: [],
      afterSave: false,
    }
  },
  computed: {
    ...mapState('comments', ['commentCount', 'commentableId', 'commentableType']),
    ...mapState('auth', ['avatar', 'userId']),
    ...mapGetters('auth', ['currentRole']),
  },
  watch: {
    didacticMethods () {
      this.setDidacticMethods(this.didacticMethods)
    },
    commentCount () {
      if (!this.commentCount) return
      if (this.commentableType === 'SyllabusBasicInformationRow') {
        const rows = this.syllabus.sections[0].rows
        const row = rows.find(row => row.id === this.commentableId)
        row.commentCount = this.commentCount
      } else if (this.commentableType === 'Effect') {
        const subjectEffect = this.syllabus.subjectEffects.find(subEff => {
          return subEff.id === this.commentableId
        })
        subjectEffect.commentCount = this.commentCount
        this.$store.dispatch('syllabusFill/setSubjectEffects', this.syllabus.subjectEffects)
      } else {
        const section = this.syllabus.sections.find(section => section.id === this.commentableId)
        section.commentCount = this.commentCount
      }
      this.$store.dispatch('comments/setCommentCount', 0)
    },
  },
  mounted () {
    this.getSyllabus(this.$route.params.id)
  },
  methods: {
    ...mapActions('syllabusFill', ['setDidacticMethods']),
    async changeStatus (newStatus) {
      const oldStatus = this.syllabus.header.status
      try {
        await this.$api.post('syllabuses/set_status', {
          id: this.syllabus.id,
          status: newStatus,
        })
        this.syllabus.header.status = newStatus
      } catch (e) {
        this.$toastr.e(e.errors.messages)
        if (!e.errors.emailError) {
          this.syllabus.header.status = oldStatus
        } else {
          this.syllabus.header.status = newStatus
        }
      }
    },
    async save () {
      try {
        const syllabus = this.prepareSyllabus(this.syllabus)
        await this.$api.put(`syllabuses/${this.syllabus.id}`, { syllabus: syllabus })
        this.$toastr.s(this.$t('general.syllabus_saved'))
        this.afterSave = true
      } catch (e) {
        this.$showError(this.$getErrors(e.errors))
      }
    },
  },
  destroyed () {
    this.$store.dispatch('comments/resetState')
    this.$store.dispatch('syllabusFill/resetState')
  },
}
</script>
