<template>
  <i :class="iconClass" class="arrow-icon" v-on="$listeners"/>
</template>

<script>
export default {
  name: 'BaseArrow',
  props: {
    show: {
      required: true,
      type: Boolean,
    },
  },
  computed: {
    iconClass () {
      return this.show ? 'pcg-icon-arrow-up' : 'pcg-icon-arrow-down'
    },
  },
}
</script>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .arrow-icon {
    color: $main-active-color;
  }
}
</style>
