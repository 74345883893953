<template>
  <div>
    <div class="prk-fz-14 font-weight-bold pcg-the-darkest-gray-color">
      {{ $t('components.prk_syllabus.section.choose_element') }}
    </div>

    <div class="prk-fz-12 pcg-main-active-color font-weight-bold">
      <div class="choose-border"/>
      <b-row>
        <b-col v-for="(value, index) in elements" :key="index"
               cols="6" md="3" class="pointer"
               @click="$emit(`add-${$_.kebabCase(value).replace(/\s/g, '')}`)"
        >
          <img :src="getImgUrl(mapKey(value))"/>
          {{ $t(`components.prk_syllabus.new_element.${$_.snakeCase(value)}.title`) }}
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PrkChooseElement',
  data () {
    return {
      elements: ['checkbox', 'oneLine', 'textField', 'table'],
    }
  },
  methods: {
    mapKey (key) {
      return key === 'oneLine' ? 'text-field' : this.$_.kebabCase(key)
    },
    getImgUrl (key) {
      return require('../../../../assets/images/icons/' + key + '.svg')
    },
  },
}
</script>

<style scoped lang="scss">
@import 'app/javascript/prk/assets/stylesheets/vars';

.choose-border {
  margin: 1.285rem -2.2rem 0;
  border-top: 1px solid $pcg-lightest-gray;
  padding-top: 1.285rem;
}

img {
  margin-right: .4rem;
}
</style>

<style scoped lang="scss">
@import './app/javascript/prk/assets/stylesheets/vars-contrast';

.contrast {
  .choose-border {
    border-top: 1px solid $pcg-lightest-gray;
  }
}
</style>
