<template>
  <b-col cols="12" lg="auto" class="mb-3">
    <div class="d-flex align-items-center">
          <span class="d-inline-block mr-3">
            {{ $t('general.specialization') }}
          </span>
      <prk-select
          class="prk-fz-14 w-100"
          color="aqua"
          :include-blank="true"
          :options="specializations"
          reset
          :value="specializationId"
          @input="setSpecialization"
      />
    </div>
  </b-col>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkSelect from '../../PrkSelect'
const { mapActions } = createNamespacedHelpers('semesters')

export default {
  name: 'NewSubjectSpecializations',
  components: {
    PrkSelect,
  },
  props: {
    semester: { type: Object, default: () => ({}) },
  },
  jsonapi: {
    specializations: {
      config: () => ({
        method: 'get',
        url: 'specializations',
      }),
      update (specializations) {
        return this.$_.unionBy(specializations, 'name').map(spec => {
          return { text: spec.name, value: spec.id }
        })
      },
    },
  },
  data () {
    return {
      specializations: [],
    }
  },
  computed: {
    specializationId () {
      const specialization = this.specializations.find(specialization => {
        return specialization.text === this.semester.specialization?.name
      })
      return specialization?.value
    },
  },
  methods: {
    ...mapActions(['editSemester']),
    setSpecialization (id) {
      const semester = this.$_.cloneDeep(this.semester)
      semester.specialization = this.specializations.filter(specialization => {
        return specialization.value === id
      }).map(specialization => ({ id: specialization.value, name: specialization.text }))[0]
      semester._edited = 1
      this.editSemester(semester)
    },
  },
}
</script>
