<template>
  <div class="d-flex align-items-center justify-content-end h-100">
    <base-button v-if="kek" text="download_excel" class="mx-2">
      <base-icon width="20" height="21">
        <icon-excel/>
      </base-icon>
    </base-button>

    <pcg-btn size="small" @click="action">
          <span class="font-weight-bold prk-fz-12" >
           {{
              editStandard
                  ? $t('components.prk_dictionaries.edit_standard')
                  : $t('components.prk_dictionaries.create_standard')
            }}
          </span>
    </pcg-btn>
    <pcg-btn v-if="kek && selectedStandardEffects" size="small" variant="additional"
             @click="$emit('remove:standard-effects')"
    >
      {{ $t('general.remove_selected') }}
    </pcg-btn>
  </div>
</template>

<script>
export default {
  name: 'DictionariesBeam',
  props: {
    editStandard: { type: Boolean, default: false },
    kek: { type: Boolean, default: true },
    selectedStandardEffects: { type: Number, default: 0 },
    action: { type: Function, required: true },
  },
}
</script>
