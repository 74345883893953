<template>
  <div class="home" :class="{'mt-5': $mq === 'xs' || $mq === 'sm'}">
    <b-row>
      <b-col xs="12" sm="5" md="5" class="d-flex align-items-center">
        <div class="image main-page-logo">
          <img src="../assets/images/logo.svg" alt="main" >
        </div>
<!--        <div>-->
<!--          <div class="txt prk-fz-38 pb-4">{{ $t('general.university_syllabus') }}</div>-->
<!--          <div class="list-container">-->
<!--            <ul class="item-container">-->
<!--              <li v-for="(item,index) in items" :key="index" class="prk-fz-16 item mb-4">{{ item }}</li>-->
<!--            </ul>-->
<!--          </div>-->
<!--        </div>-->

      </b-col>
      <b-col xs="12" sm="7"
             class="d-flex align-items-center justify-content-end"
             :class="{'mt-3': $mq === 'xs'}"
      >
        <div class="image">
          <img src="../assets/images/login/knowledge.svg" alt="main">
        </div>
      </b-col>
    </b-row>
<!--    <b-row class="b-container" :class="{'spaces': $mq !== 'xs'}" align-h="around">-->
<!--      <b-col v-for="(item, index) in bottom_items" :key="index" cols="auto" class="b-item-container">-->
<!--        <div class="title prk-fz-14 font-weight-bold">{{ item.title }}</div>-->
<!--        <div class="txt prk-fz-14 font-weight-bold">{{ item.txt }}</div>-->
<!--      </b-col>-->
<!--    </b-row>-->
  </div>
</template>

<script>
import WindowResizeMixin from '../mixins/window_resize'
import { mapGetters } from 'vuex'

export default {
  name: 'login',
  mixins: [WindowResizeMixin],
  data () {
    return {
      items: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        'Sed consectetur arcu nibh pellentesque',
        'Vitae maecenas accumsan commodo, lectus proin volutpat faucibus nunc',
      ],
      bottom_items: [
        {
          title: 'Lorem ipsum',
          txt: 'Lorem ipsum Dolor sit amet, consecterur adipscing elit',
        },
        {
          title: 'Lorem ipsum',
          txt: 'Lorem ipsum Dolor sit amet, consecterur adipscing elit',
        },
        {
          title: 'Lorem ipsum',
          txt: 'Lorem ipsum Dolor sit amet, consecterur adipscing elit',
        },
        {
          title: 'Lorem ipsum',
          txt: 'Lorem ipsum Dolor sit amet, consecterur adipscing elit',
        },
        {
          title: 'Lorem ipsum',
          txt: 'Lorem ipsum Dolor sit amet, consecterur adipscing elit',
        },
      ],
    }
  },
  watch: {
    windowWidth (newValue) {
      this.setBackgroundImageStyle()
    },
    contrast (newValue) {
      newValue ? this.clearBackgroundImage() : this.setBackgroundImage()
    },
  },
  computed: {
    ...mapGetters('page', ['contrast']),
  },
  methods: {
    setBackgroundImageStyle () {
      this.$store.dispatch('pageCover/setBackgroundImageStyle', {
        height: '100% !important',
      })
    },
    setBackgroundImage () {
      if (!this.contrast) {
        this.$store.dispatch('pageCover/setBackgroundImage', require('../assets/images/Blank.jpeg'))
      }
    },
    setBackgroundImageClasses () {
      this.$store.dispatch('pageCover/setBackgroundImageClasses', ['no-gradient'])
    },
    setSidebarMenuClasses () {
      this.$store.dispatch('sidebarMenu/setSidebarMenuClasses', ['no-box-shadow'])
    },
    clearBackgroundImage () {
      this.$store.dispatch('pageCover/clearBackgroundImage')
    },
    loginCallback () {
      if (this.$route.query.code) {
        const query = { ...this.$route.query }
        this.$router.replace({ query: {} })
        this.$store.dispatch('auth/signIn', query)
          .then(response => {
            const redirectTo = this.$store.getters['auth/getRedirectTo']

            if (redirectTo && redirectTo.name) {
              this.$store.dispatch('auth/clearRedirectTo')
              this.$router.push(redirectTo)
            } else {
              this.$router.push({ name: 'pcg_components_path' })
            }
          })
          .catch(error => {
            this.$toastr.e(error.response.data.error)
          })
      }
    },
    login () {
      window.location = '/users/auth/openid_connect'
    },
  },
  created () {
    this.loginCallback()
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setBackgroundImage()
      vm.setBackgroundImageStyle()
      vm.setBackgroundImageClasses()
      vm.setSidebarMenuClasses()
    })
  },
}
</script>

<style lang="scss" scoped>
@import '../assets/stylesheets/vars';

/deep/ .pcg-btn-sidebar-menu {
  display: none !important;
}

.home {
  text-align: center;
  position: relative;
  color: $pcg-the-darkest-gray;
}

.txt {
  color: $pcg-dark-blue;
  font-weight: 600;
  text-align: left;
}

.list-container {
  padding: 3.571rem 2.8rem 2.1rem 2.142rem;
  border-radius: 5px;
  background-color: $pcg-lightest-gray;
  max-width: 520px;
}

.item {
  text-align: left;
}

.item-container {
  list-style: none;
  padding-left: 0;
}

.item::before {
  content: '';
  border-radius: 50%;
  background-color: $pcg-aqua-blue;
  display: block;
  float: left;
  transform: translateY(7px);
  margin-bottom: 2rem;
  width: .714rem;
  height: .714rem;
  margin-right: 2rem;
}

.image {
  max-height: 647px;

  img {
    max-width: 100%;
    max-height: 400px;
  }
  &.main-page-logo{
    min-width: 70%;
  }
}

.b-container {

  margin-top: 4rem;

  &.spaces {
    margin-top: 100px !important;
  }

  .b-item-container {
    max-width: 16.5rem;

    .title {
      color: $pcg-aqua-blue;
    }

    .txt {
      text-align: center;
      color: $pcg-the-darkest-gray
    }
  }
}
</style>
<style lang="scss" scoped>
@import "../assets/stylesheets/vars-contrast";

.contrast {
  .home {
    color: $pcg-the-darkest-gray;
  }

  .txt {
    color: $pcg-dark-blue;
  }

  .list-container {
    background-color: $pcg-lightest-gray;

    .item::before {
      background-color: $pcg-aqua-blue;
    }
  }

  .b-item-container {
    .title {
      color: $pcg-aqua-blue;
    }

    .txt {
      color: $prk-dark-black
    }
  }
}
</style>
