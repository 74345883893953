<template>
  <b-row class="mt-4">
    <b-col cols="auto">
      <prk-select
          color="aqua"
          :default-option="{ text: $t('general.choose') }"
          :label="$tc('general.field')"
          :error-text="$t('general.errors.required')"
          :options="fields"
          :show-error="error"
          :value="fieldId"
          @input="updateField"
      >
      </prk-select>
    </b-col>
  </b-row>
</template>

<script>
import PrkSelect from '../../../PrkSelect'
export default {
  name: 'FieldSelect',
  components: {
    PrkSelect,
  },
  props: {
    fieldId: { required: true, type: String },
    error: { required: true, type: Boolean },
  },
  jsonapi: {
    fields: {
      config: { method: 'get', url: 'fields?active=true' },
      update (fields) {
        return fields.map(({ id, name }) => ({ value: id, text: name }))
      },
    },
  },
  data () {
    return {
      fields: [],
    }
  },
  methods: {
    updateField (id) {
      this.$emit('update:field', id, this.findFieldName(id))
    },
    findFieldName (id) {
      return this.fields.find(field => field.value === id).text
    },
  },
}
</script>
