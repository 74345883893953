<template>
  <base-beam>
    <div class="d-flex h-100 align-items-center justify-content-end">
      <base-button text="download_doc" class="mx-2">
        <base-icon width="20" height="21">
          <icon-doc/>
        </base-icon>
      </base-button>

      <base-button text="download_pdf" class="mx-2">
        <base-icon width="18" height="21">
          <icon-pdf/>
        </base-icon>
      </base-button>

      <base-button text="download_excel" class="mx-2">
        <base-icon width="20" height="21">
          <icon-excel/>
        </base-icon>
      </base-button>

      <base-button text="download_excel" class="mx-2">
        <base-icon width="41" height="41">
          <icon-printer/>
        </base-icon>
      </base-button>
    </div>
  </base-beam>
</template>

<script>
export default {
  name: 'PrkBeamSyllabusesStudent',
}
</script>
