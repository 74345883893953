<template>
  <div>
    <div v-if="!isGeneralComponent">
      <basic-information-about-subject :section="section"/>
    </div>
    <div v-else>
      <subject-information-table :section="section"/>
    </div>
  </div>
</template>

<script>
import { syllabusHeaderStatus } from '../../../../mixins/sylabus_header_status'
import SubjectInformationTable from './SubjectInformationTable'
import BasicInformationAboutSubject from './PrkBasicInformationAboutSubject'

export default {
  name: 'PrkSubjectInformation',
  inheritAttrs: false,
  components: {
    SubjectInformationTable,
    BasicInformationAboutSubject,
  },
  mixins: [syllabusHeaderStatus],
  props: {
    section: { required: true, type: Object },
  },
  computed: {
    isGeneralComponent () {
      return ['prk-table'].includes(this.section.detailedComponent)
    },
  },
}
</script>
