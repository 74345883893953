<template>
  <div class="prk-shadow br-5 mb-5">
    <prk-table
        :show-headline="false"
        :fields-props="{ tdClass: tdClass, thAttr: thAttr }"
        :items="elements"
        class="student-workload"
        :disable-actions="(['to_fill', 'accepted'].includes(status))"
    >
      <template v-slot:table-colgroup="{ fields }">
        <col v-for="field in fields" :key="field.key"
             :style="{ 'width': field.key === 'hours' ? '30%' : '60%' }"
        >
      </template>
      <template v-slot:content="{ data, header }">
        <template v-if="header.key !== 'actions'">
          <span v-if="data.item[header.key].type === 'text'">
            <span :class="{ 'font-weight-bold': fillContent && editMode }">
              {{ data.item[header.key].value }}
            </span>
          </span>
          <pcg-text-input
              v-if="data.item[header.key].type === 'input' && fillContent && editMode"
              :show-label="false"
              :value="data.index === 1 ? workWithTeacher : ownWork"
              :show-error="showError(data.index)"
              :error-text="errorText(data.index)"
              @input="setWorkload(data.index, $event)"
          />
          <div v-else-if="data.item[header.key].type === 'input'">
            {{ data.index === 1 ? ownWork : workWithTeacher }}
          </div>
        </template>
      </template>
    </prk-table>
  </div>
</template>

<script>
import PrkTable from '../../../PrkTable'
import { syllabusHeaderStatus } from '../../../../mixins/sylabus_header_status'
import { syllabusWorkload } from '../../../../mixins/syllabus_workload'
import { fillStatus } from '../../../../mixins/syllabus/status'
import { inject } from '@vue/composition-api'

export default {
  name: 'StudentWorkload',
  setup () {
    const editMode = inject('editMode', true)
    return { editMode }
  },
  inheritAttrs: false,
  components: {
    PrkTable,
  },
  mixins: [syllabusHeaderStatus, syllabusWorkload, fillStatus],
  methods: {
    showError (index) {
      return this.$v.validateSumHours.$invalid ||
          (this.$v.ownWork.$invalid && index === 1) ||
          this.$v.workWithTeacher.$invalid
    },
    errorText (index) {
      if (this.$v.ownWork.$invalid && index === 1) {
        return this.$t('general.must_be_greater_than_or_equal_to_0')
      } else if (this.$v.workWithTeacher.$invalid) {
        return this.$t('general.must_be_greater_than_or_equal_to_0')
      } else if (this.$v.validateSumHours.$invalid) {
        return this.$t('views.syllabus.student_workload.hours_not_match_to_ects')
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/breakpoints";

::v-deep.student-workload .pcg-input {
  width: 30%;
  margin: auto;
  @include media-breakpoint-down(md) {
    width: 100%;
  }
}
</style>
