export const detailsTable = {
  computed: {
    showDetailsFields () {
      return this.items.map(item => item._showDetails)
    },
  },
  watch: {
    showDetailsFields () {
      this.$nextTick(() => {
        const openDetails = document.querySelectorAll('.b-table-has-details')
        openDetails.forEach(el => el.classList.add('pcg-the-lightest-gray-background-color'))
        const detailsTable = document.querySelectorAll('.b-table-details')
        detailsTable.forEach(el => el.classList.add('pcg-the-lightest-gray-background-color'))
      })
    },
  },
}
