<template>
  <path
    d="M11 2.27621L9.3 0.617676L5.5 4.32499L1.7 0.617676L0 2.27621L3.8 5.98353L0 9.69084L1.7 11.3494L5.5 7.64206L9.3 11.3494L11 9.69084L7.2 5.98353L11 2.27621Z"/>
</template>

<script>
// height: 12, width: 12
export default {
  name: 'IconRemove',
}
</script>
