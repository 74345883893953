<template>
  <div>
    <return path-name="dictionaries_fields_path">
      {{ $t('components.prk_dictionaries.back_to_fields') }}
    </return>

    <div class="d-flex justify-content-end">

      <pcg-btn variant="additional" size="small" @click="addDiscipline">
        <span class="font-weight-bold">
          {{ $t('components.prk_dictionaries.new_discipline') }}
        </span>
      </pcg-btn>
    </div>

    <dictionary-table
        active-date
        :destroy-resource="destroyResource"
        :edit-resource="editResource"
        :items="items"
        name="discipline"
        resource-name="discipline"
        :update-resource="updateResource"
        url="disciplines"
    />

    <dictionary-form-modal
        :action="action(discipline, 'disciplines', params)"
        :btn-title="btnTitle"
        :errors="errors"
        :modalId="editMode ? 'edit-modal' : 'add-modal'"
        :title-modal="title"
        v-bind.sync="discipline"
        @hidden="hideModal('discipline')"
        @clear-error="errors[$event]= ''"
    />
    <div class="anchor"/>
  </div>
</template>

<script>
import Return from '../../components/base_components/Return'
import DictionaryFormModal from '../../components/dictionaries/modal/DictionaryFormModal'
import DictionaryTable
  from '../../components/dictionaries/shared/DictionaryTable'
import useList from '../../composables/useList'
import useDictionary from '../../composables/useDictionary'
import { dictionary } from '../../mixins/dictionary'

export default {
  name: 'DictionaryDisciplines',
  setup (props, { root }) {
    const {
      items, setItems, removeRow, findItemById,
    } = useList(root)
    const {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      updateResource,
    } = useDictionary(root)
    return {
      addResource,
      clearModel,
      destroyResource,
      editMode,
      editResource,
      errors,
      findItemById,
      items,
      removeRow,
      setItems,
      updateResource,
    }
  },
  components: {
    DictionaryFormModal,
    Return,
    DictionaryTable,
  },
  mixins: [dictionary],
  data () {
    return {
      params: {},
      discipline: {
        name: '',
        [Symbol.for('_innerName')]: 'discipline',
      },
    }
  },
  computed: {
    title () {
      if (this.editMode) {
        return this.$t('general.edit_discipline')
      } else {
        return this.$t('components.prk_dictionaries.new_discipline_title')
      }
    },
    btnTitle () {
      if (this.editMode) {
        return this.$t('general.update')
      } else {
        return this.$t('components.prk_modals.directional.add_discipline')
      }
    },
  },
  watch: {
    errors () {
      if (!Object.keys(this.errors).length) return
      this.$toastr.e(this.errors.base)
      this.errors = {}
    },
  },
  created () {
    this.fieldId = this.$route.params.fieldId
    this.getDisciplines()
  },
  methods: {
    addDiscipline () {
      this.$bvModal.show('add-modal')
      this.params = {
        fieldId: this.fieldId,
        discipline: this.discipline,
      }
    },

    async getDisciplines () {
      const params = { field_id: this.fieldId }
      let { data: disciplines } = await this.$api.get('disciplines', params)
      disciplines = disciplines.map(discipline => {
        return {
          id: discipline.id,
          ...discipline.attributes,
          [Symbol.for('_innerName')]: 'discipline',
        }
      })
      this.setItems(disciplines)
      this.setHeader()
    },

    setHeader () {
      this.$store.dispatch('header/setHeader', {
        title: this.$route.params.field,
      })
    },
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.setHeader()
    })
  },
}
</script>
