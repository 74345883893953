<template>
  <prk-table
      :items="filteredItems"
      :syllabus-header="true"
      :show-headline-index="false"
      :headline-index="section.number"
      :fields-props="{ tdClass: 'py-4' }"
      disable-actions
  >
    <template #headline-text>
      <div v-if="editMode"
           class="d-flex align-items-center justify-content-between">
        <div>{{ section.header }}</div>
        <prk-comment-modal-button
            modal-id="commentModal"
            :comment-count="section.commentCount"
            @input="setComments(section, 'SyllabusSection')"
        />
      </div>
    </template>

    <template v-slot:content="{ header, data }">
        <span :class="{ 'font-weight-bold': header.key === 'term' }">
          {{ data.item[header.key] }}
        </span>
    </template>
  </prk-table>
</template>
<script>
import PrkTable from '../../../PrkTable'
import PrkCommentModalButton from '../../../PrkCommentModalButton'
import { inject, ref } from '@vue/composition-api'
import useComments from '../../../../composables/useComments'

export default {
  name: 'subject-information-table',
  components: {
    PrkCommentModalButton,
    PrkTable,
  },
  setup (_, { root }) {
    const semesters = inject('semesters', ref([]))
    const editMode = inject('editMode', true)
    const status = inject('status')
    const { setComments } = useComments(root)
    semesters.value.sort((a, b) => a.semesterNumber - b.semesterNumber)
    return {
      semesters,
      status,
      editMode,
      setComments,
    }
  },
  props: {
    section: { required: true, type: Object },
  },
  computed: {
    filteredItems () {
      if (this.semesters.length && this.section.number === '1.1') {
        return this.semesters.map(semester => {
          return {
            semester: semester.semester,
            ...Object.fromEntries(this.getFormsWithHours(semester)),
            ects: Object.values(semester.subjectForms.map(form => form.ects)).reduce((t, n) => (t + n), 0),
          }
        })
      } else if (this.semesters.length && this.section.number === '1.3') {
        return this.semesters.map(semester => {
          return {
            semester: semester.semester,
            ...Object.fromEntries(this.getFormOfCredits(semester)),
          }
        })
      } else {
        return []
      }
    },
    subjectForms () {
      return this.semesters.map(sem => sem.subjectForms.map(sub => {
        sub.semesterId = sem.id
        return sub
      })).flat().map(s => [s.formOfClassName, s.formOfCreditName, s.numberOfHours, s.semesterId])
    },
    formNames () {
      return this.$_.uniq(this.subjectForms.map(form => form[0]))
    },
  },
  methods: {
    getFormsWithHours (sem) {
      const formsWithHours = []
      this.subjectForms.forEach(form => {
        let numberOfHours = form[2]
        if (form[3] !== sem.id) numberOfHours = '-'
        const index = formsWithHours.findIndex(fwh => fwh[0] === form[0])
        if (!~index) {
          formsWithHours.push([form[0], [numberOfHours]])
        } else {
          formsWithHours[index][1].push(numberOfHours)
        }
      })
      formsWithHours.forEach(fwh => {
        fwh[1] = this.mapHours(fwh[1])
      })
      return formsWithHours
    },
    mapHours (hours) {
      if (hours.every(hour => !Number.isInteger(hour))) {
        return '-'
      } else {
        return hours.filter(hour => Number.isInteger(hour)).join(', ')
      }
    },
    getFormOfCredits (sem) {
      const formWithFormOfCredits = []
      this.subjectForms.map(form => {
        let formOfCredit = form[1]
        if (form[3] !== sem.id) formOfCredit = '-'
        const index = formWithFormOfCredits.findIndex(fwfc => fwfc[0] === form[0])
        if (!~index) {
          formWithFormOfCredits.push([form[0], [formOfCredit]])
        } else {
          formWithFormOfCredits[index][1].push(formOfCredit)
        }
      })
      formWithFormOfCredits.forEach(fwfc => {
        fwfc[1] = this.mapFormOfCredits(fwfc[1])
      })
      return formWithFormOfCredits
    },
    mapFormOfCredits (formOfCredits) {
      if (formOfCredits.every(foc => foc === '-')) {
        return '-'
      } else {
        return formOfCredits.filter(foc => foc !== '-').join(', ')
      }
    },
  },
}
</script>
