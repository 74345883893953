<template>
  <div>
    <b-row class="my-3">
      <b-col cols="12" lg="auto">
        <div class="d-flex flex-column">
          <span class="prk-fz-12 mb-2">
            {{ $t('general.number_of_terms') }}
          </span>
          <prk-select
              v-model="selectedNumberOfTerms"
              :options="numberOfTerms"
              :default-option="numberOfTerms[0]"
              color="aqua"
              @input="setModified(true)"
          />
        </div>
      </b-col>
    </b-row>

    <b-tabs @input="$emit('change', semesters[$event].id)"
            @changed="setActive"
    >
      <b-tab
          v-for="(term, index) in this.semesters"
          :key="index"
          :title="getSemesterName(term)"
          @click="$emit('change', term.id)"
      />
    </b-tabs>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import PrkSelect from '../../PrkSelect'
import useSemesters from '../../../composables/useSemesters'

const { mapState, mapActions } = createNamespacedHelpers('semesters')

export default {
  name: 'NumberOfTerms',
  setup (_, { root }) {
    const { defaultNumberOfTerms } = useSemesters(root)
    return { defaultNumberOfTerms }
  },
  components: {
    PrkSelect,
  },
  props: {
    specialKind: { required: true, type: Boolean },
  },
  jsonapi: {
    semesters: {
      config: ({ studyPlanId }) => ({
        method: 'get',
        url: 'semesters',
        params: {
          study_plan_id: studyPlanId,
          serializer: 'form',
        },
      }),
      fetchPolicy: 'no-cache',
      variables () {
        return {
          studyPlanId: this.studyPlanId,
        }
      },
      skip () {
        return this.studyPlanId.length === 0
      },
      update (semesters) {
        return semesters.sort((a, b) => a.semesterNumber - b.semesterNumber)
      },
    },
  },
  data () {
    return {
      semesters: [],
      numberOfTerms: [],
      selectedNumberOfTerms: 1,
    }
  },
  computed: {
    ...mapState(['allSemesters']),
    studyPlanId () {
      return this.$store.getters['studyPlans/planId']
    },
  },
  watch: {
    allSemesters () {
      this.semesters = this.$_.cloneDeep(this.allSemesters)
    },
    specialKind: {
      immediate: true,
      handler () {
        if (this.specialKind) {
          this.numberOfTerms = this.defaultNumberOfTerms.slice(0, 1)
        } else {
          this.numberOfTerms = this.defaultNumberOfTerms
        }
      },
    },
  },
  created () {
    const unwatch = this.$watch('semesters', function () {
      this.setSemesters(this.$_.cloneDeep(this.semesters))
      this.selectedNumberOfTerms = this.$_.uniqBy(this.semesters, 'semesterNumber').length
      unwatch()
      this.$watch('selectedNumberOfTerms', function (newNumberOfTerms, oldNumberOfTerms) {
        const difference = newNumberOfTerms - oldNumberOfTerms
        if (difference > 1) {
          for (let i = 0; i < difference; i++) {
            this.addSemester()
          }
        } else if (difference >= 0) {
          this.addSemester()
        } else {
          this.semesters = this.$_.uniqBy(this.semesters, 'semesterNumber')
          this.semesters = this.semesters.slice(0, newNumberOfTerms)
        }
        this.setSemesters(this.semesters)
      })
    })

    if (this.studyPlanId.length) return

    this.addSemester()
    this.setSemesters(this.semesters)
  },
  methods: {
    ...mapActions(['setSemesters']),
    addSemester () {
      const semNumber = this.$_.uniqBy(this.semesters, 'semesterNumber').length + 1
      this.semesters.push({
        id: `${this.$uuidv4()}__new`,
        semesterNumber: semNumber,
        specialization: null,
        speciality: null,
        semesterType: this.$isEven(semNumber) ? 'summer' : 'winter',
      })
    },
    setModified () {
      this.$store.dispatch('studyPlans/setModified', true)
    },
    getSemesterName (term) {
      return `${this.$tc('general.term')} ${this.defaultNumberOfTerms[term.semesterNumber - 1].text}`
    },
    setActive (newTabs, oldTabs) {
      if (newTabs > oldTabs) return
      const index = newTabs.findIndex(tab => tab.$data.localActive)
      this.$emit('change', this.semesters[index].id)
    },
  },
}
</script>
