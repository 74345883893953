<template>
  <div class="description-container pb-3">
    <label class="pcg-input-label">{{ $t('components.prk_syllabus.section.description') }}</label>
    <prk-editor :value="$attrs.description"
                @input="$emit('update:description', $event)"/>
  </div>
</template>
<script>
import PrkEditor from '../../../PrkEditor'
export default {
  name: 'PrkDescription',
  components: { PrkEditor },
}
</script>
<style scoped lang="scss">
.description-container {
  margin-top: 1.8rem;
}
</style>
