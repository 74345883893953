<template>
  <div class="br-5 mb-5">
    <pcg-box class="literature">
      <template v-for="(type, index) in literatureTypes">
        <literature-section
            :key="index"
            :status="status"
            :supplementary="index === 1"
            @set:supplementary="supplementary = $event">
          <template>
            <div class="d-flex align-items-center justify-content-between">
              <span>
                {{ $t(`general.${type}_literature`) }}
              </span>
            </div>
          </template>
        </literature-section>
      </template>
    </pcg-box>
    <add-literature-modal :supplementary="supplementary"/>
  </div>
</template>

<script>
import LiteratureSection from './LiteratureSection'
import AddLiteratureModal from './AddLiteratureModal'
import { inject } from '@vue/composition-api'

export default {
  name: 'Literature',
  setup () {
    const status = inject('status')
    return { status }
  },
  inheritAttrs: false,
  components: {
    AddLiteratureModal,
    LiteratureSection,
  },
  props: {
    section: { required: true, type: Object },
    user: { required: true, type: Object },
  },
  data () {
    return {
      literatureTypes: ['basic', 'supplementary'],
      supplementary: false,
    }
  },
}
</script>

<style scoped lang="scss">
::v-deep {
  &.literature.box-container {
    margin-bottom: 0;
    padding-right: 0;
    padding-left: 0;
    box-shadow: none;
  }
}
</style>
